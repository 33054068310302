import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Row, Alert, ButtonGroup } from "react-bootstrap";
import { Icon, Label, Image, Radio } from 'semantic-ui-react'
import {validateEmail, validateText, formatRut, validatePhone } from '../../libs/validation';
import { sortByKeyDesc } from '../../libs/arrays';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import LoaderButton from "../../components/LoaderButton";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import ResidentDetailModal from "./ResidentDetailModal";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ListResidentSummary.css';
import config from "../../config";

export default class ListResidentSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
          showPicture: false,
          disableEdit: true,
          isSendingValidationEmail: false,
          isDisablesSendingEmail: false,
          isDeleting: false,
          isEditing: false,
          isLoading: false,
          isError: false,
          showDetails: false,
          showDelete: false,
          showSendEmailValidation: false,
          showModalDisableSendEmail: false,
          showEdit: false,
          showResident: false,
          showAlertSuccess: false,
          showAlertError: false,
          alertMessage: '',
          deleteCandidate: null,
          actionCandidateIndex: -1,
          residentCandidate: null,
          sendEmailCandidate: null,
          disableSendEmailCandidate: null,
          editCandidate: null,
          resident_picture_type: 'CURRENT_PIC',
          resident_picture: '',
          card_id:'',
          card_id_type: '',
          name:'',
          lastname:'',
          email: '',
          phone: '',
          is_owner: false,
          isEditNewEmail: false,
          residents: []
        };
    }

    componentDidMount() {
      this.findResidents();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findResidents();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findResidents = async () => {
      this.setState({ isLoading: true, residents: [] });
      try {
        const results = await this.residents();
        const residents = sortByKeyDesc(results.residents, "unit")
        this.setState({ residents });
        this.props.onHandleSize(residents);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    residents() {
      return invokeApig({
        path: "/resident/find",
        method: "POST",
        body: {
            "all": "P"
        }
      });
    }

    deleteResident(residents) {
      return invokeApig({
        path: "/resident",
        method: "DELETE",
        body: {
          "unitId": this.state.deleteCandidate.unit,
          "card_id": this.state.deleteCandidate.card_id
        }
      });
    }

    updateResident(resident) {
      return invokeApig({
        path: `/resident`,
        method: "PUT",
        body: resident
      });
    }

    sendValidationEmail() {
      return invokeApig({
        path: `/resident/send`,
        method: "POST",
        body: {
          "unitId": this.state.sendEmailCandidate.unit,
          "card_id": this.state.sendEmailCandidate.card_id
        }
      });
    }

    disableSendmail() {
      return invokeApig({
        path: `/resident/send`,
        method: "PUT",
        body: {
          "unitId": this.state.disableSendEmailCandidate.unit,
          "card_id": this.state.disableSendEmailCandidate.card_id
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener encomiendas. Intenta más tarde." : "No hay datos";
      }
    }

    handleClose = () => {
      this.setState({ showDelete: false, deleteCandidate: null, actionCandidateIndex: -1,
        showEdit: false, editCandidate: null, showPicture: false, showDetails: false,
        showResident: false, residentCandidate: null, resident_picture: '', resident_picture_type: 'CURRENT_PIC',
        showSendEmailValidation: false, sendEmailCandidate: null,
        showModalDisableSendEmail: false, disableSendEmailCandidate: null });
    }

    handleDisablesSendEmail = async event => {
      event.preventDefault();
      this.setState({ isDisablesSendingEmail: true });
      try {
        const response = await this.disableSendmail();
        this.setState({ isDisablesSendingEmail: false, showModalDisableSendEmail: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al desactivar envío de correos a residente!" });
        } else {

          var resident = this.state.residents[this.state.actionCandidateIndex];
          resident.email_status = "DISABLED"

          this.handleAlertSuccess({ message: "Desactivación de envío de correos exitosa!" });
        }
      } catch (e) {
        this.setState({ isDisablesSendingEmail: false, showModalDisableSendEmail: false, showAlertError: true, alertMessage: "Error al enviar correo a residente!!" });
      }
    }

    handleEmailValidation = async event => {
      event.preventDefault();
      this.setState({ isSendingValidationEmail: true });
      try {
        const response = await this.sendValidationEmail();
        this.setState({ isSendingValidationEmail: false, showSendEmailValidation: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al enviar correo a residente!" });
        } else {

          var resident = this.state.residents[this.state.actionCandidateIndex];
          resident.email_status = "PENDING"

          this.handleAlertSuccess({ message: "Correo enviado correctamente!" });

        }
      } catch (e) {
        this.setState({ isSendingValidationEmail: false, showSendEmailValidation: false, showAlertError: true, alertMessage: "Error al enviar correo a residente!!" });
      }
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteResident();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar residente!" });
        } else {

          this.state.residents.splice(this.state.actionCandidateIndex, 1);
          this.props.reloadResidents();

          this.handleAlertSuccess({ message: "Residente eliminado correctamente!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar residente!" });
      }
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({showAlertSuccess:true},()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.size === this.state.editCandidate.size
            && this.state.name === this.state.editCandidate.name
            && this.state.age === this.state.editCandidate.age
            && this.state.color === this.state.editCandidate.color;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '', name:'',
        lastname:'', email:'', phone:'', is_owner:false,
        showError: false, errorMessage: '', resident_picture: '',
        resident_picture_type: 'CURRENT_PIC',});
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.lastname)) {
        message = 'Debe ingresar apellido';
        return message;
      }

      if (this.state.email && this.state.email !== '-' && !validateEmail(this.state.email)) {
        message = 'Debe ingresar email válido';
        return message;
      }

      if (this.state.phone && this.state.phone !== '-' && !validatePhone(this.state.phone)) {
        message = 'Debe ingresar teléfono válido (Formato: +56)';
        return message;
      }

      return message;
    }

    residentCellFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.lastname) !== 'undefined' ? cell.name + " " + cell.lastname: "";
    }

    residentFormatter = (resident) => {
      return resident
      && typeof(resident.name) !== 'undefined'
      && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
    }

    ownerFormatter = (cell, row) => {
      return cell && cell === true?
        <Label color="green">SI</Label>
        :
        <Label color="red">NO</Label>
    }

    emailStatusFormatter= (cell, row) => {
      let color = cell === 'ACTIVE' ? 'green': 'red'
      if (typeof(row.has_email) !== 'undefined' && row.has_email) {
        let status = this.props.emailStatus[cell];
        return <Label color={color} className={color}>{status}</Label>;
      }

      return '-';
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);
      return <Link to={{}} onClick={this.showDetails.bind(cell, row)}>{id}</Link>;
    }

    unitFormatter = (cell, row) => {
      return <Link to={{}} onClick={this.showDetails.bind(cell, row)}>{cell}</Link>;
    }

    showDetails = (cell, row, rowIdx) => {
      this.setState({ showDetails: true, residentCandidate: cell });
    }

    showEditResident = (cell, row, index, enumObject) => {
      this.setState({ isLoading: false,
        card_id: cell.card_id,
        card_id_type: cell.card_id_type ? cell.card_id_type : 'RUT',
        name: cell.name, lastname: cell.lastname,
        is_owner: cell.is_owner,
        email: cell.email,
        phone: cell.phone,
        showEdit: true, editCandidate: cell
      });
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setState({ showDelete: true, actionCandidateIndex: index, deleteCandidate: cell, unit: cell.unit });
    }

    showSendValidation = (cell, row, index, enumObject) => {
      this.setState({ showSendEmailValidation: true, actionCandidateIndex: index, sendEmailCandidate: cell });
    }

    showDisableSendEmail = (cell, row, index, enumObject) => {
      this.setState({ showModalDisableSendEmail: true, actionCandidateIndex: index, disableSendEmailCandidate: cell });
    }

    showResidentPicture = (cell, row, rowIdx) => {
      this.setState({ showPicture: true, residentCandidate: cell });
    }

    drawButton = (cell, row, index, enumObject) => {
      return <ButtonGroup className="actionsBtn">

              {  row['email_status'] !== 'ACTIVE' && row['email'] && row['email'] !== '-' &&
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Envía correo de verificación</Tooltip>}>
                  <Button bsStyle="success" onClick={this.showSendValidation.bind(cell, row, index, enumObject)} className="btnSendValidation">
                    <Glyphicon glyph="envelope" />
                  </Button>
                </OverlayTrigger>
              }
              {  row['email_status'] === 'ACTIVE' &&
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Desactiva las notificaciones de correo</Tooltip>}>
                  <Button bsStyle="warning" onClick={this.showDisableSendEmail.bind(cell, row, index, enumObject)} className="btnDisableSendValidation">
                    <Glyphicon glyph="volume-off" />
                  </Button>
                </OverlayTrigger>
              }
              {/*
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
                <Button bsStyle="warning" onClick={this.showEditResident.bind(cell, row, index, enumObject)} className="btnEditResident">
                  <Glyphicon glyph="edit" />
                </Button>
              </OverlayTrigger>
              */}
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
               <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, index, enumObject)} className="btnDeleteResident">
                 <Glyphicon glyph="trash" />
               </Button>
              </OverlayTrigger>

              {this.props.condo && this.props.condo.picture.resident && typeof(row.resident_picture_url) !== 'undefined' &&
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
                <Button bsStyle="primary" onClick={this.showResidentPicture.bind(cell, row)} className="btnShowVisitor">
                  <Glyphicon glyph="camera" />
                </Button>
              </OverlayTrigger>
              }
             </ButtonGroup>;
    }

    render(){

        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };

        const data = this.state.residents;

        return(
          <div className="Resident">
          {
            this.state.showAlertSuccess &&
            <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          {
            this.state.showAlertError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          <Col sm={12} className="residents">
          <BootstrapTable
            pagination
            data={ data }
            selectRow={ selectRowProp }
            options={ tableOptions }
            hover>
            <TableHeaderColumn dataField='unit' dataFormat={ this.unitFormatter } filter={ { type: 'TextFilter', placeholder: 'Ingresa unidad', delay: 10 } } dataSort={ true } ><Glyphicon glyph="home" /> Unidad</TableHeaderColumn>
            <TableHeaderColumn dataField='card_id' dataFormat={ this.actionsFormatter } isKey filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } } dataSort={ true } >RUT (u otro)</TableHeaderColumn>
            <TableHeaderColumn dataField='name' filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } } dataSort={ true }>Nombre</TableHeaderColumn>
            <TableHeaderColumn dataField='lastname' filter={ { type: 'TextFilter', placeholder: 'Ingrese apellido', delay: 10 } } dataSort={ true }>Apellido</TableHeaderColumn>
            <TableHeaderColumn headerAlign='center' dataAlign='center' dataField='is_owner' dataFormat={ this.ownerFormatter } dataSort={ true } >Dueño</TableHeaderColumn>
            <TableHeaderColumn dataField='email' filter={ { type: 'TextFilter', placeholder: 'Ingrese email', delay: 10 } } dataSort={ true }><Icon name="mail" /> Email</TableHeaderColumn>
            <TableHeaderColumn dataField='email_status' headerAlign='center' dataAlign='center' dataFormat={ this.emailStatusFormatter } dataSort={ true } >Envío de email</TableHeaderColumn>
            <TableHeaderColumn dataField='phone' headerAlign='center' dataAlign='center' filter={ { type: 'TextFilter', placeholder: 'Ingrese teléfono', delay: 10 } } dataSort={ true }><Icon name="phone" />Teléfono</TableHeaderColumn>
            <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} dataField='special_condition' headerAlign='center' filter={ { type: 'TextFilter', placeholder: 'Ingresa condición', delay: 10 } } dataSort={ true }><Icon name="wheelchair" />Condición especial</TableHeaderColumn>
            <TableHeaderColumn hidden={this.props.profile === 'Comite'} headerAlign='center' dataAlign='center' dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
          </BootstrapTable>
            {
            	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más mascotas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
            }
          </Col>
          <Modal show={this.state.showModalDisableSendEmail} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.state.disableSendEmailCandidate && this.state.disableSendEmailCandidate.unit}</Label>
              <Label color='red'>DESACTIVACIÓN</Label> Desactivación de envío de correos</Modal.Title>
            </Modal.Header>
            { this.state.disableSendEmailCandidate &&
              <Modal.Body>
                <p>¿Está seguro que quiere desactivar el envio de correos de encomiendas a <Label color='red'>{this.residentFormatter(this.state.disableSendEmailCandidate)}</Label>?</p>
                <p className="psmall"><b>Nota:</b> Avisar a residente que puede volver a activar notificaciones de encomiendas.</p>
              </Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDisablesSendingEmail} onClick={this.handleDisablesSendEmail} text="Desactivar" loadingText="Desactivando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showSendEmailValidation} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='green'><Icon name='building' />{this.state.sendEmailCandidate && this.state.sendEmailCandidate.unit}</Label>
              <Label color='green'>VERIFICACIÓN</Label> Envío de correo de verificación</Modal.Title>
            </Modal.Header>
            { this.state.sendEmailCandidate &&
              <Modal.Body>
                <p>¿Está seguro que quiere enviar correo de verificación a <Label color='green'>{this.residentFormatter(this.state.sendEmailCandidate)}</Label>?</p>
                <p className="psmall"><b>Nota:</b> Avisar a residente que recibirá un link de verificación en su correo (<b>{this.state.sendEmailCandidate.email}</b>), al cual debe acceder.</p>
              </Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="success" isLoading={this.state.isSendingValidationEmail} onClick={this.handleEmailValidation} text="Enviar" loadingText="Enviando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showDelete} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.state.deleteCandidate && this.state.deleteCandidate.unit}</Label>
              <Label color='red'>ELIMINACIÓN</Label> Eliminar residente</Modal.Title>
            </Modal.Header>
            { this.state.deleteCandidate &&
              <Modal.Body>¿Está seguro que quiere eliminar residente <Label color='red'>{this.residentFormatter(this.state.deleteCandidate)}</Label>?</Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='orange'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='orange'>EDICIÓN</Label> Datos del residente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.editCandidate &&
                this.renderFormEdit()
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showDetails} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Label color='blue'>
                <Icon name='building' />{this.props.residentCandidate && this.residentCandidate.unit}</Label> Detalle de residente
              </Modal.Title>
            </Modal.Header>
            <ResidentDetailModal {...this.props} cell={this.state.residentCandidate}/>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='blue'><Icon name='user' />Nombre:</Label> {this.state.residentCandidate && this.residentFormatter(this.state.residentCandidate)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.residentCandidate &&
                <Image
                  className="resident_picture_modal"
                  src={config.s3_files.BUCKET + this.state.residentCandidate.resident_picture_url} />
              }
            </Modal.Body>
          </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="card_id">
            <Col componentClass={ControlLabel} sm={3}>
            <Radio
              label='RUT '
              name='radioGroup'
              value='RUT'
              disabled
              checked={this.state.card_id_type === 'RUT'}
            />
            <Radio
              label='Otro'
              name='radioGroup'
              value='OTHER'
              disabled
              checked={this.state.card_id_type === 'OTHER'}
            />
            </Col>
            <Col sm={8}>
              <FormControl
              disabled
  	          onChange={this.handleChange}
  	          value={this.state.card_id}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="name">
            <Col componentClass={ControlLabel} sm={3}>
              Nombre (*)
            </Col>
            <Col sm={8}>
              <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="ej: Diego"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="lastname">
            <Col componentClass={ControlLabel} sm={3}>
              Apellido (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.lastname}
                placeholder="ej: Salas"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="email">
            <Col componentClass={ControlLabel} sm={3}>
              Email
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.email}
                placeholder="residente@condominio.com (opcional)"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="phone">
            <Col componentClass={ControlLabel} sm={3}>
              Teléfono
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.phone}
                placeholder="+56981000000 (opcional)"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Teléfono.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="is_owner">
            <Col componentClass={ControlLabel} sm={3}>
              Es dueño?
            </Col>
            <Col sm={1}>
              <FormControl
                onChange={this.handleChange}
                checked={this.state.is_owner}
                type='checkbox'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Es dueño?</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {this.props.condo && this.props.condo.picture.resident &&
            <FormGroup controlId="resident_picture_id">
              <Col componentClass={ControlLabel} sm={3}>
              <Radio
                label='Foto actual'
                name='radioGroup'
                value='CURRENT_PIC'
                checked={this.state.resident_picture_type === 'CURRENT_PIC'}
                onChange={this.handleChangeRadio}
              />
              <Radio
                label='Foto nueva'
                name='radioGroup'
                value='NEW_PIC'
                checked={this.state.resident_picture_type === 'NEW_PIC'}
                onChange={this.handleChangeRadio}
              />
              </Col>
              { this.state.resident_picture_type === 'NEW_PIC' &&
                  <React.Fragment>
                  <Col sm={4}>
                    <Webcam
                      audio={false}
                      height={config.picture.HEIGHT_AVATAR}
                      width={config.picture.WIDTH_AVATAR}
                      screenshotFormat={config.picture.FORMAT}
                      videoConstraints={config.picture.CONSTRAINS_AVATAR}
                    />
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col md={12}>
                        <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
                      </Col>
                    </Row>
                    {this.state.resident_picture &&
                    <React.Fragment>
                      <Row>
                      <Col md={12}>
                        <p className="resident_picture_previous_text">Previa:</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <img className="resident_picture_previous" alt="Previa" src={this.state.resident_picture} />
                      </Col>
                    </Row>
                    </React.Fragment>
                    }
                  </Col>
                  </React.Fragment>
              }
              { this.state.resident_picture_type === 'CURRENT_PIC' &&
                <Col sm={8}>

                <Image
                  className="resident_picture_avatar"
                  src={this.state.editCandidate.resident_picture_url ? config.s3_files.BUCKET + this.state.editCandidate.resident_picture_url: "pic_not_found.png"} />
                </Col>
              }
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de residente.</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
          }
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
