import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import VisitorDetailModal from "./VisitorDetailModal";
import moment from 'moment';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import { formatRut } from '../../libs/validation';

export default class ListVisitorBlocked extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            detailCandidate: null,
            deleteCandidate: null,
            showDetails: false,
            showDelete: false,
            isDeleting: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            visitors: []
        };
    }

    componentDidMount() {
      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedUnit !== this.props.selectedUnit) {
        this.findVisitors();
      }
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        if(this.props.selectedUnit){
          const results = await this.visitors();
          const visitors = sortByKey(results.visitors, "created_date")
          this.setState({ visitors: visitors });
        }
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteVisitor();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar visita!" });
        } else {
          this.handleAlertSuccess({ message: "Visita eliminada correctamente!" });
          this.findVisitors();
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar encomienda!" });
      }
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '',
        name:'', lastname: '', status: '',
        showError: false, errorMessage: ''
      });
    }

    visitors() {
      return invokeApig({
        path: "/blacklist/find",
        method: "POST",
        body: {
            "unitId": this.props.selectedUnit["unit"]
        }
      });
    }

    deleteVisitor() {
      return invokeApig({
        path: "/blacklist",
        method: "DELETE",
        body: {
          "unitId": this.props.selectedUnit["unit"],
          "card_id": this.state.deleteCandidate.card_id
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);
      return id;
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    drawButton = (cell, row, rowIdx) => {
      return <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, rowIdx)} className="btnDeleteDelivery">
                <Glyphicon glyph="trash" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>;
    }

    showConfirmDelete = (cell, row, rowIdx) => {
      this.setState({ showDelete: true, deleteCandidate: cell });
    }

    showDetails = (cell, row, rowIdx) => {
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleClose = () => {
      this.setState({
        showDelete: false, deleteCandidate: null,
        showDetails: false, detailCandidate: null });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        //...
      });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }>
                <TableHeaderColumn
                  isKey
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'>Nombre</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese aapellido', delay: 10 } }
                  dataField='lastname'>Apellido</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Fecha creación
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_creates'
                  dataFormat={ this.userFormatter }>Conserje</TableHeaderColumn>
                <TableHeaderColumn
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='red'>ELIMINACIÓN</Label> Eliminar de lista negra</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>¿Está seguro que quiere eliminar a <Label color='red'>{this.visitorFormatter(this.state.deleteCandidate)}</Label> de la lista negra?
                <p></p><p className="psmall">
                <b>Nota:</b> Eliminar de la lista negra implica que el residente permite el acceso futuro de esta persona a su unidad.
                </p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }

}
