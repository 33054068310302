import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from 'semantic-ui-react'
import ReactExport from "react-excel-exportz";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ResidentExport extends Component {

  render() {
      return (
        <ExcelFile
          filename="residentes"
          element={
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Exportar a excel</Tooltip>}>
              <Button bsStyle="success" disabled={!this.props.data.length}><Icon fitted name="download" /></Button>
            </OverlayTrigger>
          }>
            <ExcelSheet data={this.props.data} name="Residentes">
                <ExcelColumn label="Unidad" value="unit"/>
                <ExcelColumn label="Rut (u otro)" value="card_id"/>
                <ExcelColumn label="Nombre"
                             value={(col) => col.name + " " + col.lastname}/>
                <ExcelColumn label="Dueño"
                             value={(col) => col.is_owner ? "Si" : "No"}/>
                <ExcelColumn label="Email" value="email"/>
                <ExcelColumn label="Teléfono" value="phone"/>
                <ExcelColumn label="Condición especial" value="special_condition"/>
            </ExcelSheet>
        </ExcelFile>
      );
  }
}
