import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from 'semantic-ui-react'
import ReactExport from "react-excel-exportz";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class VisitorReportExport extends Component {

  dateFormatter = (cell) => {
    if (cell) {
      const date = moment(cell);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  simpleFormatter = (cell) => {
    return cell ? cell : "-";
  }

  unitFormatter = (cell) => {
    return cell === "provider"? "Proveedor": cell;
  }

  timeFormatter = (cell, row) => {
    if (cell) {
      let hours = parseInt(cell / 60, 10).toString();
      let minutes = parseInt(cell % 60, 10).toString();
      let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
      return exceeded_time;
    }
  }

  userFormatter = (cell) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  render() {
      return (
        <ExcelFile
          filename={this.props.filename}
          element={
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Exportar a excel</Tooltip>}>
              <Button bsStyle="success" disabled={!this.props.data.length}><Icon fitted name="download" /></Button>
            </OverlayTrigger>
          }>
            <ExcelSheet data={this.props.data} name="Visitas">
              <ExcelColumn label="Unidad"
                           value={(col) => this.unitFormatter(col.unit)}/>
              <ExcelColumn label="Rut (u otro)" value="card_id"/>
              <ExcelColumn label="Nombre"
                           value={(col) => col.name + " " + col.lastname}/>
              <ExcelColumn label="Entrada"
                           value={(col) => this.dateFormatter(col.created_date)}/>
              <ExcelColumn label="Conserje entrada"
                           value={(col) => this.userFormatter(col.user_who_receives)}/>
              <ExcelColumn label="Salida"
                           value={(col) => this.dateFormatter(col.departure_date)}/>
              <ExcelColumn label="Conserje salida"
                           value={(col) => this.userFormatter(col.user_who_departures)}/>
              <ExcelColumn label="Patente" value="car_license_plate"/>
              <ExcelColumn label="Estacionamiento" value="car_park"/>
              <ExcelColumn label="Total"
                           value={(col) => this.timeFormatter(col.elapsed_time)}/>
              <ExcelColumn label="Excedido"
                           value={(col) => this.timeFormatter(col.exceeded_time)}/>
              <ExcelColumn label="Folio"
                           value={(col) => this.simpleFormatter(col.penalty_payment_info)}/>
              <ExcelColumn label="Motivo"
                           value={(col) => this.simpleFormatter(col.penalty_skip_reason)}/>
            </ExcelSheet>
        </ExcelFile>
      );
  }
}
