import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { invokeApig } from "../../libs/awsLib";
import "./NewUser.css";
import {validateEmail, validateText, validatePhone} from '../../libs/validation';
import { Link } from "react-router-dom";

export class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isLoadEdit: false,
      isEdit: props.username ? true : false,
      show: false,
      email:'',
      name:'',
      lastName: '',
      phoneNumber: '',
      profile: 'Operador',
      status: '',
      enabled: false,
      showError: false,
      errorMessage: ''
    };
  }

  validateForm() {
	  return this.state.email.length > 0 && this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  createUser(user) {
    return invokeApig({
      path: "/user",
      method: "POST",
      body: user
    });
  }

  updateUser(user) {
    return invokeApig({
      path: `/user`,
      method: "PUT",
      body: user
    });
  }

  //Método que Obtiene los datos del propietario seleccionado por id
  getUser() {
    return invokeApig({
      path: `/user/${this.props.username}`
    });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '' });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleEdit = async event =>{
    event.preventDefault();
    try {
      this.setState({ isLoadEdit: true });
      const results = await this.getUser();
      this.setState({ ...results.data, show: true, isLoadEdit: false });
    } catch (e) {
      this.props.onAlertError({ message: "Error al consultar datos del usuario!" });
      this.setState({ isLoadEdit: false });
    }
  }

  getErrors() {
    let message = undefined;
    if (!validateEmail(this.state.email)) {
      message = 'Debe ingresar el email correctamente';
    } else {
      if (!validateText(this.state.name)) {
        message = 'Debe ingresar el nombre correctamente';
      } else {
        if (!validateText(this.state.lastName)) {
          message = 'Debe ingresar el apellido correctamente';
        } else {
          if (this.state.phoneNumber && !validatePhone(this.state.phoneNumber)) {
            message = 'Debe ingresar el teléfono correctamente: +01234567890';
          }
        }
      }
    }
    return message;
  }

  resetForm = () => {
    this.setState({ email:'', name:'', lastName: '', phoneNumber: '',
      profile: 'Operador', status: '', enabled: false, showError: false, errorMessage: ''
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const user = {
        email: this.state.email,
        name: this.state.name,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        profile: this.state.profile,
        status: this.state.status
      };
      if (this.state.isEdit) {
        const response = await this.updateUser(user);
        if (response.errorMessage) {
          this.props.onAlertError({ message: "Error al actualizar usuario! Por favor revise los datos e intente nuevamente." });
        } else {
          this.resetForm();
          this.props.onAlertSuccess({ message: "Usuario actualizado exitosamente!" }, user);
        }
      } else {
        const response = await this.createUser(user);
        if (response.errorMessage) {
          let msgExtra = 'Por favor revise los datos e intente nuevamente.'
          if (response.errorType === 'UsernameExistsException') {
            msgExtra = 'Usuario ya existe.'
          } else {
            if (response.errorType === 'InvalidParameterException') {
              if (response.errorMessage.indexOf('Invalid phone number format') !== -1) {
                msgExtra = 'El formato del número de teléfono es incorrecto, debe ser del tipo +01234567890.'
              }
            }
          }
          this.props.onAlertError({ message: "Error al crear usuario! " + msgExtra });
        } else if (response.code === 401) {
          this.props.onAlertError({ message: "Atención: " + response.message });
        } else {
          this.resetForm();
          this.props.onAlertSuccess({ message: "Usuario ingresado exitosamente! Se ha enviado un correo con una clave temporal a " + user.email, refresh: true }, user);
        }
      }
      this.handleClose();
    } catch (e) {
      this.props.onAlertError({ message: "Error al guardar usuario! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  statusFormatter = () => {
    if (this.state.status === 'Activo' && !this.state.enabled) {
        return "Inactivo";
    }
    return this.state.status;
  }

  renderFormNew(){
    return(
	  <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="email">
          <Col componentClass={ControlLabel} sm={3}>
            Email (*)
          </Col>
          <Col sm={8}>
            <FormControl
            disabled={this.state.isEdit}
	          onChange={this.handleChange}
	          value={this.state.email}
	          placeholder="usuario@dominio.com"
	        />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email del usuario.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="Ingrese solo texto"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre del usuario.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastName">
  	      <Col componentClass={ControlLabel} sm={3}>
  	        Apellido (*)
  	      </Col>
  	      <Col sm={8}>
  	        <FormControl
  	          onChange={this.handleChange}
  	          value={this.state.lastName}
  	          placeholder="Ingrese solo texto"
  	        />
  	  	  </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido del usuario.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
  	    </FormGroup>
        <FormGroup controlId="phoneNumber">
  	      <Col componentClass={ControlLabel} sm={3}>
  	        Teléfono
  	      </Col>
  	      <Col sm={8}>
  	        <FormControl
  	          onChange={this.handleChange}
  	          value={this.state.phoneNumber}
  	          placeholder="+01234567890"
  	        />
  	  	  </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de teléfono.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
  	    </FormGroup>
        <FormGroup controlId="profile">
          <Col componentClass={ControlLabel} sm={3}>
            Perfil (*)
          </Col>
          <Col sm={8}>
            <FormControl componentClass="select" onChange={this.handleChange} value={this.state.profile} >
              <option value="Operador">Conserje</option>
              <option value="Supervisor">Administrador</option>
              <option value="Comite">Comité</option>
            </FormControl>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al perfil del usuario.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="status">
          <Col componentClass={ControlLabel} sm={3}>
            Estado (*)
          </Col>
          <Col sm={8}>
            <FormControl componentClass="select" onChange={this.handleChange} value={this.statusFormatter()} >
              <option value="Sin confirmar" disabled>Selecciona...</option>
              <option value="Activo">Activo</option>
              <option value="Inactivo">Inactivo</option>
              <option value="Deshabilitado" disabled>Deshabilitado</option>
              <option value="Con problemas" disabled>Con problemas</option>
              <option value="Debe cambiar clave" disabled>Debe cambiar clave</option>
              <option value="Desconocido" disabled>Desconocido</option>
            </FormControl>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al estado actual.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={this.state.isEdit ? "Modificar" : "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }

  render() {
    return (
      <div className="userBtn">
      {
        this.state.isEdit ?
        <Button bsStyle="primary" onClick={this.handleEdit}>
          {
            this.state.isLoadEdit ?
            <Glyphicon glyph="refresh" className="spinning" />
            :
            <div>
              <Glyphicon glyph="edit" />Editar
            </div>
          }
        </Button>
        :
		    <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user" />Crear usuario</Button>
      }
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos del usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default NewUser
