import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from 'semantic-ui-react'
import ReactExport from "react-excel-exportz";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class DeliveryExport extends Component {

  dateFormatter = (cell) => {
    if (cell) {
      const date = moment(cell);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  render() {
      return (
        <ExcelFile
          filename="encomiendas-pendientes"
          element={
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Exportar a excel</Tooltip>}>
              <Button bsStyle="success"><Icon fitted name="download" /></Button>
            </OverlayTrigger>
          }>
            <ExcelSheet data={this.props.data} name="Residentes">
                <ExcelColumn label="Unidad" value="unit"/>
                <ExcelColumn label="Código barra" value="barcode"/>
                <ExcelColumn label="Descripción" value="description"/>
                <ExcelColumn label="Tipo"
                             value={(col) => this.props.deliveryType[col.type]}/>
                <ExcelColumn label="Fecha recepción"
                             value={(col) => this.dateFormatter(col.created_date)}/>
                <ExcelColumn label="Conserje"
                             value={(col) => col.user_who_receives.name + " " + col.user_who_receives.familyName}/>
            </ExcelSheet>
        </ExcelFile>
      );
  }
}
