import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Visitor.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListVisitorDaily from "./ListVisitorDaily";
import NewVisitor from "./NewVisitor";
import NewVisitorCountry from "./NewVisitorCountry";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default class VisitorDaily extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      runJoy: false,
      selectedDate: new Date(),
      steps: [
        {
          target: '.Visitor .visitors',
          content: 'En este listado se muestran las visitas que ingresaron en vehículo, que superaron el tiempo máximo permitido y que fueron multadas',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table a',
          content: 'Puedes ver el detalle de una visita haciendo click en el enlace',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message, closable) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false },()=>{
      if (closable) {
        window.setTimeout(()=>{this.setState({showAlertError:false})}, 3000)
      }
    });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#' });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleSize = (sizeValue) => {
      this.setState({size: sizeValue});
  }

  handleChange = (date) => {
    if (date === null || date === "") {
      date = new Date();
    }
    this.setState({ selectedDate: date });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy, size } = this.state;
    return (
      <div className="Visitor">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Visitas del día</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      <span className="pheader">({size}) Visitas del día</span>
	    </PageHeader>
      <ButtonGroup>
        {this.props.condo
          && this.props.condo.visitor
          && this.props.condo.visitor.country
            ? <NewVisitorCountry {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
            : <NewVisitor {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
          }
      </ButtonGroup>
      <ButtonGroup>
        <Link to="/visitor/summary">
           <Button variant="secondary" ><Glyphicon glyph="link"/> ir a Visita en vehículo</Button>
       </Link>
      </ButtonGroup>
      <ButtonGroup>
        <DatePicker
          className="date-picker"
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}/>
        <Button onClick={this.handleUpdate} className="btnRefresh">
          <Glyphicon glyph="refresh" />
        </Button>
      </ButtonGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListVisitorDaily {...this.props}
          selectedDate={this.state.selectedDate}
          onHandleSize={this.handleSize}
          isLoading={this.state.isLoading}
          onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
