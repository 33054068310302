import React, { Component } from "react";
import { Breadcrumb, Glyphicon, Row, Col,
  Grid, Tooltip, OverlayTrigger } from "react-bootstrap";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import { getUserAttributes } from "../libs/awsLib";
import './Home.css';
import PublicHome from './PublicHome';
import GridMenu from './menu/GridMenu';

import store from '../state/Store'

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowManual: false,
      isLoading: true,
      isError: false,
      runJoy: false,
      email: null,
      name: null,
      lastname: null,
      profile: null,
      showVideo: false,
      units: [],
      steps: [
        {
          target: '.unit-select-menu',
          content: 'Comienza seleccionando la unidad de interés',
          placement: 'bottom',
        },
        {
          target: '.concierge-menu',
          content: 'Se activará el menu de conserjería, en el que puedes ver: encomiendas, visitas, residentes y mascotas de la unidad selecciona',
          placement: 'bottom',
        },
        {
          target: '.summary-menu',
          content: 'En este menú puedes ver toda la información de resumen del condominio. Todas las encomiendas recibidas, todas las visitas en vehículo del momento y todas las mascotas',
          placement: 'bottom',
        },
        {
          target: '.booking-menu',
          content: 'Puedes reservar un espacio común',
          placement: 'bottom',
        },
        {
          target: '.book-menu',
          content: 'Puedes registrar eventos importantes que suceden en el condominio',
          placement: 'bottom',
        },
        {
          target: '.provider-menu',
          content: 'Puedes registrar visitas de proveedores',
          placement: 'bottom',
        },
        {
          target: '.manual-btn',
          content: '¿Tienes dudas? Revisa el manual en línea',
          placement: 'bottom',
        },
        {
          target: '.menu-btn',
          content: '¿Quieres cambiar la posición del menú. Horizontal o vertical. Adelante!!',
          placement: 'bottom',
        },{
          target: '.grid-summary-menu',
          content: 'Este menú representa una vista resumida de las opciones más usadas de DeptoGestion. Empecemos!!!',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ email: user.email, name: user.name, lastName: user.family_name, profile: user.profile });
    this.props.onCheckPaymentAdvice(null);
  }

  handlePaymentAdvice = () => {
    this.props.onCheckPaymentAdvice(null);
  }

  handleClose = () => {
    this.setState({ showVideoChecklist: false, showVideoForm: false, showVideoTalk: false });
  }
  handleVideo = (e) => {
    e.preventDefault();
    this.setState({ showVideo: true });
  }
  handleCloseVideo = () => {
    this.setState({ showVideo: false });
  }

  handleHelp = () => {
    this.setState({runJoy: true});
  }

  handleMenuOrientation = () => {
    var menu_orientation = localStorage.getItem("menu_orientation")
    menu_orientation = menu_orientation === null || menu_orientation === 'h' ? 'v':'h'
    localStorage.setItem('menu_orientation', menu_orientation);
    window.location.reload();
  }

  showManual = () => {
    this.setState({isShowManual: !this.state.isShowManual});
    // window.open("/userManual.pdf#toolbar=0");
  }

  showFullscreen = () => {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  renderUserHome() {
    const { steps, runJoy, isShowManual } = this.state;
    return (
      <div>
  	  <div>
        <Breadcrumb>
          <Breadcrumb.Item active>
            Bienvenido(a) {
              this.state.name ?
              <b>{this.state.name + ' ' + (this.state.lastName ? this.state.lastName : '') + ' (' + this.state.email + ')'} &nbsp; <span>{this.props.condo && 'Plan: ' + store.getState().plan}</span></b>
              :
              ''
            }
          </Breadcrumb.Item>
          <OverlayTrigger placement="bottom" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Despliegue de menú vertical/horizontal</Tooltip>}>
            <Glyphicon className="help menu-btn" glyph="th-list" onClick={this.handleMenuOrientation}/>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Manual de usuario</Tooltip>}>
            <Glyphicon className="help manual-btn" glyph="book" onClick={this.showManual} />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Pantalla completa</Tooltip>}>
            <Glyphicon className="help" glyph="resize-full" onClick={this.showFullscreen} />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Información</Tooltip>}>
            <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
          </OverlayTrigger>
        </Breadcrumb>
        { isShowManual ?
        <div className="pdfContainer">
            <embed src="/userManual20210101.pdf#toolbar=0" width="100%" height="100%"/>
        </div>
        :
        <Grid>
          <Row>
            <Col sm={12}>
                <GridMenu {...this.props} summary={this.props.summary}/>
            </Col>
          </Row>
        </Grid>
        }
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}/>
      </div>
      {this.props.condo && this.props.condo.payment && this.props.condo.payment.suspended &&
        <div>
          <Breadcrumb className="BreadcrumbSuspended" onClick={this.handlePaymentAdvice.bind()}>
            <Breadcrumb.Item active>
              Cuenta temporalmente suspendida
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      }
      </div>

  	);
  }
  render() {
    return (
      <div className="Home">
        <Grid>
        {
        	this.props.isAuthenticated
        	?
        	this.renderUserHome()
        	:
        	<PublicHome />
        }
        </Grid>
      </div>
    );
  }
}
