import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Visitor.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListVisitorBlocked from "./ListVisitorBlocked";
import NewVisitorBlocked from "./NewVisitorBlocked";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

export default class VisitorBlocked extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      steps: [
        {
          target: '.btn-primary',
          content: 'Para agregar una nueva visita en lista negra presione aquí',
          placement: 'bottom',
        },
        {
          target: '.Visitor .visitors',
          content: 'En este listado se muestran las visitas que no tienen autorización a ingresar a la unidad',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="Visitor">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Visitas en lista negra</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      Visitas en lista negra
	    </PageHeader>
        {
          this.props.selectedUnit === null ?
            <Alert bsStyle="danger">
              <strong>Debe seleccionar unidad</strong>
            </Alert>
          :
          <ButtonGroup>
            <NewVisitorBlocked {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
            <Button onClick={this.handleUpdate} className="btnRefresh">
              <Glyphicon glyph="refresh" />
            </Button>
          </ButtonGroup>
        }
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListVisitorBlocked {...this.props} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
