import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Row, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react';
import { invokeApig } from "../../libs/awsLib";
import { validateText, validateNumber, validateTextAndNumber } from '../../libs/validation';
import { Link } from "react-router-dom";
import Select from 'react-select';
import Webcam from "react-webcam";
import config from "../../config";
import './NewPet.css';

export class NewPet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      show: false,
      name:'',
      color: '',
      age: 1,
      otherSpecies: '',
      species: 'Perro',
      size: 'LITTLE',
      selectedResident: null,
      showError: false,
      errorMessage: '',
      pet_picture: ''
    };
  }

  isRut(){return this.state.card_id_type === 'RUT';}

  handleChangeRadio = (e, { value }) => this.setState({ card_id_type: value });

  handleChange = event => {
    if (event.target.id === "is_owner") {
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  createPet(pet) {
    pet.unitId = this.props.selectedUnit["unit"];
    return invokeApig({
      path: "/pet",
      method: "POST",
      body: pet
    });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '' });
    this.setState({ otherSpecies: '', species: 'Perro', name: '', color: '',
      age: 1, size: 'LITTLE', selectedResident: null, pet_picture: '' });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  isOtherSpecies() {
      return this.state.species === 'Otro';
  }

  getErrors() {
    let message = undefined;
    if (this.isOtherSpecies() && !validateText(this.state.otherSpecies)) {
      message = 'Debe ingresar otra especie';
      return message;
    }

    if (!validateTextAndNumber(this.state.name)) {
      message = 'Debe ingresar nombre';
      return message;
    }

    if (!validateText(this.state.color)) {
      message = 'Debe ingresar color';
      return message;
    }

    if (!validateNumber(this.state.age)) {
      message = 'Debe ingresar edad. Mínimo es un año.';
      return message;
    }

    return message;
  }

  handleResidentChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ selectedResident: option, showError: false });
  };

  resetForm = () => {
    this.setState({ age:'', name:'', color: '', size: 'LITTLE', pet_picture: '',
      is_owner: false, card_id: '', showError: false, errorMessage: ''
    });
  }

  shortResident (resident) {
    return {
      "name": resident["name"],
      "lastname": resident["lastname"],
      "card_id": resident["card_id"],
      "email": resident["email"],
      "phone": resident["phone"]
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }

    if (!this.state.selectedResident) {
      this.setState({showError: true, errorMessage: "Seleccione residente que recibe encomienda!"});
      return;
    }

    this.setState({ isLoading: true });
    try {
      const pet = {
        species: this.isOtherSpecies() ? this.state.otherSpecies : this.state.species,
        name: this.state.name,
        color: this.state.color,
        age: this.state.age,
        size: this.state.size,
        resident: this.shortResident(this.state.selectedResident),
      };

      if (this.state.pet_picture) {
        pet["pet_picture_64"] = this.state.pet_picture;
      }

      const response = await this.createPet(pet);
      if (response.code === 0) {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Mascota ingresada exitosamente!", refresh: true });
      } else {
        this.props.onAlertError({ message: "Error al registrar mascota." });
      }

      this.handleClose();
    } catch (e) {
      this.props.onAlertError({ message: "Error al registrar mascota! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  capture = () => {
     const pet_picture = this.webcam.getScreenshot();
     this.setState({ pet_picture });
  }

  renderFormNew(residents) {
    return(
	   <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="species">
          <Col componentClass={ControlLabel} sm={3}>
            Especie
          </Col>
          <Col sm={8}>
            <FormControl componentClass="select" onChange={this.handleChange} value={this.state.species} >
              <option value="Perro">Perro</option>
              <option value="Gato">Gato</option>
              <option value="Otro">Otro</option>
            </FormControl>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Especie. Si es otro, completar el siguiente campo.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        { this.state.species === 'Otro' &&
          <FormGroup controlId="otherSpecies">
            <Col componentClass={ControlLabel} sm={3}>
              Otra especie (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.otherSpecies}
                placeholder="ej: tortuga"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Especie. Si es otro, completar el siguiente campo.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
        }
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="ej: bobby"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre de pila.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="color">
          <Col componentClass={ControlLabel} sm={3}>
            Color (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.color}
              placeholder="ej: café"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Color descriptivo.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="age">
          <Col componentClass={ControlLabel} sm={3}>
            Edad
          </Col>
          <Col sm={8}>
            <FormControl
              type="number"
              onChange={this.handleChange}
              value={this.state.age}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Edad aproximada. Mínimo 1 año.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="size">
          <Col componentClass={ControlLabel} sm={3}>
            Tamaño
          </Col>
          <Col sm={8}>
            <FormControl componentClass="select" onChange={this.handleChange} value={this.state.size} >
              <option value="LITTLE">{this.props.petSize.LITTLE}</option>
              <option value="MEDIUM">{this.props.petSize.MEDIUM}</option>
              <option value="LARGE">{this.props.petSize.LARGE}</option>
            </FormControl>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tamaño.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="resident">
          <Col componentClass={ControlLabel} sm={3}>
            Residente responsable (*)
          </Col>
          <Col sm={8}>
            { residents.length ?
              <Select
                onChange={this.handleResidentChange}
                options={residents}
                placeholder="Seleccione" />
              :
              <div>Unidad no tiene residentes.
              <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
              </div>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que es responsable de la mascota.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {this.props.condo && this.props.condo.picture.pet &&
        <FormGroup controlId="pet_picture">
          <Col componentClass={ControlLabel} sm={3}>
            Foto mascota
          </Col>
          <Col sm={4}>
            <Webcam
              audio={false}
              height={config.picture.HEIGHT_AVATAR}
              width={config.picture.WIDTH_AVATAR}
              screenshotFormat={config.picture.FORMAT}
              videoConstraints={config.picture.CONSTRAINS_AVATAR}
            />
          </Col>
          <Col sm={4}>
            <Row>
              <Col md={12}>
                <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
              </Col>
            </Row>
            {this.state.pet_picture &&
            <React.Fragment>
              <Row>
              <Col md={12}>
                <p className="pet_picture_previous_text">Previa:</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <img className="pet_picture_previous" alt="Previa" src={this.state.pet_picture} />
              </Col>
            </Row>
            </React.Fragment>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de la mascota.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        }
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={"Crear"}
              loadingText="Guardando…"
            />
      </Form>
    );
  }

  render() {
    const residents = this.props.residents ?
      this.props.residents.map(v => ({
        label: v.name + " " + v.lastname + " ("+ v.card_id +")",
        value: v
      })) : [];

    return (
      <div className="petBtn">
	    <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar mascota</Button>
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos de la mascota</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew(residents)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {this.props.condo && this.props.condo.picture.pet &&
      <Modal className="pet_cam_modal" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Webcam
          className="pet_cam"
          audio={false}
          height={config.picture.HEIGHT}
          width={config.picture.WIDTH}
          ref={node => this.webcam = node}
          screenshotFormat={config.picture.FORMAT}
          screenshotQuality={config.picture.QUALITY_HIGH}
          videoConstraints={config.picture.CONSTRAINS}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      }
      </div>
    );
  }
}

export default NewPet
