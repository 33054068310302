import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from 'semantic-ui-react'
import ReactExport from "react-excel-exportz";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class PetExport extends Component {

  render() {
      return (
        <ExcelFile
          filename="mascotas"
          element={
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Exportar a excel</Tooltip>}>
              <Button bsStyle="success" disabled={!this.props.data.length}><Icon fitted name="download" /></Button>
            </OverlayTrigger>
          }>
            <ExcelSheet data={this.props.data} name="Mascotas">
                <ExcelColumn label="Unidad" value="unit"/>
                <ExcelColumn label="Nombre" value="name"/>
                <ExcelColumn label="Especie" value="specie"/>
                <ExcelColumn label="Color" value="color"/>
                <ExcelColumn label="Tamaño"
                             value={(col) => this.props.petSize[col.size]}/>
                <ExcelColumn label="Responsable"
                             value={(col) => col.resident.name + " " + col.resident.lastname}/>
            </ExcelSheet>
        </ExcelFile>
      );
  }
}
