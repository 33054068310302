import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import { Icon, Label, Image } from 'semantic-ui-react'
import { validateText, validateNumber } from '../../libs/validation';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import LoaderButton from "../../components/LoaderButton";
import { Link } from "react-router-dom";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ListPet.css';
import config from "../../config";

export default class ListPetSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            disableEdit: true,
            isDeleting: false,
            isEditing: false,
            isLoading: false,
            isError: false,
            showDelete: false,
            showEdit: false,
            showPet: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            deleteCandidate: null,
            editCandidate: null,
            showDetailsCandidate: null,
            actionCandidateIndex: -1,
            name:'',
            age: 1,
            size: '',
            color: '',
            pets: []

        };
    }

    componentDidMount() {
      this.findPets();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findPets();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findPets = async () => {
      this.setState({ isLoading: true, pets: [] });
      try {
        const results = await this.pets();
        this.setState({ pets: results.pets });
        this.props.onHandleSize(results.pets);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    pets() {
      return invokeApig({
        path: "/pet/find",
        method: "POST",
        body: {
            "status": "P"
        }
      });
    }

    deletePet(residents) {
      return invokeApig({
        path: "/pet",
        method: "DELETE",
        body: {
          "unitId": this.state.unit,
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    updatePet(pet) {
      pet.unitId = this.state.unit;
      return invokeApig({
        path: `/pet`,
        method: "PUT",
        body: pet
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener encomiendas. Intenta más tarde." : "No hay datos";
      }
    }

    handleClose = () => {
      this.setState({ showDelete: false, deleteCandidate: null, actionCandidateIndex: -1,
        showEdit: false, editCandidate: null, showPicture: false, showDetailsCandidate: null,
        showPet: false, disableEdit: true, unit: '' });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deletePet();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar mascota!" });
        } else {
          this.state.pets.splice(this.state.actionCandidateIndex, 1);
          this.handleAlertSuccess({ message: "Mascota eliminada correctamente!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar mascota!" });
      }
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({showAlertSuccess:true},()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.size === this.state.editCandidate.size
            && this.state.name === this.state.editCandidate.name
            && this.state.age === this.state.editCandidate.age
            && this.state.color === this.state.editCandidate.color;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    resetForm = () => {
      this.setState({ unit: '', color:'', size: '', name:'',
        age:1, showError: false, errorMessage: ''});
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {

        const pet = {
          created_date: this.state.editCandidate.created_date,
          name: this.state.name,
          size: this.state.size,
          color: this.state.color,
          age: this.state.age
        };

        const response = await this.updatePet(pet);
        if (response.code === 0) {
          this.findPets();
          this.resetForm();
          this.handleAlertSuccess({ message: "Mascota actualizada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar mascota! Por favor revise los datos e intente nuevamente." });
        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar mascota!" });

      }
      this.setState({ isLoading: false });
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.color)) {
        message = 'Debe ingresar color';
        return message;
      }

      if (!validateNumber(this.state.age)) {
        message = 'Debe ingresar edad. Mínimo es un año.';
        return message;
      }

      return message;
    }

    showEditPet = (cell, row, index, enumObject) => {
      this.setState({ isLoading: false,
        name: cell.name,
        age: cell.age,
        color: cell.color,
        size: cell.size,
        showEdit: true,
        editCandidate: cell,
        unit: cell.unit,
        actionCandidateIndex: index
      });
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setState({ showDelete: true, actionCandidateIndex: index, deleteCandidate: cell, unit: cell.unit });
    }

    showPetPicture = (cell, row, index, enumObject) => {
      this.setState({ showPicture: true, actionCandidateIndex: index, showDetailsCandidate: cell });
    }

    residentCellFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.lastname) !== 'undefined' ? cell.name + " " + cell.lastname: "";
    }

    residentFormatter = (resident) => {
      return resident
      && typeof(resident.name) !== 'undefined'
      && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
    }

    speciesFormatter = (cell) => {
      if (cell) {
        var color = "green";
        if (cell === 'PERRO') {
          color = 'blue'
        } else if (cell === 'GATO'){
          color = 'red'
        };
        return <Label color={color}>{cell}</Label>
      }
    }

    petSizeFormatter = (cell, row) => {
      return this.props.petSize[cell];
    }

    drawButton = (cell, row, index, enumObject) => {
      return row['status'] === 'ACTIVE' &&
         <ButtonGroup className="actionsBtn">
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
            <Button bsStyle="warning" onClick={this.showEditPet.bind(cell, row, index, enumObject)} className="btnEditPet">
              <Glyphicon glyph="edit" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
            <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, index, enumObject)} className="btnDeletePet">
              <Glyphicon glyph="trash" />
            </Button>
          </OverlayTrigger>
          {this.props.condo && this.props.condo.picture.pet && typeof(row.pet_picture_url) !== 'undefined' &&
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
            <Button bsStyle="primary" onClick={this.showPetPicture.bind(cell, row, index, enumObject)} className="btnShowVisitor">
              <Glyphicon glyph="camera" />
            </Button>
          </OverlayTrigger>
          }
         </ButtonGroup>;
    }

    render(){

        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };

        const data = this.state.pets;

        return(
          <div className="Pet">
          {
            this.state.showAlertSuccess &&
            <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          {
            this.state.showAlertError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          <Col sm={12} className="pets">
            <BootstrapTable
              pagination
              data={ data }
              selectRow={ selectRowProp }
              options={ tableOptions }
              hover>
              <TableHeaderColumn dataField='unit' filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } } dataSort={ true } >
                <Glyphicon glyph="home" /> Unidad
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='name'
                isKey
                filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                dataSort={ true }>Nombre</TableHeaderColumn>
              <TableHeaderColumn
                dataField='species'
                dataFormat={ this.speciesFormatter }
                filter={ { type: 'TextFilter', placeholder: 'Ingrese especie', delay: 10 } }
                dataSort={ true } >Especie</TableHeaderColumn>
              <TableHeaderColumn
                dataField='color'
                filter={ { type: 'TextFilter', placeholder: 'Ingrese color', delay: 10 } }
                dataSort={ true } >Color</TableHeaderColumn>
              <TableHeaderColumn
                dataField='size'
                dataFormat={ this.petSizeFormatter }
                filterFormatted
                filter={ { type: 'TextFilter', placeholder: 'Ingrese tamaño', delay: 10 } }
                dataSort={ true } >Tamaño</TableHeaderColumn>
              <TableHeaderColumn
                dataField='resident'
                dataFormat={ this.residentCellFormatter }
                filterFormatted
                filter={ { type: 'TextFilter', placeholder: 'Ingrese tamaño', delay: 10 } }
                dataSort={ true } >Responsable</TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.props.profile === 'Comite'}
                width='150'
                headerAlign='center'
                dataAlign='center'
                dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
            </BootstrapTable>
            {
            	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más mascotas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
            }
          </Col>
          <Modal show={this.state.showDelete} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.state.unit}</Label>
              <Label color='red'>ELIMINACIÓN</Label> Eliminar mascota</Modal.Title>
            </Modal.Header>
            { this.state.deleteCandidate &&
              <Modal.Body>¿Está seguro que quiere eliminar mascota <Label color='red'>{this.state.deleteCandidate.name}</Label>?</Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='orange'><Icon name='building' />{this.state.unit}</Label>
                <Label color='orange'><Icon name='edit' /></Label> Editar mascota</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.editCandidate &&
                this.renderFormEdit()
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='blue'><Icon name='paw' />Nombre:</Label> {this.state.showDetailsCandidate && this.state.showDetailsCandidate.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.showDetailsCandidate &&
                <Image
                  className="pet_picture"
                  wrapp
                  size='medium'
                  src={config.s3_files.BUCKET + this.state.showDetailsCandidate.pet_picture_url} />
              }
            </Modal.Body>
          </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="species">
            <Col componentClass={ControlLabel} sm={3}>
              Especie
            </Col>
            <Col sm={8}>
              <FormControl
                disabled
                onChange={this.handleChange}
                value={this.state.editCandidate.species}
              />
            </Col>
            <Col sm={1}>
            </Col>
          </FormGroup>
          <FormGroup controlId="name">
            <Col componentClass={ControlLabel} sm={3}>
              Nombre (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
                placeholder="ej: bobby"
              />
            </Col>
            <Col sm={1}>
            </Col>
          </FormGroup>
          <FormGroup controlId="color">
            <Col componentClass={ControlLabel} sm={3}>
              Color (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.color}
                placeholder="ej: café"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Color descriptivo.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="age">
            <Col componentClass={ControlLabel} sm={3}>
              Edad
            </Col>
            <Col sm={8}>
              <FormControl
                type="number"
                onChange={this.handleChange}
                value={this.state.age}
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Edad aproximada. Mínimo 1 año.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="size">
            <Col componentClass={ControlLabel} sm={3}>
              Tamaño
            </Col>
            <Col sm={8}>
              <FormControl componentClass="select" onChange={this.handleChange} value={this.state.size} >
                <option value="LITTLE">{this.props.petSize.LITTLE}</option>
                <option value="MEDIUM">{this.props.petSize.MEDIUM}</option>
                <option value="LARGE">{this.props.petSize.LARGE}</option>
              </FormControl>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tamaño.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="resident">
            <Col componentClass={ControlLabel} sm={3}>
              Residente responsable
            </Col>
            <Col sm={8}>
              <FormControl
                disabled
                onChange={this.handleChange}
                value={this.residentFormatter(this.state.editCandidate.resident)}
              />
            </Col>
            <Col sm={1}>
            </Col>
          </FormGroup>
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
