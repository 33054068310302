import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Button, Glyphicon, Col, Alert, ButtonGroup, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import 'moment/locale/es';
import './ListBookingSummary.css';
import {sortByKey} from '../../libs/arrays';
import BookingDetailModal from "./BookingDetailModal";

export default class ListBookingSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthorizing: false,
            isRejecting: false,
            rejectCandidate: null,
            detailCandidate: null,
            showReject: false,
            authorizeCandidate: null,
            showAuthorize: false,
            showDetails: false,
            disablePenalty: true,
            payment_info: '',
            penalty_skip_reason: '',
            unit: '',
            lastname: '',
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            lastId: '',
            bookings: []
        };
    }

    componentDidMount = async () => {
      this.findBookings();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findBookings();
      }

      if (!prevProps.isLoading && this.props.isLoading) {
        this.findBookings();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findBookings = async () => {
      this.setState({ isLoading: true, bookings: [] });
      try {
        const results = await this.bookings();
        const bookings = sortByKey(results.bookings, "start")
        this.setState({ bookings: bookings });
        this.setState({ lastId: results.lastId });
        this.props.onHandleSize(bookings.length);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    bookings() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/booking/find",
        method: "POST",
        body: {
            "year_month_search": selectedDateMoment.local().format("YYYY-MM")
        }
      });
    }

    updateBooking(booking) {
      return invokeApig({
        path: "/booking",
        method: "PUT",
        body: booking
      });
    }

    handleReject = async event => {
      event.preventDefault();
      this.setState({ isRejecting: true });
      try {
        const booking = {
          id: this.state.rejectCandidate.id,
          resourceId: this.state.rejectCandidate.resourceId,
          reject: "KO"
        };

        const response = await this.updateBooking(booking);
        this.setState({ isRejecting: false, showReject: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al rechazar reserva!" });
        } else {
          this.handleAlertSuccess({ message: "Reserva rechazada" });
          this.findBookings();
          this.handleClose();
        }
      } catch (e) {
        this.setState({ isRejecting: false, showReject: false, showAlertError: true, alertMessage: "Error al rechazar reserva!!" });
      }
    }

    handleAuthorize = async event => {
      event.preventDefault();
      this.setState({ isAuthorizing: true });
      try {
        const booking = {
          id: this.state.authorizeCandidate.id,
          resourceId: this.state.authorizeCandidate.resourceId,
          authorize: "OK"
        };

        const response = await this.updateBooking(booking);
        this.setState({ isAuthorizing: false, showAuthorize: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al autorizar reserva!" });
        } else {
          this.handleAlertSuccess({ message: "Reserva autorizada correctamente" });
          this.findBookings();
          this.handleClose();
        }
      } catch (e) {
        this.setState({ isAuthorizing: false, showAuthorize: false, showAlertError: true, alertMessage: "Error al autorizar reserva!!" });
      }
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas excedidas. Intenta más tarde." : "No hay datos";
      }
    }

    showDoAuthorize = (cell, row, rowIdx) => {
      this.setState({ showAuthorize: true, authorizeCandidate: cell, unit: cell.unit, });
    }

    showDoReject = (cell, row, rowIdx) => {
      this.setState({ showReject: true, rejectCandidate: cell, unit: cell.unit, });
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setState({ showDetails: true, detailCandidate: cell, unit: cell.unit });
    }

    unitFormatter = (cell, row) => {
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{cell}</Link>;
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        const dateFormatted = date.format("DD-MM-YYYY");
        const hourFormatted = date.format("HH:mm");
        let color = cell === row["start"] ? "blue": "red";
        return <span>
          <Label>{dateFormatted}</Label>
          <Label color={color}><Icon name='time' />{hourFormatted}</Label>
          </span>;
      }
    }

    statusFormatter = (cell, row) => {
      return this.props.bookingStatus[cell];
    }

    nameFormatter = (cell, row) => {
      return cell.name + " " + cell.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    assetFormatter = (cell, row) => {
      return cell !== 'undefined' ? cell : "";
    }

    rowClassNameFormat = (row, rowIdx) => {
      return row['status'] === 'PENDING' ? 'b_pending' : 'b_active';
    }

    drawButton = (cell, row, rowIdx) => {
      return row['status'] === 'PENDING' ?
              <ButtonGroup className="actionsBtn">
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Autorizar</Tooltip>}>
                  <Button bsStyle="success" onClick={this.showDoAuthorize.bind(cell, row, rowIdx)} className="showDoAuthorize">
                    <Glyphicon glyph="ok" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">No autorizar</Tooltip>}>
                  <Button bsStyle="danger" onClick={this.showDoReject.bind(cell, row, rowIdx)} className="showDoReject">
                    <Glyphicon glyph="remove" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              :
              <ButtonGroup>
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
                  <Button  onClick={this.loadDetails.bind(cell, row, rowIdx)}  className="btnShowDetail">
                    <Glyphicon glyph="search" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>;
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleClose = () => {
      this.setState({
        showDetails: false, detailCandidate: null,
        showError: false, unit: '',
        showAuthorize: false, authorizeCandidate: null,
        showReject: false, rejectCandidate: null});
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        //do nothing
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption(),
          exportCSVText: 'Exportar a CSV'
        };
        const data = this.state.bookings;
        return(
          <div className="Booking">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="bookings">
              <BootstrapTable
                csvFileName='reservas.csv'
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                trClassName={this.rowClassNameFormat}>
                <TableHeaderColumn
                  isKey
                  dataField='unit'
                  filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.unitFormatter }>
                  <Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese residente', delay: 10 } }
                  dataField='resident'
                  dataFormat={ this.nameFormatter }>Residente
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese lugar', delay: 10 } }
                  dataField='resourceName'
                  dataFormat={ this.assetFormatter }>Lugar
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha inicio', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='start'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Inicio
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha fin', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='end'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Fin
                </TableHeaderColumn>
                {/*<TableHeaderColumn
                  dataSort={ true }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='status'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese estado', delay: 10 } }
                  dataFormat={ this.statusFormatter }>Estado
                </TableHeaderColumn>
                */}
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese folio', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='payment_info'>
                  Info. adicional</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_creates'
                  dataFormat={this.userFormatter}>Conserje</TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  className="actions-btn"
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showAuthorize} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'>
                  <Icon name='building' />{this.state.unit}
                </Label> Autorizar reserva
                </Modal.Title>
              </Modal.Header>
              { this.state.authorizeCandidate &&
                <Modal.Body>¿Confirma la autorización de la reserva a nombre de <Label color='green'>{this.nameFormatter(this.state.authorizeCandidate.resident)}</Label>?
                <p></p>
                <p className="psmall-center"><b>Lugar:</b> {this.state.authorizeCandidate.resourceName}</p>
                <p className="psmall-center"><b>Inicio/Fin:</b> {this.state.authorizeCandidate.start} / {this.state.authorizeCandidate.end}</p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="success" isLoading={this.state.isAuthorizing} onClick={this.handleAuthorize} text="Autorizar" loadingText="Autorizando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showReject} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.state.unit}</Label> Rechazar reserva</Modal.Title>
              </Modal.Header>
              { this.state.rejectCandidate &&
                <Modal.Body>¿Confirma el rechazo de la reserva a nombre de <Label color='red'>{this.nameFormatter(this.state.rejectCandidate.resident)}</Label>?
                <p></p>
                <p className="psmall-center"><b>Lugar:</b> {this.state.rejectCandidate.resourceName}</p>
                <p className="psmall-center"><b>Inicio/Fin:</b> {this.state.rejectCandidate.start} / {this.state.rejectCandidate.end}</p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isRejecting} onClick={this.handleReject} text="Rechazar" loadingText="Rechazando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.state.unit}</Label> Detalle de la reserva
                </Modal.Title>
              </Modal.Header>
              <BookingDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
}
