import React, { Component } from "react";
import {
    Col, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import { Label, Image } from 'semantic-ui-react'
import { formatRut } from '../../libs/validation';
import config from "../../config";
import './ResidentDetailModal.css';
import moment from 'moment';


export default class ResidentDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.cell,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  nameFormatter = (name, lastname) => {
    return name + " " + lastname;
  }

  ownerFormatter = (cell, row) => {
    return cell && cell === true?
      <Label color="green">SI</Label>
      :
      <Label color="red">NO</Label>
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  render(){
    const { created_date, resident_picture_url, card_id, card_id_type, name, lastname, is_owner, email, phone, special_condition} = this.state ;

    const id = card_id_type === 'OTHER' ? card_id : formatRut(card_id);

    return(
      <div className="Resident">
        <Form horizontal>
          <FormGroup controlId="card_id">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        RUT (u otro){' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{id}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="name">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Nombre{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.nameFormatter(name, lastname)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="visitors_number">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Es dueño?{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.ownerFormatter(is_owner)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { email &&
          <FormGroup controlId="email">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Email{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{email}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { phone &&
          <FormGroup controlId="phone">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Teléfono{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{phone}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { special_condition &&
          <FormGroup controlId="special_condition">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Condición especial{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{special_condition}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          <FormGroup controlId="created_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Creado el{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { resident_picture_url &&
          <FormGroup controlId="resident_picture_url">
            <Col componentClass={ControlLabel} sm={6}>
              Foto{' '}
            </Col>
            <Col sm={6}>
              <Image
                className="resident_picture"
                size='small'
                src={config.s3_files.BUCKET + resident_picture_url} />
            </Col>
          </FormGroup>
          }
  	    </Form>
      </div>
    );
  }
}
