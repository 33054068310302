import React, { Component } from "react";
import { Form, FormGroup, FormControl, Glyphicon, Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from '../../config';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const recaptchaRef = React.createRef();

export default class Contact extends Component {
  constructor(props) {
  	super(props);
  	this.state = {
  	  isLoading: false,
  	  name: '',
  	  phone: '',
  	  email: '',
      message: '',
      alertMessage: '',
      isErrorMessage: false,
      captchaOK: false
  	};
  }

  // const recaptchaRef = React.createRef();
  onChange = (value) => {
    this.setState({ captchaOK: true });
  }

  onExpired = (value) => {
    this.setState({ captchaOK: false });
  }
  onErrored = (value) => {
    this.setState({ captchaOK: false });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  validate() {
    var errors = [];
    if (this.state.name.length < 3) {
      errors.push('Debe ingresar un nombre válido');
    }
    if (this.state.email.length < 8) {
      errors.push('Debe ingresar un email válido');
    }
    if (this.state.phone.length < 8) {
      errors.push('Debe ingresar un teléfono válido');
    }
    if (this.state.message.length < 3) {
      errors.push('Debe ingresar el mensaje');
    }
    if (!this.state.captchaOK) {
      errors.push('Debes marcar el captcha');
    }
    return errors;
  }
  handleSubmit = async event => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.length) {
      this.setState({ alertMessage: errors.join('<br>'), isErrorMessage: true });
      return;
    }
    this.setState({ isLoading: true });
    try {
  	  const response = await this.contactSupport({
        ...this.props.fields,
  	    name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message + " (app)"
      });
      console.log(response);
      this.setState({ isLoading: false, captchaOK: false, alertMessage: 'Hemos recibido tu mensaje, pronto nos comunicaremos contigo.', isErrorMessage: false, name: '', email: '', phone: '', message: '' });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, captchaOK: false, alertMessage: 'Error al enviar mensaje. Favor intenta nuevamente', isErrorMessage: true });
    }
  }
  async contactSupport(contact) {
    const result = await fetch(config.apiGateway.URL + '/contact', {
      method: 'POST',
      headers: { 'content-type': 'application/json'},
      body: JSON.stringify(contact)
    });
    return result;
  }
  render() {
    return (
      <div>
        <h2>Cont&aacute;ctanos</h2>
          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup controlId="name">
              <Col xs={10}>
                <FormControl componentClass="input" onChange={this.handleChange} value={this.state.name} placeholder="Nombre completo" />
              </Col>
              <Col xs={2}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Ingrese su nombre.</Tooltip>} placement="left">
                  <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="email">
              <Col xs={10}>
                <FormControl componentClass="input" onChange={this.handleChange} value={this.state.email} placeholder="Email" />
              </Col>
              <Col xs={2}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Ingrese su e-mail.</Tooltip>} placement="left">
                  <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="phone">
              <Col xs={10}>
                <FormControl componentClass="input" onChange={this.handleChange} value={this.state.phone} placeholder="Teléfono" />
              </Col>
              <Col xs={2}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Ingrese su teléfono.</Tooltip>} placement="left">
                  <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="message">
              <Col xs={10}>
                <FormControl
                  componentClass="textarea"
                  onChange={this.handleChange}
                  value={this.state.message}
                  placeholder="Mensaje"
                />
              </Col>
              <Col xs={2}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Ingrese su mensaje lo más claro posible.</Tooltip>} placement="left">
                  <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="captcha">
              <Col xs={10}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.captcha.KEY}
                  onChange={this.onChange}
                  onExpired={this.onExpired}
                  onErrored={this.onErrored}
                  hl={"es"}
                />
              </Col>
              <Col xs={2}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Completa el captcha para validar que no eres un robot.</Tooltip>} placement="left">
                  <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            { this.state.alertMessage && this.state.isErrorMessage  && <Alert bsStyle="danger" ><div dangerouslySetInnerHTML={{__html: this.state.alertMessage}}/></Alert> }
            { this.state.alertMessage && !this.state.isErrorMessage  && <Alert bsStyle="success" ><div dangerouslySetInnerHTML={{__html: this.state.alertMessage}}/></Alert> }
            <FormGroup controlId="buttons" className="align-center">
              <Col xs={10}>
                <LoaderButton
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Enviar"
                  loadingText="Enviando…" />
              </Col>
              <Col xs={2}></Col>
            </FormGroup>
          </Form>
      </div>
    );
  }
}
