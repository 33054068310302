import React, { Component, createRef } from "react";
import { invokeApig } from "../../libs/awsLib";
import "./AssetsSumary.css"
import CrudModal from "./CrudModal" 

export default class ControlledAccessSummary extends Component {
  constructor(props) {
    super(props)
    this.accessRef = createRef()
  }

  onDelete = async (state) => {
    return await invokeApig({
      path: "/controlledaccess/" + state.selectedElement,
      method: "DELETE"
    })
  }

  onAdd = async (state) => {
    const body = {
      controlledAccessId: state.selectedElement,
      controlledAccessName: state.elementName
    }
    return await invokeApig({
      path: "/condo",
      method: "PUT",
      body: body
    })
  }

  onModify = async (state) => {
    const id = state.selectedElement
    const name = state.elementName
    const body = {
      controlledAccessId: id,
      controlledAccessName: name
    }
    return await invokeApig({
      path: "/condo",
      method: "PUT",
      body: body
    })
  }

  handleShow = () => {
    this.accessRef.current.handleShow()
  }

  onReturn = (resp) => {
   const elementsMap = {}
    resp.access_control.forEach(element => {
      elementsMap[element.id] = element
    })
    return elementsMap
  }

  render() {
    return (
      <CrudModal mode='CONTROLLED_ACCESS' ref={this.accessRef} elements={this.props.ctrlAccess}
        onDelete={this.onDelete} onAdd={this.onAdd} onModify={this.onModify} onReturn={this.onReturn}
        title="Accesos controlados">
      </CrudModal>
    )
  }
}
