import React, { Component, createRef } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import {
  Form, FormGroup, FormControl, ControlLabel, ButtonGroup, Button, Glyphicon,
  Tooltip, OverlayTrigger, Col, Row, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "./FormSetup.css";
import AssetsSumary from "./AssetsSummary";
import { validateEmail, validateFreeText, validateNumber, validateNumberWithZero } from '../../libs/validation';
import ControlledAccessSummary from "./ControlledAccessSummary";
import { checkAccess } from '../../libs/commons'

export default class FormSetup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      short_name: '',
      store_max_time: '',
      phone: '',
      email: '',
      email_alias: '',
      parking_max_time: '',
      isLoading: true,
      isUploading: false,
      showAssetsList: false,
      assets: [],
      showCtrlAccessList: [],
      access_control: [],
      okMsg: '',
      errMsg: '',
      showError: false,
      showOk: false
    }
    this.assetsRef = createRef()
    this.ctrlAccessRef = createRef()
  }

  handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
  }

  handleRadioChange = event => {
    this.setState({
      status: event.target.value
    });
  }

  handleChangeCheckbox = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  }

  showAssetsList = event => {
    this.assetsRef.current.handleShow()
  }

  showCtrlAccessList = event => {
    this.ctrlAccessRef.current.handleShow()
  }

  findSetups = async () => {
    const data = await this.setups()
    return data
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  setups = async () => {
    return await invokeApig({
      path: "/condo",
      method: "GET",
    });
  }

  getErrors = () => {
    let message = null
    if (this.state.name.trim().length <= 0 || !validateFreeText(this.state.name, 1, 100)) {
      message = 'Debe ingresar un nombre válido'
      return message
    }

    if (this.state.short_name.trim().length <= 0 || !validateFreeText(this.state.short_name, 1, 50)) {
      message = 'Debe ingresar un nombre corto válido'
      return message
    }

    if (!validateNumber(this.state.store_max_time)) {
      message = 'Debe ingresar un tiempo de almacenamiento válido'
      return message
    }

    if (!validateNumberWithZero(this.state.parking_max_time)) {
      message = 'Debe ingresar un tiempo de estacionamiento válido'
      return message
    }

    if (!validateEmail(this.state.email)) {
      message = 'Debe ingresar un email válido'
      return message
    }

    if (!validateEmail(this.state.email_alias)) {
      message = 'Debe ingresar un alias de email válido'
      return message
    }

    return message
  }

  normalize = () => {
    this.setState({
      name: this.state.name.trim(),
      short_name: this.state.short_name.trim(),
      email: this.state.email.trim(),
      email_alias: this.state.email_alias.trim(),
    })
  }

  updateCondo = async () => {
    this.setState({
      isUploading: true
    })
    try {
      const message = this.getErrors()
      if (message !== null) {
        this.setState({showOk: false, showError: true, errMsg: message})
        return
      }
      const body = {
        name: this.state.name,
        short_name: this.state.short_name,
        store_max_time: Number(this.state.store_max_time),
        phone: this.state.phone,
        email: this.state.email,
        email_alias: this.state.email_alias,
        parking_max_time: Number(this.state.parking_max_time)
      }
      let resp = await invokeApig({
          path: "/condo",
          method: "PUT",
          body: body
      })
      if (resp.code === 0) {
        this.updateFields(resp.condo)
        this.setState({
          okMsg: 'El condominio fue actualizado exitosamente',
          showOk: true
        })
      } else {
        throw new Error('No se puede actualizar el condominio')
      }
    } catch (e) {
      console.error(e);
      this.setState({showError: true, errMsg: 'No se puede actualizar condominio'})
    } finally {
      this.setState({
        isUploading: false
      })
    }
  }

  updateFields(condo) {
    this.setState({
      name: condo.name,
      short_name: condo.short_name,
      parking_max_time: condo.parking_max_time,
      store_max_time: condo.delivery.store_max_time,
      phone: condo.contact.phone,
      email: condo.contact.email,
      email_alias: condo.contact.email_alias,
      assets: condo.assets,
      access_control: condo.access_control
    })
  }

  async componentDidMount() {
    const data = await this.findSetups()
    this.updateFields(data.condo)
    this.setState({
      isLoading: false
    })
  }

  setupForm = () => (
    <>
      {checkAccess('ADVANCED', 'EXPERT') &&
      <div className="sectionBtn">
        <ButtonGroup className="headerBtn">
          <Button bsStyle="primary" onClick={this.showAssetsList}>
            <Glyphicon glyph="calendar"/> Espacios comunes
          </Button>
        </ButtonGroup>
        <ButtonGroup className="headerBtn">
          <Button bsStyle="primary" onClick={this.showCtrlAccessList}>
            <Glyphicon glyph="lock"/> Accesos controlados
          </Button>
        </ButtonGroup>
      </div>
      }
      <Form horizontal onSubmit={this.handleSubmit}>
        <Row className="RowSetup">
          <Col sm={2} />
          <Col sm={3} className="ColSetup">
            <FormGroup controlId="name">
              <ControlLabel className="LabelSetup">Nombre del condominio</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre del condominio.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
              />
            </FormGroup>
          </Col>
          <Col sm={3} className="ColSetup">
            <FormGroup controlId="short_name">
              <ControlLabel className="LabelSetup">Nombre corto del condominio</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre corto del condominio.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                onChange={this.handleChange}
                value={this.state.short_name}
              />
            </FormGroup>
          </Col>
          <Col sm={2} />
        </Row>
        <Row className="RowSetup">
          <Col sm={2} />
          <Col sm={3} className="ColSetup">
            <FormGroup controlId="email">
              <ControlLabel className="LabelSetup">Email</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email de contacto del condominio.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                placeholder="mail@xxxxx.cl"
              />
            </FormGroup>
          </Col>
          <Col sm={3} className="ColSetup">
            <FormGroup controlId="email_alias">
              <ControlLabel className="LabelSetup">Alias de email</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Alias de email que se desplegar&aacute; en los pie de correo</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                onChange={this.handleChange}
                value={this.state.email_alias}
              />
            </FormGroup>
          </Col>
          <Col sm={2} className="ColSetup">
            <FormGroup controlId="phone">
              <ControlLabel className="LabelSetup">Teléfono contacto</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Teléfono de contacto del condominio.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                onChange={this.handleChange}
                value={this.state.phone}
                placeholder="+56912345678"
              />
            </FormGroup>
          </Col>
          <Col sm={2} />
        </Row>
        <Row className="RowSetup">
          <Col sm={2} />
          <Col sm={3} className="ColSetup">
            <FormGroup controlId="store_max_time" id="delivery_class">
              <ControlLabel className="LabelSetup">Tiempo almacenaje (hrs)</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tiempo máximo que puede estar almacenado una encomienda en conserjería en horas.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                type="number"
                onChange={this.handleChange}
                value={this.state.store_max_time}
              />
            </FormGroup>
          </Col>
          <Col sm={4} className="ColSetup">
            <FormGroup controlId="parking_max_time" id="delivery_class">
              <ControlLabel className="LabelSetup">Tiempo máximo de estacionamiento (hrs)</ControlLabel>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tiempo máximo que puede ocupar un vehículo puede ocupar los estacionamientos de visita.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign" /></Link>
              </OverlayTrigger>
              <FormControl
                type="number"
                onChange={this.handleChange}
                value={this.state.parking_max_time}
              />
            </FormGroup>
          </Col>
          <Col sm={3} />
        </Row>
        <Row className="RowSetup">
        </Row>
        {
          (this.state.showError || this.state.showOk) &&
          <Alert bsStyle={this.state.showOk ? "success" : "danger"} onDismiss={() => this.setState({showOk: false, showError: false})}>
            <strong>{this.state.showOk ? this.state.okMsg : this.state.errMsg}</strong>
          </Alert>
        }
        <LoaderButton
          block
          bsStyle="primary"
          bsSize="large"
          isLoading={this.state.isUploading}
          text="Actualizar"
          loadingText="Actualizando…"
          onClick={() => this.updateCondo()}
        />
      </Form>
      <AssetsSumary ref={this.assetsRef} assets={this.state.assets}/>
      <ControlledAccessSummary ref={this.ctrlAccessRef} ctrlAccess={this.state.access_control}/>
      </>)

  render() {
    if (this.state.isLoading)
      return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>
    else
      return this.setupForm()
  }
}
