import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Col, Button, Glyphicon,
    Breadcrumb, PageHeader, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import moment from 'moment';

export default class UnitDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      steps: [
    	  {
          target: '.form-horizontal',
          content: 'En esta sección se muestran los datos actuales de la unidad',
          placement: 'top'
        },
        {
          target: '.form-horizontal button:nth-of-type(1)',
          content: 'Puedes volver al listado de unidades',
          placement: 'top'
        }
      ]
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  statusFormatter = (status) => {
    return this.props.unitStatus[status];
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render(){
    const { steps, runJoy } = this.state;
    const { unit, number, floor, status } = this.state ;
    return(
      <div className="Unit">
        <Breadcrumb>
  	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item componentClass={Link} href="/unit" to="/unit">Unidades</Breadcrumb.Item>
  	      <Breadcrumb.Item active>Detalle</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
      	<PageHeader>
          Unidad <small>Detalle</small>
        </PageHeader>
        <Form horizontal>
  	        <FormGroup controlId="unit">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Unidad{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{unit}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="number">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Número{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{number}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="floor">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Piso{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{floor}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          <FormGroup controlId="status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Estado{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.statusFormatter(status)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          <FormGroup>
              <center className="detail-buttons">
                <Link to={{ pathname:"/unit"}}>
                  <Button bsStyle="primary" className="btnShowUnit">
                    <Glyphicon glyph="chevron-left" /> Volver
                  </Button>
                </Link>
              </center>
          </FormGroup>
  	    </Form>
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          callback={this.handleJoyrideCallback}
          scrollToFirstStep={true}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }} />
      </div>
    );
  }
}
