import React, { Component, createRef } from "react";
import { invokeApig } from "../../libs/awsLib";
import "./AssetsSumary.css"
import CrudModal from "./CrudModal" 

export default class AssetsSummary extends Component {
  constructor(props) {
    super(props)
    this.assetsRef = createRef()
  }

  onDelete = async (state) => {
    return await invokeApig({
      path: "/asset/" + state.selectedElement,
      method: "DELETE"
    })
  }

  onAdd = async (state) => {
    const body = {
      assetId: state.selectedElement,
      assetName: state.elementName
    }
    return await invokeApig({
      path: "/condo",
      method: "PUT",
      body: body
    })
  }

  onModify = async (state) => {
    const id = state.selectedElement
    const name = state.elementName
    const body = {
      assetId: id,
      assetName: name
    }
    return await invokeApig({
      path: "/condo",
      method: "PUT",
      body: body
    })
  }

  handleShow = () => {
    this.assetsRef.current.handleShow()
  }

  onReturn = (resp) => {
   const elementsMap = {}
    resp.assets.forEach(element => {
      elementsMap[element.id] = element
    })
    return elementsMap
  }

  render() {
    return (
      <CrudModal mode='ASSETS' ref={this.assetsRef} elements={this.props.assets}
        onDelete={this.onDelete} onAdd={this.onAdd} onModify={this.onModify} onReturn={this.onReturn}
        title="Espacios comunes">
      </CrudModal>
    )
  }
}
