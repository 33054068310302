import React, { Component } from "react";
import {
    Col, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import { Label } from 'semantic-ui-react'
import moment from 'moment';

export default class PenaltyDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.cell,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  nameFormatter = (name, lastname) => {
    return name + " " + lastname;
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  statusFormatter = (cell) => {
    var color = ''
    switch(cell) {
      case 'PENDING':
        color = 'red';
        break;
      case 'DELETED':
        color = 'grey';
        break;
      default:
        color = 'green';
    }

    let status = this.props.penaltyStatus[cell];
    return <Label color={color} className={color}>{status}</Label>;
  }

  typeFormatter = (cell, row) => {
    var color = ''
    var cellStr = cell ? cell : 'PENALTY'
    switch(cellStr) {
      case 'PENALTY':
        color = 'red';
        break;
      case 'WARNING':
        color = 'orange';
        break;
      default:
        color = 'red';
    }

    let type = this.props.penaltyType[cellStr];
    return <Label color={color} className={color}>{type}</Label>;
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  render(){
    const { created_date, event_date, payment_info, user_who_creates,
      title, description, status, skip_reason, penalty_type } = this.state ;

    return(
      <div className="Visitor">
        <Form horizontal>
          { penalty_type &&
          <FormGroup controlId="penalty_type">
            <Col componentClass={ControlLabel} sm={6}>
              Tipo{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.typeFormatter(penalty_type)}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { created_date &&
          <FormGroup controlId="created_date">
            <Col componentClass={ControlLabel} sm={6}>
              Fecha creación{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date)}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { event_date &&
          <FormGroup controlId="event_date">
            <Col componentClass={ControlLabel} sm={6}>
              Fecha evento{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(event_date)}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          <FormGroup controlId="title">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Motivo{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{title}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="title">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Descripción{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{description}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Estado{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.statusFormatter(status)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { payment_info &&
          <FormGroup controlId="payment_info">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Folio pago{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{payment_info}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          <FormGroup controlId="user_who_creates">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Usuario{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.userFormatter(user_who_creates)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { skip_reason &&
          <FormGroup controlId="skip_reason">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Motivo eliminación{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{skip_reason}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
  	    </Form>
      </div>
    );
  }
}
