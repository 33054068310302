import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import React, { Component } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from 'semantic-ui-react'
import './PublicHome.css';

import Scrollchor from 'react-scrollchor';

const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='DeptoGestion'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Conserjería 2.0'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Link to={{ pathname:"/contact" }}>
      <Button primary size='huge'>
        Contáctanos
        <Icon name='right arrow' />
      </Button>
    </Link>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = {
    activeItem: '/home'
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed, activeItem } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        {/*<BackgroundSlider images={[image1, image2, image3, image4]} duration={8} transition={2}/>*/}
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 700, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' name='/home' active={activeItem === '/home'} onClick={this.handleItemClick}>
                  <Scrollchor to="">Inicio</Scrollchor>
                </Menu.Item>
                <Menu.Item as='a' name='/about' active={activeItem === '/about'} onClick={this.handleItemClick}>
                  <Scrollchor to="#about">Acerca de</Scrollchor>
                </Menu.Item>
                <Menu.Item position='right'>
                <Link to={{ pathname:"/login" }}>
                  <Button as='a' inverted={!fixed}>
                    Acceso conserjes
                  </Button>
                </Link>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {
    activeItem: '/home'
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name, sidebarOpened: false });
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened, activeItem } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' name='/home' active={activeItem === '/home'} onClick={this.handleItemClick}>
            <Scrollchor to="">Inicio</Scrollchor>
          </Menu.Item>
          <Menu.Item as='a' name='/about' active={activeItem === '/about'} onClick={this.handleItemClick}>
            <Scrollchor to="#about">Acerca de</Scrollchor>
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Link to={{ pathname:"/login" }}>
                    <Button as='a' inverted>
                      Acceso conserjes
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Image className="app-logo-home" rounded src="/black-logo453x150.png" responsive="true"/>
    <Segment id="about" style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              ¿Qué es DeptoGestión?
            </Header>
            <p style={{ fontSize: '1.33em' }}>
            Es un software de apoyo a la gestión de las conserjerías, desarrollado de la mano de conserjes y administradores, que facilita las tareas rutinarias de conserjes, brindando un mejor servicio a los residentes.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              ¿Qué puedes hacer con DeptoGestión?
            </Header>
            <p style={{ fontSize: '1.33em' }}>
            <ul >
              <li>Registrar las encomiendas y notificar a los residentes via email para que retiren.</li>
              <li>Registrar visitas y usos de los estacionamientos de visita.</li>
              <li>Agendar los diferentes espacios comunes de la comunidad a los residentes (Quinchos, salas, etc).</li>
              <li>Registrar las mascotas del condominio y asignar los residentes responsables.</li>
              <li>Registrar eventos en el libro de novedades.</li>
            </ul>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image size='large' src='/macbookpro15.png' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    {/*}
    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "Fácil de usar para los conserjes. Ya no usamos libros!"
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <Image avatar src='/condo/533200184.png' />
              <b>Enyel</b>, conserje en Solsticio Capital
            </p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "El control de visitas y encomiendas de DeptoGestion nos permite brindar la seguridad que la comunidad espera"
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <Image avatar src='/condo/533200184.png' />
              <b>Falconery</b>, administrador en Solsticio Capital
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    */}
    {/*<Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Breaking The Grid, Grabs Your Attention
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Instead of focusing on content creation and hard work, we have learned how to master the
          art of doing nothing by providing massive amounts of whitespace and generic content that
          can seem massive, monolithic and worth your attention.
        </p>
        <Button as='a' size='large'>
          Read More
        </Button>
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Case Studies</a>
        </Divider>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Did We Tell You About Our Bananas?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
          it's really true. It took years of gene splicing and combinatory DNA research, but our
          bananas can really dance.
        </p>
        <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button>
      </Container>
    </Segment>
    */}
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Acerca de' />
              <List link inverted>
                <List.Item as='a'><Scrollchor to="">Inicio</Scrollchor></List.Item>
                <List.Item as='a'><Link to={{ pathname:"/contact" }}>Contáctanos</Link></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Servicios' />
              <List link inverted>
                <List.Item >Visitas</List.Item>
                <List.Item >Encomiendas</List.Item>
                <List.Item >Libro novedades</List.Item>
                <List.Item >Mascotas</List.Item>
                <List.Item >Pantallas informativas</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={9} className="footer-3">
              <Header as='h4' inverted>
                ¿Quieres una demo?
              </Header>
              <p>
                Quieres una visita y demostración de nuestro software. Sólo ponte en contacto con nosotros!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)
export default HomepageLayout
