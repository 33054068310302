import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Report.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon } from "react-bootstrap";
import ListReport from "./ListReport";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      steps: [
        {
          target: '.Report .reports',
          content: 'En este listado se muestran los reportes disponibles',
          placement: 'bottom',
        },
        {
          target: '.Report .table .date-picker',
          content: 'Selecciona el mes de interés',
          placement: 'bottom',
        },
        {
          target: '.Report .table .btn-success',
          content: 'Descargar el reporte de interés haciendo click en el botón',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleSize = (size) => {
      this.setState({ size });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy } = this.state;
    const pending = this.props.condo && this.props.condo.payment && this.props.condo.payment.pending;
    return (
      <div className="Report">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Reportes</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
        <span className="pheader">Reportes mensuales</span>
	    </PageHeader>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        { pending &&
          <span>
            <p>Atención: se registra deuda pendiente por servicio.</p>
            <p>No es posible generar reportes</p>
          </span>
        }
        { !pending &&
          <ListReport {...this.props} onHandleSize={this.handleSize} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} />
        }

        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
          />
      </div>
    );
  }

}
