import React, { Component } from "react";
import {
    Col, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import { Icon, Label, Image } from 'semantic-ui-react'
import { formatRut } from '../../libs/validation';
import moment from 'moment';
import config from "../../config";
import './VisitorDetailModal.css';


export default class VisitorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.cell,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  nameFormatter = (name, lastname) => {
    return name + " " + lastname;
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  statusFormatter = (status) => {
    return this.props.visitorStatus[status];
  }

  typeFormatter = (type) => {
    return <span><Label color="red">{this.props.visitType[type]}</Label></span>;
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  residentFormatter = (resident) => {
    return resident
    && typeof(resident.name) !== 'undefined'
    && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
  }

  licenseFormatter= (car_license_plate, car_park) => {
    return <span>
      <Label>{car_license_plate}</Label>
      <Label>{car_park}</Label></span>;
  }

  companyFormatter = (company) => {
    return <Label color='blue'>{company}</Label>;
  }

  elapsedFormatter = (cell, color) => {
    if (cell) {
      let hours = parseInt(cell / 60, 10).toString();
      let minutes = parseInt(cell % 60, 10).toString();
      let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
      return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
    }
  }

  render(){
    const { card_id, card_id_type, name, lastname, created_date,
      user_who_receives, departure_date, visitors_number,
      user_who_departures, user_who_penalty,
      car_license_plate, car_park, exceeded_time,
      elapsed_time, exceeded_status, penalty_date, reason,
      penalty_payment_info, penalty_skip_reason, resident,
      company, provider_type, visitor_picture_url, visit_type} = this.state ;

    const id = card_id_type === 'OTHER' ? card_id : formatRut(card_id);

    return(
      <div className="Visitor">
        <Form horizontal>
          { provider_type &&
          <FormGroup controlId="provider_type">
            <Col componentClass={ControlLabel} sm={6}>
              Tipo de proveedor{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{provider_type}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { company &&
          <FormGroup controlId="company">
            <Col componentClass={ControlLabel} sm={6}>
              Empresa{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.companyFormatter(company)}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { reason &&
          <FormGroup controlId="reason">
            <Col componentClass={ControlLabel} sm={6}>
              Motivo de visita{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{reason}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          <FormGroup controlId="card_id">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        RUT (u otro){' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{id}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="name">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Visita{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.nameFormatter(name, lastname)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="visitors_number">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Nº Visitas{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{visitors_number}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { resident &&
          <FormGroup controlId="resident">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Residente{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.residentFormatter(resident)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
  		    <FormGroup controlId="created_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Ingreso{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date) + ' ('+ this.userFormatter(user_who_receives) + ')'}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          { departure_date &&
          <FormGroup controlId="departure_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Salida{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(departure_date) + ' ('+ this.userFormatter(user_who_departures) + ')'}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { car_license_plate && car_license_plate !== '-' &&
          <FormGroup controlId="car_license_plate">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Vehículo{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.licenseFormatter(car_license_plate, car_park)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { elapsed_time &&
          <FormGroup controlId="elapsed_time">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Tiempo total{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.elapsedFormatter(elapsed_time, 'blue')}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { typeof(exceeded_time) !== "undefined" && exceeded_time > 0 &&
          <FormGroup controlId="exceeded_time">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Tiempo excedido{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.elapsedFormatter(exceeded_time, 'red')}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { user_who_penalty &&
          <FormGroup controlId="created_date">
            <Col componentClass={ControlLabel} sm={6}>
              Fecha y responsable de multa{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(penalty_date) + ' ('+ this.userFormatter(user_who_penalty) + ')'}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { exceeded_status && exceeded_status === 'EXCEEDED_WITH_PENALTY' &&
          <FormGroup controlId="exceeded_status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Folio pago{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{penalty_payment_info}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { exceeded_status && exceeded_status === 'EXCEEDED_WITH_NO_PENALTY' &&
          <FormGroup controlId="exceeded_status">
            <Col componentClass={ControlLabel} sm={6}>
              Razón de omisión{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{penalty_skip_reason}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { visitor_picture_url &&
          <FormGroup controlId="visitor_picture_url">
            <Col componentClass={ControlLabel} sm={6}>
              Foto{' '}
            </Col>
            <Col sm={6}>
              <Image
                className="visitor_picture"
                size='small'
                src={config.s3_files.BUCKET + visitor_picture_url} />
            </Col>
          </FormGroup>
          }
          { visit_type &&
          <FormGroup controlId="visit_type">
            <Col componentClass={ControlLabel} sm={6}>
              Tipo{' '}
            </Col>
            <Col sm={6}>{this.typeFormatter(visit_type)}</Col>
          </FormGroup>
          }
  	    </Form>
      </div>
    );
  }
}
