import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AccessControlDetailModal from "./AccessControlDetailModal";
import moment from 'moment';
import './ListAccessControl.css';
import {sortByKey} from '../../libs/arrays';
import { validateNumber, validateText, validateLicense, validateCarPark, formatRut } from '../../libs/validation';

export default class ListAccessControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            disableEdit: true,
            has_car: false,
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            editCandidate: null,
            detailCandidate: null,
            deleteCandidate: null,
            departuresCandidate: null,
            actionCandidateIndex: -1,
            showDetails: false,
            showDelete: false,
            showEdit: false,
            showDeparture: false,
            isDepartured: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            visitors: [],
            suggestions: {
              card_id: [],
              name: [],
              lastname: [],
              car_license_plate: []
            }
        };
    }

    componentDidMount() {
      this.findAccessLog();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findAccessLog();
        return;
      }
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findAccessLog();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findAccessLog = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.accessLog();
        this.props.onCheckPaymentAdvice(results);
        const visitors = sortByKey(results.access_log, "created_date")
        this.setState({ visitors: visitors });
        this.props.onHandleSize(visitors.length);
      } catch (e) {
        alert(e)
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteAccess();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar acceso!" });
        } else {
          this.state.visitors.splice(this.state.actionCandidateIndex, 1);
          this.handleAlertSuccess({ message: "Acceso eliminado correctamente!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar acceso!" });
      }
    }

    handleDeparture = async event => {
      event.preventDefault();
      this.setState({ isDepartured: true });
      try {
        const access = {
          operation: "DEPARTURE",
          created_date: this.state.departuresCandidate.created_date
        };

        const response = await this.updateAccess(access);
        this.setState({ isDepartured: false, showDeparture: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al marcar salida!" });
        } else {
          this.handleAlertSuccess({ message: "Salida marcada correctamente!" });
          this.findAccessLog();
        }
      } catch (e) {
        this.setState({ isDepartured: false, showDeparture: false, showAlertError: true, alertMessage: "Error al marcar salida!" });
      }
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '',
        name:'', lastname: '', visitors_number: 1,
        has_car: false, car_license_plate: '', car_park: '', status: '',
        showError: false, errorMessage: ''
      });
    }

    updateAccess(access) {
      access.unitId = this.state.departuresCandidate["unit"];
      return invokeApig({
        path: `/access`,
        method: "PUT",
        body: access
      });
    }

    accessLog() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/access/find",
        method: "POST",
        body: {
            "summary": "S",
            "year_month_search": selectedDateMoment.local().format("YYYY-MM")
        }
      });
    }

    deleteAccess() {
      return invokeApig({
        path: "/access",
        method: "DELETE",
        body: {
          "unitId": this.state.deleteCandidate["unit"],
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.lastname)) {
        message = 'Debe ingresar apellido';
        return message;
      }

      if (!validateNumber(this.state.visitors_number)) {
        message = 'Debe ingresar número de personas';
        return message;
      }

      if (this.state.has_car) {
        if (!validateLicense(this.state.car_license_plate)) {
          message = 'Debe ingresar patente válida, ej: AABB77';
          return message;
        }
        if (!validateCarPark(this.state.car_park)) {
          message = 'Debe ingresar estacionamiento válido, ej: 5V o 5 Visita';
          return message;
        }
      }
      this.setState({showError: false});
      return message;
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener accesos. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.resident.card_id_type === 'OTHER' ? row.resident.card_id : formatRut(row.resident.card_id);
      return <span><Link to={{}} onClick={this.showDetails.bind(cell, row)}>{id}</Link></span>;
    }

    unitFormatter = (cell, row) => {
      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;
      return <span><Link to={{}} onClick={this.showDetails.bind(cell, row)}>{unit}</Link></span>;
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    accessFormatter = (cell, row) => {
      if (row.access) {
        return row.access.name;
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.resident.name + " " + row.resident.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter= (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        <Label>{row.car_park}</Label></span>
        :
        <Icon name="male" />;
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    rowClassNameFormat = (row, rowIdx) => {
      if (row['status'] === 'DEPARTED') return 'departed';
      if (row['car_park'] === '-' && row['car_license_plate'] === '-') return;
      return 'received';
    }

    drawButton = (cell, row, index, enumObject) => {
      return row['status'] === 'ACCESS_GRANTED' ?
          <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Marcar salida</Tooltip>}>
              <Button bsStyle="success" onClick={this.showDoDeparture.bind(cell, row, index, enumObject)} className="btnDoDelivery">
                <Glyphicon glyph="log-out" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, index, enumObject)} className="btnDeleteDelivery">
                <Glyphicon glyph="trash" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
         :
         <ButtonGroup className="actionsBtn">
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
             <Button onClick={this.showDetails.bind(cell, row)}  className="btnShowDelivery">
               <Glyphicon glyph="search" />
             </Button>
           </OverlayTrigger>
         </ButtonGroup>;
    }

    showEditVisitor = (cell, row, index, enumObject) => {
      this.setState({ name: cell.name, lastname: cell.lastname,
        card_id: cell.card_id, card_id_type: cell.card_id_type ? cell.card_id_type : 'RUT', });
      this.setState({ visitors_number: cell.visitors_number, actionCandidateIndex: index });
      this.setState({ showEdit: true, editCandidate: cell });
      if (cell.car_license_plate && cell.car_license_plate !== '-'
        && cell.car_park && cell.car_park !== '-') {
        this.setState({ has_car: true, car_license_plate: cell.car_license_plate, car_park: cell.car_park });
      }
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setState({ showDelete: true, actionCandidateIndex: index, deleteCandidate: cell });
    }

    showDoDeparture = (cell, row, index, enumObject) => {
      this.setState({ showDeparture: true, actionCandidateIndex: index, departuresCandidate: cell });
    }

    showDetails = (cell, row) => {
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    showVisitorPicture = (cell, row, index, enumObject) => {
      this.setState({ showPicture: true, actionCandidateIndex: index, detailCandidate: cell });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleClose = () => {
      this.setState({
        has_car: false, actionCandidateIndex: -1,
        showDelete: false, deleteCandidate: null,
        showDetails: false, detailCandidate: null,
        showDeparture: false, departuresCandidate: null,
        showEdit: false, editCandidate: null, showPicture: false,
        disableEdit: true });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.id === "has_car" ? event.target.checked:event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.has_car === this.state.editCandidate.has_car
            && this.state.name === this.state.editCandidate.name
            && this.state.lastname === this.state.editCandidate.lastname
            && this.state.visitors_number === this.state.editCandidate.visitors_number;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.visitors;
        return(
          <div className="AccessControl">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="accessControls">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                trClassName={this.rowClassNameFormat}>
                <TableHeaderColumn
                  dataField='unit'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese unidad', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.unitFormatter }>Unidad</TableHeaderColumn>
                <TableHeaderColumn
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese Nº Personas', delay: 10 } }
                  width='100'
                  dataSort={ true }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='visitors_number'>Nº Personas</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese accesso', delay: 10 } }
                  dataSort={ true }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='access'
                  dataFormat={ this.accessFormatter }>Acceso</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese observación', delay: 10 } }
                  dataSort={ true }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='detail'>Observación</TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_creates'
                  dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='departure_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Salida
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_departures'
                  dataFormat={ this.userFormatter }>Conserje (salida)</TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más accesos...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.state.deleteCandidate && this.state.deleteCandidate["unit"]}</Label>
                <Label color='red'>ELIMINACIÓN</Label> Eliminar accesso de residente</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>¿Está seguro que quiere eliminar el accesso a <Label color='red'>{this.state.deleteCandidate.access.name}</Label> de <Label color='red'>{this.visitorFormatter(this.state.deleteCandidate.resident)}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDeparture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.state.departuresCandidate && this.state.departuresCandidate["unit"]}</Label>
                <Label color='green'>SALIDA</Label> Marcar salida de residente</Modal.Title>
              </Modal.Header>
              { this.state.departuresCandidate &&
                <Modal.Body>¿Confirma la salida de <Label color='green'>{this.visitorFormatter(this.state.departuresCandidate.resident)}</Label> desde <Label color='green'>{this.state.departuresCandidate.access.name}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="success" isLoading={this.state.isDepartured} onClick={this.handleDeparture} text="Salida" loadingText="Guardando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.state.detailCandidate && this.state.detailCandidate["unit"]}</Label> Detalle del acceso
                </Modal.Title>
              </Modal.Header>
              <AccessControlDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>

          </div>
        );
    }

}
