import React, { Component } from "react";
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Button, Glyphicon, Col, Alert, ButtonGroup, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import { Icon, Label, Image } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import 'moment/locale/es';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import { formatRut } from '../../libs/validation';
import VisitorDetailModal from "./VisitorDetailModal";
import config from "../../config";

const provider = 'provider';

export default class ListVisitorDaily extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            profile: '',
            disablePenalty: true,
            payment_info: '',
            penalty_skip_reason: '',
            detailCandidate: null,
            unit: '',
            unit_name: '',
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            penalty_status: "PENALTY",
            isLoading: true,
            isError: false,
            showDetails: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            lastId: '',
            unitDetail: '',
            visitors: []
        };
    }

    componentDidMount = async () => {
      const user = await getUserAttributes();
      this.setState({ profile: user.profile });

      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findVisitors();
      }

      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitors();
        const visitors = sortByKey(results.visitors, "created_date")
        // const visitors = results.visitors;
        this.setState({ visitors: visitors });
        this.setState({ lastId: results.lastId });
        this.props.onHandleSize(visitors.length);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    visitors() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "daily": "EC",
            "day_search": selectedDateMoment.local().format("YYYY-MM-DD")
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas excedidas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);

      var type = "";
      if (typeof(row.visit_type) !== 'undefined') {
        type = <span><Icon color='red' name='food' fitted/> <Icon color='red' name='dolly' fitted/></span>
      }

      return <span><Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{id}</Link> {type}</span>;
    }

    unitFormatter = (cell, row) => {
      if (cell === provider)
        return <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">{row['provider_type']}</Tooltip>}>
            <Label color='blue'>{row['company']}</Label>
        </OverlayTrigger>;

      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{unit}</Link>;
    }

    unitFormatterInModalTitle = () => {
      if (this.state.unitDetail === provider)
        return "Proveedor";
      return this.state.unit_name !== '' ? this.state.unit_name:this.state.unitDetail
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter = (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        {row.car_park && <Label>{row.car_park}</Label>}
        </span>
        : <Icon name="male" />;
    }

    parkFormatter= (cell, row) => {
      return cell && cell !== "-" ? <Label>{cell}</Label> : '';
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    setUnitToShow = (cell) => {
      if (typeof(cell.unit_name) !== 'undefined') {
        this.setState({ unit_name: cell.unit_name });
      }
      this.setState({ unitDetail: cell.unit });
    }

    showVisitorPicture = (cell, row, rowIdx) => {
      this.setState({ showPicture: true, detailCandidate: cell });
    }

    drawButton = (cell, row, rowIdx) => {
      return <ButtonGroup className="actionsBtn">
               <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
                 <Button onClick={this.loadDetails.bind(cell, row, rowIdx)} className="btnShowDelivery">
                   <Glyphicon glyph="search" />
                 </Button>
               </OverlayTrigger>
               {this.props.condo && this.props.condo.picture.visitor && typeof(row.visitor_picture_url) !== 'undefined' &&
               <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
                 <Button bsStyle="primary" onClick={this.showVisitorPicture.bind(cell, row)} className="btnShowVisitor">
                   <Glyphicon glyph="camera" />
                 </Button>
               </OverlayTrigger>
               }
             </ButtonGroup>;
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleClose = () => {
      this.setState({
        penalty_status: 'PENALTY',
        payment_info: '', penalty_skip_reason: '',
        disablePenalty: true, showError: false,
        showDetails: false, detailCandidate: null,
        showDelete: false, deleteCandidate: null, unit: '', unit_name: '',
        showPenalty: false, exceededCandidate: null, showPicture: false,
        showEdit: false, editCandidate: null, unitDetail: '' });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        //do nothing
      });
    }

    handleChangeRadio = (e, { value }) => this.setState(
      { penalty_status: value,
        payment_info: '',
        penalty_skip_reason: '',
        disablePenalty: true
      }
    );

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption(),
          exportCSVText: 'Exportar a CSV'
        };
        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                csvFileName='visitas-pendientes.csv'
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                hover>
                <TableHeaderColumn
                  dataField='unit'
                  filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.unitFormatter }>
                  <Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter }>Estado</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' headerAlign='center' dataAlign='center' dataField='visitors_number'>Nº Visitas</TableHeaderColumn>*/}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                {/*<TableHeaderColumn dataField='user_who_receives' dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>*/}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='departure_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Salida
                </TableHeaderColumn>
                {/*<TableHeaderColumn dataField='user_who_departures' dataFormat={ this.userFormatter }>Conserje (salida)</TableHeaderColumn>*/}
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese patente', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='car_license_plate'
                  dataFormat={ this.licenseFormatter }>
                  <Icon name="car" />Vehículo
                </TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.unitFormatterInModalTitle()}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
            <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  <Label color='blue'><Icon name='user' />Nombre:</Label> {this.state.detailCandidate && this.visitorFormatter(this.state.detailCandidate)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.detailCandidate &&
                  <Image
                    className="visitor_picture_modal"
                    src={config.s3_files.BUCKET + this.state.detailCandidate.visitor_picture_url} />
                }
              </Modal.Body>
            </Modal>
          </div>
        );
    }
}
