import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import { invokeApig } from "../../libs/awsLib";
import { validateText, validateTextAndNumber, validateFreeText2 } from '../../libs/validation';
import Autosuggest from '../../libs/autosuggest/Autosuggest';
import moment from 'moment'
import 'moment/locale/es';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./NewDailyBook.css";
import { Link } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class NewDailyBook extends Component {
  constructor(props) {
    super(props);
    this.maxLengthDescription = 1000;
    this.state = {
      description_counter: 0,
      send_email: false,
      send_email_concierge: false,
      selectedTime: new Date(),
      person_in_charge: '',
      description: '',
      title: '',
      isLoading: false,
      isLoadEdit: false,
      show: false,
      showError: false,
      errorMessage: '',
      value: '',
      maxLengthExceed: false,
      suggestions: ['Ingreso', 'Egreso', 'Ronda', 'Nota', 'Inicio de turno', 'Fin de turno', 'Basura', 'Llaves', 'Mantención', 'Proveedor']
    };
    this.handleChangeTextarea = this.handleChangeTextarea.bind(this);
  }

  handleChangeTime = (time) => {
    if (time === null || time === "") {
      time = new Date();
    }
    this.setState({ selectedTime: time });
  }

  handleChange = event => {
    if (event.target.id === "send_email") {
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleChangeTextarea = value => {
    let cleanText = this.removeHTML(value);

    if (cleanText.length > this.maxLengthDescription) {
      this.setState({
        maxLengthExceed: true,
        showError:true,
        errorMessage: 'Excede el largo máximo permitido'
      })

      window.setTimeout(()=>{this.setState({showError:false})}, 3000)
      return
    }

    this.setState({
      description: value,
      description_counter: cleanText.length,
      maxLengthExceed: false
    })
  }

  removeHTML = (str) =>{
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  createBookEvent(bookEvent) {
    return invokeApig({
      path: "/book",
      method: "POST",
      body: bookEvent
    });
  }

  onChangeTitle = (event) => {
    this.setState({
      title: event
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.resetForm();
    this.setState({ show: true });
  }

  resetForm = () => {
    this.setState({ person_in_charge:'', title: '', description: '', description_counter: 0,
      showError: false, errorMessage: '', selectedTime: new Date(), send_email: false, maxLengthExceed: false
    });
  }

  getErrors() {
    let message = undefined;

    if (!validateTextAndNumber(this.state.title)) {
      message = 'Debe ingresar tipo de evento';
      return message;
    }

    if (!validateText(this.state.person_in_charge)) {
      message = 'Debe ingresar responsable';
      return message;
    }

    if (!validateFreeText2(this.state.description)) {
      message = 'Debe ingresar descripción del evento';
      return message;
    }

    this.setState({showError: false});
    return message;
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const selectedTimeMoment = moment(this.state.selectedTime);
      const selectedDateMoment = moment(this.props.selectedDate);
      let time = selectedTimeMoment.local().format("HH:mm");
      let event_date = selectedDateMoment.local().format("YYYY-MM-DD") + ' ' + time;
      let date = selectedDateMoment.local().format("YYYYMMDD");

      const bookEvent = {
        person_in_charge: this.state.person_in_charge,
        time: time,
        date: date,
        event_date: event_date,
        description: this.state.description,
        title: this.state.title,
        type: 'DAILY',
        send_email: this.state.send_email
      };

      const response = await this.createBookEvent(bookEvent);
      if (response.code !== 0) {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear evento! " + msgExtra });
      } else {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Evento ingresado exitosamente!", refresh: true }, bookEvent);
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.handleClose();
      this.props.onAlertError({ message: "Error al registrar evento! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  render() {
    const selectedDateMoment = moment(this.props.selectedDate);
    return (
      <div className="diaryBookBtn">

      <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar evento</Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Label color='blue'>
              <Icon name='calendar' />{selectedDateMoment.local().format("DD-MM-YYYY")}
            </Label> Datos del evento
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  renderFormNew(){
    return(
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="selectedTime">
          <Col componentClass={ControlLabel} sm={3}>
            Hora (*)
          </Col>
          <Col sm={8}>
          <DatePicker
            className="time-picker  form-control"
            selected={this.state.selectedTime}
            onChange={this.handleChangeTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            locale="es-CL"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            timeCaption="Hora"
          />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Hora de evento.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="title">
          <Col componentClass={ControlLabel} sm={3}>
            Tipo Evento (*)
          </Col>
          <Col sm={8}>
            <Autosuggest
              datalist={this.state.suggestions}
              placeholder="Ej: Ronda"
              onChange={this.onChangeTitle}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tipo de evento (ronda, basura, etc).</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="person_in_charge">
          <Col componentClass={ControlLabel} sm={3}>
            Responsable (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.person_in_charge}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Responsable del evento.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="description">
          <Col componentClass={ControlLabel} sm={3}>
            Descripción (*)
          </Col>
          <Col sm={8}>
            <ReactQuill id="description"
              value={this.state.description}
              onChange={this.handleChangeTextarea}
              maxLength={this.maxLengthDescription}/>
              <span>{this.state.description_counter}/{this.maxLengthDescription}</span>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">De qué se trata el evento.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="send_email">
          <Col componentClass={ControlLabel} sm={3}>
            Avisar a administración?
          </Col>
          <Col sm={1}>
            <FormControl
              onChange={this.handleChange}
              type='checkbox'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Si se marca la casilla, se enviará correo a la administración con esta novedad</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>

        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewDailyBook
