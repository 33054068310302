import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import { Icon, Menu, Dropdown } from 'semantic-ui-react'
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { sortByKeyDesc } from '../../libs/arrays';
import Select from 'react-select';
import './HMenu.css';
import { checkAccess } from '../../libs/commons'
import { connect } from 'react-redux';
import { updatePlan } from "../../state/Actions";

function mapDispatchToProps(dispatch) {
  return {
    updatePlan: plan => dispatch(updatePlan(plan))
  }
}

function mapStateToProps(state) {
  return {
    plan: state.plan
    }
}

export class HMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      isClearable: true,
      isRtl: false,
      isSearchable: true,
      activeItem: '',
      selectedOption: '',
      profile: '',
      preferred_username: null,
      units: []
    }
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ profile: user.profile, preferred_username: user.preferred_username });
    this.updateUnits();
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (this.props.onGoMenu) {
      this.props.onGoMenu(name);
    }
  }

  handleLogoutClick = () => {
    if (this.props.onLogout) {
      this.props.onChangeUnit(null);
      this.props.onLogout();
    }
  }

  changeRoutePath = (e, link) => {
      e.preventDefault();
      this.props.onGoMenu(link);
  }

  handleChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : '';
    this.setState({ selectedOption: option });

    if (option === '') {
      this.props.onChangeUnit(null);
      return
    }

    const optionFull = this.state.units.filter((unit,index,arr)=>{
      return unit["unit"] === option
    })

    this.props.onChangeUnit(optionFull[0]);
  };

  updateUnits = async () => {
    this.setState({ isLoading: true, units: [] });
    try {
      const results = await this.getCondo();
      const units = sortByKeyDesc(results.units, "number")
      this.props.onSetCondo(results.condo);
      this.setState({ units: units });
      this.props.updatePlan(results.condo.plan)
    } catch (e) {
      console.error(e)
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  units() {
      return invokeApig({
          path: "/unit/find",
          method: "POST",
          body: {}
      });
  }

  getCondo() {
      return invokeApig({
          path: "/condo",
          method: "GET"
      });
  }

  render() {
    const {
       isClearable,
       isSearchable,
       isLoading,
       isRtl,
       activeItem,
       selectedOption
    } = this.state;

    const options = this.state.units.map(v => ({
        label: v.hasOwnProperty('unit_name') ? v.unit_name : v.unit,
        value: v.unit
      }));

    const suspended = this.props.condo && this.props.condo.payment && this.props.condo.payment.suspended;

    return (
      <Menu stackable className="h-menu">
        <Menu.Item className="logo_item" onClick={(e) => this.changeRoutePath(e, '/')}>
          {this.state.preferred_username &&
          <img className="logo_image" alt="Condominio" src={"/condo/" + this.state.preferred_username + ".png"} />
          }
        </Menu.Item>
        { this.state.profile !== 'Comite' &&
        <React.Fragment>
        <Menu.Item className='concierge unit-select-menu' color='blue'>
          <Select
            className="h-basic-single"
            classNamePrefix="select"
            isDisabled={suspended}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name="unit"
            placeholder="Seleccione unidad"
            onChange={this.handleChange}
            options={options}
          />
        </Menu.Item>
        <Dropdown item text='Conserjería' className="concierge concierge-menu" color='blue' disabled={suspended}>
          <Dropdown.Menu>
              <Dropdown.Item icon='dolly' text="Encomiendas" name='/delivery' active={activeItem === '/delivery'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>
              <Dropdown.Item icon='users' text="Visitas" name='/visitor' active={activeItem === '/visitor'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>
              <Dropdown.Item icon='user times' text="Visitas en lista negra" name='/visitor/blocked' active={activeItem === '/visitor/blocked'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>
              <Dropdown.Item icon='user' text="Residentes" name='/resident' active={activeItem === '/resident'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>
              {checkAccess('ADVANCED', 'EXPERT') && <Dropdown.Item icon='paw' text="Mascotas" name='/pet' active={activeItem === '/pet'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>}
              { this.props.condo && this.props.condo.access_control &&
                <Dropdown.Item icon='key' text="Accesos controlados" name='/access/control' active={activeItem === '/access/control'} disabled={selectedOption === ''} onClick={this.handleItemClick}/>
              }
          </Dropdown.Menu>
        </Dropdown>
        </React.Fragment>
        }
        <Dropdown item text='Resumen' className="summary-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='dolly' text="Encomiendas recibidas" name='/delivery/summary' active={activeItem === '/delivery/summary'} onClick={this.handleItemClick}/>
            <Dropdown.Item icon='car' text="Visitas en vehículo" name='/visitor/summary' active={activeItem === '/visitor/summary'} onClick={this.handleItemClick}/>
            <Dropdown.Item icon='users' text="Visitas del día" name='/visitor/daily' active={activeItem === '/visitor/daily'} onClick={this.handleItemClick}/>
            {checkAccess('ADVANCED', 'EXPERT') &&
              <Dropdown.Item icon='paw' text="Mascotas del condominio" name='/pet/summary' active={activeItem === '/pet/summary'} onClick={this.handleItemClick}/>
            }
            <Dropdown.Item icon='user' text="Residentes del condominio" name='/resident/summary' active={activeItem === '/resident/summary'} onClick={this.handleItemClick}/>
            { this.props.condo && this.props.condo.access_control &&
              <Dropdown.Item icon='key' text="Accessos del condominio" name='/access/control/summary' active={activeItem === '/access/control/summary'} onClick={this.handleItemClick}/>
            }
          </Dropdown.Menu>
        </Dropdown>
        {checkAccess('ADVANCED', 'EXPERT') &&
          <Dropdown item text='Reservas' className="booking-menu" disabled={suspended}>
            <Dropdown.Menu>
              <Dropdown.Item icon='calendar alternate outline' text="Reservar espacio común" name='/booking' active={activeItem === '/booking'} onClick={this.handleItemClick}/>
              { this.state.profile !== 'Operador' &&
              <Dropdown.Item icon='table' text="Listado de reservas" name='/booking/summary' active={activeItem === '/booking/summary'} onClick={this.handleItemClick}/>
              }
            </Dropdown.Menu>
          </Dropdown>
        }
        <Dropdown item text='Libros' className="book-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='book' text="Novedades" name='/book/diarybook' active={activeItem === '/book/diarybook'} onClick={this.handleItemClick}/>
            { this.state.profile !== 'Operador' &&
            <Dropdown.Item icon='book' text="Comunicaciones" name='/book/notice' active={activeItem === '/book/notice'} onClick={this.handleItemClick}/>
            }
          </Dropdown.Menu>
        </Dropdown>
        {checkAccess('ADVANCED', 'EXPERT') &&
        <Dropdown item text='Proveedores' className="provider-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='wrench' text="Visitas" name='/visitor/provider' active={activeItem === '/visitor/provider'} onClick={this.handleItemClick}/>
          </Dropdown.Menu>
        </Dropdown>
        }
        { this.state.profile !== 'Operador' &&
          <React.Fragment>
            {checkAccess('ADVANCED', 'EXPERT') &&
            <Dropdown item text='Multas' disabled={suspended}>
              <Dropdown.Menu>
                <Dropdown.Item icon='car' text="Visitas con multa pendiente" name='/visitor/exceeded' active={activeItem === '/visitor/exceeded'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='clipboard outline' text="Visitas multadas" name='/visitor/infracted' active={activeItem === '/visitor/infracted'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='edit outline' text="Multas generales" name='/penalty/summary' active={activeItem === '/visitor/infracted'} onClick={this.handleItemClick}/>
              </Dropdown.Menu>
            </Dropdown>
            }
            <Dropdown item text='Administración' disabled={suspended}>
              <Dropdown.Menu>
                {checkAccess('EXPERT') &&
                  <Dropdown.Item icon='download' text="Reportes" name='/report' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
                }
                <Dropdown.Item icon='building' text="Unidades" name='/unit' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='user' text="Usuarios" name='/user' active={activeItem === '/user'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='settings' text="Configuración general" name='/settings' active={activeItem === '/settings'} onClick={this.handleItemClick}/>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        }
        { this.state.profile === 'Operador' &&
          <React.Fragment>
            <Dropdown item text='Otros' disabled={suspended}>
              <Dropdown.Menu>
                <Dropdown.Item icon='building' text="Unidades" name='/unit' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        }
        <Menu.Item position='right' className='sign-out' onClick={this.handleLogoutClick}>
          <Icon name='sign-out' />
          Salir
        </Menu.Item>
      </Menu>
    )
  }
}

const connectApp = connect(mapStateToProps, mapDispatchToProps)(HMenu)
export default withRouter(connectApp)
