import React, { Component } from "react";
import { Breadcrumb, Glyphicon, PageHeader, Grid, Row, Col, Modal, Button } from "react-bootstrap";
import ContactForm from './ContactForm';
import { Image } from "react-bootstrap";
import './Contact.css';
import text from '../../config.text';

export default class Contact extends Component {
  constructor(props) {
  	super(props);
  	this.state = {
      show: false
  	};
  }

  handleClickMap = (evt) => {
    evt.preventDefault();
    this.setState({show: true});
  }

  handleCloseMap = () => {
    this.setState({show: false});
  }

  render() {
    return (
      <div className="Contact">
        <Grid>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
            <Breadcrumb.Item active>Contacto</Breadcrumb.Item>
    	  </Breadcrumb>
  	    <PageHeader>
        <center>
          <Image
            className="logo_header"
            rounded
            responsive
            src={"/black-logo453x150.png"}/>
          </center>
          Contacto
        </PageHeader>
        <Row>
          <Col md={6}>
            {
              text.contact.address &&
              <div>
                <h3>Vis&iacute;tanos</h3>
                <p>
                  <Glyphicon glyph='map-marker' /> <a href="#address" onClick={this.handleClickMap}>{ text.contact.address }</a>
                </p>
                <hr/>
              </div>
            }
            <div>
              <h3>Ll&aacute;manos</h3>
              {
                text.contact.cellphone &&
                <p><Glyphicon glyph='phone' /> <a href={ "tel:" + text.contact.cellphone.replace(' ', '') }>{ text.contact.cellphone }</a></p>
              }
              {
                text.contact.phone &&
                <p><Glyphicon glyph='phone-alt' /> <a href={"tel:" + text.contact.phone.replace(' ', '') }>{text.contact.phone}</a></p>
              }
            </div>
            <hr/>
            <div>
              <h3>Escr&iacute;benos</h3>
              <p>
                <Glyphicon glyph='envelope' /> <a href={"mailto:" + text.contact.email} target="_blank" rel="noopener noreferrer">{text.contact.email}</a>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <ContactForm />
          </Col>
        </Row>
        <Modal show={this.state.show} onHide={this.handleCloseMap} style={{width: '630px', margin: '0 auto'}}>
          <Modal.Header>
            <Modal.Title>Nuestra ubicaci&oacute;n</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe title="Mapa" src={"https://maps.google.com/maps?q=" + text.contact.address + "&t=&z=13&ie=UTF8&iwloc=&output=embed"} width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseMap}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        </Grid>
      </div>
    );
  }
}
