import React, { Component } from "react";
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Button, Glyphicon, Col, Alert, ButtonGroup, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import 'moment/locale/es';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import { formatRut } from '../../libs/validation';
import VisitorDetailModal from "./VisitorDetailModal";

export default class ListVisitorInfracted extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: '',
            disablePenalty: true,
            payment_info: '',
            penalty_skip_reason: '',
            detailCandidate: null,
            unit: '',
            unit_name: '',
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            penalty_status: "PENALTY",
            isLoading: true,
            isError: false,
            showDetails: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            lastId: '',
            visitors: []
        };
    }

    componentDidMount = async () => {
      const user = await getUserAttributes();
      this.setState({ profile: user.profile });

      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findVisitors();
        return;
      }

      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitors();
        const visitors = sortByKey(results.visitors, "created_date")
        // const visitors = results.visitors;
        this.setState({ visitors: visitors });
        this.setState({ lastId: results.lastId });
        this.props.onHandleSize(visitors);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    visitors() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "infracted_status": "EC",
            "year_month_search": selectedDateMoment.local().format("YYYY-MM")
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas excedidas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{id}</Link>;
    }

    unitFormatter = (cell, row) => {
      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{unit}</Link>;
    }

    unitFormatterInModalTitle = () => {
      return this.state.unit_name !== '' ? this.state.unit_name:this.state.unit
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    elapsedFormatter = (cell, row) => {
      if (cell) {
        // let duration = moment.duration({"minutes": cell});//.humanize();
        let hours = parseInt(cell / 60, 10).toString();
        let minutes = parseInt(cell % 60, 10).toString();
        // return duration.humanize();
        let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
        let color = cell === row["elapsed_time"] ? "blue": "red";
        return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
      }
    }

    timeFormatter = (cell, color) => {
      if (cell) {
        let hours = parseInt(cell / 60, 10).toString();
        let minutes = parseInt(cell % 60, 10).toString();
        let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
        return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter = (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        <Label>{row.car_park}</Label></span>
        : '';
    }

    exceededStatusFormatter = (cell, row) => {
      return cell && cell === 'EXCEEDED_WITH_PENALTY'?
          <OverlayTrigger placement="right" overlay={<Tooltip id="modal-tooltip">Folio: {row.penalty_payment_info}</Tooltip>}>
            <Label color="red">SI</Label>
          </OverlayTrigger>
        :
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="modal-tooltip">Motivo: {row.penalty_skip_reason}</Tooltip>}>
          <Label color="green">NO</Label>
        </OverlayTrigger>
    }

    licenseFormatterFromExceeded= () => {
      return <span>
        <Label>{this.state.exceededCandidate.car_license_plate}</Label>
        <Label>{this.state.exceededCandidate.car_park}</Label></span>;
    }

    parkFormatter= (cell, row) => {
      return cell && cell !== "-" ? <Label>{cell}</Label> : '';
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    setUnitToShow = (cell) => {
      if (typeof(cell.unit_name) !== 'undefined') {
        this.setState({ unit_name: cell.unit_name });
      }
      this.setState({ unit: cell.unit });
    }

    drawButton = (cell, row, rowIdx) => {
      return <ButtonGroup>
               <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
                 <Button  onClick={this.loadDetails.bind(cell, row, rowIdx)}  className="btnShowDelivery">
                   <Glyphicon glyph="search" />
                 </Button>
               </OverlayTrigger>
             </ButtonGroup>;
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleClose = () => {
      this.setState({
        penalty_status: 'PENALTY',
        payment_info: '', penalty_skip_reason: '',
        disablePenalty: true, showError: false,
        showDetails: false, detailCandidate: null,
        showDelete: false, deleteCandidate: null, unit: '', unit_name: '',
        showPenalty: false, exceededCandidate: null,
        showEdit: false, editCandidate: null });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        //do nothing
      });
    }

    handleChangeRadio = (e, { value }) => this.setState(
      { penalty_status: value,
        payment_info: '',
        penalty_skip_reason: '',
        disablePenalty: true
      }
    );

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption(),
          exportCSVText: 'Exportar a CSV'
        };
        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                csvFileName='visitas-pendientes.csv'
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                hover>
                <TableHeaderColumn
                  dataField='unit'
                  filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.unitFormatter }>
                  <Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter }>Estado</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' headerAlign='center' dataAlign='center' dataField='visitors_number'>Nº Visitas</TableHeaderColumn>*/}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                {/*<TableHeaderColumn dataField='user_who_receives' dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>*/}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='departure_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Salida
                </TableHeaderColumn>
                {/*<TableHeaderColumn dataField='user_who_departures' dataFormat={ this.userFormatter }>Conserje (salida)</TableHeaderColumn>*/}
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='elapsed_time'
                  dataFormat={ this.elapsedFormatter }>
                  <Icon name="time" />Total
                </TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='exceeded_time'
                  dataFormat={ this.elapsedFormatter }>
                  <Icon name="time" />Excedido
                </TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese patente', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='car_license_plate'
                  dataFormat={ this.licenseFormatter }>
                  <Icon name="car" />Vehículo
                </TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='exceeded_status'
                  dataFormat={ this.exceededStatusFormatter }>¿Multado?</TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.unitFormatterInModalTitle()}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
}
