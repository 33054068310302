import React, { Component } from "react";
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/es';
import { sortByKey } from '../../libs/arrays';
import { validateText, validateTextAndNumber, validateFreeText } from '../../libs/validation';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { Link } from "react-router-dom";

export default class DailyBookEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableEdit: true,
            deleteCandidate: null,
            showDelete: false,
            showEdit: false,
            showDeparture: false,
            isDepartured: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            person_in_charge: '',
            title: '',
            description: '',
            profile: '',
            events: []
        };
    }

    componentDidMount = async () => {
      const user = await getUserAttributes();
      this.setState({ profile: user.profile });

      this.findBookEvents();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findBookEvents();
      }
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findBookEvents();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findBookEvents = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.bookEvents();
        const events = sortByKey(results.events, "event_date")
        this.setState({ events: events });
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteBookEvent();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar evento en libro de novedades!" });
        } else {
          this.handleAlertSuccess({ message: "Evento eliminado correctamente!" });
          this.findBookEvents();
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar evento en libro de novedades!" });
      }
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {
        const bookEvent = {
          title: this.state.title,
          description: this.state.description,
          person_in_charge: this.state.person_in_charge,
          created_date: this.state.editCandidate.created_date
        };

        const response = await this.updateBookEvent(bookEvent);
        if (response.code === 0) {
          this.state.editCandidate.title = this.state.title;
          this.state.editCandidate.description = this.state.description;
          this.state.editCandidate.person_in_charge = this.state.person_in_charge;
          this.resetForm();
          this.props.onAlertSuccess({ message: "Evento de libro de novedades actualizado exitosamente!"});
        } else {
          this.props.onAlertError({ message: "Error al modificar de libro de novedades! Por favor revise los datos e intente nuevamente." });
        }
        this.handleClose();
      } catch (e) {
        this.resetForm();
        this.handleClose();
        this.props.onAlertError({ message: "Error al modificar evento de libro de novedades!" });
      }
      this.setState({ isLoading: false });
    }

    resetForm = () => {
      this.setState({
        person_in_charge:'', title: '', description: '',
        showError: false, errorMessage: ''
      });
    }

    bookEvents() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/book/find",
        method: "POST",
        body: {
            "date": selectedDateMoment.local().format("YYYYMMDD"),
            "book": "D1"
        }
      });
    }

    updateBookEvent(bookEvent) {
      return invokeApig({
        path: "/book",
        method: "PUT",
        body: bookEvent
      });
    }

    deleteBookEvent() {
      return invokeApig({
        path: "/book",
        method: "DELETE",
        body: {
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    getErrors() {
      let message = undefined;

      if (!validateTextAndNumber(this.state.title)) {
        message = 'Debe ingresar tipo de evento';
        return message;
      }

      if (!validateText(this.state.person_in_charge)) {
        message = 'Debe ingresar responsable';
        return message;
      }

      if (!validateFreeText(this.state.description)) {
        message = 'Debe ingresar descripción del evento';
        return message;
      }

      this.setState({showError: false});
      return message;
    }

    drawOptionButtons = (bookEvent) => {
      return <React.Fragment>
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar evento</Tooltip>}>
                  <i onClick={() => { this.showConfirmEdit(bookEvent) }} className="bookBtn glyphicon glyphicon-edit"></i>
                </OverlayTrigger>
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar evento</Tooltip>}>
                  <i onClick={() => { this.showConfirmDelete(bookEvent) }} className="bookBtn glyphicon glyphicon-trash"></i>
                </OverlayTrigger>
            </React.Fragment>;
    }

    showConfirmDelete = (bookEvent) => {
      this.setState({ showDelete: true, deleteCandidate: bookEvent });
    }

    showConfirmEdit = (bookEvent) => {
      this.setState({ title: bookEvent.title, description: bookEvent.description, person_in_charge: bookEvent.person_in_charge });
      this.setState({ showEdit: true, editCandidate: bookEvent, isEditing: true });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleClose = () => {
      this.setState({
        showDelete: false, deleteCandidate: null, isEditing: false,
        showEdit: false, editCandidate: null, disableEdit: true
      });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.description === this.state.editCandidate.description
            && this.state.person_in_charge === this.state.editCandidate.person_in_charge
            && this.state.title === this.state.editCandidate.title;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    dateFormatter = (cell) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    titleFormatter = (event) => {
      return <span>
      <Label color='blue'>{event.title}</Label>
      <Label color='green'>{event.person_in_charge}</Label>
      </span>
    }

    render(){
        const bookEvents = this.state.events;
        const events = bookEvents && bookEvents.length ?
          bookEvents.map((bookEvent, index) =>
            <TimelineEvent
                key={index}
                title={this.titleFormatter(bookEvent)}
                buttons={ this.state.profile === 'Supervisor' && this.drawOptionButtons(bookEvent)}
                createdAt={this.dateFormatter(bookEvent.event_date)}
                icon={<Glyphicon glyph="book" />}
                >{<div dangerouslySetInnerHTML={{__html: bookEvent.description}} />}
            </TimelineEvent>
          )
          : [<TimelineEvent
                key='none'
                title="Sin registros"
                icon={<Glyphicon glyph="pencil" />}
                >Sin registros para este día
            </TimelineEvent>];

        return(
          <div className="Events">
          { this.state.isLoading && !this.state.isEditing ?
            <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>
            :
            <Timeline>
              {events}
            </Timeline>
          }
          <Modal show={this.state.showDelete} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='trash' />ELIMINACIÓN</Label> Eliminar evento de libro de novedades</Modal.Title>
            </Modal.Header>
            { this.state.deleteCandidate &&
              <Modal.Body><p>¿Está seguro que quiere eliminar el evento <Label color='red'><Icon name='trash' />{this.state.deleteCandidate.title}</Label>?</p>
              <p className="psmall"><b>Responsable:</b> {this.state.deleteCandidate.person_in_charge}</p>
              <p className="psmall"><b>Fecha:</b> {this.state.deleteCandidate.event_date}</p>
              </Modal.Body>

            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='orange'>EDICIÓN</Label> Datos del evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.editCandidate &&
              this.renderFormEdit()
            }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
          <Form horizontal onSubmit={this.handleEditSubmit}>
            <FormGroup controlId="time">
              <Col componentClass={ControlLabel} sm={3}>
                Hora
              </Col>
              <Col sm={8}>
                <FormControl
                  disabled='true'
                  onChange={this.handleChange}
                  value={this.state.editCandidate.time}
                />
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Hora de evento.</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="title">
              <Col componentClass={ControlLabel} sm={3}>
                Tipo Evento (*)
              </Col>
              <Col sm={8}>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.title}
                />
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tipo de evento (ronda, basura, etc).</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="person_in_charge">
              <Col componentClass={ControlLabel} sm={3}>
                Responsable (*)
              </Col>
              <Col sm={8}>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.person_in_charge}
                />
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tipo de evento (ronda, basura, etc).</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
            <FormGroup controlId="description">
              <Col componentClass={ControlLabel} sm={3}>
                Descripción (*)
              </Col>
              <Col sm={8}>
                <textarea
                  id="description"
                  className="form-control"
                  rows="5"
                  value={this.state.description}
                  onChange={this.handleChange}
                  maxLength="1000" />
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">De que se trata el evento.</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>

            {
              this.state.showError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.errorMessage}</strong>
              </Alert>
            }
            <LoaderButton
                  block
                  disabled={this.state.disableEdit}
                  bsStyle="warning"
                  bsSize="large"
                  type="submit"
                  isLoading={this.state.isLoading}
                  text={ "Modificar" }
                  loadingText="Guardando…"
                />
          </Form>
      );
    }
}
