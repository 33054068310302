import React, { Component } from "react";
import "./ResidentValidation.css";
import { Image, Breadcrumb, Glyphicon, Row, Col, PageHeader, Alert } from "react-bootstrap";
import moment from 'moment'
import config from '../../config';

export default class ResidentValidation extends Component {
  constructor(props) {
    super(props);

    let search = new URLSearchParams(this.props.location.search);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      search: search,
      p1: '',
      p2: '',
      p3: '',
      p4: '',
      p5: ''
    };
  }

  componentDidMount() {
    let p1 = this.state.search.get("p1");
    let p2 = this.state.search.get("p2");
    let p3 = this.state.search.get("p3");
    let p4 = this.state.search.get("p4");
    let p5 = this.state.search.get("p5");
    if (p1&&p2&&p3&&p4&&p5) {
      this.setState({ p1:p1, p2:p2, p3:p3, p4:p4, p5:p5 },()=>{
        this.validationAsync();
      });
    }else{
      this.handleAlertError({message: 'Se ha producido un error inesperado.'});
    }
  }

  validationAsync = async () => {
    this.setState({ isLoading: true });

    try {
        const result = await this.validation({
          "condo": this.state.p1,
          "unit": this.state.p2,
          "id": this.state.p3,
          "token": this.state.p4,
          "k0": new moment(),
          "k1": this.state.p5,
        });

        if (result.code === 0) {
          this.handleAlertSuccess({ message: "Validación exitosa! Ya puedes recibir correos de:" });
        } else if (result.code === 400) {
          this.handleAlertSuccess({ message: "Ya puedes recibir correos de:" });
        } else {
          this.handleAlertError({message: 'Se ha producido un error inesperado. Contacte al conserje de turno.'});
        }

    } catch (e) {
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  async validation(info) {
    const result = await fetch(config.apiGateway.URL + '/resident/validation', {
      method: 'POST',
      headers: { 'content-type': 'application/json'},
      body: JSON.stringify(info)
    }).then(response => response.json());
    return result;
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true });
    } else {
      this.setState(newState);
    }
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  render() {
    const {
       p1
    } = this.state;

    return (
      <div className="ResidentValidation">
      <Breadcrumb>
      <Row>
        <Col xs="3">
          <Image
            thumbnail
            responsive
            className="condo-logo-validation"
            src={"/condo/" + p1 + ".png"}
            />
        </Col>
        <Col className="header-title-validation" xs="9"><span>Verificación de email</span></Col>
      </Row>
      </Breadcrumb>

      <PageHeader className="page-header-validation">
	      Unidad: {this.state.p2}
	    </PageHeader>

        {
          this.state.isLoading &&
          <Alert bsStyle="warning">
            <strong><Glyphicon glyph="refresh" className="spinning" /> Activando...</strong>
          </Alert>
        }
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success">
        	  <strong>{this.state.alertMessage}</strong>
            <p></p>
            <p>
              <ul >
                <li>Encomiendas</li>
                <li>Visitas</li>
                <li>Reservas</li>
                <li>Comunicados y más</li>
              </ul>
            </p>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger">
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
      </div>
    );
  }

}
