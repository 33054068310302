import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Button, Glyphicon, Col, Alert } from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ListReport.css';
import {sortByKey} from '../../libs/arrays';
import VisitorReportExport from "./VisitorReportExport";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/es';

export default class ListReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableEdit: true,
            unit: '',
            number: '',
            floor: '',
            status: '',
            parking: '',
            warehouse: '',
            other: '',
            isLoading: false,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            selectedDateVisitorWithCar: null,
            selectedDateVisitorWithoutCar: null,
            selectedDateVisitorProvider: null,
            units: [],
            visitors_with_car_filename: "",
            visitors_without_car_filename: "",
            visitors_providers_filename: "",
            visitors_with_car: [],
            visitors_without_car: [],
            visitors_providers: [],
            reports: [{"name":"Visitas en vehículo", "filename": "visitas_vehiculo_", "index":1 },
                      {"name":"Visitas a pie", "filename": "visitas_", "index":2 },
                      {"name":"Visitas Proveedores", "filename": "visitas_proveedores_", "index":3 }/*,{"name":"Encomiendas", "index":4}*/]
        };
    }

    findVisitors = async (withCar) => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitors(withCar);
        const visitors = sortByKey(results.visitors, "created_date")
        if (withCar) {
          this.setState({ visitors_with_car: visitors });
        } else {
          this.setState({ visitors_without_car: visitors });
        }
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    findVisitorsProviders = async (withCar) => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitorsProviders();
        const visitors = sortByKey(results.visitors, "created_date")
        this.setState({ visitors_providers: visitors });
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    visitors(withCar) {
      const selectedDateMoment = withCar ? moment(this.state.selectedDateVisitorWithCar) : moment(this.state.selectedDateVisitorWithoutCar);
      const yearMonthSearch = selectedDateMoment.local().format("YYYY-MM");

      if (withCar) {
        this.setState({ visitors_with_car_filename: this.state.reports[0].filename + yearMonthSearch });
      } else {
        this.setState({ visitors_without_car_filename: this.state.reports[1].filename + yearMonthSearch });
      }

      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "monthly2": "M",
            "has_car": withCar,
            "year_month_search": yearMonthSearch
        }
      });
    }

    visitorsProviders() {
      const selectedDateMoment = moment(this.state.selectedDateVisitorProvider);
      const yearMonthSearch = selectedDateMoment.local().format("YYYY-MM");
      this.setState({ visitors_providers_filename: this.state.reports[2].filename + yearMonthSearch });

      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "unitId": "provider",
            "monthly": "PV",
            "year_month_search": yearMonthSearch
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      }
      // else {
      //   return this.state.isError ? "Error al obtener lista de reportes. Intenta más tarde." : "No hay datos";
      // }
    }

    drawCounter =  (cell, row, rowIdx) => {
      switch (row.index) {
        case 1:
          return this.state.visitors_with_car.length
        case 2:
          return this.state.visitors_without_car.length
        case 3:
          return this.state.visitors_providers.length
        default:
          return 0
      }
    }
    drawCalendar = (cell, row, rowIdx) => {
      if (row.index === 1) {
        return <DatePicker
          className="date-picker"
          selected={this.state.selectedDateVisitorWithCar}
          onChange={this.handleChangeVisitorWithCar}
          dateFormat="MMM/yyyy"
          showMonthYearPicker={true}
          maxDate={new Date()}
        />
      } else if (row.index === 2) {
        return <DatePicker
          className="date-picker"
          selected={this.state.selectedDateVisitorWithoutCar}
          onChange={this.handleChangeVisitorWithoutCar}
          dateFormat="MMM/yyyy"
          showMonthYearPicker={true}
          maxDate={new Date()}
        />
      } else if (row.index === 3) {
        return <DatePicker
          className="date-picker"
          selected={this.state.selectedDateVisitorProvider}
          onChange={this.handleChangeVisitorProvider}
          dateFormat="MMM/yyyy"
          showMonthYearPicker={true}
          maxDate={new Date()}
        />
      }

    }

    drawButton = (cell, row, rowIdx) => {
      if (row.index === 1) {
        return <VisitorReportExport data={this.state.visitors_with_car} filename={this.state.visitors_with_car_filename}/>
      } else if (row.index === 2) {
        return <VisitorReportExport data={this.state.visitors_without_car} filename={this.state.visitors_without_car_filename}/>
      } else if (row.index === 3) {
        return <VisitorReportExport data={this.state.visitors_providers} filename={this.state.visitors_providers_filename}/>
      }
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleChangeVisitorWithCar = (date) => {
      if (date === null || date === "") {
        date = new Date();
      }
      this.setState({ selectedDateVisitorWithCar: date },()=>{
          this.findVisitors(true)
      });
    }

    handleChangeVisitorWithoutCar = (date) => {
      if (date === null || date === "") {
        date = new Date();
      }
      this.setState({ selectedDateVisitorWithoutCar: date },()=>{
          this.findVisitors(false)
      });
    }

    handleChangeVisitorProvider = (date) => {
      if (date === null || date === "") {
        date = new Date();
      }
      this.setState({ selectedDateVisitorProvider: date },()=>{
          this.findVisitorsProviders()
      });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.reports;
        return(
          <div className="Report">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="reports">
              <BootstrapTable
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }>
                <TableHeaderColumn
                  dataField='name'
                  dataSort={ true }
                  isKey
                  width='55%'
                  headerAlign='center'
                  dataAlign='center'>Reporte</TableHeaderColumn>
                <TableHeaderColumn
                  width='15%'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawCalendar}>Seleccione mes</TableHeaderColumn>
                <TableHeaderColumn
                  width='15%'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawCounter}>Registros</TableHeaderColumn>
                <TableHeaderColumn
                  width='15%'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más unidades...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Col sm={12} className="footer">
              { this.state.isLoading &&
                <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>
              }
              { this.state.isError &&
                <div><Glyphicon glyph="refresh" className="spinning" />Error al obtener lista de reportes. Intenta más tarde</div>
              }
            </Col>
          </div>
        );
    }

}
