
const initialState = {
  plan: 'BASIC'
};

function rootReducer(state = initialState, action) {
  if (action.type === 'UPDATE_PLAN') {
    return {
      ...state,
      plan: action.payload
    }
  }
  return state;
};

export default rootReducer;
