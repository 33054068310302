import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Image, Radio } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import VisitorDetailModal from "./VisitorDetailModal";
import moment from 'moment';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import { validateNumber, validateText, validateLicense, validateCarPark, formatRut } from '../../libs/validation';
import config from "../../config";

export default class ListVisitor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            disableEdit: true,
            has_car: false,
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            editCandidate: null,
            detailCandidate: null,
            deleteCandidate: null,
            departuresCandidate: null,
            actionCandidateIndex: -1,
            showDetails: false,
            showDelete: false,
            showEdit: false,
            showDeparture: false,
            isDepartured: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            visitors: [],
            suggestions: {
              card_id: [],
              name: [],
              lastname: [],
              car_license_plate: []
            }
        };
    }

    componentDidMount() {
      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedUnit !== this.props.selectedUnit) {
        this.findVisitors();
        return;
      }
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findVisitors();
        return;
      }
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        if(this.props.selectedUnit){
          const results = await this.visitors();
          this.props.onCheckPaymentAdvice(results);
          const visitors = sortByKey(results.visitors, "created_date")
          this.setState({ visitors: visitors });
          this.props.onVisitorLoaded(visitors);
        }
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteVisitor();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar visita!" });
        } else {
          this.state.visitors.splice(this.state.actionCandidateIndex, 1);
          this.handleAlertSuccess({ message: "Visita eliminada correctamente!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar visita!" });
      }
    }

    handleDeparture = async event => {
      event.preventDefault();
      this.setState({ isDepartured: true });
      try {
        const visitor = {
          operation: this.state.departuresCandidate.has_car? "DEPARTURE":"DEPARTURE2",
          created_date: this.state.departuresCandidate.created_date
        };

        const response = await this.updateVisitor(visitor);
        this.setState({ isDepartured: false, showDeparture: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al marcar salida!" });
        } else {
          this.handleAlertSuccess({ message: "Salida marcada correctamente!" });
          this.findVisitors();
        }
      } catch (e) {
        this.setState({ isDepartured: false, showDeparture: false, showAlertError: true, alertMessage: "Error al marcar salida!" });
      }
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {
        const visitor = {
          name: this.state.name,
          lastname: this.state.lastname,
          visitors_number: this.state.visitors_number,
          has_car: this.state.has_car,
          car_license_plate: this.state.has_car? this.state.car_license_plate: '-',
          car_park: this.state.has_car ? this.state.car_park : '-',
          operation: "EDIT",
          created_date: this.state.editCandidate.created_date
        };

        const response = await this.updateVisitor(visitor);
        if (response.code === 0) {
          this.state.editCandidate.name = this.state.name;
          this.state.editCandidate.lastname = this.state.lastname;
          this.state.editCandidate.visitors_number = this.state.visitors_number;
          this.state.editCandidate.has_car = this.state.has_car;
          this.state.editCandidate.car_park = this.state.has_car ? this.state.car_park : '-';
          this.state.editCandidate.car_license_plate = this.state.has_car? this.state.car_license_plate: '-';

          this.resetForm();
          this.handleAlertSuccess({ message: "Visita actualizada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar visita! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar visita!" });

      }
      this.setState({ isLoading: false });
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '',
        name:'', lastname: '', visitors_number: 1,
        has_car: false, car_license_plate: '', car_park: '', status: '',
        showError: false, errorMessage: ''
      });
    }

    updateVisitor(visitor) {
      visitor.unitId = this.props.selectedUnit["unit"];
      return invokeApig({
        path: `/visitor`,
        method: "PUT",
        body: visitor
      });
    }

    visitors() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "unitId": this.props.selectedUnit["unit"],
            "monthly": "V",
            "year_month_search": selectedDateMoment.local().format("YYYY-MM")
        }
      });
    }

    deleteVisitor() {
      return invokeApig({
        path: "/visitor",
        method: "DELETE",
        body: {
          "unitId": this.props.selectedUnit["unit"],
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.lastname)) {
        message = 'Debe ingresar apellido';
        return message;
      }

      if (!validateNumber(this.state.visitors_number)) {
        message = 'Debe ingresar número de visitas';
        return message;
      }

      if (this.state.has_car) {
        if (!validateLicense(this.state.car_license_plate)) {
          message = 'Debe ingresar patente válida, ej: AABB77';
          return message;
        }
        if (!validateCarPark(this.state.car_park)) {
          message = 'Debe ingresar estacionamiento válido, ej: 5V o 5 Visita';
          return message;
        }
      }
      this.setState({showError: false});
      return message;
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);

      var type = "";
      if (typeof(row.visit_type) !== 'undefined') {
        type = <span><Icon color='red' name='food' fitted/> <Icon color='red' name='dolly' fitted/></span>
      }

      return <span><Link to={{}} onClick={this.showDetails.bind(cell, row)}>{id}</Link> {type}</span>;
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter = (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        {row.car_park && <Label>{row.car_park}</Label>}
        </span>
        : <Icon name="male" />;
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    rowClassNameFormat = (row, rowIdx) => {
      if (row['status'] === 'DEPARTED') return 'departed';
      if (row['car_park'] === '-' && row['car_license_plate'] === '-') return;
      return 'received';
    }

    drawButton = (cell, row, index, enumObject) => {
      return row['status'] === 'RECEIVED' || row['status'] === 'RECEIVED_WITH_CAR' ?
          <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Marcar salida</Tooltip>}>
              <Button bsStyle="success" onClick={this.showDoDeparture.bind(cell, row, index, enumObject)} className="btnDoDelivery">
                <Glyphicon glyph="log-out" />
              </Button>
            </OverlayTrigger>
            { this.props.condo &&
              this.props.condo.visitor &&
              !this.props.condo.visitor.country &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
              <Button bsStyle="warning" onClick={this.showEditVisitor.bind(cell, row, index, enumObject)} className="btnEditDelivery">
                <Glyphicon glyph="edit" />
              </Button>
            </OverlayTrigger>
            }
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, index, enumObject)} className="btnDeleteDelivery">
                <Glyphicon glyph="trash" />
              </Button>
            </OverlayTrigger>
            {this.props.condo && this.props.condo.picture.visitor && typeof(row.visitor_picture_url) !== 'undefined' &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
              <Button bsStyle="primary" onClick={this.showVisitorPicture.bind(cell, row, index, enumObject)} className="btnShowVisitor">
                <Glyphicon glyph="camera" />
              </Button>
            </OverlayTrigger>
            }
          </ButtonGroup>
         :
         <ButtonGroup className="actionsBtn">
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
             <Button onClick={this.showDetails.bind(cell, row)}  className="btnShowDelivery">
               <Glyphicon glyph="search" />
             </Button>
           </OverlayTrigger>
           {this.props.condo && this.props.condo.picture.visitor && typeof(row.visitor_picture_url) !== 'undefined' &&
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
             <Button bsStyle="primary" onClick={this.showVisitorPicture.bind(cell, row, index, enumObject)} className="btnShowVisitor">
               <Glyphicon glyph="camera" />
             </Button>
           </OverlayTrigger>
           }
         </ButtonGroup>;
    }

    showEditVisitor = (cell, row, index, enumObject) => {
      this.setState({ name: cell.name, lastname: cell.lastname,
        card_id: cell.card_id, card_id_type: cell.card_id_type ? cell.card_id_type : 'RUT', });
      this.setState({ visitors_number: cell.visitors_number, actionCandidateIndex: index });
      this.setState({ showEdit: true, editCandidate: cell });
      if (cell.car_license_plate && cell.car_license_plate !== '-'
        && cell.car_park && cell.car_park !== '-') {
        this.setState({ has_car: true, car_license_plate: cell.car_license_plate, car_park: cell.car_park });
      }
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setState({ showDelete: true, actionCandidateIndex: index, deleteCandidate: cell });
    }

    showDoDeparture = (cell, row, index, enumObject) => {
      this.setState({ showDeparture: true, actionCandidateIndex: index, departuresCandidate: cell });
    }

    showDetails = (cell, row) => {
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    showVisitorPicture = (cell, row, index, enumObject) => {
      this.setState({ showPicture: true, actionCandidateIndex: index, detailCandidate: cell });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleClose = () => {
      this.setState({
        has_car: false, actionCandidateIndex: -1,
        showDelete: false, deleteCandidate: null,
        showDetails: false, detailCandidate: null,
        showDeparture: false, departuresCandidate: null,
        showEdit: false, editCandidate: null, showPicture: false,
        disableEdit: true });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.id === "has_car" ? event.target.checked:event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.has_car === this.state.editCandidate.has_car
            && this.state.name === this.state.editCandidate.name
            && this.state.lastname === this.state.editCandidate.lastname
            && this.state.visitors_number === this.state.editCandidate.visitors_number;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                trClassName={this.rowClassNameFormat}>
                <TableHeaderColumn
                  isKey
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter }>Estado</TableHeaderColumn> */}
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese Nº Visitas', delay: 10 } }
                  width='100'
                  dataSort={ true }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='visitors_number'>Nº Visitas</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_receives'
                  dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='departure_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Salida
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_departures'
                  dataFormat={ this.userFormatter }>Conserje (salida)</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese patente', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='car_license_plate'
                  dataFormat={ this.licenseFormatter }>
                  <Icon name="car" />Vehículo
                </TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='red'>ELIMINACIÓN</Label> Eliminar visita</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>¿Está seguro que quiere eliminar visita <Label color='red'>{this.visitorFormatter(this.state.deleteCandidate)}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDeparture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='green'>SALIDA</Label> Marcar salida de visita</Modal.Title>
              </Modal.Header>
              { this.state.departuresCandidate &&
                <Modal.Body>¿Confirma la salida de la visita <Label color='green'>{this.visitorFormatter(this.state.departuresCandidate)}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="success" isLoading={this.state.isDepartured} onClick={this.handleDeparture} text="Salida" loadingText="Guardando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showEdit} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  <Label color='orange'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                  <Label color='orange'><Icon name='edit' /></Label> Editar visita</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.editCandidate &&
                  this.renderFormEdit()
                }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
            <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  <Label color='blue'><Icon name='user' />Nombre:</Label> {this.state.detailCandidate && this.visitorFormatter(this.state.detailCandidate)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.detailCandidate &&
                  <Image
                    className="visitor_picture_modal"
                    src={config.s3_files.BUCKET + this.state.detailCandidate.visitor_picture_url} />
                }
              </Modal.Body>
            </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="card_id">
            <Col componentClass={ControlLabel} sm={3}>
            <Radio
              label='RUT '
              name='radioGroup'
              value='RUT'
              disabled
              checked={this.state.card_id_type === 'RUT'}
            />
            <Radio
              label='Otro'
              name='radioGroup'
              value='OTHER'
              disabled
              checked={this.state.card_id_type === 'OTHER'}
            />
            </Col>
            <Col sm={8}>
              <FormControl
              disabled
  	          onChange={this.handleChange}
  	          value={this.state.card_id}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="name">
            <Col componentClass={ControlLabel} sm={3}>
              Nombre (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
                placeholder="ej: Diego"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="lastname">
            <Col componentClass={ControlLabel} sm={3}>
              Apellido (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.lastname}
                placeholder="ej: Salas"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="visitors_number">
            <Col componentClass={ControlLabel} sm={3}>
              N° Visitas (*)
            </Col>
            <Col sm={2}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.visitors_number}
                type='number'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de visitas.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {/*<FormGroup controlId="has_car">
            <Col componentClass={ControlLabel} sm={3}>
              Viene en vehículo?
            </Col>
            <Col sm={1}>
              <FormControl
                checked={this.state.has_car}
                onChange={this.handleChange}
                type='checkbox'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Visita viene en vehículo?</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="car_license_plate">
            <Col componentClass={ControlLabel} sm={3}>
              Patente
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.car_license_plate}
                disabled={!this.state.has_car}
                placeholder="ej: AABB77"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Patente de vehículo.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>

          <FormGroup controlId="car_park" visible='false'>
            <Col componentClass={ControlLabel} sm={3}>
              Estacionamiento
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.car_park}
                disabled={!this.state.has_car}
                placeholder="ej: 5V"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Estacionamiento.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          */}
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
