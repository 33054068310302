import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Navbar, Alert, Row, Col, Image, Glyphicon, Modal, Button } from "react-bootstrap";
import { Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import Routes from "./Routes";
import BottomScrollListener from "react-bottom-scroll-listener";
import { authUser, signOutUser, getUserAttributes, invokeApig } from "./libs/awsLib";
// import { Offline } from "react-detect-offline";
import SideMenu from './containers/menu/SideMenu';
import HMenu from './containers/menu/HMenu';
import config from './config';
import text from './config.text';

import "./App.css";
import "./menu.css"

import { connect } from 'react-redux';

import { updatePlan } from "./state/Actions";


function mapDispatchToProps(dispatch) {
  return {
    updatePlan: plan => dispatch(updatePlan(plan))
  }
}

function mapStateToProps(state) {
  return {
    plan: state.plan
    }
}

class App extends Component {
  constructor(props) {
    super(props);

    const visitTypeEnum = {
        DELIVERY: 'Repartidor',
        REGULAR: 'Regular',
        OTHER: 'Otro',
    }

    const penaltyTypeEnum = {
        PENALTY: 'MULTA',
        WARNING: 'ADVERTENCIA',
        OTHER: 'OTRA'
    }

    const penaltyStatusEnum = {
        PENDING: 'PENDIENTE',
        DELETED: 'ELIMINADA',
        OK: 'OK'
    }

    const petSizeEnum = {
        LITTLE: 'Pequeño',
        MEDIUM: 'Mediano',
        LARGE: 'Grande'
    }

    const emailStatusEnum = {
        ACTIVE: 'ACTIVADO',
        PENDING: 'PENDIENTE',
        DISABLED: 'DESACTIVADO',
        NO_EMAIL: 'Sin email'
    }

    const unitStatusEnum = {
        DELETED: 'ELIMINADO',
        ACTIVE: 'OK',
        UNPAID: 'DEUDA'
    }

    const deliveryTypeEnum = {
        BOX: 'Caja',
        PACKAGE: 'Paquete',
        ENVELOPE: 'Sobre',
        OTHER: 'Otro'
    }

    const deliveryStatusEnum = {
        DELETED: 'Eliminado',
        RECEIVED: 'Recibido',
        DELIVERED: 'Entregado'
    }

    const visitorStatusEnum = {
        DELETED: 'Eliminado',
        RECEIVED: 'Ingreso',
        RECEIVED_WITH_CAR: 'Ingreso con vehículo',
        DEPARTED: 'Salida'
    }

    const bookingStatusEnum = {
        ACTIVE: 'Activo',
        PENDING: 'Pendiente',
        REJECTED: 'Rechazado',
        DELETED: 'Eliminado'
    }

    this.state = {
      petSize: petSizeEnum,
      visitType: visitTypeEnum,
      penaltyStatus: penaltyStatusEnum,
      penaltyType: penaltyTypeEnum,
      emailStatus: emailStatusEnum,
      unitStatus: unitStatusEnum,
      visitorStatus: visitorStatusEnum,
      bookingStatus: bookingStatusEnum,
      deliveryType: deliveryTypeEnum,
      deliveryStatus: deliveryStatusEnum,
      isAuthenticated: false,
      isAuthenticating: true,
      showAlertError: false,
      alertMessage: '',
      profile: null,
      fullname: '',
      selectedUnit: null,
      residents: [],
      condo: null,
      expanded: false,
      isLoading: false,
      showtyc: false,
      showPaymentAdvice: false,
      termsHtml: '',
      changelogHtml: '',
      adviceColor: "red"
    };
  }

  async componentDidMount() {
    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
        const user = await getUserAttributes();
        this.setState({ profile: user.profile });

        this.props.updatePlan('BASIC')
      }
    }
    catch(e) {
      this.handleAlertError('Usuario no autenticado');
    }
    this.setState({ isAuthenticating: false });
  }

  async componentDidUpdate() {
    const user = await getUserAttributes();
    if (user.profile !== this.state.profile) {
      this.setState({ profile: user.profile });
    }
  }

  userHasAuthenticated = (authenticated, fullname) => {
    this.setState({ isAuthenticated: authenticated });
    if (fullname) {
      this.setState({ fullname: fullname });
    }
  }

  handleOpenTyC = async () => {
    try {
      const results = await fetch('/terms.html');
      this.setState({ showtyc: true, termsHtml: await results.text()});
    } catch(e) {
      console.error(e);
    }
  }

  handleOpenChangeLog = async () => {
    try {
      const results = await fetch('/changelog.html');
      this.setState({ showcl: true, changelogHtml: await results.text()});
    } catch(e) {
      console.error(e);
    }
  }

  handleOpenPaymentAdvice = () => {
    if (this.state.condo.payment.suspended || this.state.condo.payment.pending) {
      this.setState({ showPaymentAdvice: true});
    }
  }

  checkPaymentAdvice = (result) => {
    if (this.state.condo && this.state.condo.payment) {
      if (!result) {
          this.setState({ showPaymentAdvice: this.state.condo.payment.suspended});
          return;
      }
      if (result.code === 10) {
        const condo = this.state.condo
        condo.payment.suspended = true
        this.setState({ condo: condo });
        this.setState({ showPaymentAdvice: true });
      }
    }
  }

  handleClose = () => {
    this.setState({ showcl: false, showtyc: false, showPaymentAdvice: false });
  }

  handleLogout = () => {
    signOutUser();

    this.userHasAuthenticated(false);

    this.setState({ condo: null });

    this.props.history.push("/login");
  }

  onToggle = (event) => {
  	this.setState({ expanded: !this.state.expanded });
  }

  onSelect = (event) => {
    this.setState({ expanded: false });
  }

  onSelectMenu = (event) => {
	  this.setState({ expanded: false, openFleetMenu: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message, showAlertError: true });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  }

  goMenu = (to, search, detail) => {
    // this.props.history.push(to);
    this.setState({ expanded: false });
    this.props.history.push({
      pathname: to,
      search: search,
      state: { detail: detail }
    })
  }

  setCondo = (c) => {
    this.setState({ condo: c });
  }

  changeUnit = (unit) => {
    if (unit === this.state.selectedUnit) return;

    this.setState({
        selectedUnit: unit, residents: []
    },() => {
      if (unit) {
        this.findResidentsAsync();
      }
    });
  }

  findResidentsAsync = async () => {
    try {
        this.setState({ residents: [], isLoading: true });
        const results = await this.findResidents();
        this.setState({ residents: results.residents, isLoading: false });
    } catch (e) {
      this.setState({isError: true});
    }
  }

  findResidents() {
    return invokeApig({
      path: "/resident/find",
      method: "POST",
      body: {
          "unitId": this.state.selectedUnit["unit"]
      }
    });
  }
  onToggle = (event) => {
  	this.setState({ expanded: !this.state.expanded });
  }
  onSelect = (event) => {
    this.setState({ expanded: false });
  }
  renderPrivateScreen(childProps) {
    return (
      <div className="row">
        <Row>
          <Col md={12}>
            {
            	this.state.showAlertError &&
            	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            	  <strong>{this.state.alertMessage}</strong>
            	</Alert>
            }
          </Col>
        </Row>
	    </div>
    );
  }

  renderPrivateHome(childProps) {
    const menu_orientation = localStorage.getItem('menu_orientation');
    if (menu_orientation === null || menu_orientation === 'h') {
      return this.renderPrivateH(childProps);
    } else {
      return this.renderPrivateV(childProps);
    }
  }

  renderPrivateV(childProps) {
    return (
      <div className="row">
        <Row>
          <Col md={2}>
            <Navbar onToggle={this.onToggle} onSelect={this.onSelect} expanded={this.state.expanded}>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <SideMenu
                  condo={this.state.condo}
                  onGoMenu={this.goMenu}
                  onLogout={this.handleLogout}
                  onChangeUnit={this.changeUnit}
                  onSetCondo={this.setCondo}/>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col md={10}>
            {
            	this.state.showAlertError &&
            	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            	  <strong>{this.state.alertMessage}</strong>
            	</Alert>
            }
	          <Routes childProps={childProps} />
          </Col>
        </Row>
	    </div>
    );
  }

  renderPrivateH(childProps) {
    return (
      <div className="row">
        <Row>
          <Navbar onToggle={this.onToggle} onSelect={this.onSelect} expanded={this.state.expanded}>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Col md={12}>
                <HMenu
                  condo={this.state.condo}
                  onGoMenu={this.goMenu}
                  onLogout={this.handleLogout}
                  onChangeUnit={this.changeUnit}
                  onSetCondo={this.setCondo}/>
              </Col>
            </Navbar.Collapse>
          </Navbar>
        </Row>
        <Row>
          <Col md={12}>
            {
            	this.state.showAlertError &&
            	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            	  <strong>{this.state.alertMessage}</strong>
            	</Alert>
            }
	          <Routes childProps={childProps} />
          </Col>
        </Row>
	    </div>
    );
  }

  renderPublic(childProps) {
    return (
      <div className="row">
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Routes childProps={childProps} />
  		</div>
    );
  }

  render() {

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      selectedUnit: this.state.selectedUnit,
      residents: this.state.residents,
      deliveryType: this.state.deliveryType,
      deliveryStatus: this.state.deliveryStatus,
      visitType: this.state.visitType,
      penaltyType: this.state.penaltyType,
      penaltyStatus: this.state.penaltyStatus,
      visitorStatus: this.state.visitorStatus,
      bookingStatus: this.state.bookingStatus,
      unitStatus: this.state.unitStatus,
      emailStatus: this.state.emailStatus,
      petSize: this.state.petSize,
      reloadResidents: this.findResidentsAsync,
      onGoMenu: this.goMenu,
      onCheckPaymentAdvice: this.checkPaymentAdvice,
      appLoading: this.state.isLoading,
      condo: this.state.condo,
      profile: this.state.profile
    };

    const suspended = this.state.condo && this.state.condo.payment && this.state.condo.payment.suspended;
    const pending = this.state.condo && this.state.condo.payment && this.state.condo.payment.pending;

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        {/*}<Offline>
          <Alert bsStyle="danger" >
            <strong>Sin conexión a internet!</strong>
          </Alert>
        </Offline>
        */}
  	    {
  	      this.state.isAuthenticated ?
            this.state.profile === 'Screen' ? this.renderPrivateScreen(childProps) : this.renderPrivateHome(childProps)
            :
            this.renderPublic(childProps)
  	    }
        <footer className="navbar-default">
          <div className="nav-footer">
            <a href="https://www.deptogestion.com" target="_blank" rel="noopener noreferrer" className="logo-footer">
              <Image src="/poweredby.png" />
            </a>

            { this.state.isAuthenticated ?
              <React.Fragment>
              <span className='version_log' onClick={this.handleOpenChangeLog}>{config.version}</span>
              {pending && !suspended &&
                <Label onClick={this.handleOpenPaymentAdvice} className="pending_payment_advice" color={this.state.adviceColor}><div className="marquee"><p>ATENCIÓN: SE REGISTRA DEUDA PENDIENTE POR SERVICIO</p></div></Label>
              }
              {suspended &&
                <Label onClick={this.handleOpenPaymentAdvice} className="pending_payment_advice" color={this.state.adviceColor}><div className="marquee"><p>ATENCIÓN: LA APP SE ENCUENTRA SUSPENDIDA</p></div></Label>
              }
              <Link to={{}} onClick={this.handleOpenTyC}>Términos y condiciones</Link>
              </React.Fragment>
              :
              <span><Glyphicon glyph='envelope' /> <a href={"mailto:" + text.contact.email} target="_blank" rel="noopener noreferrer">{text.contact.email}</a></span>
            }
          </div>
        </footer>
        <Modal show={this.state.showtyc} onHide={()=>{return false;}}>
          <Modal.Header>
            <Modal.Title>Términos y condiciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BottomScrollListener>
              {scrollRef => (
                <div ref={scrollRef} className="terms" dangerouslySetInnerHTML={{__html: this.state.termsHtml}}></div>
              )}
            </BottomScrollListener>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showcl} onHide={()=>{return false;}}>
          <Modal.Header>
            <Modal.Title>Control de cambios</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BottomScrollListener>
              {scrollRef => (
                <div ref={scrollRef} className="changelog" dangerouslySetInnerHTML={{__html: this.state.changelogHtml}}></div>
              )}
            </BottomScrollListener>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showPaymentAdvice} onHide={()=>{return false;}}>
          <Modal.Header>
            <Modal.Title><Label color='red'>Deuda pendiente</Label></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Estimado usuario(a),</p>
            { suspended &&
              <p>La app ha sido suspendida.</p>
            }
            <p>Existe un pago pendiente por servicio. Solicitamos pueda regularizar esta situación.</p>
            <p>Cualquier duda, contáctese al correo: <a href={"mailto:" + text.contact.email} target="_blank" rel="noopener noreferrer">{text.contact.email}</a></p>
            <p>Lamentamos los inconvenientes</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const connectApp = connect(mapStateToProps, mapDispatchToProps)(App)
export default withRouter(connectApp)
