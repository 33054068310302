import ReactDOM from 'react-dom';
import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Row, Alert } from "react-bootstrap";
import Select from 'react-select';
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Radio } from 'semantic-ui-react'
import { invokeApig } from "../../libs/awsLib";
import { validateNumber, validateText, validateTextAndNumber, validateLicense,
  validatePassport, validateCarPark, formatRut, validateFreeText2,
  validateRut, cleanRut, cleanRutFromReader } from '../../libs/validation';
import {DelayInput} from 'react-delay-input';
import Autosuggest from '../../libs/autosuggest/Autosuggest';
import BlacklistAlert from '../blacklist/BlacklistAlert'
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import config from "../../config";
import './NewVisitor.css';

const provider = 'provider';

export class NewVisitorProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      previuos_card_id: '',
      name_placeholder: '',
      lastname_placeholder: '',
      person_found: 1,
      unit: provider,
      has_car: false,
      name: '',
      lastname: '',
      card_id: '',
      card_id_type: 'RUT',
      visitors_number: 1,
      reason: '',
      car_license_plate: '',
      car_park: '',
      provider_type: '',
      company: '',
      isLoading: null,
      isLoadEdit: false,
      isBlacklisted: false,
      blacklist:[],
      show: false,
      showError: false,
      errorMessage: '',
      visitor_picture: '',
      parkings_lots_in_use: [],
      suggestions: ['Ascensores', 'Cámaras CCTV', 'Calderas', 'Bombas', 'Electrógeno', 'Piscina', 'Eléctrico', 'Gas', 'Fosas', 'TV Cable', 'Telefonía', 'Internet', 'Otro']
    };
  }

  isRut(){return this.state.card_id_type === 'RUT';}

  findVisitors = async () => {
    this.setState({ visitors: [] });
    try {
      const results = await this.visitors();
      this.setState({ visitors: results.visitors });

      const parkings_lots_in_use = results.visitors.map((visitor) =>
          visitor.car_park
        );

      this.setState({ parkings_lots_in_use });

    } catch (e) {
      this.setState({isError: true});
    }
  }

  handleChangeRadio = (e, { value }) => this.setState({ card_id_type: value });

  handleParkingSlotChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ car_park: option });
  };

  handleChange = event => {
    if (event.target.id === "has_car") {
      if (event.target.checked) {
        this.findVisitors();
      }
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    if (event.target.id === "card_id") {
      var card_id_value = cleanRutFromReader(event.target.value)
      this.setState({
          [event.target.id]: this.isRut() ? formatRut(card_id_value): card_id_value,
      });
      return;
    }

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onChangeProviderType = (event) => {
    this.setState({
      provider_type: event
    });
  };

  onKeyPress = event => {
    if (event.which === 13) {
      event.preventDefault();

      this.searchPerson();

      let nameInput = ReactDOM.findDOMNode(this.refs.nameInput);
      if (nameInput && nameInput.focus instanceof Function) {
          nameInput.focus();
      }
    }
  }

  onBlur = event => {
    this.searchPerson();
  }

  createVisitor(visitor) {
    visitor.unitId = this.state.unit;
    return invokeApig({
      path: "/visitor",
      method: "POST",
      body: visitor
    });
  }

  visitors() {
    return invokeApig({
      path: "/visitor/find",
      method: "POST",
      body: {
          "status_summary": "RC"
      }
    });
  }

  getPerson(card_id) {
      return invokeApig({
          path: "/person/" + card_id,
          method: "GET"
      });
  }

  searchPerson = async event => {
    const card_id = this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id;
    const previuos_card_id = this.state.previuos_card_id;

    if (card_id.length < 7) return;
    if (card_id === previuos_card_id) return;

    this.setState({ isSearching: true, person_found: 1,
      lastname_placeholder: 'Buscando...', name_placeholder: 'Buscando...',
      lastname: '', name: '' });
    const response = await this.getPerson(card_id);

    this.setState({person_found: response.code})
    if (response.code === 0) {
      this.setState({
        name: response.person.name,
        lastname: response.person.lastname,
        company: typeof(response.person.company) !== 'undefined' ? response.person.company:'',
        provider_type: typeof(response.person.provider_type) !== 'undefined' ? response.person.provider_type:''
      })

      const blacklist = response.blacklist;
      if (blacklist && blacklist.length > 0) {
        this.handleClose();

        this.setState({
          isBlacklisted: true, blacklist: blacklist
        });
      }
    }

    this.setState({ isSearching: false, previuos_card_id: card_id, name_placeholder: '', lastname_placeholder: '' });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '' });
    this.setState({ has_car: false, name: '', lastname: '',
      card_id: '', card_id_type: 'RUT',
      provider_type: '', company: '', reason: '',
      visitors_number: 1, car_license_plate: '', parkings_lots_in_use: [],
      car_park: '', name_placeholder: '', visitor_picture: '',
      lastname_placeholder: '', person_found: 1,
      isSearching: false, previuos_card_id: '' });
  }

  handleBClose = () => {
    this.setState({ isBlacklisted: false, blacklist: [], isSearching: false, previuos_card_id: '' });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  getErrors() {
    let message = undefined;

    if (this.isRut() && !validateRut(this.state.card_id)) {
      message = 'Debe ingresar un RUT válido';
      return message;
    } else if (!this.isRut() && !validatePassport(this.state.card_id)) {
      message = 'Debe ingresar un id válido';
      return message;
    }

    if (!validateText(this.state.name)) {
      message = 'Debe ingresar nombre';
      return message;
    }

    if (!validateText(this.state.lastname)) {
      message = 'Debe ingresar apellido';
      return message;
    }

    if (!validateText(this.state.provider_type)) {
      message = 'Debe ingresar tipo de proveedor';
      return message;
    }

    if (!validateTextAndNumber(this.state.company)) {
      message = 'Debe ingresar empresa proveedora';
      return message;
    }

    if (this.state.reason && !validateFreeText2(this.state.reason)) {
      message = 'Debe ingresar motivo de visita';
      return message;
    }

    if (!validateNumber(this.state.visitors_number)) {
      message = 'Debe ingresar número de visitas';
      return message;
    }

    if (this.state.has_car) {
      if (!validateLicense(this.state.car_license_plate)) {
        message = 'Debe ingresar patente válida, ej: AABB77';
        return message;
      }
      if (!validateCarPark(this.state.car_park)) {
        message = 'Debe seleccionar estacionamiento';
        if ( !this.props.condo.parkings_lots.length ) {
          message = 'Debe ingresar estacionamiento válido, ej: 5V o 5 Visita';
        }

        return message;
      }
    }
    this.setState({showError: false});
    return message;
  }

  resetForm = () => {
    this.setState({ card_id:'', name:'', lastname: '', visitors_number: 1,
      has_car: false, car_license_plate: '', car_park: '', reason: '',
      provider_type: '', company: '', visitor_picture: '',
      card_id_type: 'RUT', showError: false, errorMessage: '', name_placeholder: '',
      lastname_placeholder: '', person_found: 1,
      isSearching: false, previuos_card_id: ''
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const visitor = {
        card_id: this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id,
        card_id_type: this.state.card_id_type,
        name: this.state.name,
        lastname: this.state.lastname,
        visitors_number: this.state.visitors_number,
        has_car: this.state.has_car,
        company: this.state.company,
        provider_type: this.state.provider_type
      };

      if (this.state.visitor_picture) {
        visitor["visitor_picture_64"] = this.state.visitor_picture;
      }

      if (this.state.reason) {
        visitor["reason"] = this.state.reason;
      }

      if (this.state.has_car) {
        visitor.car_license_plate = this.state.car_license_plate;
        visitor.car_park = this.state.car_park;
      }

      const response = await this.createVisitor(visitor);
      if (response.code === 0) {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Visita ingresada exitosamente!", refresh: true }, visitor);
      } else if (response.code === 405) {
        this.setState({showError: true, errorMessage: 'Estacionamiento en uso, favor seleccione otro.'});
        this.setState({ isLoading: false });
        return;
      } else {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear visita! " + msgExtra });
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.props.onAlertError({ message: "Error al registrar visita! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  capture = () => {
     const visitor_picture = this.webcam.getScreenshot();
     this.setState({ visitor_picture });
  }

  render() {

    var parkings_lots = [];
    if (this.props.condo.parkings_lots) {
      const parkings_lots_arr = this.props.condo.parkings_lots.filter( ( el ) => !this.state.parkings_lots_in_use.includes( el ) );
      parkings_lots = parkings_lots_arr.map(v => ({
        label: v,
        value: v
      }));
    }

    const colorAlert = "red";

    return (
      <div className="visitorBtn">
      { this.props.profile !== 'Comite' &&
      <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar visita</Button>
      }
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />Proveedor</Label> Datos de la visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew(parkings_lots)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.isBlacklisted} onHide={this.handleBClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Label color={colorAlert}><Icon name='dont' />Proveedor</Label>
            <Label color={colorAlert}>ATENCIÓN</Label> Visita en lista negra
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlacklistAlert blacklist={this.state.blacklist} color={colorAlert} danger={true}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleBClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {this.props.condo && this.props.condo.picture.pet &&
      <Modal className="visitor_cam_modal" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Webcam
          className="pet_cam"
          audio={false}
          height={config.picture.HEIGHT}
          width={config.picture.WIDTH}
          ref={node => this.webcam = node}
          screenshotFormat={config.picture.FORMAT}
          screenshotQuality={config.picture.QUALITY_HIGH}
          videoConstraints={config.picture.CONSTRAINS}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      }
      </div>
    );
  }

  renderFormNew(parkings_lots){
    return(
    <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="card_id">
          <Col componentClass={ControlLabel} sm={3}>
          <Radio
            label='RUT'
            name='radioGroup'
            value='RUT'
            checked={this.state.card_id_type === 'RUT'}
            onChange={this.handleChangeRadio}
          />
          <Radio
            label='Otro'
            name='radioGroup'
            value='OTHER'
            checked={this.state.card_id_type === 'OTHER'}
            onChange={this.handleChangeRadio}
          />
          </Col>
          <Col sm={7}>
              <DelayInput
                autoFocus={true}
                minLength={0}
                delayTimeout={100}
                placeholder="Rut u otro del personal de la empresa"
                onKeyPress={this.onKeyPress}
                id="card_id"
                className="form-control"
                value={this.state.card_id}
                onBlur={this.onBlur}
                onChange={this.handleChange} />
          </Col>
          <Col sm={1}>
            { this.state.isSearching &&
              <Icon loading color='blue' name='spinner' size='large'/>
            }
            { this.state.person_found === 0 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona encontrada</Tooltip>}>
                <Icon color='green' name='user' size='large' />
              </OverlayTrigger>
            }
            { this.state.person_found === 404 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona no encontrada. Debe registrar datos</Tooltip>}>
                <Icon color='red' name='user times' size='large'/>
              </OverlayTrigger>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT. Compatible con lector de códigos <Glyphicon glyph="qrcode" /></Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              ref="nameInput"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="ej: Diego"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastname">
          <Col componentClass={ControlLabel} sm={3}>
            Apellido (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.lastname}
              placeholder="ej: Salas"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="provider_type">
          <Col componentClass={ControlLabel} sm={3}>
            Tipo proveedor (*)
          </Col>
          <Col sm={8}>
            <Autosuggest
              datalist={this.state.suggestions}
              placeholder="ej: Ascensores"
              value={this.state.provider_type}
              onChange={this.onChangeProviderType}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tipo de proveedor. Si no está en la lista puedes ingresar uno.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="company">
          <Col componentClass={ControlLabel} sm={3}>
            Empresa (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.company}
              placeholder="ej: Otis"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre de la empresa. Usar 'Particular' si no tiene.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="reason">
          <Col componentClass={ControlLabel} sm={3}>
            Motivo visita
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.reason}
              placeholder="ej: mantención mensual"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Motivo de la visita.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="visitors_number">
          <Col componentClass={ControlLabel} sm={3}>
            N° Visitas (*)
          </Col>
          <Col sm={2}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.visitors_number}
              type='number'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de visitas.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="has_car">
          <Col componentClass={ControlLabel} sm={3}>
            Viene en vehículo?
          </Col>
          <Col sm={1}>
            <FormControl
              onChange={this.handleChange}
              type='checkbox'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Visita viene en vehículo?</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="car_license_plate">
          <Col componentClass={ControlLabel} sm={3}>
            Patente
          </Col>
          <Col sm={8}>
            <FormControl
              maxLength={6}
              onChange={this.handleChange}
              value={this.state.car_license_plate}
              disabled={!this.state.has_car}
              placeholder="ej: AABB77"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Patente de vehículo.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="car_park" visible='false'>
          <Col componentClass={ControlLabel} sm={3}>
            Estacionamiento
          </Col>
          <Col sm={8}>
            { parkings_lots.length ?
              <Select
                isDisabled={!this.state.has_car}
                onChange={this.handleParkingSlotChange}
                options={parkings_lots}
                placeholder="Seleccione" />
              :
              <React.Fragment>
                <Icon.Group>
                  <Icon size='large' color='red' name='dont' fitted/>
                  <Icon color='black' name='car' fitted/>
                </Icon.Group>
                <Label color='red'>Sin estacionamientos disponibles</Label>
              </React.Fragment>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Estacionamientos habilitados en el condominio.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {this.props.condo && this.props.condo.picture.visitor &&
        <FormGroup controlId="visitor_picture">
          <Col componentClass={ControlLabel} sm={3}>
            Foto visita
          </Col>
          <Col sm={4}>
            <Webcam
              audio={false}
              height={config.picture.HEIGHT_AVATAR}
              width={config.picture.WIDTH_AVATAR}
              screenshotFormat={config.picture.FORMAT}
              videoConstraints={config.picture.CONSTRAINS_AVATAR}
            />
          </Col>
          <Col sm={4}>
            <Row>
              <Col md={12}>
                <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
              </Col>
            </Row>
            {this.state.visitor_picture &&
            <React.Fragment>
              <Row>
              <Col md={12}>
                <p className="visitor_picture_previous_text">Previa:</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <img className="visitor_picture_previous" alt="Previa" src={this.state.visitor_picture} />
              </Col>
            </Row>
            </React.Fragment>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de visita.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        }
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewVisitorProvider
