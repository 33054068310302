export function validateEmail(text) {
  return /^[a-zA-Z]+[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(text);
}
export function validateNumber(text) {
  var re = new RegExp('^[0-9]+$');
  return re.test(text) && text > 0;
}
export function validateNumberWithZero(text) {
  var re = new RegExp('^[0-9]+$');
  return re.test(text) && text >= 0;
}
export function validateText(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validateTextAndNumber(text, ini = 2, end = 64) {
  var re = new RegExp('^[0-9a-zA-ZáéíóúÁÉÍÓÚñÑ ]{' + ini + ',' + end + '}$');
  return re.test(text);
}

export function validateFreeText(text, ini = 2, end = 1000) {
  var re = new RegExp('^[0-9a-zA-ZáéíóúÁÉÍÓÚñÑ (),.;:!@#$%&*¿?_":{}/]{' + ini + ',' + end + '}$');
  return re.test(text);
}

export function validateFreeText2(text, ini = 2, end = 1000) {
  var re = new RegExp('[^]{' + ini + ',' + end + '}$');
  return re.test(text);
}

export function validateBarcode(text, ini = 2, end = 64) {
  var re = new RegExp('^[a-zA-Z0-9]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validatePassport(text, ini = 6, end = 64) {
  var re = new RegExp('^[a-zA-Z0-9]{' + ini + ',' + end + '}$');
  return re.test(text);
}
export function validatePhone(text) {
  return /^[+]{1}[0-9]{3,16}$/.test(text);
}
export function validateLicense(text) {
  return /^[a-zA-Z]{2}[a-zA-Z0-9]{3}$/.test(text)
    || /^[a-zA-Z]{2}[a-zA-Z0-9]{2}[0-9]{2}$/.test(text);
}
export function validateCarPark(text) {
  return /^[0-9]{1,3}[a-zA-Z ]{1,10}$/.test(text);
}
// export function validatePassword(text) {
//   return /(?=.*[a-z])/.test(text) && /(?=.*[A-Z])/.test(text) && /(?=.*[0-9])/.test(text) && /(?=.*[!@#$%^&*(),.;?_":{}|<>/])/.test(text) && /(?=.{8,})/.test(text);
// }
export function validatePassword(text) {
  return /(?=.*[a-z])/.test(text) && /(?=.*[A-Z])/.test(text) && /(?=.*[0-9])/.test(text) && /(?=.{8,})/.test(text);
}
export function validateRut (rut) {
  if (typeof rut !== 'string') {
    return false
  }
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false
  }

  rut = cleanRut(rut)

  var t = parseInt(rut.slice(0, -1), 10)
  var m = 0
  var s = 1

  while (t > 0) {
    s = (s + (t % 10) * (9 - m++ % 6)) % 11
    t = Math.floor(t / 10)
  }

  var v = s > 0 ? '' + (s - 1) : 'K'
  return v === rut.slice(-1)
}

export function formatRut (rut) {
  rut = cleanRut(rut)

  if (rut.length < 4) return;

  var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
  for (var i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + '.' + result
  }

  return result
}

export function cleanRut (rut) {
  return typeof rut === 'string'
    ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    : ''
}

export function cleanRutFromReader (rut) {
    var rut_candidate = rut;
    if (rut.includes("RUN")) {
      let position = rut.indexOf('RUN');
      let edge = rut.substring(position + 4 + 7, position + 4 + 8);
      let offset = validateNumber(edge) ? 1 : 0;
      rut_candidate =
          rut.substring(position + 4, position + 4 + 7 + offset)
          + rut.substring(position + 4 + 8 + offset, position + 4 + 9 + offset);

      return rut_candidate;
    }
    if (rut.includes("C1s")) {
      rut_candidate = rut.substring(0, 9);
      return rut_candidate;
    }

    return rut_candidate;
}
