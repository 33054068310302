import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Visitor.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListVisitor from "./ListVisitor";
import NewVisitor from "./NewVisitor";
import NewVisitorCountry from "./NewVisitorCountry";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import VisitorExport from "./VisitorExport";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default class Visitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportableVisitors: [],
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      selectedDate: new Date(),
      steps: [
        {
          target: '.btn-primary',
          content: 'Para agregar una nueva visita presione aquí',
          placement: 'bottom',
        },
        {
          target: '.Visitor .visitors',
          content: (
            <div>
              <p>En este listado se muestran las visitas existentes.</p>
              <p>Las celdas <b style={{ background: '#ffb3b3' }}>rojas</b> representan visitas que aun están en la unidad,</p>
              <p>mientras que las <b style={{ background: '#ccffcc' }}>verdes</b> representan visitas que ya salieron de la unidad.</p>
            </div>
          ),
          placement: 'bottom',
        },
        {
          target: '.Visitor .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table a',
          content: 'Puedes ver el detalle de una visita haciendo click en el enlace',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleChange = (date) => {
    if (date === null || date === "") {
      date = new Date();
    }
    this.setState({ selectedDate: date });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleVisitorLoaded = (visitors) => {
      this.setState({exportableVisitors: visitors});
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy } = this.state;
    const pending = this.props.condo && this.props.condo.payment && this.props.condo.payment.pending;
    return (
      <div className="Visitor">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Visitas</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      Visitas
	    </PageHeader>
        {
          this.props.selectedUnit === null ?
            <Alert bsStyle="danger">
              <strong>Debe seleccionar unidad</strong>
            </Alert>
          :
          <ButtonGroup className="headerBtn">
          {this.props.condo
            && this.props.condo.visitor
            && this.props.condo.visitor.country
              ? <NewVisitorCountry {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
              : <NewVisitor {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
            }

            <DatePicker
              className="date-picker"
              selected={this.state.selectedDate}
              onChange={this.handleChange}
              dateFormat="MMM/yyyy"
              showMonthYearPicker={true}
              maxDate={new Date()}
            />
            <Button onClick={this.handleUpdate} className="btnRefresh">
              <Glyphicon glyph="refresh" />
            </Button>
            { !pending &&
              <VisitorExport data={this.state.exportableVisitors} />
            }
          </ButtonGroup>
        }
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListVisitor {...this.props}
          selectedDate={this.state.selectedDate}
          isLoading={this.state.isLoading}
          onVisitorLoaded={this.handleVisitorLoaded}
          onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
