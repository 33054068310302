import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { validateFreeText } from '../../libs/validation';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ListUnit.css';
import {sortByKeyDesc} from '../../libs/arrays';
import { Link } from "react-router-dom";

export default class ListUnit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableEdit: true,
            unit: '',
            number: '',
            floor: '',
            status: '',
            parking: '',
            warehouse: '',
            other: '',
            editCandidate: null,
            deleteCandidate: null,
            showDelete: false,
            showEdit: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            units: []
        };
    }

    componentDidMount() {
      this.findUnits();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedUnit !== this.props.selectedUnit) {
        this.findUnits();
      }
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findUnits();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findUnits = async () => {
      this.setState({ isLoading: true, units: [] });
      try {
        const results = await this.units();
        const units = sortByKeyDesc(results.units, "number")
        this.setState({ units: units });
        this.props.onHandleSize(units.length);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const unit = {
          unit: this.state.deleteCandidate.unit,
          status: "DELETED"
        };
        const response = await this.updateUnit(unit);
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code === 0) {
          this.state.deleteCandidate.status = "DELETED";
          this.handleAlertSuccess({ message: "Unidad eliminada correctamente!" });
        } else {
          this.handleAlertError({ message: "Error al eliminar unidad!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar encomienda!" });
      }
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {
        const unit = {
          unit: this.state.unit,
          status: this.state.status,
          warehouse: this.state.warehouse,
          parking: this.state.parking
        };

        const response = await this.updateUnit(unit);
        if (response.code === 0) {
          this.state.editCandidate.status = this.state.status;
          this.state.editCandidate.warehouse = this.state.warehouse;
          this.state.editCandidate.parking = this.state.parking;
          this.resetForm();
          this.handleAlertSuccess({ message: "Unidad actualizada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar unidad! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar unidad!" });

      }
      this.setState({ isLoading: false });
    }

    resetForm = () => {
      this.setState({ number:'', floor:'', status: '', unit: '',
        showError: false, errorMessage: '', parking: '',
        warehouse: '', other: '',
      });
    }

    updateUnit(unit) {
      return invokeApig({
        path: `/unit`,
        method: "PUT",
        body: unit
      });
    }

    units() {
      return invokeApig({
        path: "/unit/find",
        method: "POST"
      });
    }

    getErrors() {
      let message = undefined;

      if (this.state.warehouse && !validateFreeText(this.state.warehouse)) {
        message = 'Debe ingresar bodega válida';
        return message;
      }

      if (this.state.parking && !validateFreeText(this.state.parking)) {
        message = 'Debe ingresar estacionamiento válido';
        return message;
      }

      this.setState({showError: false});
      return message;
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener unidades. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {

      return row.hasOwnProperty('unit_name') ? row.unit_name : row.unit;


      // return <Link to={{ pathname:"/unit/detail", state: row }}>{cell}</Link>;
    }

    statusFormatter = (cell, row) => {
      return this.props.unitStatus[cell];
    }

    rowClassNameFormat = (row, rowIdx) => {
      return row['status'] === 'ACTIVE' ? 'unitStatusActive' : 'unitStatusWarning';
    }

    drawButton = (cell, row, rowIdx) => {
      return <div>
          <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
              <Button bsStyle="warning" onClick={this.showEditUnit.bind(cell, row, rowIdx)} className="btnEditDelivery">
                <Glyphicon glyph="edit" />
              </Button>
            </OverlayTrigger>
            {/*<Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, rowIdx)} className="btnDeleteDelivery">
              <Glyphicon glyph="trash" />
            </Button>*/}
          </ButtonGroup>
          </div>;
    }

    showEditUnit = (cell, row, rowIdx) => {
      this.setState({ unit: cell.unit, number: cell.number,
        floor: cell.floor, status: cell.status,
        warehouse: cell.warehouse, parking: cell.parking });
      this.setState({ showEdit: true, editCandidate: cell });
    }

    // showConfirmDelete = (cell, row, rowIdx) => {
    //   this.setState({
    //       deleteCandidate: cell
    //   },() => {
    //     this.setState({ showDelete: true});
    //   });
    // }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleClose = () => {
      this.setState({
        showDelete: false, deleteCandidate: null,
        showEdit: false, editCandidate: null,
        disableEdit: true });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.status === this.state.editCandidate.status
            && this.state.warehouse === this.state.editCandidate.warehouse
            && this.state.parking === this.state.editCandidate.parking;
          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.units;
        return(
          <div className="Unit">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="units">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }>
                {/*trClassName={this.rowClassNameFormat}>*/}
                <TableHeaderColumn dataField='unit' dataFormat={this.actionsFormatter} filter={ { type: 'TextFilter', placeholder: 'Ingresa unidad', delay: 10 } } dataSort={ true } isKey>Unidad</TableHeaderColumn>
                {/*<TableHeaderColumn dataField='number'>Número</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='floor'>Piso</TableHeaderColumn>
                {/*<TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter } filter={ { type: 'TextFilter', placeholder: 'Ingresa estado', delay: 10 } } dataSort={ true }>Estado</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='warehouse' filter={ { type: 'TextFilter', placeholder: 'Ingresa bodega', delay: 10 } } dataSort={ true } >Bodega(s)</TableHeaderColumn>
                <TableHeaderColumn dataField='parking' filter={ { type: 'TextFilter', placeholder: 'Ingresa estacionamiento', delay: 10 } } dataSort={ true } >Estacionamiento(s)</TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más unidades...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                { this.state.deleteCandidate &&
                <Label color='red'><Icon name='building' />{this.state.deleteCandidate.unit}</Label>
                }
                <Label color='red'>ELIMINACIÓN</Label> Eliminar unidad</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>¿Está seguro que quiere eliminar unidad <Label color='red'>{this.state.deleteCandidate.unit}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showEdit} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  { this.state.editCandidate &&
                  <Label color='orange'><Icon name='building' />{this.state.editCandidate.unit}</Label>
                  }
                  <Label color='orange'><Icon name='edit' /></Label> Editar unidad</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.editCandidate &&
                  this.renderFormEdit()
                }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="unit">
            <Col componentClass={ControlLabel} sm={3}>
              Unidad
            </Col>
            <Col sm={8}>
              <FormControl
                disabled
    	          onChange={this.handleChange}
    	          value={this.state.unit}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Unidad</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="number">
            <Col componentClass={ControlLabel} sm={3}>
              Número (*)
            </Col>
            <Col sm={8}>
              <FormControl
                disabled
                onChange={this.handleChange}
                value={this.state.number}
                type='number'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="floor">
            <Col componentClass={ControlLabel} sm={3}>
              Piso (*)
            </Col>
            <Col sm={8}>
              <FormControl
                disabled
                onChange={this.handleChange}
                value={this.state.floor}
                type='number'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Piso.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {/*<FormGroup controlId="status">
            <Col componentClass={ControlLabel} sm={3}>
              Estado (*)
            </Col>
            <Col sm={8}>
              <FormControl componentClass="select" onChange={this.handleChange} value={this.state.status} >
                <option value="ACTIVE">{this.props.unitStatus.ACTIVE}</option>
                <option value="UNPAID">{this.props.unitStatus.UNPAID}</option>
              </FormControl>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Estado.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          */}
          <FormGroup controlId="warehouse">
            <Col componentClass={ControlLabel} sm={3}>
              Bodega(s)
            </Col>
            <Col sm={8}>
              <FormControl
    	          onChange={this.handleChange}
    	          value={this.state.warehouse}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Bodega(s) (Dejar vacío si no tiene)</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="parking">
            <Col componentClass={ControlLabel} sm={3}>
              Estacionamiento(s)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.parking}
            />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Estacionamiento(s) (Dejar vacío si no tiene)</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
