import React, { Component } from "react";
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Radio } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Autosuggest from '../../libs/autosuggest/Autosuggest';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import 'moment/locale/es';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import VisitorDetailModal from "./VisitorDetailModal";
import { validateFreeText, validateNumber, formatRut } from '../../libs/validation';

export default class ListVisitorExceeded extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: '',
            disablePenalty: true,
            payment_info: '',
            penalty_skip_reason: '',
            unit: '',
            unit_name: '',
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            editCandidate: null,
            deleteCandidate: null,
            detailCandidate: null,
            exceededCandidate: null,
            penalty_status: "PENALTY",
            showDelete: false,
            showEdit: false,
            showPenalty: false,
            showDetails: false,
            isPenalty: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            visitors: [],
            suggestions: ['No excede el tiempo requerido', 'Autorizado por: ', 'Hora real de salida: ']
        };
    }

    componentDidMount = async () => {
      const user = await getUserAttributes();
      this.setState({ profile: user.profile });

      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitors();
        const visitors = sortByKey(results.visitors, "created_date")
        this.setState({ visitors: visitors });
        this.props.onHandleSize(visitors);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteVisitor();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar visita!" });
        } else {
          this.handleAlertSuccess({ message: "Visita eliminada correctamente!" });
          this.findVisitors();
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar visita!" });
      }
    }

    handlePenalty = async event => {
      event.preventDefault();
      this.setState({ isPenalty: true });
      try {
        const visitor = {
          operation: this.state.penalty_status,
          created_date: this.state.exceededCandidate.created_date
        };

        var successMessage = "Multa guardada correctamente!";
        if (this.state.penalty_status === "PENALTY") {
          visitor.payment_info = this.state.payment_info;
        } else {
          successMessage = "Omisión de multa guardada correctamente!";
          visitor.penalty_skip_reason = this.state.penalty_skip_reason;
        }

        const response = await this.updateVisitor(visitor);
        this.setState({ isPenalty: false, showPenalty: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al registrar!" });
        } else {
          this.handleAlertSuccess({ message: successMessage });
          this.findVisitors();
          this.resetForm();
        }
      } catch (e) {
        this.setState({ isPenalty: false, showPenalty: false, showAlertError: true, alertMessage: "Error al guardar multa!" });
      }
    }

    resetForm = () => {
      this.setState({ penalty_status: 'PENALTY', payment_info: '', showError: false,
        errorMessage: '', disablePenalty: true, penalty_skip_reason: '', unit: '', unit_name: '',
      });
    }

    updateVisitor(visitor) {
      visitor.unitId = this.state.unit;
      return invokeApig({
        path: `/visitor`,
        method: "PUT",
        body: visitor
      });
    }

    visitors() {
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "exceeded_status": "EC"
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{id}</Link>;
    }

    unitFormatter = (cell, row) => {
      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{unit}</Link>;
    }

    unitFormatterInModalTitle = () => {
      return this.state.unit_name !== '' ? this.state.unit_name:this.state.unit
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    dateInFormatter = () => {
      const date = moment(this.state.exceededCandidate.created_date);
      const dateFormatted = date.format("DD-MM-YYYY HH:mm");

      const user = this.state.exceededCandidate.user_who_receives;
      const userFormatted = user["name"] + " " + user["familyName"];

      return dateFormatted + " (" + userFormatted + ")";
    }

    dateOutFormatter = () => {
      if (this.state.exceededCandidate) {
        const date = moment(this.state.exceededCandidate.departure_date);
        const dateFormatted = date.format("DD-MM-YYYY HH:mm");

        const user = this.state.exceededCandidate.user_who_departures;
        const userFormatted = user["name"] + " " + user["familyName"];

        return dateFormatted + " (" + userFormatted + ")";
      }
      return "";
    }

    elapsedFormatter = (cell, row) => {
      if (cell) {
        // let duration = moment.duration({"minutes": cell});//.humanize();
        let hours = parseInt(cell / 60, 10).toString();
        let minutes = parseInt(cell % 60, 10).toString();
        // return duration.humanize();
        let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
        let color = cell === row["elapsed_time"] ? "blue": "red";
        return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
      }
    }

    timeFormatter = (cell, color) => {
      if (cell) {
        let hours = parseInt(cell / 60, 10).toString();
        let minutes = parseInt(cell % 60, 10).toString();
        let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
        return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter= (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        <Label>{row.car_park}</Label></span>
        : '';
    }

    licenseFormatterFromExceeded= () => {
      return <span>
        <Label>{this.state.exceededCandidate.car_license_plate}</Label>
        <Label>{this.state.exceededCandidate.car_park}</Label></span>;
    }

    parkFormatter= (cell, row) => {
      return cell && cell !== "-" ? <Label>{cell}</Label> : '';
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    drawButton = (cell, row, rowIdx) => {
      return this.state.profile === 'Supervisor' ?
              <ButtonGroup className="actionsBtn">
                <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">¿Multar?</Tooltip>}>
                  <Button bsStyle="danger" onClick={this.showDoPenalty.bind(cell, row, rowIdx)} className="showDoPenalty">
                    <Glyphicon glyph="pushpin" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
             :
              <ButtonGroup>
                <Link to={{ pathname:"/visitor/detail", state: row }}>{cell}
                  <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
                   <Button className="btnShowDelivery">
                     <Glyphicon glyph="search" />
                   </Button>
                  </OverlayTrigger>
                </Link>
              </ButtonGroup>;
    }

    showDoPenalty = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showPenalty: true, exceededCandidate: cell });
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    setUnitToShow = (cell) => {
      if (typeof(cell.unit_name) !== 'undefined') {
        this.setState({ unit_name: cell.unit_name });
      }
      this.setState({ unit: cell.unit });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleClose = () => {
      this.setState({
        penalty_status: 'PENALTY',
        payment_info: '', penalty_skip_reason: '',
        disablePenalty: true, showError: false,
        showDelete: false, deleteCandidate: null, unit: '', unit_name: '',
        showPenalty: false, exceededCandidate: null,
        showDetails: false, detailCandidate: null,
        showEdit: false, editCandidate: null });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showPenalty) {

          var disablePenalty;

          if (this.state.penalty_status === "PENALTY") {
            disablePenalty = !validateNumber(this.state.payment_info);
          } else {
            disablePenalty = !validateFreeText(this.state.penalty_skip_reason, 2, 64);
          }

          this.setState({ disablePenalty: disablePenalty });
        }
      });
    }

    handleChangeSkipReason= (event) => {
      this.setState({
          penalty_skip_reason: event
      },() => {
        if (this.state.showPenalty) {
          var disablePenalty = !validateFreeText(this.state.penalty_skip_reason, 2, 64);
          this.setState({ disablePenalty: disablePenalty });
        }
      });
    };

    handleChangeRadio = (e, { value }) => this.setState(
      { penalty_status: value,
        payment_info: '',
        penalty_skip_reason: '',
        disablePenalty: true
      }
    );

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                hover>
                <TableHeaderColumn dataField='unit' filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } } dataSort={ true } dataFormat={ this.unitFormatter }>
                  <Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn dataField='card_id' filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } } dataSort={ true } isKey dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter }>Estado</TableHeaderColumn> */}
                {/* <TableHeaderColumn width='100' headerAlign='center' dataAlign='center' dataField='visitors_number'>Nº Visitas</TableHeaderColumn>*/}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_receives'
                  dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='departure_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Salida
                </TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_departures'
                  dataFormat={ this.userFormatter }>Conserje (salida)</TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='elapsed_time'
                  dataFormat={ this.elapsedFormatter }>
                  <Icon name="time" />Total
                </TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='exceeded_time'
                  dataFormat={ this.elapsedFormatter }>
                  <Icon name="time" />Excedido
                </TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese patente', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='car_license_plate'
                  dataFormat={ this.licenseFormatter }>
                  <Icon name="car" />Vehículo
                </TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  width='150'
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showPenalty} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                <Label color='red'>MULTA</Label> Tiempo excedido en uso de estacionamientos de visita</Modal.Title>
              </Modal.Header>
              { this.state.exceededCandidate &&
                <Modal.Body>

                <Form horizontal>
                  <FormGroup controlId="created_date">
                    <Col componentClass={ControlLabel} sm={5}>
                      Fecha entrada
                    </Col>
                    <Col sm={7}>
                      <FormControl.Static>{this.dateInFormatter()}</FormControl.Static>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="departure_date">
                    <Col componentClass={ControlLabel} sm={5}>
                      Fecha salida
                    </Col>
                    <Col sm={7}>
                      <FormControl.Static>{this.dateOutFormatter()}</FormControl.Static>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="departure_date">
                    <Col componentClass={ControlLabel} sm={5}>
                      Vehículo
                    </Col>
                    <Col sm={7}>
                      <FormControl.Static>{this.licenseFormatterFromExceeded()}</FormControl.Static>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="exceeded_time">
                    <Col componentClass={ControlLabel} sm={5}>
                      Tiempo total
                    </Col>
                    <Col sm={7}>
                      <FormControl.Static>{this.timeFormatter(this.state.exceededCandidate.elapsed_time, "blue")}</FormControl.Static>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="exceeded_time">
                    <Col componentClass={ControlLabel} sm={5}>
                      Tiempo excedido
                    </Col>
                    <Col sm={7}>
                      <FormControl.Static>{this.timeFormatter(this.state.exceededCandidate.exceeded_time, "red")}</FormControl.Static>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="penalty_status">
                    <Col componentClass={ControlLabel} sm={5}>
                      Aplicar multa?
                    </Col>
                    <Col sm={6}>
                      <Radio
                        label='Si'
                        name='radioGroup'
                        value='PENALTY'
                        checked={this.state.penalty_status === 'PENALTY'}
                        onChange={this.handleChangeRadio}
                      />
                      <Radio
                        label='No'
                        name='radioGroup'
                        value='NO_PENALTY'
                        checked={this.state.penalty_status === 'NO_PENALTY'}
                        onChange={this.handleChangeRadio}
                      />
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </FormGroup>
                  { this.state.penalty_status === "PENALTY" &&
                    <FormGroup controlId="payment_info">
                      <Col componentClass={ControlLabel} sm={5}>
                        Folio pago (*)
                      </Col>
                      <Col sm={5}>
                        <FormControl
                        type='number'
                        autoFocus={true}
            	          value={this.state.payment_info}
            	          placeholder="01234567890"
                        onChange={this.handleChange}
            	        />
                      </Col>
                      <Col sm={2}>
                        <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Folio de pago si es requerido.</Tooltip>}>
                          <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                        </OverlayTrigger>
                      </Col>
                    </FormGroup>
                  }
                  { this.state.penalty_status === "NO_PENALTY" &&
                    <FormGroup controlId="penalty_skip_reason">
                      <Col componentClass={ControlLabel} sm={5}>
                        Motivo de omisión (*)
                      </Col>
                      <Col sm={5}>
                        <Autosuggest
                          datalist={this.state.suggestions}
                          onChange={this.handleChangeSkipReason}
                        />
                      </Col>
                      <Col sm={2}>
                        <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Motivo de la omisión de multa.</Tooltip>}>
                          <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                        </OverlayTrigger>
                      </Col>
                    </FormGroup>
                  }
                </Form>

                <p></p>
                <p className="psmall-center"><b>Nota:</b> La generación del cobro debe ser gestionada por Administración</p>
                <p className="psmall-center">Se dejará un registro de si la unidad fue o no multada (fecha y responsable)</p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton disabled={this.state.disablePenalty} bsStyle="danger" isLoading={this.state.isPenalty} onClick={this.handlePenalty} text="Registrar" loadingText="Registrando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.unitFormatterInModalTitle()}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
}
