import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import {validateBarcode, validateTextAndNumber } from '../../libs/validation';
import {sortByKey} from '../../libs/arrays';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import LoaderButton from "../../components/LoaderButton";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import './ListDeliverySummary.css';
import Select from 'react-select';
import CanvasDraw from "react-canvas-draw";
import DeliveryDetailModal from "./DeliveryDetailModal";

export default class ListDeliverySummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableEdit: true,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isLoadingResidents: false,
            isError: false,
            showDelete: false,
            showDetails: false,
            showEdit: false,
            showDelivery: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            selectedResident: null,
            actionCandidateIndex: null,
            deleteCandidate: null,
            detailCandidate: null,
            deliveryCandidate: null,
            editCandidate: null,
            deliveries: [],
            unit: '',
            unit_name: '',
            residents: [],
            barcode:'',
            description:'',
            resident:'',
            type: 'BOX',
            sign: '',
            sign_flag: false,
            sign_flag2: false,
            signProps: {
              width: 300,
              height: 200,
              brushRadius: 2,
              lazyRadius: 12
            }
        }
    }

    componentDidMount() {
      this.findDeliveries();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findDeliveries();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findResidents = async () => {
      try {
          this.setState({ residents: [], isLoadingResidents: true });
          const results = await this.residents();
          this.setState({ residents: results.residents, isLoadingResidents: false });
      } catch (e) {
        this.setState({isError: true});
      }
    }

    residents() {
      return invokeApig({
        path: "/resident/find",
        method: "POST",
        body: {
            "unitId": this.state.unit
        }
      });
    }

    findDeliveries = async () => {
      this.setState({ isLoading: true, deliveries: [] });
      try {
        const results = await this.deliveries();
        this.props.onCheckPaymentAdvice(results);
        const deliveries = sortByKey(results.deliveries, "created_date")
        this.props.onHandleSize(deliveries);
        this.setState({ deliveries: deliveries });
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    deliveries() {
      return invokeApig({
        path: "/delivery/find",
        method: "POST",
        body: {
            "status": "P"
        }
      });
    }

    deleteDelivery() {
      return invokeApig({
        path: "/delivery",
        method: "DELETE",
        body: {
          "unitId": this.state.unit,
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    updateDelivery(delivery) {
      return invokeApig({
        path: "/delivery",
        method: "PUT",
        body: delivery
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener encomiendas. Intenta más tarde." : "No hay datos";
      }
    }

    handleClose = () => {
      this.setState({ showDetails: false, detailCandidate: null,
        showDelete: false, deleteCandidate: null, actionCandidateIndex: null, unit: '', unit_name: '',
        showEdit: false, editCandidate: null, showError: false, sign: '',
        showDelivery: false, deliveryCandidate: null, sign_flag: false, sign_flag2: false,
        selectedResident: null });
    }

    handleCloseSign = () => {
      this.setState({ sign_flag2: false });
      const sign = JSON.parse(this.saveableCanvas2.getSaveData());
      if (sign && sign.lines.length) {
        this.setState({sign: this.saveableCanvas2.getSaveData()});
      }
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteDelivery();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar usuario!" });
        } else {
          this.state.deliveries.splice(this.state.actionCandidateIndex, 1);
          this.handleAlertSuccess({ message: "Encomienda eliminada correctamente!" });
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar encomienda!" });
      }
    }

    unitFormatterInModalTitle = () => {
      return this.state.unit_name !== '' ? this.state.unit_name:this.state.unit
    }

    unitFormatter = (cell, row) => {
      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;

      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{unit}</Link>;
    }

    actionsFormatter = (cell, row) => {
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{cell}</Link>;
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    typeFormatter = (cell, row) => {
      return this.props.deliveryType[cell];
    }

    statusFormatter = (cell, row) => {
      return this.props.deliveryStatus[cell];
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    elapsedFormatter = (cell, row) => {
      if (cell) {
        moment.locale('es');
        const date = moment(cell).fromNow(true);

        const creation_date = moment(cell);
        const diff = moment().diff(creation_date);
        const diffDuration = moment.duration(diff);
        const dateAsMinutes = diffDuration.asMinutes();

        if ( this.props.condo && this.props.condo.delivery && this.props.condo.delivery.store_max_time ) {
          const max_time_hours = this.props.condo.delivery.store_max_time;
          const max_time = max_time_hours * 60;
          if (max_time < dateAsMinutes) {
            const diff2 = parseInt(max_time_hours / 24, 10);
            return <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Encomienda con más de {diff2} día(s). Dar aviso a residente</Tooltip>}>
                  <span>
                    <Label className="delivery-exceeded-label" color='red'>
                      <Icon loading name='time' />{date}
                    </Label>
                  </span>
                </OverlayTrigger>;
          }
        }

        return <Label color='blue'><Icon name='time' />{date}</Label>;
      }
    }

    rowClassNameFormat = (row, rowIdx) => {
      return row['status'] === 'RECEIVED' ? 'received' : 'delivered';
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({showAlertSuccess:true},()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleChange = event => {
      if (event.target.id === "sign_flag") {
        this.setState({
            [event.target.id]: event.target.checked
        },() => {

        });
        return;
      }

      this.setState({
          [event.target.id]: event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.barcode === this.state.editCandidate.barcode
            && this.state.description === this.state.editCandidate.description
            && this.state.type === this.state.editCandidate.type;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    resetForm = () => {
      this.setState({ barcode:'', description:'',
        type: 'BOX', unit: '', unit_name: '',
        showError: false, errorMessage: ''});
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {
        const delivery = {
          barcode: this.state.barcode,
          description: this.state.description,
          type: this.state.type,
          operation: "EDIT",
          created_date: this.state.editCandidate.created_date,
          unitId: this.state.unit
        };

        const response = await this.updateDelivery(delivery);
        if (response.code === 0) {
          this.state.editCandidate.barcode = this.state.barcode
          this.state.editCandidate.description = this.state.description
          this.state.editCandidate.type = this.state.type
          this.resetForm();
          this.handleAlertSuccess({ message: "Encomienda actualizada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar encomienda! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar encomienda!" });

      }
      this.setState({ isLoading: false });
    }

    handleResidentChange = selectedOption => {
      const option = selectedOption ? selectedOption.value : null;
      this.setState({ selectedResident: option, showError: false });
    };

    handleDoDeliverySubmit = async event => {
      event.preventDefault();

      const errorMsg = this.getErrors();

      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }

      if (!this.state.selectedResident) {
        this.setState({showError: true, errorMessage: "Seleccione residente que recibe encomienda!"});
        return;
      }

      if (this.state.sign_flag) {
        const sign = JSON.parse(this.saveableCanvas.getSaveData());
        if (!sign || !sign.lines.length) {
          this.setState({showError: true, errorMessage: "Debe firmar entrega!"});
          return;
        }
      }

      this.setState({ isLoading: true });
      try {
        const delivery = {
          operation: "DELIVERY",
          created_date: this.state.deliveryCandidate.created_date,
          unitId: this.state.unit,
          resident: this.state.selectedResident,
          resident_sign: this.state.sign_flag ? this.saveableCanvas.getSaveData(): "-",
          resident_has_sign: this.state.sign_flag
        };

        const response = await this.updateDelivery(delivery);
        if (response.code === 0) {

          this.state.deliveries.splice(this.state.actionCandidateIndex, 1);

          this.resetForm();
          this.handleAlertSuccess({ message: "Encomienda entregada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al entregar encomienda! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al entregar encomienda!" });

      }
      this.setState({ isLoading: false });
    }

    getErrors() {
      let message = undefined;
      if (!validateBarcode(this.state.barcode)) {
        message = 'Debe ingresar código de barra';
      } else {
        if (!validateTextAndNumber(this.state.description)) {
          message = 'Debe ingresar descripción';
        }
      }
      return message;
    }

    showEditDelivery = (cell, row, index, enumObject) => {
      this.setUnitToShow(cell);
      this.setState({ barcode: cell.barcode, description: cell.description, type: cell.type });
      this.setState({ showEdit: true, editCandidate: cell });
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setUnitToShow(cell);
      this.setState({
        showDelete: true,
        deleteCandidate: cell,
        actionCandidateIndex: index
      });
    }

    showDoDelivery = (cell, row, index, enumObject) => {
      this.setUnitToShow(cell);
      this.setState({
         barcode: cell.barcode, description: cell.description,
         type: cell.type, showDelivery: true, deliveryCandidate: cell,
         actionCandidateIndex: index
      },() => {
       this.findResidents();
      });
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    setUnitToShow = (cell) => {
      if (typeof(cell.unit_name) !== 'undefined') {
        this.setState({ unit_name: cell.unit_name });
      }
      this.setState({ unit: cell.unit });
    }

    drawButton = (cell, row, index, enumObject) => {
      return <ButtonGroup className="actionsBtn">
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Entregar</Tooltip>}>
                <Button bsStyle="success" onClick={this.showDoDelivery.bind(cell, row, index, enumObject)} className="btnDoDelivery">
                  <Glyphicon glyph="log-out" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
                <Button bsStyle="warning" onClick={this.showEditDelivery.bind(cell, row, index, enumObject)} className="btnEditDelivery">
                  <Glyphicon glyph="edit" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
                <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, index, enumObject)} className="btnDeleteDelivery">
                  <Glyphicon glyph="trash" />
                </Button>
              </OverlayTrigger>
            </ButtonGroup>;
    }

    render(){
        const residents = this.state.residents ?
          this.state.residents.map(v => ({
            label: v.name + " " + v.lastname + " ("+ v.card_id +")",
            value: v
          })) : [];

        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };

        const data = this.state.deliveries;
        return(
          <div className="ListDeliverySummary">
          {
            this.state.showAlertSuccess &&
            <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          {
            this.state.showAlertError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          <Col sm={12} className="deliveries">
            <BootstrapTable
              pagination
              data={ data }
              selectRow={ selectRowProp }
              options={ tableOptions }
              trClassName={this.rowClassNameFormat}>
              <TableHeaderColumn
                dataField='unit'
                filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } }
                dataSort={ true }
                dataFormat={ this.unitFormatter }>
                <Glyphicon glyph="home" /> Unidad
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='barcode'
                filter={ { type: 'TextFilter', placeholder: 'Ingresa un código', delay: 10 } }
                dataSort={ true }
                isKey
                dataFormat={ this.actionsFormatter }>
                <Glyphicon glyph="barcode" /> Código barra
              </TableHeaderColumn>
              <TableHeaderColumn
                filter={ { type: 'TextFilter', placeholder: 'Ingrese descripción', delay: 10 } }
                dataField='description'>Descripción</TableHeaderColumn>
              {/*<TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter } dataSort={ true }>Estado</TableHeaderColumn>*/}
              <TableHeaderColumn
                filterFormatted
                filter={ { type: 'TextFilter', placeholder: 'Ingrese tipo', delay: 10 } }
                dataField='type'
                width='90'
                dataFormat={ this.typeFormatter }>Tipo</TableHeaderColumn>
              <TableHeaderColumn
                filterFormatted
                filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                dataField='created_date'
                dataFormat={ this.dateFormatter }
                dataSort={ true }>Fecha recepción</TableHeaderColumn>
              <TableHeaderColumn
                filterFormatted
                filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                dataField='user_who_receives'
                dataFormat={ this.userFormatter } >Conserje recepción</TableHeaderColumn>
              <TableHeaderColumn
                headerAlign='center'
                dataAlign='center'
                dataField='created_date'
                dataFormat={ this.elapsedFormatter }>Tiempo almacenado</TableHeaderColumn>
              <TableHeaderColumn
                hidden={this.props.profile === 'Comite'}
                width='150'
                headerAlign='center'
                dataAlign='center'
                dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
            </BootstrapTable>
            {
            	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más encomiendas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
            }
          </Col>
          <Modal show={this.state.showDelete} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
              <Label color='red'>ELIMINACIÓN</Label> Eliminar encomienda</Modal.Title>
            </Modal.Header>
            { this.state.deleteCandidate &&
              <Modal.Body>¿Está seguro que quiere eliminar la encomienda <Label color='red'><Icon name='trash' />{this.state.deleteCandidate.description}</Label>?</Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='orange'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                <Label color='orange'>EDICIÓN</Label> Datos de la encomienda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.editCandidate &&
                this.renderFormEdit()
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal backdrop={'static'} keyboard={false} show={this.state.showDelivery} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                <Label color='green'>ENTREGA</Label> Datos de la encomienda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.deliveryCandidate &&
                this.renderFormDoDelivery(residents)
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showDetails} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Label color='blue'>
                <Icon name='building' />{this.unitFormatterInModalTitle()}</Label> Detalle de la encomienda
              </Modal.Title>
            </Modal.Header>
            <DeliveryDetailModal {...this.props} cell={this.state.detailCandidate}/>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal className="sign-modal-dialog" backdrop={'static'} keyboard={false} show={this.state.sign_flag2} onHide={this.handleCloseSign}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='green'>FIRMA</Label> Datos de la encomienda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CanvasDraw
                  ref={canvasDraw => (this.saveableCanvas2 = canvasDraw)}
                  canvasWidth={window.innerWidth-2}
                  canvasHeight={window.innerHeight-65-57-2}
                  brushRadius={this.state.signProps.brushRadius}
                  lazyRadius={this.state.signProps.lazyRadius}
                />
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup className="actionsBtn">
                <Button bsStyle="success" onClick={this.handleCloseSign}>OK</Button>
                <Button bsStyle="warning" onClick={() => {
                  this.saveableCanvas2.clear();
                }}>Borrar
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal>
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="barcode">
            <Col componentClass={ControlLabel} sm={3}>
              Código barra (*)
            </Col>
            <Col sm={8}>
              <FormControl
  	          onChange={this.handleChange}
  	          value={this.state.barcode}
  	          placeholder="01234567890"
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Código de barra.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="description">
            <Col componentClass={ControlLabel} sm={3}>
              Descripción (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.description}
                placeholder="ej: Caja correos de Chile"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Descripción de la encomienda.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="type">
            <Col componentClass={ControlLabel} sm={3}>
              Tipo (*)
            </Col>
            <Col sm={8}>
              <FormControl componentClass="select" onChange={this.handleChange} value={this.state.type} >
                <option value="BOX">{this.props.deliveryType.BOX}</option>
                <option value="PACKAGE">{this.props.deliveryType.PACKAGE}</option>
                <option value="ENVELOPE">{this.props.deliveryType.ENVELOPE}</option>
                <option value="OTHER">{this.props.deliveryType.OTHER}</option>
              </FormControl>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al tipo de encomienda.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }

    renderFormDoDelivery(residents){
      return(
  	  <Form horizontal onSubmit={this.handleDoDeliverySubmit}>
          <FormGroup controlId="barcode">
            <Col componentClass={ControlLabel} sm={3}>
              <Glyphicon glyph="barcode" /> Código barra
            </Col>
            <Col sm={9}>
  		      	<FormControl.Static>{this.state.barcode}</FormControl.Static>
  		      </Col>
          </FormGroup>
          <FormGroup controlId="description">
            <Col componentClass={ControlLabel} sm={3}>
              Descripción
            </Col>
            <Col sm={9}>
  		      	<FormControl.Static>{this.state.description}</FormControl.Static>
  		      </Col>
          </FormGroup>
          <FormGroup controlId="type">
            <Col componentClass={ControlLabel} sm={3}>
              Tipo
            </Col>
            <Col sm={9}>
  		      	<FormControl.Static>{this.typeFormatter(this.state.type)}</FormControl.Static>
  		      </Col>
          </FormGroup>
          <FormGroup controlId="resident">
            <Col componentClass={ControlLabel} sm={3}>
              Residente (*)
            </Col>
            <Col sm={8} className="resident_select_list">
              { this.state.isLoadingResidents &&
                <div>Cargando residentes...</div>
              }
              { residents.length > 0 &&
                <Select
                  onChange={this.handleResidentChange}
                  options={residents}
                  placeholder="Seleccione" />
              }
              { !this.state.isLoadingResidents && !residents.length &&
                <div>Unidad no tiene residentes.
                <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
                </div>
              }
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que recibe la encomienda.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          { this.props.condo && this.props.condo.delivery && this.props.condo.delivery.signature &&
          <FormGroup controlId="sign_flag">
            <Col componentClass={ControlLabel} sm={3}>
              Requiere firma?
            </Col>
            <Col sm={1}>
              <FormControl
                onChange={this.handleChange}
                type='checkbox'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Requiere firma?</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
           }
          { this.state.sign_flag &&
            <FormGroup controlId="resident_sign">
              <Col componentClass={ControlLabel} sm={3}>
                Firma
              </Col>
              <Col sm={9}>
                <FormControl.Static id="canvasDrawId">
                  <CanvasDraw
                    ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                    canvasWidth={this.state.signProps.width}
                    canvasHeight={this.state.signProps.height}
                    brushRadius={this.state.signProps.brushRadius}
                    lazyRadius={this.state.signProps.lazyRadius}
                    saveData={this.state.sign}
                  />
                  <ButtonGroup className="actionsBtn">
                    <Button bsStyle="success" onClick={() => {
                      this.setState({ sign_flag2: true });
                    }}><Glyphicon glyph="zoom-in"/> Zoom
                    </Button>
                    <Button bsStyle="warning" onClick={() => {
                      this.saveableCanvas.clear();
                    }}>Borrar
                    </Button>
                  </ButtonGroup>
                </FormControl.Static>
              </Col>
            </FormGroup>
          }
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={!residents.length}
                bsStyle="success"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Entregar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
