import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import LoaderButton from "../../components/LoaderButton";
import { invokeApig } from "../../libs/awsLib";
import {validateBarcode, validateTextAndNumber} from '../../libs/validation';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

export class NewDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isLoadEdit: false,
      isEdit: props.isEdit,
      show: false,
      barcode:'',
      description:'',
      type: 'BOX',
      status: '',
      showError: false,
      errorMessage: ''
    };
  }

  validateForm() {
	  return this.state.email.length > 0 && this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  createDelivery(delivery) {
    delivery.unitId = this.props.selectedUnit["unit"];
    if (this.props.selectedUnit.hasOwnProperty('unit_name')) {
      delivery.unit_name = this.props.selectedUnit["unit_name"];
    }
    return invokeApig({
      path: "/delivery",
      method: "POST",
      body: delivery
    });
  }

  updateVisitor(visitor) {
    return invokeApig({
      path: `/user`,
      method: "PUT",
      body: visitor
    });
  }

  getDelivery() {
    return invokeApig({
      path: `/delivery/${this.props.username}`
    });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '',
        barcode:'', description:'', type: 'BOX', status: ''});
  }

  handleShow = () => {
    if(!this.props.selectedUnit){
      this.props.onAlertError({ message: "Debe seleccionar unidad" }, true);
      return;
    }

    this.setState({ show: true });
  }

  handleEdit = async event =>{
    event.preventDefault();
    try {
      this.setState({ isLoadEdit: true });
      const results = await this.getDelivery();
      this.setState({ ...results.data, show: true, isLoadEdit: false });
    } catch (e) {
      this.props.onAlertError({ message: "Error al consultar datos de la encomienda!" });
      this.setState({ isLoadEdit: false });
    }
  }

  getErrors() {
    let message = undefined;
    // if (!validateBarcode(this.state.barcode)) {
    //
    //   this.setState({ barcode: new Date().getTime() });
      // message = 'Debe ingresar código de barra';
    // } else {
      if (!validateTextAndNumber(this.state.description)) {
        message = 'Debe ingresar descripción';
      }
    // }
    return message;
  }

  resetForm = () => {
    this.setState({ barcode:'', description:'', type: 'BOX', phoneNumber: '',
      profile: 'Operador', status: '', showError: false, errorMessage: ''
    });
  }

  onKeyPress = event => {
    if (event.target.id === "barcode" && event.which === 13) {
      event.preventDefault();
      let descriptionInput = ReactDOM.findDOMNode(this.refs.descriptionInput);
      if (descriptionInput && descriptionInput.focus instanceof Function) {
          descriptionInput.focus();
      }
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const delivery = {
        barcode: validateBarcode(this.state.barcode) ? this.state.barcode : new Date().getTime().toString(),
        description: this.state.description,
        type: this.state.type
      };
      if (this.state.isEdit) {
        const response = await this.updateVisitor(delivery);
        if (response.errorMessage) {
          this.props.onAlertError({ message: "Error al actualizar usuario! Por favor revise los datos e intente nuevamente." });
        } else {
          this.resetForm();
          this.props.onAlertSuccess({ message: "Encomienda actualizada exitosamente!" }, delivery);
        }
      } else {
        const response = await this.createDelivery(delivery);
        if (response.code !== 0) {
          let msgExtra = 'Por favor revise los datos e intente nuevamente.'
          this.props.onAlertError({ message: "Error al crear encomienda! " + msgExtra });
        } else {
          this.resetForm();
          this.props.onAlertSuccess({ message: "Encomienda ingresada exitosamente!", refresh: true }, delivery);
        }
      }
      this.handleClose();
    } catch (e) {
      this.props.onAlertError({ message: "Error al guardar encomienda! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }
  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }
  renderFormNew(){
    return(
	  <Form horizontal onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress}>
        <FormGroup controlId="barcode">
          <Col componentClass={ControlLabel} sm={3}>
            Código barra
          </Col>
          <Col sm={8}>
            <FormControl
            autoFocus={true}
	          onChange={this.handleChange}
	          value={this.state.barcode}
	          placeholder="01234567890"
	        />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Código de barra (si no tiene, usar un dato diferenciador o dejar vacío).</Tooltip>}>
              <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="description">
          <Col componentClass={ControlLabel} sm={3}>
            Descripción (*)
          </Col>
          <Col sm={8}>
            <FormControl
              ref="descriptionInput"
              onChange={this.handleChange}
              value={this.state.description}
              placeholder="ej: Caja correos de Chile"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Descripción de la encomienda.</Tooltip>}>
              <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="type">
          <Col componentClass={ControlLabel} sm={3}>
            Tipo (*)
          </Col>
          <Col sm={8}>
            <FormControl componentClass="select" onChange={this.handleChange} value={this.state.type} >
              <option value="BOX">{this.props.deliveryType.BOX}</option>
              <option value="PACKAGE">{this.props.deliveryType.PACKAGE}</option>
              <option value="ENVELOPE">{this.props.deliveryType.ENVELOPE}</option>
              <option value="OTHER">{this.props.deliveryType.OTHER}</option>
            </FormControl>
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al tipo de encomienda.</Tooltip>}>
              <Link className="info" to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={this.state.isEdit ? "Modificar" : "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }

  render() {
    return (
      <div className="deliveryBtn">
      {
        this.state.isEdit ?
        <Button bsStyle="primary" onClick={this.handleEdit}>
          {
            this.state.isLoadEdit ?
            <Glyphicon glyph="refresh" className="spinning" />
            :
            <div>
              <Glyphicon glyph="edit" />
            </div>
          }
        </Button>
        :
		    <Button bsStyle="primary" onClick={this.handleShow}>
          <Glyphicon glyph="envelope"/> Registrar encomienda</Button>
      }
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos de la encomienda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default NewDelivery
