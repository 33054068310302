import React, { Component } from "react";
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { Image } from "react-bootstrap";
import { InputGroup, PageHeader, Form, FormGroup, FormControl, ControlLabel, Col, OverlayTrigger, Tooltip, Glyphicon, Alert, Modal, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Link } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";
import config from "../config";
import "./Login.css";
import {validateEmail, validateText, validateNumber, validatePhone, validatePassword} from '../libs/validation';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertError: false,
      alertMessage: '',
      email: "",
      phone_number: "",
      password: "",
      newPassword: "",
      newPasswordRep: "",
      verificationCode: '',
      isChangePassword: false,
      isForgotPassword: false,
      isVerificationCode: false,
      user: null,
      showtyc: true,
      termsHtml: '',
      enableButtonTyC: false,
      passwordType: 'password'
    };
  }

  handleEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "mousedown") {
      this.setState({ passwordType: "text"});
    } else {
      this.setState({ passwordType: "password"});
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  isBottom(el) {
    if (el && el.getBoundingClientRect())
      return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById('header');
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
    }
  }
  async showNewPasswordForm(userAttributes, user) {
	  this.setState({ ...userAttributes, isChangePassword: true, user: user, isLoading: false });

    const results = await fetch('/terms.html');
    if (results.status !== 200) {
      throw new Error(await results.text());
    }
    this.setState({termsHtml: await results.text()});
	  return Promise.resolve();
  }

  login(email, password) {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID
    });
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: result => resolve(),
        onFailure: err => {
          if (err.code === 'PasswordResetRequiredException') {
            this.setState({isForgotPassword: true, isVerificationCode: true});
          }
          reject(err)
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => this.showNewPasswordForm(userAttributes, user)
      })
    );
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  getLoginErrors() {
    let message = undefined;
    if (!validateEmail(this.state.email)) {
      message = 'Debe ingresar el email correctamente';
    } else {
      if (!validatePassword(this.state.password)) {
        message = 'Debe ingresar la clave correctamente';
      }
    }
    return message;
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getLoginErrors();
    if (errorMsg) {
      this.handleAlertError(errorMsg, {isLoading: false});
      return;
    }
    this.setState({ isLoading: true, showtyc: true });
    try {
      await this.login(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      let message = 'Error al iniciar sesión';
      if (e.code === 'UserNotFoundException') {
        message = 'El email ingresado no está registrado';
      } else {
        if (e.code === 'NotAuthorizedException') {
          if (e.message === 'Incorrect username or password.') {
            message = 'Clave incorrecta, intenta nuevamente.';
          } else { //"User is disabled."
            message = 'Cuenta suspendida. Para mas información contáctese con DeptoGestion.';
          }
        }
      }
      this.handleAlertError(message, {isLoading: false});
    }
  }

  changePassword(newPassword, name, family_name, phone_number) {
    const userAttributes = {
      name: name,
      family_name: family_name,
      phone_number: phone_number
    }
    return new Promise((resolve, reject) =>
      this.state.user.completeNewPasswordChallenge(newPassword, userAttributes, {
  	    onSuccess: result => resolve(),
  	    onFailure: err => reject(err)
  	  })
  	);
  }
  getErrorsChangePassword() {
    let message = undefined;
    if (!validateText(this.state.name)) {
      message = 'Debe ingresar el nombre correctamente';
    } else {
      if (!validateText(this.state.family_name)) {
        message = 'Debe ingresar el apellido correctamente';
      } else {
        if (this.state.phone_number && !validatePhone(this.state.phone_number)) {
          message = 'Debe ingresar el teléfono correctamente: +01234567890';
        } else {
          if (!validatePassword(this.state.newPassword)) {
            message = 'Debe ingresar una clave válida: que contenga mayúscula, minúscula, número y un mínimo de 8 caracteres';
          } else {
            if (this.state.newPassword !== this.state.newPasswordRep) {
              message = 'Las claves no coinciden. Debes ingresar la misma clave';
            }
          }
        }
      }
    }
    return message;
  }
  handleSubmitChangePassword = async event => {
    event.preventDefault();
    const errorMsg = this.getErrorsChangePassword();
    if (errorMsg) {
      this.handleAlertError(errorMsg, { isLoading: false });
      return;
    }
    this.setState({ isLoading: true });
    try {
      await this.changePassword(this.state.newPassword, this.state.name, this.state.family_name, this.state.phone_number);
      this.setState({ isLoading: false });
      this.props.userHasAuthenticated(true);
    } catch (e) {
      let message = 'Error al modificar la clave. Intenta nuevamente';
      this.handleAlertError(message, { isLoading: false });
    }
  }
  handleAlertError = (message, extras) => {
    this.setState({ ...extras, alertMessage: message, showAlertError: true });
  }
  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }
  handleButtonTerms = () => {
    this.setState({enableButtonTyC: true});
  }
  handleCloseClickTyC = () => {
    this.setState({ showtyc: false, isChangePassword: false, email: "", password: "" });
  }
  handleClickTyC = () => {
    this.setState({ showtyc: false });
  }
  handleShowForgotPassword = (evt) => {
    evt.preventDefault();
    this.setState({isForgotPassword: true, isVerificationCode: false});
  }
  handleCloseForgotPassword = () => {
    this.setState({isForgotPassword: false});
  }
  handleDismissForgotError = () => {
    this.setState({showAlertForgotError: false, alertForgotMessage: ''});
  }
  getForgotPasswordErrors() {
    let message = undefined;
    if (!validateEmail(this.state.email)) {
      message = 'Debe ingresar el email correctamente';
    }
    return message;
  }
  handleForgotPassword = (evt) => {
    evt.preventDefault();
    const errorMsg = this.getForgotPasswordErrors();
    if (errorMsg) {
      this.setState({showAlertForgotError: true, alertForgotMessage: errorMsg});
      return;
    }
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID
    });
    const user = new CognitoUser({ Username: this.state.email, Pool: userPool });
    user.forgotPassword({
      onSuccess: (result) => {
        this.setState({isVerificationCode: true, showAlertForgotError: false});
      },
      onFailure: (err) => {
        var message = 'Se ha producido un error al recuperar la contraseña. Intenta más tarde.';
        if (err.message === 'User is disabled.') {
          message = 'Cuenta suspendida. Para mas información contáctese con DeptoGestion.';
        }

        this.setState({showAlertForgotError: true, alertForgotMessage: message});

      }
    });
  }
  getConfirmPasswordErrors() {
    let message = undefined;
    if (!validateNumber(this.state.verificationCode)) {
      message = 'Debe ingresar el código de verificación correctamente';
    } else {
      if (!validatePassword(this.state.newPassword)) {
        message = 'Debe ingresar la clave correctamente';
      }
    }
    return message;
  }
  handleConfirmPassword = (evt) => {
    evt.preventDefault();
    const errorMsg = this.getConfirmPasswordErrors();
    if (errorMsg) {
      this.setState({showAlertForgotError: true, alertForgotMessage: errorMsg});
      return;
    }
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID
    });
    const user = new CognitoUser({ Username: this.state.email, Pool: userPool });
    var verificationCode = this.state.verificationCode;
    var newPassword = this.state.newPassword;
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        this.setState({isForgotPassword: false, isVerificationCode: false, isLoading: false, alertMessage: '', showAlertError: false});
      },
      onFailure: (err) => {
        const message = 'Hubo un error al resetear la contraseña. Intenta nuevamente.';
        this.setState({showAlertForgotError: true, alertForgotMessage: message});
      }
    });
  }
  renderChangePwd() {
    return (
      <div className="Login">
        <Form horizontal onSubmit={this.handleSubmitChangePassword}>
          <FormGroup controlId="name" bsSize="large">
  	        <Col componentClass={ControlLabel} sm={5}>Nombre (*)</Col>
            <Col sm={6}>
              <FormControl autoFocus type="text" value={this.state.name} onChange={this.handleChange} placeholder="Sólo texto"/>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tu nombre. Sólo letras.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
  	      </FormGroup>
  	      <FormGroup controlId="family_name" bsSize="large">
            <Col componentClass={ControlLabel} sm={5}>Apellido (*)</Col>
            <Col sm={6}>
              <FormControl type="text" value={this.state.family_name} onChange={this.handleChange} placeholder="Sólo texto"/>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tu apellido. Sólo letras.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
  	      </FormGroup>
  	      <FormGroup controlId="phone_number" bsSize="large">
            <Col componentClass={ControlLabel} sm={5}>Teléfono</Col>
            <Col sm={6}>
              <FormControl type="text" value={this.state.phone_number} onChange={this.handleChange} placeholder="+56990000000"/>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tu teléfono. Formato +56990000000.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
  	      </FormGroup>
  	      <FormGroup controlId="newPassword" bsSize="large">
  	        <Col componentClass={ControlLabel} sm={5}>Nueva Clave (*)</Col>
            <Col sm={6}>
              <InputGroup controlId="newPassword" bsSize="large">
                <FormControl {...this.props}
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  type={this.state.passwordType}
                  placeholder="May/min y números"
                />
                <InputGroup.Button>
                  <Button
                    onMouseDown={ this.handleEvent }
                    onMouseUp={ this.handleEvent }
                    onMouseOut={ this.handleEvent } >
                    <Glyphicon glyph="glyphicon glyphicon-eye-open" />
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tu nueva clave. Debe contener mayúscula, minúscula, número y mínimo 8 caracteres.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
  	      </FormGroup>
  	      <FormGroup controlId="newPasswordRep" bsSize="large">
  	        <Col componentClass={ControlLabel} sm={5}>Confirmar Nueva Clave (*)</Col>
            <Col sm={6}>
                <FormControl {...this.props}
                  value={this.state.newPasswordRep}
                  onChange={this.handleChange}
                  type={this.state.passwordType}
                />

            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Repite tu nueva clave.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
  	      </FormGroup>
          {
          	this.state.showAlertError &&
          	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
          	  <strong>{this.state.alertMessage}</strong>
          	</Alert>
          }
  	      <LoaderButton
  	        block
  	        bsSize="large"
  	        disabled={!this.validateForm()}
  	        type="submit"
  	        isLoading={this.state.isLoading}
  	        text="Cambiar clave"
  	        loadingText="Enviando…"
  	      />
  	    </Form>
        <Modal show={this.state.showtyc} onHide={()=>{return false;}}>
          <Modal.Header>
            <Modal.Title>Términos y condiciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BottomScrollListener onBottom={this.handleButtonTerms}>
              {scrollRef => (
                <div ref={scrollRef} className="terms" dangerouslySetInnerHTML={{__html: this.state.termsHtml}}></div>
              )}
            </BottomScrollListener>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseClickTyC}>No acepto</Button>
            <Button onClick={this.handleClickTyC} bsStyle="primary" disabled={!this.state.enableButtonTyC}>Acepto</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  renderForgotPassword() {
    return (
      <Modal show={this.state.isForgotPassword} onHide={()=>{return false;}}>
        <Modal.Header>
          <Modal.Title>Recuperación de contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            this.state.isVerificationCode ?
            <div>
              <FormGroup controlId="verificationCode" bsSize="large">
                <ControlLabel>Ingresa el código de verificación que enviamos a tu email &nbsp;
                  <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Código de 6 dígitos enviado a tu correo.</Tooltip>}>
                    <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                  </OverlayTrigger>
                </ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={this.state.verificationCode}
                  onChange={this.handleChange}
                  placeholder="Código"
                />
              </FormGroup>
              <FormGroup controlId="newPassword" bsSize="large">
                <ControlLabel>Ingresa la nueva contraseña &nbsp;
                  <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tu nueva clave. Debe contener mayúscula, minúscula, número y mínimo 8 caracteres.</Tooltip>}>
                    <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                  </OverlayTrigger>
                </ControlLabel>
                <FormControl
                  autoFocus
                  type="password"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  placeholder="Contraseña"
                />
              </FormGroup>
            </div>
            :
            <FormGroup controlId="email" bsSize="large">
              <ControlLabel>Ingresa tu email</ControlLabel>
              <FormControl
                autoFocus
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder="usuario@dominio.com"
              />
            </FormGroup>
          }
          {
          	this.state.showAlertForgotError &&
          	<Alert bsStyle="danger" onDismiss={this.handleDismissForgotError}>
          	  <strong>{this.state.alertForgotMessage}</strong>
          	</Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseForgotPassword}>Cancelar</Button>
          {
            this.state.isVerificationCode ?
            <Button onClick={this.handleConfirmPassword} bsStyle="primary">Cambiar contraseña</Button>
            :
            <Button onClick={this.handleForgotPassword} bsStyle="primary">Enviar</Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
  renderLogin() {
    return (
      <div className="Login">
  	    <PageHeader>
          <center>
            <Image
              className="logo_header"
              rounded
              responsive
              src={"/black-logo453x150.png"}/>
            </center>
            <center>Inicia sesión</center>
  	    </PageHeader>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="usuario@dominio.com"
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Clave</ControlLabel>
              <FormControl {...this.props}
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
          </FormGroup>
          {
          	this.state.showAlertError &&
          	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
          	  <strong>{this.state.alertMessage}</strong>
          	</Alert>
          }
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Ingresar"
            loadingText="Enviando…"
            bsStyle="primary"
          />
          <Link to="#" className="forgotPasswordText" onClick={this.handleShowForgotPassword}>
            &iquest;Olvidaste la contrase&ntilde;a?
          </Link>
          <p></p>
          <Link to="contact">
            &iquest;Aún no eres usuario?
          </Link>
        </form>
        {
          this.renderForgotPassword()
        }
      </div>
    );
  }
  render() {
    return (
      this.state.isChangePassword ?
      this.renderChangePwd()
      :
      this.renderLogin()
    );
  }
}
