import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";

import Contact from "./containers/pub/Contact";

import Booking from "./containers/booking/Booking";
import BookingSummary from "./containers/booking/BookingSummary";

import Summary from "./containers/summary/Summary";

import Delivery from "./containers/delivery/Delivery";
import DeliveryDetail from "./containers/delivery/DeliveryDetail";
import DeliverySummary from "./containers/delivery/DeliverySummary";

import Unit from "./containers/unit/Unit";
import UnitDetail from "./containers/unit/UnitDetail";

import Report from "./containers/report/Report";

import Visitor from "./containers/visitor/Visitor";
import VisitorDaily from "./containers/visitor/VisitorDaily";
import VisitorDetail from "./containers/visitor/VisitorDetail";
import VisitorSummary from "./containers/visitor/VisitorSummary";
import VisitorExceeded from "./containers/visitor/VisitorExceeded";
import VisitorProvider from "./containers/visitor/VisitorProvider";
import VisitorInfracted from "./containers/visitor/VisitorInfracted";
import VisitorBlocked from "./containers/visitor/VisitorBlocked";

import Settings from "./containers/settings/Settings";

import AccessControl from "./containers/access/AccessControl";
import AccessControlSummary from "./containers/access/AccessControlSummary";

import DailyBook from "./containers/book/DailyBook";
import NoticeBook from "./containers/book/NoticeBook";

import Resident from "./containers/resident/Resident";
import ResidentSummary from "./containers/resident/ResidentSummary";
import ResidentValidation from "./containers/resident/ResidentValidation";

import Pet from "./containers/pet/Pet";
import PetSummary from "./containers/pet/PetSummary";

import PenaltySummary from "./containers/penalty/PenaltySummary";

import User from "./containers/user/User";
import UserDetail from "./containers/user/UserDetail";
import Profile from "./containers/user/Profile";

import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/resident/validation" exact component={ResidentValidation} props={childProps} />
    <AppliedRoute path="/contact" exact component={Contact} props={childProps} />

    <AuthenticatedRoute path="/user" exact component={User} props={childProps} />
    <AuthenticatedRoute path="/user/detail" exact component={UserDetail} props={childProps} />

    <AuthenticatedRoute path="/booking" exact component={Booking} props={childProps} />
    <AuthenticatedRoute path="/booking/summary" exact component={BookingSummary} props={childProps} />

    <AuthenticatedRoute path="/summary" exact component={Summary} props={childProps} />

    <AuthenticatedRoute path="/delivery" exact component={Delivery} props={childProps} />
    <AuthenticatedRoute path="/delivery/detail" exact component={DeliveryDetail} props={childProps} />
    <AuthenticatedRoute path="/delivery/summary" exact component={DeliverySummary} props={childProps} />

    <AuthenticatedRoute path="/visitor" exact component={Visitor} props={childProps} />
    <AuthenticatedRoute path="/visitor/daily" exact component={VisitorDaily} props={childProps} />
    <AuthenticatedRoute path="/visitor/detail" exact component={VisitorDetail} props={childProps} />
    <AuthenticatedRoute path="/visitor/summary" exact component={VisitorSummary} props={childProps} />
    <AuthenticatedRoute path="/visitor/exceeded" exact component={VisitorExceeded} props={childProps} />
    <AuthenticatedRoute path="/visitor/provider" exact component={VisitorProvider} props={childProps} />
    <AuthenticatedRoute path="/visitor/infracted" exact component={VisitorInfracted} props={childProps} />
    <AuthenticatedRoute path="/visitor/blocked" exact component={VisitorBlocked} props={childProps} />

    <AuthenticatedRoute path="/access/control" exact component={AccessControl} props={childProps} />
    <AuthenticatedRoute path="/access/control/summary" exact component={AccessControlSummary} props={childProps} />

    <AuthenticatedRoute path="/book/diarybook" exact component={DailyBook} props={childProps} />
    <AuthenticatedRoute path="/book/notice" exact component={NoticeBook} props={childProps} />

    <AuthenticatedRoute path="/unit" exact component={Unit} props={childProps} />
    <AuthenticatedRoute path="/unit/detail" exact component={UnitDetail} props={childProps} />

    <AuthenticatedRoute path="/report" exact component={Report} props={childProps} />

    <AuthenticatedRoute path="/resident" exact component={Resident} props={childProps} />
    <AuthenticatedRoute path="/resident/summary" exact component={ResidentSummary} props={childProps} />

    <AuthenticatedRoute path="/pet" exact component={Pet} props={childProps} />
    <AuthenticatedRoute path="/pet/summary" exact component={PetSummary} props={childProps} />

    <AuthenticatedRoute path="/penalty/summary" exact component={PenaltySummary} props={childProps} />

    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps}/>

    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />

    <Route component={NotFound} />
  </Switch>;
