const dev = {
  s3: {
    BUCKET: "files.labs.deptogestion.com"
  },
  s3_files: {
    BUCKET: "https://app.labs.deptogestion.com/"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.labs.deptogestion.com"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_nOS3vE4GO",
    APP_CLIENT_ID: "5q98cn1ag89uap49p1u811fh39",
    IDENTITY_POOL_ID: "us-west-2:ecfc9281-311e-41c4-9db5-aa984ab4d0f7"
  },
  captcha: {
    KEY: '6Le_qaoZAAAAAPim2oK7_fH53EtMuaMV2rbvAM4J'
  },
  picture: {
    WIDTH: 640,
    HEIGHT: 480,
    WIDTH_AVATAR: 200,
    HEIGHT_AVATAR: 150,
    FORMAT: "image/jpeg",
    QUALITY_HIGH: 0.96,
    QUALITY_MEDIUM: 0.96,
    QUALITY_LOW: 0.92,
    CONSTRAINS: {width: 640, height: 480, facingMode: "environment"},
    CONSTRAINS_AVATAR: {width: 200, height: 150, facingMode: "environment"}
  }
};

const prod = {
  s3: {
    BUCKET: "files.deptogestion.com"
  },
  s3_files: {
    BUCKET: "https://app.deptogestion.com/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.deptogestion.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_T7ql2W6me",
    APP_CLIENT_ID: "b6ql4jln752dvtevis9i35t1i",
    IDENTITY_POOL_ID: "us-east-1:52faa685-bbb1-4b6f-a886-b68e7c702db5"
  },
  captcha: {
    KEY: '6Le_qaoZAAAAAPim2oK7_fH53EtMuaMV2rbvAM4J'
  },
  picture: {
    WIDTH: 640,
    HEIGHT: 480,
    WIDTH_AVATAR: 200,
    HEIGHT_AVATAR: 150,
    FORMAT: "image/jpeg",
    QUALITY_HIGH: 0.96,
    QUALITY_MEDIUM: 0.96,
    QUALITY_LOW: 0.92,
    CONSTRAINS: {width: 640, height: 480, facingMode: "user"},
    CONSTRAINS_AVATAR: {width: 200, height: 150, facingMode: "user"}
  }
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : dev;

config.version = "v.1.4";

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
