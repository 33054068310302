import React, { Component } from "react";
import { invokeApig } from "../../libs/awsLib";
import {
    Col, Form, FormGroup, ControlLabel, FormControl, Glyphicon
} from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import moment from 'moment';

export default class UserDetailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.cell,
      isLoading: false,
      show: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      sign: '',
      signProps: {
        width: 180,
        height: 180,
        brushRadius: 1,
        lazyRadius: 12
      }
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  getDelivery() {
    return invokeApig({
      path: `/delivery/find`,
      method: "POST",
      body: {
        "unitId": this.props.selectedUnit["unit"],
        "created_date": this.state.created_date
      }
    });
  }

  handleGetSign = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const response = await this.getDelivery();
      this.setState({ isLoading: false });

      if (response.code !== 0) {
        this.handleAlertError({ message: "Error al obtener firma!" });
        return;
      }

      if (response.deliveries && response.deliveries.length) {
        this.setState({ sign: response.deliveries[0].resident_sign });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleAlertError({ message: "Error al resetear clave del usuario!" });
    }
  }

  handleAlertSuccess = (message, user) => {
    var newState = { ...user, alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    this.setState(newState);
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  bookdateFormatter = (cell, start) => {
    if (cell) {
      const date = moment(cell);
      const dateFormatted = date.format("DD-MM-YYYY");
      const hourFormatted = date.format("HH:mm");
      let color = start ? "blue": "red";
      return <span>
        <Label>{dateFormatted}</Label>
        <Label color={color}><Icon name='time' />{hourFormatted}</Label>
        </span>;
    }
  }

  typeFormatter = (type) => {
    return this.props.deliveryType[type];
  }

  statusFormatter = (status) => {
    const statusFormatted = this.props.bookingStatus[status]
    const color = status === 'PENDING' ? 'red':'green';
    return <Label color={color}>{statusFormatted}</Label>
  }

  residentFormatter = (resident) => {
    return resident
    && typeof(resident.name) !== 'undefined'
    && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  render(){
    const { resident, resourceName, start, end,
      status, payment_info, user_who_creates } = this.state ;
    return(
      <div className="Booking">
        <Form horizontal onSubmit={this.handleSubmit}>
  	       <FormGroup controlId="resident">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        <Glyphicon glyph="user" /> Residente{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.residentFormatter(resident)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="resourceName">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Lugar{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{resourceName}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="start">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Inicio{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.bookdateFormatter(start, true)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          <FormGroup controlId="end">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Fin{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.bookdateFormatter(end, false)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          <FormGroup controlId="status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Estado{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.statusFormatter(status)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="payment_info">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Info. adicional {' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{payment_info}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="user_who_creates">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Conserje{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.userFormatter(user_who_creates)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  	    </Form>
      </div>
    );
  }
}
