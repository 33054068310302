import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import Select from 'react-select';
import { Icon, Label, Image, Radio } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import 'moment/locale/es';
import './ListVisitor.css';
import {sortByKey} from '../../libs/arrays';
import VisitorDetailModal from "./VisitorDetailModal";
import { validateNumber, validateText, validateLicense, validateCarPark, formatRut } from '../../libs/validation';
import config from "../../config";

const provider = 'provider';

export default class ListVisitorSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            disableEdit: true,
            has_car: false,
            unit: '',
            unit_name: '',
            name: '',
            lastname: '',
            card_id: '',
            card_id_type: '',
            visitors_number: 1,
            car_license_plate: '',
            car_park: '',
            editCandidate: null,
            deleteCandidate: null,
            detailCandidate: null,
            departuresCandidate: null,
            actionCandidateIndex: -1,
            showDelete: false,
            showEdit: false,
            showDetails: false,
            showDeparture: false,
            isDepartured: false,
            isDeleting: false,
            isEditing: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            visitors: [],
            iconColor: "white",
            parkings_lots_in_use: []
        };
    }

    componentDidMount() {
      this.findVisitors();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isLoading && this.props.isLoading) {
        this.findVisitors();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findVisitors = async () => {
      this.setState({ isLoading: true, visitors: [] });
      try {
        const results = await this.visitors();
        this.props.onCheckPaymentAdvice(results);
        const visitors = sortByKey(results.visitors, "created_date");
        this.setState({ visitors: visitors });
        this.props.onHandleSize(visitors.length);

        const slots = visitors.map((visitor) =>
            visitor.car_park
          );
        this.props.onHandleSlots(slots);

      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    findFreeParkingSlots = async () => {
      try {
        const results = await this.free_parkings_lots();
        const parkings_lots_in_use = results.visitors.map((visitor) =>
            visitor.car_park
          );

        this.setState({ parkings_lots_in_use });

      } catch (e) {
        this.setState({isError: true});
      }
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteVisitor();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar visita!" });
        } else {
          this.handleAlertSuccess({ message: "Visita eliminada correctamente!" });
          this.findVisitors();
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar visita!" });
      }
    }

    handleDeparture = async event => {
      event.preventDefault();
      this.setState({ isDepartured: true });
      try {
        const visitor = {
          operation: this.state.departuresCandidate.unit === provider? "DEPARTURE2":"DEPARTURE",
          created_date: this.state.departuresCandidate.created_date
        };

        const response = await this.updateVisitor(visitor);
        this.setState({ isDepartured: false, showDeparture: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al marcar salida!" });
        } else {
          this.state.visitors.splice(this.state.actionCandidateIndex, 1);

          this.props.onHandleSize(this.state.visitors.length);
          const slots = this.state.visitors.map((visitor) => visitor.car_park);
          this.props.onHandleSlots(slots);

          this.handleAlertSuccess({ message: "Salida marcada correctamente!" });
        }
      } catch (e) {
        this.setState({ isDepartured: false, showDeparture: false, showAlertError: true, alertMessage: "Error al marcar salida!" });
      }
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {
        const visitor = {
          name: this.state.name,
          lastname: this.state.lastname,
          visitors_number: this.state.visitors_number,
          has_car: this.state.has_car,
          car_license_plate: this.state.has_car? this.state.car_license_plate: '-',
          car_park: this.state.has_car ? this.state.car_park : '-',
          operation: "EDIT",
          created_date: this.state.editCandidate.created_date
        };

        const response = await this.updateVisitor(visitor);
        if (response.code === 0) {
          this.state.editCandidate.name = this.state.name;
          this.state.editCandidate.lastname = this.state.lastname;
          this.state.editCandidate.visitors_number = this.state.visitors_number;
          this.state.editCandidate.has_car = this.state.has_car;
          this.state.editCandidate.car_park = this.state.has_car ? this.state.car_park : '-';
          this.state.editCandidate.car_license_plate = this.state.has_car? this.state.car_license_plate: '-';

          this.resetForm();
          this.findVisitors();
          this.handleAlertSuccess({ message: "Visita actualizada exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar visita! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar visita!" });

      }
      this.setState({ isLoading: false });
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '',
        name:'', lastname: '', visitors_number: 1, unit: '', unit_name: '',
        has_car: false, car_license_plate: '', car_park: '', status: '',
        showError: false, errorMessage: ''
      });
    }

    updateVisitor(visitor) {
      visitor.unitId = this.state.unit;
      return invokeApig({
        path: `/visitor`,
        method: "PUT",
        body: visitor
      });
    }

    visitors() {
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "status": "RC"
        }
      });
    }

    free_parkings_lots() {
      return invokeApig({
        path: "/visitor/find",
        method: "POST",
        body: {
            "status_summary": "RC"
        }
      });
    }

    deleteVisitor() {
      return invokeApig({
        path: "/visitor",
        method: "DELETE",
        body: {
          "unitId": this.state.unit,
          "created_date": this.state.deleteCandidate.created_date
        }
      });
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.lastname)) {
        message = 'Debe ingresar apellido';
        return message;
      }

      if (!validateNumber(this.state.visitors_number)) {
        message = 'Debe ingresar número de visitas';
        return message;
      }

      if (this.state.has_car) {
        if (!validateLicense(this.state.car_license_plate)) {
          message = 'Debe ingresar patente válida, ej: AABB77';
          return message;
        }
        if (!validateCarPark(this.state.car_park)) {
          message = 'Debe ingresar estacionamiento válido, ej: 5V o 5 Visita';
          return message;
        }
      }
      this.setState({showError: false});
      return message;
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener visitas. Intenta más tarde." : "No hay datos";
      }
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);

      var type = "";
      if (typeof(row.visit_type) !== 'undefined') {
        type = <span><Icon color='red' name='food' fitted/> <Icon color='red' name='dolly' fitted/></span>
      }

      return <span><Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{id}</Link> {type}</span>;
    }

    unitFormatter = (cell, row) => {
      if (cell === provider)
        return <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">{row['provider_type']}</Tooltip>}>
            <Label color='blue'>{row['company']}</Label>
        </OverlayTrigger>;

      const unit = typeof(row.unit_name) !== 'undefined' ? row.unit_name : cell;
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{unit}</Link>;
    }

    unitFormatterInModalTitle = () => {
      if (this.state.unit === provider)
        return "Proveedor";
      return this.state.unit_name !== '' ? this.state.unit_name:this.state.unit
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    elapsedFormatter = (cell, row) => {
      if (cell) {
        moment.locale('es');
        const date = moment(cell).fromNow(true);

        const creation_date = moment(cell);
        const diff = moment().diff(creation_date);
        const diffDuration = moment.duration(diff);
        const dateAsMinutes = diffDuration.asMinutes();

        if ( row["unit"] !== "provider" && this.props.condo && this.props.condo.parking_max_time ) {
          const max_time = this.props.condo.parking_max_time * 60;
          if (max_time < dateAsMinutes) {
            const diff2 = parseInt(dateAsMinutes - max_time, 10);
            var diff2_str = diff2 + "min";
            if (diff2 > 60) {
              diff2_str = parseInt(diff2 / 60,10) + "hr(s)";
            }

            return <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Vehículo excedido [{diff2_str}]</Tooltip>}>
                  <span>
                    <Label className="visitor-exceeded-label" color='red'>
                      <Icon loading name='time' />{date}
                    </Label>
                  </span>
                </OverlayTrigger>;
          }
        }

        return <Label color='blue'><Icon name='time' />{date}</Label>;
      }
    }

    statusFormatter = (cell, row) => {
      const status =  this.props.visitorStatus[cell];
      return <Label color='blue'><Icon name='calendar' />{status}</Label>
    }

    nameFormatter = (cell, row) => {
      return row.name + " " + row.lastname;
    }

    userFormatter = (cell, row) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    licenseFormatter= (cell, row) => {
      return cell && cell !== "-" ?
        <span>
        <Label>{row.car_license_plate}</Label>
        {row.car_park && <Label>{row.car_park}</Label>}
        </span>
        : '';
    }

    parkFormatter= (cell, row) => {
      return cell && cell !== "-" ? <Label>{cell}</Label> : '';
    }

    visitorFormatter = (visitor) => {
      return visitor
      && typeof(visitor.name) !== 'undefined'
      && typeof(visitor.lastname) !== 'undefined' ? visitor.name + " " + visitor.lastname: "";
    }

    rowClassNameFormat = (row, rowIdx) => {
      if (row['status'] === 'DEPARTED') return 'departed';
      if (row['car_park'] === '-' && row['car_license_plate'] === '-') return;
      return 'received';
    }

    drawButton = (cell, row, index, enumObject) => {
      return row['unit'] !== provider ?
          <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Marcar salida</Tooltip>}>
              <Button bsStyle="success" onClick={this.showDoDeparture.bind(cell, row, index, enumObject)} className="btnDoDelivery">
                <Glyphicon glyph="log-out" />
              </Button>
            </OverlayTrigger>
            { this.props.condo &&
              this.props.condo.visitor &&
              !this.props.condo.visitor.country &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
              <Button bsStyle="warning" onClick={this.showEditVisitor.bind(cell, row, index, enumObject)} className="btnEditDelivery">
                <Glyphicon glyph="edit" />
              </Button>
            </OverlayTrigger>
            }
            {/*
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, rowIdx)} className="btnDeleteDelivery">
                <Glyphicon glyph="trash" />
              </Button>
            </OverlayTrigger>
            */}
            {this.props.condo && this.props.condo.picture.visitor && typeof(row.visitor_picture_url) !== 'undefined' &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
              <Button bsStyle="primary" onClick={this.showVisitorPicture.bind(cell, row, index, enumObject)} className="btnShowVisitor">
                <Glyphicon glyph="camera" />
              </Button>
            </OverlayTrigger>
            }
          </ButtonGroup>
         :
         <ButtonGroup className="actionsBtn">
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Marcar salida</Tooltip>}>
             <Button bsStyle="success" onClick={this.showDoDeparture.bind(cell, row, index, enumObject)} className="btnDoDelivery">
               <Glyphicon glyph="log-out" />
             </Button>
           </OverlayTrigger>
           { this.props.condo &&
             this.props.condo.visitor &&
             !this.props.condo.visitor.country &&
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
             <Button bsStyle="warning" onClick={this.showEditVisitor.bind(cell, row, index, enumObject)} className="btnEditDelivery">
               <Glyphicon glyph="edit" />
             </Button>
           </OverlayTrigger>
          }
           {this.props.condo && this.props.condo.picture.visitor && typeof(row.visitor_picture_url) !== 'undefined' &&
           <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
             <Button bsStyle="primary" onClick={this.showVisitorPicture.bind(cell, row, index, enumObject)} className="btnShowVisitor">
               <Glyphicon glyph="camera" />
             </Button>
           </OverlayTrigger>
           }
         </ButtonGroup>;
    }

    showEditVisitor = (cell, row, index, enumObject) => {
      this.findFreeParkingSlots();
      this.setUnitToShow(cell);
      this.setState({ name: cell.name, lastname: cell.lastname,
        card_id: cell.card_id, card_id_type: cell.card_id_type ? cell.card_id_type : 'RUT',
        visitors_number: cell.visitors_number,
        showEdit: true, editCandidate: cell });
      if (cell.car_license_plate && cell.car_license_plate !== '-'
        && cell.car_park && cell.car_park !== '-') {
        this.setState({ has_car: true, car_license_plate: cell.car_license_plate, car_park: cell.car_park });
      }
    }

    showConfirmDelete = (cell, row, index, enumObject) => {
      this.setUnitToShow(cell);
      this.setState({ showDelete: true, actionCandidateIndex: index, deleteCandidate: cell });
    }

    showDoDeparture = (cell, row, index, enumObject) => {
      this.setUnitToShow(cell);
      this.setState({ showDeparture: true, actionCandidateIndex: index, departuresCandidate: cell });
    }

    showVisitorPicture = (cell, row, index, enumObject) => {
      this.setState({ showPicture: true, actionCandidateIndex: index, detailCandidate: cell });
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setUnitToShow(cell);
      this.setState({ showDetails: true, detailCandidate: cell });
    }

    setUnitToShow = (cell) => {
      if (typeof(cell.unit_name) !== 'undefined') {
        this.setState({ unit_name: cell.unit_name });
      }
      this.setState({ unit: cell.unit });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false  });
    }

    handleClose = () => {
      this.setState({
        has_car: false,
        disableEdit: true, showError: false, actionCandidateIndex: -1,
        showDetails: false, detailCandidate: null, showPicture: false,
        showDelete: false, deleteCandidate: null, unit: '', unit_name: '',
        showDeparture: false, departuresCandidate: null, parkings_lots_in_use: [],
        showEdit: false, editCandidate: null });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.id === "has_car" ? event.target.checked:event.target.value
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.name === this.state.editCandidate.name
            && this.state.lastname === this.state.editCandidate.lastname
            && this.state.visitors_number === this.state.editCandidate.visitors_number
            && this.state.car_license_plate === this.state.editCandidate.car_license_plate
            && this.state.car_park === this.state.editCandidate.car_park;

          this.setState({ disableEdit: disableEdit });
        }
      });
    }

    handleParkingSlotChange = selectedOption => {
      const option = selectedOption ? selectedOption.value : null;
      this.setState({
          car_park: option
      },() => {
        if (this.state.showEdit) {
          const disableEdit = this.state.car_park === this.state.editCandidate.car_park;

          this.setState({ disableEdit: disableEdit });
        }
      });
    };

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption(),
          exportCSVText: 'Exportar a CSV'
        };

        var parkings_lots = [];
        if (this.props.condo && this.props.condo.parkings_lots) {
          const parkings_lots_arr = this.props.condo.parkings_lots.filter( ( el ) => !this.state.parkings_lots_in_use.includes( el ) );
          parkings_lots = parkings_lots_arr.map(v => ({
            label: v,
            value: v
          }));
        }

        const data = this.state.visitors;
        return(
          <div className="Visitor">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="visitors">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                trClassName={this.rowClassNameFormat}>
                <TableHeaderColumn
                  dataField='unit'
                  filter={ { type: 'TextFilter', placeholder: 'Ingresa una unidad', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.unitFormatter }>
                  <Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField='card_id'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } }
                  dataSort={ true }
                  dataFormat={ this.actionsFormatter }>RUT (u otro)</TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese nombre', delay: 10 } }
                  dataField='name'
                  dataFormat={ this.nameFormatter }>Nombre</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status' dataFormat={ this.statusFormatter }>Estado</TableHeaderColumn> */}
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese Nº Visitas', delay: 10 } }
                  width='100'
                  headerAlign='center'
                  dataAlign='center'
                  dataField='visitors_number'>Nº Visitas</TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese conserje', delay: 10 } }
                  dataField='user_who_receives'
                  dataFormat={ this.userFormatter }>Conserje (entrada)</TableHeaderColumn>
                <TableHeaderColumn
                  headerAlign='center'
                  dataAlign='center'
                  dataField='created_date'
                  dataFormat={ this.elapsedFormatter }>Tiempo transcurrido</TableHeaderColumn>
                <TableHeaderColumn
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese patente', delay: 10 } }
                  headerAlign='center'
                  dataAlign='center'
                  dataField='car_license_plate'
                  dataFormat={ this.licenseFormatter }>
                  <Icon name="car" />Vehículo
                </TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más visitas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                <Label color='red'>ELIMINACIÓN</Label> Eliminar visita</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>¿Está seguro que quiere eliminar visita <Label color='red'>{this.visitorFormatter(this.state.deleteCandidate)}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDeparture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                <Label color='green'>SALIDA</Label> Marcar salida de visita</Modal.Title>
              </Modal.Header>
              { this.state.departuresCandidate &&
                <Modal.Body>¿Confirma la salida de la visita <Label color='green'>{this.visitorFormatter(this.state.departuresCandidate)}</Label>?</Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="success" isLoading={this.state.isDepartured} onClick={this.handleDeparture} text="Salida" loadingText="Guardando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showEdit} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  <Label color='orange'><Icon name='building' />{this.unitFormatterInModalTitle()}</Label>
                  <Label color='orange'>EDICIÓN</Label> Editar visita</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.editCandidate &&
                  this.renderFormEdit(parkings_lots)
                }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='building' />{this.unitFormatterInModalTitle()}</Label> Detalle de la visita
                </Modal.Title>
              </Modal.Header>
              <VisitorDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
            <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>
                  <Label color='blue'><Icon name='user' />Nombre:</Label> {this.state.detailCandidate && this.visitorFormatter(this.state.detailCandidate)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.detailCandidate &&
                  <Image
                    className="visitor_picture_modal"
                    src={config.s3_files.BUCKET + this.state.detailCandidate.visitor_picture_url} />
                }
              </Modal.Body>
            </Modal>
          </div>
        );
    }

    renderFormEdit(parkings_lots){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="card_id">
            <Col componentClass={ControlLabel} sm={3}>
            <Radio
              label='RUT '
              name='radioGroup'
              value='RUT'
              disabled
              checked={this.state.card_id_type === 'RUT'}
            />
            <Radio
              label='Otro'
              name='radioGroup'
              value='OTHER'
              disabled
              checked={this.state.card_id_type === 'OTHER'}
            />
            </Col>
            <Col sm={8}>
              <FormControl
              disabled
  	          onChange={this.handleChange}
  	          value={this.state.card_id}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="name">
            <Col componentClass={ControlLabel} sm={3}>
              Nombre (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
                placeholder="ej: Diego"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="lastname">
            <Col componentClass={ControlLabel} sm={3}>
              Apellido (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.lastname}
                placeholder="ej: Salas"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="visitors_number">
            <Col componentClass={ControlLabel} sm={3}>
              N° Visitas (*)
            </Col>
            <Col sm={2}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.visitors_number}
                type='number'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de visitas.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="car_license_plate">
            <Col componentClass={ControlLabel} sm={3}>
              Patente
            </Col>
            <Col sm={8}>
              <FormControl
                maxLength={6}
                onChange={this.handleChange}
                value={this.state.car_license_plate}
                disabled={!this.state.has_car}
                placeholder="ej: AABB77"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Patente de vehículo.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="car_park" visible='false'>
            <Col componentClass={ControlLabel} sm={3}>
              Estacionamiento
            </Col>
            <Col sm={8}>
                <Select
                  isDisabled={!this.state.has_car}
                  onChange={this.handleParkingSlotChange}
                  options={parkings_lots}
                  noOptionsMessage={() => "Sin estacionamientos disponibles"}
                  placeholder={this.state.car_park} />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Estacionamientos habilitados en el condominio.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
