import ReactDOM from 'react-dom';
import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Radio } from 'semantic-ui-react'
import { invokeApig } from "../../libs/awsLib";
import { validateText, validatePassport, formatRut,
  validateRut, cleanRut, cleanRutFromReader } from '../../libs/validation';
import {DelayInput} from 'react-delay-input';
import { Link } from "react-router-dom";

export class NewVisitorBlocked extends Component {
  constructor(props) {
    super(props);

    this.state = {
      has_car: false,
      name: '',
      lastname: '',
      card_id: '',
      card_id_type: 'RUT',
      isLoading: false,
      show: false,
      showError: false,
      errorMessage: '',
      isSearching: false,
      previuos_card_id: '',
      name_placeholder: '',
      lastname_placeholder: '',
      person_found: 1
    };
  }

  isRut(){return this.state.card_id_type === 'RUT';}

  handleChangeRadio = (e, { value }) => this.setState({ card_id_type: value });

  handleChange = event => {
    if (event.target.id === "has_car") {
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    if (event.target.id === "card_id") {
      var card_id_value = cleanRutFromReader(event.target.value)
      this.setState({
          [event.target.id]: this.isRut() ? formatRut(card_id_value): card_id_value,
      });
      return;
    }

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onKeyPress = event => {
    if (event.which === 13) {
      event.preventDefault();

      this.searchPerson();

      let nameInput = ReactDOM.findDOMNode(this.refs.nameInput);
      if (nameInput && nameInput.focus instanceof Function) {
          nameInput.focus();
      }
    }
  }

  onBlur = event => {
    this.searchPerson();
  }

  createVisitor(visitor) {
    visitor.unitId = this.props.selectedUnit["unit"];
    return invokeApig({
      path: "/blacklist",
      method: "POST",
      body: visitor
    });
  }

  searchPerson = async event => {
    const card_id = this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id;
    const previuos_card_id = this.state.previuos_card_id;

    if (card_id.length < 7) return;
    if (card_id === previuos_card_id) return;

    this.setState({ isSearching: true, person_found: 1,
      lastname_placeholder: 'Buscando...', name_placeholder: 'Buscando...',
      lastname: '', name: '' });
    const response = await this.getPerson(card_id);

    this.setState({person_found: response.code})
    if (response.code === 0) {
      this.setState({
        name: response.person.name,
        lastname: response.person.lastname
      })
    }

    this.setState({ isSearching: false, previuos_card_id: card_id, name_placeholder: '', lastname_placeholder: '' });
  }

  getPerson(card_id) {
      return invokeApig({
          path: "/person/" + card_id,
          method: "GET"
      });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '' });
    this.setState({ has_car: false, name: '', lastname: '',
      card_id: '', card_id_type: 'RUT',
      name_placeholder: '',
      lastname_placeholder: '', person_found: 1,
      isSearching: false, previuos_card_id: '' });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  getErrors() {
    let message = undefined;

    if (this.isRut() && !validateRut(this.state.card_id)) {
      message = 'Debe ingresar un RUT válido';
      return message;
    } else if (!this.isRut() && !validatePassport(this.state.card_id)) {
      message = 'Debe ingresar un id válido';
      return message;
    }

    if (!validateText(this.state.name)) {
      message = 'Debe ingresar nombre';
      return message;
    }

    if (!validateText(this.state.lastname)) {
      message = 'Debe ingresar apellido';
      return message;
    }

    this.setState({showError: false});
    return message;
  }

  resetForm = () => {
    this.setState({ card_id:'', name:'', lastname: '',
      card_id_type: 'RUT', showError: false, errorMessage: '',
      name_placeholder: '', lastname_placeholder: '', person_found: 1,
      isSearching: false, previuos_card_id: ''
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const visitor = {
        card_id: this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id,
        card_id_type: this.state.card_id_type,
        name: this.state.name,
        lastname: this.state.lastname
      };

      const response = await this.createVisitor(visitor);
      if (response.code === 0) {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Visita ingresada exitosamente!", refresh: true }, visitor);
      } else if (response.code === 405) {
        this.setState({showError: true, errorMessage: 'Estacionamiento en uso, favor seleccione otro.'});
        this.setState({ isLoading: false });
        return;
      } else {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear visita! " + msgExtra });
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.props.onAlertError({ message: "Error al registrar visita! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  render() {
    return (
      <div className="visitorBtn">

      <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar visita en lista negra</Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos de la persona a bloquear</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  renderFormNew(){
    return(
    <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="card_id">
          <Col componentClass={ControlLabel} sm={3}>
          <Radio
            label='RUT'
            name='radioGroup'
            value='RUT'
            checked={this.state.card_id_type === 'RUT'}
            onChange={this.handleChangeRadio}
          />
          <Radio
            label='Otro'
            name='radioGroup'
            value='OTHER'
            checked={this.state.card_id_type === 'OTHER'}
            onChange={this.handleChangeRadio}
          />
          </Col>
          <Col sm={7}>
              <DelayInput
                leadingNotify={true}
                trailingNotify={true}
                minLength={0}
                autoFocus={true}
                delayTimeout={100}
                placeholder="Rut u otro"
                onKeyPress={this.onKeyPress}
                onBlur={this.onBlur}
                id="card_id"
                className="form-control"
                value={this.state.card_id}
                onChange={this.handleChange} />
          </Col>
          <Col sm={1}>
            { this.state.isSearching &&
              <Icon loading color='blue' name='spinner' size='large'/>
            }
            { this.state.person_found === 0 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona encontrada</Tooltip>}>
                <Icon color='green' name='user' size='large' />
              </OverlayTrigger>
            }
            { this.state.person_found === 404 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona no encontrada. Debe registrar datos</Tooltip>}>
                <Icon color='red' name='user times' size='large'/>
              </OverlayTrigger>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT. Compatible con lector de códigos <Glyphicon glyph="qrcode" /></Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              ref="nameInput"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder={this.state.name_placeholder}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastname">
          <Col componentClass={ControlLabel} sm={3}>
            Apellido (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.lastname}
              placeholder={this.state.lastname_placeholder}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup>
          <p className="psmall">
          <b>Nota:</b> Esta persona no podrá ingresar a la unidad hasta que el residente decida removerlo de esta lista.
          </p>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewVisitorBlocked
