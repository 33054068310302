import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Booking.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListBookingSummary from "./ListBookingSummary";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default class BookingSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      runJoy: false,
      selectedDate: new Date(),
      steps: [
        {
          target: '.Booking .bookings',
          content: 'En este listado se muestran las reservas pendientes de revisión de deuda',
          placement: 'bottom',
        },
        {
          target: '.Booking .date-picker',
          content: 'Puedes buscar por mes',
          placement: 'bottom',
        },
        {
          target: '.Booking .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Booking .table a',
          content: 'Puedes ver el detalle de una visita haciendo click en el enlace',
          placement: 'bottom',
        },
        {
          target: '.Booking .actions-btn',
          content: 'Puedes aceptar o rechazar una reserva si no cumple las políticas de la comunidad',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#' });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleSize = (sizeValue) => {
      this.setState({size: sizeValue});
  }

  handleChange = (date) => {
    if (date === null || date === "") {
      date = new Date();
    }
    this.setState({ selectedDate: date });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy, size } = this.state;
    return (
      <div className="Booking">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Reservas</Breadcrumb.Item>
	      <Breadcrumb.Item active>Listado</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      <span className="pheader">({size}) Reservas por mes</span>

	    </PageHeader>
      <ButtonGroup>
        <DatePicker
          className="date-picker"
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          dateFormat="MMM/yyyy"
          showMonthYearPicker={true}
        />
        <Button onClick={this.handleUpdate} className="btnRefresh">
          <Glyphicon glyph="refresh" />
        </Button>
      </ButtonGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListBookingSummary {...this.props}
          selectedDate={this.state.selectedDate}
          onHandleSize={this.handleSize}
          isLoading={this.state.isLoading}
          onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
