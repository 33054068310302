import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListPenaltySummary from "./ListPenaltySummary";
import NewPenalty from "./NewPenalty";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import PenaltyExport from "./PenaltyExport";
import "./Penalty.css";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default class PenaltySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      penalties: [],
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      runJoy: false,
      selectedDate: new Date(),
      steps: [
        {
          target: '.btn-primary',
          content: 'Para agregar una nueva multa presione aquí',
          placement: 'bottom',
        },
        {
          target: '.Penalty .penalties',
          content: 'En este listado se muestran las multas del mes',
          placement: 'bottom',
        },
        {
          target: '.Penalty .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Penalty .table a',
          content: 'Puedes ver el detalle de una multa haciendo click en el enlace',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#' });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleChange = (date) => {
    if (date === null || date === "") {
      date = new Date();
    }
    this.setState({ selectedDate: date });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleSize = (penalties) => {
    this.setState({penalties: penalties, size: penalties.length});
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, size, runJoy } = this.state;
    const pending = this.props.condo && this.props.condo.payment && this.props.condo.payment.pending;
    return (
      <div className="Penalty">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Multas</Breadcrumb.Item>
        <Breadcrumb.Item active>Resumen</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
        <span className="pheader">({size}) Multas generales</span>
	    </PageHeader>
        <ButtonGroup className="headerBtn">
          <NewPenalty {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
          <DatePicker
            className="date-picker"
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            dateFormat="MMM/yyyy"
            showMonthYearPicker={true}
            maxDate={new Date()}
          />
          <Button onClick={this.handleUpdate} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
          { !pending &&
          <PenaltyExport {...this.props} data={this.state.penalties} />
          }
        </ButtonGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListPenaltySummary {...this.props}
          selectedDate={this.state.selectedDate}
          onHandleSize={this.handleSize}
          isLoading={this.state.isLoading}
          onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
