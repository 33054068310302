import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from 'semantic-ui-react'
import ReactExport from "react-excel-exportz";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class PenaltyExport extends Component {

  dateFormatter = (cell) => {
    if (cell) {
      const date = moment(cell);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  simpleFormatter = (cell) => {
    return cell ? cell : "-";
  }

  timeFormatter = (cell, row) => {
    if (cell) {
      let hours = parseInt(cell / 60, 10).toString();
      let minutes = parseInt(cell % 60, 10).toString();
      let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
      return exceeded_time;
    }
  }

  userFormatter = (cell) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  statusFormatter = (cell) => {
    return this.props.penaltyStatus[cell];
  }

  render() {
      return (
        <ExcelFile
          filename="multas"
          element={
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Exportar a excel</Tooltip>}>
              <Button bsStyle="success" disabled={!this.props.data.length}><Icon fitted name="download" /></Button>
            </OverlayTrigger>
          }>
            <ExcelSheet data={this.props.data} name="Multas">
              <ExcelColumn label="Unidad" value="unit"/>
              <ExcelColumn label="Fecha creación"
                           value={(col) => this.dateFormatter(col.created_date)}/>
              <ExcelColumn label="Fecha evento"
                           value={(col) => this.dateFormatter(col.event_date)}/>
              <ExcelColumn label="Motivo" value="title"/>
              <ExcelColumn label="Descripción" value="description"/>
              <ExcelColumn label="Estado"
                           value={(col) => this.statusFormatter(col.status)}/>
              <ExcelColumn label="Folio pago" value="payment_info"/>
              <ExcelColumn label="Usuario"
                           value={(col) => this.userFormatter(col.user_who_creates)}/>
              <ExcelColumn label="Motivo eliminación" value="skip_reason"/>
            </ExcelSheet>
        </ExcelFile>
      );
  }
}
