import React, { Component } from 'react'
import { Grid, Icon, Label } from 'semantic-ui-react'

export default class BlacklistAlert extends Component {

  nameFormatter = () => {
    if (this.props.blacklist && this.props.blacklist.length) {
      return this.props.blacklist[0].name + " " + this.props.blacklist[0].lastname;
    }
    return "";
  }

  unitFormatter = () => {
    if (this.props.blacklist && this.props.blacklist.length) {
      const units = this.props.blacklist.map((ban, index) =>
        <Label key={index} color="grey"><Icon name='building' />{ban.unit}</Label>
      );

      return units;
    }
    return "";
  }

  render() {
    const colorAlert = this.props.color ? this.props.color:"orange";
    const danger = this.props.danger;

    return (
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={3}>
            <Icon.Group size='huge'>
            <Icon size='big' color={colorAlert} name='dont' />
            <Icon color='black' name='user' />
            </Icon.Group>
          </Grid.Column>
          <Grid.Column width={13}>
            { this.props.blacklist &&
              <Label color={colorAlert}>{this.nameFormatter()}</Label>
            }
            <p></p>
            <p>Visitante no está autorizado para acceder a la(s) unidad(es): </p>{this.unitFormatter()}
            <p></p>
            { danger ?
              <div>
              <p>Comuníquese con algún residente de la unidad y de aviso</p>
              <p className="psmall"><b>Nota:</b> Sólo puede acceder si residente(s) solicita(n) expresamente sacar al visitante de lista negra.</p>
              </div>
              :
              <div>
              <p>Aún asi, el visitante no tiene restricción en esta unidad y puede ser registrado.</p>
              <p className="psmall"><b>Nota:</b> Comuníquese con algún residente de la(s) unidad(es) no autorizadas a dar acceso y de aviso preventivo.</p>
              </div>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
