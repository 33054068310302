import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Settings.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon } from "react-bootstrap";
import FormSetup from "./FormSetup";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      steps: [
        {
          target: '.btn-primary',
          content: 'Para agregar una nueva configuración presione aquí',
          placement: 'bottom',
        },
        {
          target: '.Setup .setups',
          content: 'En este listado se muestran las configuraciones',
          placement: 'bottom',
        },
        {
          target: '.Setup .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  render() {
    return (
      <div className="Setup">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Configuración general</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      Configuración general
	    </PageHeader>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <FormSetup {...this.props} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} />
      </div>
    );
  }

}
