import ReactDOM from 'react-dom';
import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Row, Alert } from "react-bootstrap";
import Select from 'react-select';
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Radio } from 'semantic-ui-react'
import { invokeApig } from "../../libs/awsLib";
import { validateNumber, validateText, validateLicense,
  validatePassport, formatRut,
  validateRut, cleanRut, cleanRutFromReader } from '../../libs/validation';
import {DelayInput} from 'react-delay-input';
import BlacklistAlert from '../blacklist/BlacklistAlert'
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import './NewVisitor.css';
import config from "../../config";

export class NewVisitorCountry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      has_car: false,
      delivery_flag: false,
      name: '',
      lastname: '',
      card_id: '',
      card_id_type: 'RUT',
      visitors_number: 1,
      car_license_plate: '',
      car_park: '',
      isLoading: false,
      show: false,
      showError: false,
      errorMessage: '',
      isSearching: false,
      isBlacklisted: false,
      isBlacklistedDangerLevel: false,
      blacklist:[],
      previuos_card_id: '',
      name_placeholder: '',
      lastname_placeholder: '',
      person_found: 1,
      visitor_picture: '',
      selectedResident: null,
      resident_radio_enabled: true
    };
  }

  isRut(){return this.state.card_id_type === 'RUT';}

  findVisitors = async () => {
    this.setState({ visitors: [] });
    try {
      const results = await this.visitors();
      this.setState({ visitors: results.visitors });

    } catch (e) {
      this.setState({isError: true});
    }
  }

  handleChangeRadio = (e, { value }) => this.setState({ card_id_type: value });

  handleChangeResidentRadio = (e, { value }) => this.setState({ resident_radio_enabled: value });

  handleParkingSlotChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ car_park: option });
  };

  handleChange = event => {
    if (event.target.id === "has_car") {
      if (event.target.checked) {
        this.findVisitors(); //to show parking slots
      }
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    if (event.target.id === "delivery_flag") { //TODO ugly logic
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    if (event.target.id === "card_id") {
      var card_id_value = cleanRutFromReader(event.target.value)
      this.setState({
          [event.target.id]: this.isRut() ? formatRut(card_id_value): card_id_value,
      });
      return;
    }

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onKeyPress = event => {
    if (event.which === 13) {
      event.preventDefault();

      this.searchPerson();

      let nameInput = ReactDOM.findDOMNode(this.refs.nameInput);
      if (nameInput && nameInput.focus instanceof Function) {
          nameInput.focus();
      }
    }
  }

  onBlur = event => {
    this.searchPerson();
  }

  createVisitor(visitor) {
    visitor.unitId = this.props.selectedUnit["unit"];
    if (this.props.selectedUnit.hasOwnProperty('unit_name')) {
      visitor.unit_name = this.props.selectedUnit["unit_name"];
    }
    return invokeApig({
      path: "/visitor",
      method: "POST",
      body: visitor
    });
  }

  searchPerson = async event => {
    const card_id = this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id;
    const previuos_card_id = this.state.previuos_card_id;

    if (card_id.length < 7) return;
    if (card_id === previuos_card_id) return;

    this.setState({ isSearching: true, person_found: 1,
      lastname_placeholder: 'Buscando...', name_placeholder: 'Buscando...',
      lastname: '', name: '' });
    const response = await this.getPerson(card_id);

    this.setState({person_found: response.code})
    if (response.code === 0) {
      this.setState({
        name: response.person.name,
        lastname: response.person.lastname
      })

      const blacklist = response.blacklist;
      if (blacklist && blacklist.length > 0) {
        const person = blacklist.filter(person => (person.unit === this.props.selectedUnit["unit"]));
        if (person.length > 0) {
          this.handleClose();
          this.setState({
            isBlacklistedDangerLevel: true
          });
        }

        this.setState({
          isBlacklisted: true, blacklist: blacklist
        });

      }
    }

    this.setState({ isSearching: false, previuos_card_id: card_id, name_placeholder: '', lastname_placeholder: '' });
  }

  visitors() {
    return invokeApig({
      path: "/visitor/find",
      method: "POST",
      body: {
          "status_summary": "RC"
      }
    });
  }

  getPerson(card_id) {
      return invokeApig({
          path: "/person/" + card_id,
          method: "GET"
      });
  }

  shortResident (resident) {
    return {
      "name": resident["name"],
      "lastname": resident["lastname"],
      "card_id": resident["card_id"],
      "email": resident["email"],
      "card_id_type": resident["card_id_type"]
    }
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '',
      has_car: false, delivery_flag: false, name: '', lastname: '',
      card_id: '', card_id_type: 'RUT',
      visitors_number: 1, car_license_plate: '',
      car_park: '', name_placeholder: '',
      lastname_placeholder: '', person_found: 1, selectedResident: null,
      isSearching: false, previuos_card_id: '', visitor_picture: '',
      resident_radio_enabled: true });
  }

  handleBClose = () => {
    this.setState({ isBlacklisted: false, isBlacklistedDangerLevel: false, blacklist: [], isSearching: false, previuos_card_id: '' });
  }

  handleShow = () => {
    if(!this.props.selectedUnit){
      this.props.onAlertError({ message: "Debe seleccionar unidad" }, true);
      return;
    }

    this.setState({ show: true });
  }

  handleResidentChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ selectedResident: option, showError: false });
    this.setState({ name: option.name,
                    lastname: option.lastname,
                    card_id_type: option.card_id_type,
                    card_id: option.card_id
                  });
  };

  getErrors() {
    let message = undefined;
    let selectedResident = this.state.selectedResident
    if (this.state.resident_radio_enabled && !selectedResident) {
      message = 'Debe seleccionar residente';
      return message;
    }

    if (this.isRut() && !validateRut(this.state.card_id)) {
      message = 'Debe ingresar un RUT válido';
      return message;
    } else if (!this.isRut() && !validatePassport(this.state.card_id)) {
      message = 'Debe ingresar un id válido';
      return message;
    }

    if (!validateText(this.state.name)) {
      message = 'Debe ingresar nombre';
      return message;
    }

    if (!validateText(this.state.lastname)) {
      message = 'Debe ingresar apellido';
      return message;
    }

    if (!validateNumber(this.state.visitors_number)) {
      message = 'Debe ingresar número de visitas';
      return message;
    }

    if (this.state.car_license_plate) {
      if (!validateLicense(this.state.car_license_plate)) {
        message = 'Debe ingresar patente válida, ej: AABB77';
        return message;
      } else {
        this.setState({ has_car: true });
      }
    }
    this.setState({ showError: false });
    return message;
  }

  resetForm = () => {
    this.setState({ card_id:'', name:'', lastname: '', visitors_number: 1,
      has_car: false, delivery_flag: false, car_license_plate: '', car_park: '',
      card_id_type: 'RUT', showError: false, errorMessage: '',
      name_placeholder: '', lastname_placeholder: '', person_found: 1,
      isSearching: false, previuos_card_id: '', visitor_picture: '',
      resident_radio_enabled: true
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const visitor = {
        card_id: this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id,
        card_id_type: this.state.card_id_type,
        name: this.state.name,
        lastname: this.state.lastname,
        visitors_number: this.state.visitors_number,
        has_car: this.state.has_car,
        country_visitor: true
      };

      if (this.state.car_license_plate) {
        visitor.car_license_plate = this.state.car_license_plate;
        visitor.has_car = true;
        visitor.car_park = this.state.car_park;
      }

      if (this.state.delivery_flag) {
        visitor.visit_type = "DELIVERY";
      }

      if (this.state.visitor_picture) {
        visitor["visitor_picture_64"] = this.state.visitor_picture;
      }

      const response = await this.createVisitor(visitor);
      if (response.code === 0) {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Visita ingresada exitosamente!", refresh: true }, visitor);
      } else if (response.code === 401) {
        this.setState({showError: true, errorMessage: 'Visita no autorizada a ingresar a la unidad.'});
        this.setState({ isLoading: false });
        return;
      } else if (response.code === 405) {
        this.setState({showError: true, errorMessage: 'Estacionamiento en uso, favor seleccione otro.'});
        this.setState({ isLoading: false });
        return;
      } else {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear visita! " + msgExtra });
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.props.onAlertError({ message: "Error al registrar visita! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  capture = () => {
     const visitor_picture = this.webcam.getScreenshot();
     this.setState({ visitor_picture });
  }

  render() {

    const colorAlert = this.state.isBlacklistedDangerLevel ? "red":"orange";

    const residents = this.props.residents ?
      this.props.residents.map(v => ({
        label: v.name + " " + v.lastname + " ("+ v.card_id +")",
        value: v
      })) : [];

    if (this.props.condo
      && this.props.condo.visitor
      && this.props.condo.visitor.all_residents_option_enabled
      && residents.length > 0) {
      residents.push({label: "Todos", value: "ALL" });
    }

    return (
      <div className="visitorBtn">

      <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar visita_</Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos de la visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew(residents)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.isBlacklisted} onHide={this.handleBClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Label color={colorAlert}><Icon name='dont' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
            <Label color={colorAlert}>ATENCIÓN</Label> Visita en lista negra {!this.state.isBlacklistedDangerLevel && "en otra(s) unidad(es)"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlacklistAlert blacklist={this.state.blacklist} color={colorAlert} danger={this.state.isBlacklistedDangerLevel}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleBClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {this.props.condo && this.props.condo.picture.visitor &&
      <Modal className="visitor_cam_modal" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Webcam
          className="pet_cam"
          height={config.picture.HEIGHT}
          width={config.picture.WIDTH}
          ref={node => this.webcam = node}
          screenshotFormat={config.picture.FORMAT}
          screenshotQuality={config.picture.QUALITY_HIGH}
          videoConstraints={config.picture.CONSTRAINS}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      }
      </div>
    );
  }

  renderResidents(residents){
    return(
      <Col sm={7} className="resident_select_list">
      { residents.length ?
        <Select
          onChange={this.handleResidentChange}
          options={residents}
          placeholder="Seleccione" />
        :
        <div className="no_residents">Unidad no tiene residentes.
        <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
        </div>
      }
      </Col>
    );
  }

  renderFormNew(residents){
    return(
    <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="resident_id">
          <Col componentClass={ControlLabel} sm={4}>
          <Radio
            label='Residente'
            name='radioResidentGroup'
            value={true}
            checked={this.state.resident_radio_enabled}
            onChange={this.handleChangeResidentRadio}
          />
          <Radio
            label='Otro'
            name='radioResidentGroup'
            value={false}
            checked={!this.state.resident_radio_enabled}
            onChange={this.handleChangeResidentRadio}
          />
          </Col>
          <Col sm={7} className="resident_select_list">
          { this.state.resident_radio_enabled &&
          <div>
          { residents.length ?
            <Select
              onChange={this.handleResidentChange}
              options={residents}
              placeholder="Seleccione" />
            :
            <div className="no_residents">Unidad no tiene residentes.
            <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
            </div>
          }
          </div>
          }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT. Compatible con lector de códigos <Glyphicon glyph="qrcode" /></Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        { !this.state.resident_radio_enabled &&
        <div>
        <FormGroup controlId="card_id">
          <Col componentClass={ControlLabel} sm={4}>
          <Radio
            label='RUT'
            name='radioGroup'
            value='RUT'
            checked={this.state.card_id_type === 'RUT'}
            onChange={this.handleChangeRadio}
          />
          <Radio
            label='Otro'
            name='radioGroup'
            value='OTHER'
            checked={this.state.card_id_type === 'OTHER'}
            onChange={this.handleChangeRadio}
          />
          </Col>
          <Col sm={6}>
              <DelayInput
                leadingNotify={true}
                trailingNotify={true}
                minLength={0}
                autoFocus={true}
                delayTimeout={100}
                placeholder="Rut u otro"
                onKeyPress={this.onKeyPress}
                onBlur={this.onBlur}
                id="card_id"
                className="form-control"
                value={this.state.card_id}
                onChange={this.handleChange} />
          </Col>
          <Col sm={1}>
            { this.state.isSearching &&
              <Icon loading color='blue' name='spinner' size='large'/>
            }
            { this.state.person_found === 0 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona encontrada</Tooltip>}>
                <Icon color='green' name='user' size='large' />
              </OverlayTrigger>
            }
            { this.state.person_found === 404 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona no encontrada. Debe registrar datos</Tooltip>}>
                <Icon color='red' name='user times' size='large'/>
              </OverlayTrigger>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT. Compatible con lector de códigos <Glyphicon glyph="qrcode" /></Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={4}>
            Nombre (*)
          </Col>
          <Col sm={7}>
            <FormControl
              ref="nameInput"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder={this.state.name_placeholder}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastname">
          <Col componentClass={ControlLabel} sm={4}>
            Apellido (*)
          </Col>
          <Col sm={7}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.lastname}
              placeholder={this.state.lastname_placeholder}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        </div>
        }
        <FormGroup controlId="visitors_number">
          <Col componentClass={ControlLabel} sm={4}>
            N° Visitas (*)
          </Col>
          <Col sm={2}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.visitors_number}
              type='number'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de visitas.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        { this.props.condo
          && this.props.condo.visitor
          && this.props.condo.visitor.resident_validation
          && residents.length > 0 &&
        <FormGroup controlId="resident">
          <Col componentClass={ControlLabel} sm={4}>
            Residente (*)
          </Col>
          <Col sm={7}>
              <Select
                onChange={this.handleResidentChange}
                options={residents}
                placeholder="Seleccione residente que recibe" />

          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que recibe la visita. Si no recibe alguien en particular, seleccionar la opción "Todos"</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        }
        <FormGroup controlId="car_license_plate">
          <Col componentClass={ControlLabel} sm={4}>
            Patente
          </Col>
          <Col sm={7}>
            <FormControl
              maxLength={6}
              onChange={this.handleChange}
              value={this.state.car_license_plate}
              placeholder="ej: AABB77"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Patente de vehículo.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {this.props.condo && this.props.condo.picture.visitor &&
        <FormGroup controlId="visitor_picture">
          <Col componentClass={ControlLabel} sm={4}>
            Foto visita
          </Col>
          <Col sm={4}>
            <Webcam
              audio={false}
              height={config.picture.HEIGHT_AVATAR}
              width={config.picture.WIDTH_AVATAR}
              screenshotFormat={config.picture.FORMAT}
              videoConstraints={config.picture.CONSTRAINS_AVATAR}
            />
          </Col>
          <Col sm={3}>
            <Row>
              <Col md={12}>
                <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
              </Col>
            </Row>
            {this.state.visitor_picture &&
            <React.Fragment>
              <Row>
              <Col md={12}>
                <p className="visitor_picture_previous_text">Previa:</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <img className="visitor_picture_previous" alt="Previa" src={this.state.visitor_picture} />
              </Col>
            </Row>
            </React.Fragment>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de visita.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        }
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewVisitorCountry
