import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Col, Button, Glyphicon,
    Breadcrumb, PageHeader, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import moment from 'moment';

export default class VisitorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      steps: [
    	  {
          target: '.form-horizontal',
          content: 'En esta sección se muestran los datos actuales del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal button:nth-of-type(1)',
          content: 'Puedes resetear la clave del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal .userBtn button',
          content: 'Puedes modificar los datos del usuario',
          placement: 'top'
        },
        {
          target: '.form-horizontal button:nth-of-type(2)',
          content: 'Puedes eliminar el usuario',
          placement: 'top'
        }
      ]
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  nameFormatter = (name, lastname) => {
    return name + " " + lastname;
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  statusFormatter = (status) => {
    return this.props.visitorStatus[status];
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  licenseFormatter= (car_license_plate, car_park) => {
    return <span>
      <Label>{car_license_plate}</Label>
      <Label>{car_park}</Label></span>;
  }

  elapsedFormatter = (cell, color) => {
    if (cell) {
      let hours = parseInt(cell / 60, 10).toString();
      let minutes = parseInt(cell % 60, 10).toString();
      let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
      return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
    }
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render(){
    const { steps, runJoy } = this.state;
    const { card_id, name, lastname, created_date,
      user_who_receives, departure_date,
      user_who_departures, user_who_penalty,
      car_license_plate, car_park, exceeded_time,
      elapsed_time, exceeded_status, penalty_date,
      penalty_payment_info, penalty_skip_reason,
      company, provider_type} = this.state ;
    return(
      <div className="Visitor">
        <Breadcrumb>
  	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item componentClass={Link} href="/visitor" to="/visitor">Visitas</Breadcrumb.Item>
  	      <Breadcrumb.Item active>Detalle</Breadcrumb.Item>
          <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
        </Breadcrumb>
      	<PageHeader>
          Visita <small>Detalle</small>
        </PageHeader>
        <Form horizontal>
          { provider_type &&
          <FormGroup controlId="provider_type">
            <Col componentClass={ControlLabel} sm={6}>
              Tipo de proveedor{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{provider_type}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { company &&
          <FormGroup controlId="provider_type">
            <Col componentClass={ControlLabel} sm={6}>
              Empresa{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{company}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          <FormGroup controlId="card_id">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        RUT (u otro){' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{card_id}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="name">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Visita{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.nameFormatter(name, lastname)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="created_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Ingreso{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date) + ' ('+ this.userFormatter(user_who_receives) + ')'}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          { departure_date &&
          <FormGroup controlId="departure_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Salida{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(departure_date) + ' ('+ this.userFormatter(user_who_departures) + ')'}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { car_license_plate && car_license_plate !== '-' &&
          <FormGroup controlId="car_license_plate">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Vehículo{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.licenseFormatter(car_license_plate, car_park)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { elapsed_time &&
          <FormGroup controlId="elapsed_time">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Tiempo total{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.elapsedFormatter(elapsed_time, 'blue')}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { exceeded_time && exceeded_time > 0 &&
          <FormGroup controlId="elapsed_time">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Tiempo excedido{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.elapsedFormatter(exceeded_time, 'red')}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { user_who_penalty &&
          <FormGroup controlId="created_date">
            <Col componentClass={ControlLabel} sm={6}>
              Fecha y responsable de multa{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(penalty_date) + ' ('+ this.userFormatter(user_who_penalty) + ')'}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          { exceeded_status && exceeded_status === 'EXCEEDED_WITH_PENALTY' &&
          <FormGroup controlId="exceeded_status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Folio pago{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{penalty_payment_info}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          { exceeded_status && exceeded_status === 'EXCEEDED_WITH_NO_PENALTY' &&
          <FormGroup controlId="exceeded_status">
            <Col componentClass={ControlLabel} sm={6}>
              Razón de omisión{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>{penalty_skip_reason}</FormControl.Static>
            </Col>
          </FormGroup>
          }
          <FormGroup>
              <center className="detail-buttons">
                <Button bsStyle="primary" className="btnBackDeliveryLsit" onClick={this.handleBack}>
                  <Glyphicon glyph="chevron-left" /> Volver
                </Button>
              </center>
          </FormGroup>
  	    </Form>
        <Joyride
          steps={steps}
          run={runJoy}
          continuous={true}
          callback={this.handleJoyrideCallback}
          scrollToFirstStep={true}
          locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }} />
      </div>
    );
  }
}
