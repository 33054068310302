import React, { Component } from 'react'
import { Image } from "react-bootstrap";
import { Icon, Menu, Label, Dropdown } from 'semantic-ui-react'
import { invokeApig, getUserAttributes } from '../../libs/awsLib';
import { sortByKeyDesc } from '../../libs/arrays';
import Select from 'react-select';
import './SideMenu.css';
import { checkAccess } from '../../libs/commons'

export default class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isError: false,
      isClearable: true,
      isRtl: false,
      isSearchable: true,
      activeItem: '',
      selectedOption: '',
      selectedOptionName: '',
      profile: '',
      preferred_username: null,
      units: []
    }
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ profile: user.profile, preferred_username: user.preferred_username });

    this.updateUnits();
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (this.props.onGoMenu) {
      this.props.onGoMenu(name);
    }
  }

  handleLogoutClick = () => {
    if (this.props.onLogout) {
      this.props.onChangeUnit(null);
      this.props.onLogout();
    }
  }

  changeRoutePath = (e, link) => {
      e.preventDefault();
      this.props.onGoMenu(link);
  }

  handleChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : '';
    this.setState({ selectedOption: option });


    if (option === '') {
      this.props.onChangeUnit(null);
      this.setState({ selectedOptionName: '' });
      return
    }
    const optionFull = this.state.units.filter((unit,index,arr)=>{
      return unit["unit"] === option
    })
    this.props.onChangeUnit(optionFull[0]);

    this.setState({ selectedOptionName: typeof(optionFull[0].unit_name) !== 'undefined' ? optionFull[0].unit_name: optionFull[0].unit});

  };

  updateUnits = async () => {
    this.setState({ isLoading: true, units: [] });
    try {
      const results = await this.getCondo();
      const units = sortByKeyDesc(results.units, "number")
      this.props.onSetCondo(results.condo);
      this.setState({ units: units });
    } catch (e) {
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  units() {
      return invokeApig({
          path: "/unit/find",
          method: "POST",
          body: {}
      });
  }

  getCondo() {
      return invokeApig({
          path: "/condo",
          method: "GET"
      });
  }

  render() {
    const {
       isClearable,
       isSearchable,
       isLoading,
       isRtl,
       activeItem,
       selectedOption,
       selectedOptionName
    } = this.state;

    const options = this.state.units.map(v => ({
        label: v.hasOwnProperty('unit_name') ? v.unit_name : v.unit,
        value: v.unit
      }));

    const suspended = this.props.condo && this.props.condo.payment && this.props.condo.payment.suspended;

    return (
      <Menu vertical size='large' stackable>
        <Menu.Item className='concierge'>
          { this.state.preferred_username &&
            <Image
              className="logo_image_v"
              rounded
              responsive
              onClick={(e) => this.changeRoutePath(e, '/')}
              src={"/condo/" + this.state.preferred_username + ".png"}/>
          }
          <Label
            className="unit_label"
            color="blue"
            size="big"
            onClick={(e) => this.changeRoutePath(e, '/')}>
             <div className="unit_label_selected"><Icon name='building' /> {selectedOptionName}</div>
          </Label>
        </Menu.Item>
        { this.state.profile !== 'Comite' &&
        <React.Fragment>
        <Menu.Item className='concierge unit-select-menu'>
          Seleccione unidad:
          <Select
            className="basic-single"
            classNamePrefix="select"
            isDisabled={suspended}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name="unit"
            placeholder="Unidad"
            onChange={this.handleChange}
            options={options}
          />
        </Menu.Item>
        <Menu.Item className="concierge concierge-menu">
          Conserjería
          <Menu.Menu>
            <Menu.Item name='/delivery' disabled={selectedOption === ''} active={activeItem === '/delivery'} onClick={this.handleItemClick}>
              <Icon name='dolly' />Encomiendas
            </Menu.Item>
            <Menu.Item name='/visitor' disabled={selectedOption === ''} active={activeItem === '/visitor'} onClick={this.handleItemClick}>
              <Icon name='users' />Visitas
            </Menu.Item>
            {checkAccess('EXPERT') &&
            <Menu.Item name='/visitor/blocked' disabled={selectedOption === ''} active={activeItem === '/visitor/blocked'} onClick={this.handleItemClick}>
              <Icon name='user times' />Visitas en lista negra
            </Menu.Item>}
            <Menu.Item name='/resident' disabled={selectedOption === ''} active={activeItem === '/resident'} onClick={this.handleItemClick}>
              <Icon name='user' />Residentes
            </Menu.Item>
            {checkAccess('ADVANCED', 'EXPERT') &&
            <Menu.Item name='/pet' disabled={selectedOption === ''} active={activeItem === '/pet'} onClick={this.handleItemClick}>
              <Icon name='paw' />Mascotas
            </Menu.Item>}
            { this.props.condo && this.props.condo.access_control &&
            <Menu.Item name='/access/control' disabled={selectedOption === ''} active={activeItem === '/access/control'} onClick={this.handleItemClick}>
              <Icon name='key' />Accesos controlados
            </Menu.Item>
            }
          </Menu.Menu>
        </Menu.Item>
        </React.Fragment>
        }
        <Menu.Item>
          Resumen
          <Menu.Menu className="summary-menu">
            <Menu.Item name='/delivery/summary' active={activeItem === '/delivery/summary'} onClick={this.handleItemClick} disabled={suspended}>
              <Icon name='dolly' />Encomiendas recibidas
            </Menu.Item>
            <Menu.Item name='/visitor/summary' active={activeItem === '/visitor/summary'} onClick={this.handleItemClick} disabled={suspended}>
              <Icon name='car' />Visitas en vehículo
            </Menu.Item>
            <Menu.Item name='/visitor/daily' active={activeItem === '/visitor/daily'} onClick={this.handleItemClick} disabled={suspended}>
              <Icon name='users' />Visitas del día
            </Menu.Item>
            {checkAccess('ADVANCED', 'EXPERT') &&
            <Menu.Item name='/pet/summary' active={activeItem === '/pet/summary'} onClick={this.handleItemClick} disabled={suspended}>
              <Icon name='paw' />Mascotas del condominio
            </Menu.Item>
            }
            <Menu.Item name='/resident/summary' active={activeItem === '/resident/summary'} onClick={this.handleItemClick} disabled={suspended}>
              <Icon name='user' />Residentes del condominio
            </Menu.Item>
            { this.props.condo && this.props.condo.access_control &&
              <Menu.Item name='/access/control/summary' active={activeItem === '/access/control/summary'} onClick={this.handleItemClick} disabled={suspended}>
                <Icon name='key' />Accessos del condominio
              </Menu.Item>
            }
          </Menu.Menu>
        </Menu.Item>
        {checkAccess('ADVANCED', 'EXPERT') &&
        <Dropdown item text='Reservas' className="booking-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='calendar alternate outline' text="Reservar espacio común" name='/booking' active={activeItem === '/booking'} onClick={this.handleItemClick}/>
            { this.state.profile !== 'Operador' &&
            <Dropdown.Item icon='table' text="Listado de reservas" name='/booking/summary' active={activeItem === '/booking/summary'} onClick={this.handleItemClick}/>
            }
          </Dropdown.Menu>
        </Dropdown>
        }
        <Dropdown item text='Libros' className="book-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='book' text="Novedades" name='/book/diarybook' active={activeItem === '/book/diarybook'} onClick={this.handleItemClick}/>
            { this.state.profile !== 'Operador' &&
            <Dropdown.Item icon='book' text="Comunicaciones" name='/book/notice' active={activeItem === '/book/notice'} onClick={this.handleItemClick}/>
            }
          </Dropdown.Menu>
        </Dropdown>

        {(checkAccess('ADVANCED', 'EXPERT')) &&
        <Dropdown item text='Proveedores' className="provider-menu" disabled={suspended}>
          <Dropdown.Menu>
            <Dropdown.Item icon='wrench' text="Visitas" name='/visitor/provider' active={activeItem === '/visitor/provider'} onClick={this.handleItemClick}/>
          </Dropdown.Menu>
        </Dropdown>}
        {(this.state.profile !== 'Operador') &&
          <React.Fragment>
            {checkAccess('ADVANCED', 'EXPERT') &&
              <Dropdown item text='Multas' disabled={suspended}>
                <Dropdown.Menu>
                  <Dropdown.Item icon='car' text="Visitas con multa pendiente" name='/visitor/exceeded' active={activeItem === '/visitor/exceeded'} onClick={this.handleItemClick}/>
                  <Dropdown.Item icon='clipboard outline' text="Visitas multadas" name='/visitor/infracted' active={activeItem === '/visitor/infracted'} onClick={this.handleItemClick}/>
                </Dropdown.Menu>
              </Dropdown>
            }
            <Dropdown item text='Administración' disabled={suspended}>
              <Dropdown.Menu>
                {checkAccess('EXPERT') &&
                  <Dropdown.Item icon='download' text="Reportes" name='/report' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
                }
                <Dropdown.Item icon='building' text="Unidades" name='/unit' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='user' text="Usuarios" name='/user' active={activeItem === '/user'} onClick={this.handleItemClick}/>
                <Dropdown.Item icon='settings' text="Configuración general" name='/settings' active={activeItem === '/settings'} onClick={this.handleItemClick}/>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        }
        { this.state.profile === 'Operador' &&
          <React.Fragment>
            <Dropdown item text='Otros' disabled={suspended}>
              <Dropdown.Menu>
                <Dropdown.Item icon='building' text="Unidades" name='/unit' active={activeItem === '/unit'} onClick={this.handleItemClick}/>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        }
        <Menu.Item className='sign-out' onClick={this.handleLogoutClick}>
          <Icon name='sign-out' />
          Salir
        </Menu.Item>
      </Menu>

    )
  }
}
