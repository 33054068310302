import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import './ListPenaltySummary.css';
import {sortByKey} from '../../libs/arrays';
import PenaltyDetailModal from "./PenaltyDetailModal";
import Autosuggest from '../../libs/autosuggest/Autosuggest';
import { validateFreeText } from '../../libs/validation';

export default class ListPenaltySummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: '',
            disableDeletePenalty: true,
            has_car: false,
            editCandidate: null,
            detailCandidate: null,
            deleteCandidate: null,
            okCandidate: null,
            showDelete: false,
            showDetails: false,
            showOK: false,
            isOK: false,
            isDeleting: false,
            isLoading: true,
            isError: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            penalties: [],
            skip_reason: '',
            suggestions: ['Multa no aplica', 'Error al generar multa', 'Advertencia']
        };
    }

    componentDidMount() {
      this.findPenalties();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.findPenalties();
        return;
      }

      if (!prevProps.isLoading && this.props.isLoading) {
        this.findPenalties();
        if (this.props.onEndUpdate) {
          this.props.onEndUpdate();
        }
      }
    }

    findPenalties = async () => {
      this.setState({ isLoading: true, penalties: [] });
      try {
        const results = await this.penalties();
        const penalties = sortByKey(results.penalties, "created_date")
        this.setState({ penalties });
        this.props.onHandleSize(penalties);
      } catch (e) {
        this.setState({isError: true});
      }
      this.setState({ isLoading: false });
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deletePenalty();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar multa!" });
        } else {
          this.handleAlertSuccess({ message: "Multa eliminada correctamente!" });
          this.findPenalties();
        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar multa!" });
      }
    }

    handleOk = async event => {
      event.preventDefault();
      this.setState({ isOK: true });
      try {

        const penalty = {
          operation: "EDIT_OK",
          created_date: this.state.okCandidate.created_date,
          unitId: this.state.okCandidate.unit
        };

        const response = await this.updatePenalty(penalty);
        this.setState({ isOK: false, showOK: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al marcar multa como procesada!" });
        } else {
          this.handleAlertSuccess({ message: "Multa procesada correctamente!" });
          this.findPenalties();
        }
      } catch (e) {
        this.setState({ isOK: false, showOK: false, showAlertError: true, alertMessage: "Error al marcar multa como procesada!" });
      }
    }

    resetForm = () => {
      this.setState({ disableDeletePenalty: true, skip_reason: '', status: '',
        showError: false, errorMessage: ''
      });
    }

    updatePenalty(penalty) {
      return invokeApig({
        path: `/penalty`,
        method: "PUT",
        body: penalty
      });
    }

    penalties() {
      const selectedDateMoment = moment(this.props.selectedDate);
      return invokeApig({
        path: "/penalty/find",
        method: "POST",
        body: {
            "monthly": "P",
            "year_month_search": selectedDateMoment.local().format("YYYY-MM")
        }
      });
    }

    deletePenalty() {
      return invokeApig({
        path: "/penalty",
        method: "DELETE",
        body: {
          "unitId": this.state.deleteCandidate.unit,
          "created_date": this.state.deleteCandidate.created_date,
          "skip_reason": this.state.skip_reason
        }
      });
    }

    setTableOption() {
      if (this.state.isLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener multas. Intenta más tarde." : "No hay datos";
      }
    }

    dateFormatter = (cell, row) => {
      if (cell) {
        const date = moment(cell);
        return date.format("DD-MM-YYYY HH:mm");
      }
    }

    statusFormatter = (cell, row) => {
      if (row['penalty_type'] === 'WARNING') {
        return;
      }

      var color = ''
      switch(cell) {
        case 'PENDING':
          color = 'red';
          break;
        case 'DELETED':
          color = 'grey';
          break;
        default:
          color = 'green';
      }

      let status = this.props.penaltyStatus[cell];
      return <Label color={color} className={color}>{status}</Label>;
    }

    typeFormatter = (cell, row) => {
      var color = ''
      var cellStr = cell ? cell : 'PENALTY'
      switch(cellStr) {
        case 'PENALTY':
          color = 'red';
          break;
        case 'WARNING':
          color = 'orange';
          break;
        default:
          color = 'red';
      }

      let type = this.props.penaltyType[cellStr];
      return <Label color={color} className={color}>{type}</Label>;
    }

    actionFormatter = (cell, row) => {
      return <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{cell}</Link>;
    }


    userFormatter = (cell) => {
      return cell
      && typeof(cell.name) !== 'undefined'
      && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
    }

    rowClassNameFormat = (row, rowIdx) => {
      if (row['penalty_type'] === 'WARNING') {
        return 'warning';
      }
      return row['status'] === 'PENDING' ? 'penalty' : 'nopenalty';
    }

    drawButton = (cell, row, rowIdx) => {
      return row['status'] === 'PENDING' ?
          <ButtonGroup className="actionsBtn">
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Marcar OK</Tooltip>}>
              <Button bsStyle="success" onClick={this.showDoOk.bind(cell, row, rowIdx)} className="btnDoDelivery">
                <Glyphicon glyph="ok" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, rowIdx)} className="btnDeleteDelivery">
                <Glyphicon glyph="remove" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
         :
         <ButtonGroup>
           <Link to={{}} onClick={this.loadDetails.bind(cell, row)}>{cell}
             <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver detalle</Tooltip>}>
               <Button className="btnShowDelivery">
                 <Glyphicon glyph="search" />
               </Button>
             </OverlayTrigger>
           </Link>
         </ButtonGroup>;
    }

    showConfirmDelete = (cell, row, rowIdx) => {
      this.setState({ showDelete: true, deleteCandidate: cell });
    }

    showDoOk = (cell, row, rowIdx) => {
      this.setState({ showOK: true, okCandidate: cell });
    }

    loadDetails = (cell, row, rowIdx) => {
      this.setState({ showDetails: true, detailCandidate: cell, company: cell.company });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({ showAlertSuccess: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false, showError: false });
    }

    handleClose = () => {
      this.setState({
        disableDeletePenalty: true,
        skip_reason: '',
        showDetails: false, detailCandidate: null,
        showDelete: false, deleteCandidate: null,
        showOK: false, okCandidate: null });

    }

    handleChangeSkipReason= (event) => {
      this.setState({
          skip_reason: event
      },() => {
        if (this.state.showDelete) {
          var disableDeletePenalty = !validateFreeText(this.state.skip_reason, 2, 64);
          this.setState({ disableDeletePenalty });
        }
      });
    };

    render(){
        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };
        const data = this.state.penalties;
        return(
          <div className="Penalty">
            {
              this.state.showAlertSuccess &&
              <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            {
              this.state.showAlertError &&
              <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            }
            <Col sm={12} className="penalties">
              <BootstrapTable
                pagination
                data={ data }
                selectRow={ selectRowProp }
                options={ tableOptions }
                trClassName={this.rowClassNameFormat}>
                <TableHeaderColumn
                  dataField='unit'
                  dataFormat={ this.actionFormatter }
                  filter={ { type: 'TextFilter', placeholder: 'Ingresa unidad', delay: 10 } }
                  dataSort={ true } ><Glyphicon glyph="home" /> Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='created_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Fecha creación
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={ true }
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese fecha', delay: 10 } }
                  dataField='event_date'
                  dataFormat={ this.dateFormatter }>
                  <Icon name="calendar alternate outline" />Fecha evento
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='penalty_type'
                  dataSort={ true }
                  dataFormat={ this.typeFormatter }>Tipo
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='title'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese motivo', delay: 10 } }
                  tdStyle={{ whiteSpace: 'normal' }}
                  dataSort={ true }
                  dataFormat={ this.actionFormatter }>Motivo
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='status'
                  dataFormat={ this.statusFormatter }>Estado
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='payment_info'
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese folio', delay: 10 } }
                  dataSort={ true }>Folio pago
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='user_who_creates'
                  filterFormatted
                  filter={ { type: 'TextFilter', placeholder: 'Ingrese usuario', delay: 10 } }
                  dataFormat={ this.userFormatter }
                  dataSort={ true }>Usuario
                </TableHeaderColumn>
                <TableHeaderColumn
                  hidden={this.props.profile === 'Comite'}
                  headerAlign='center'
                  dataAlign='center'
                  dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
              </BootstrapTable>
              {
              	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más multas...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
              }
            </Col>
            <Modal show={this.state.showDelete} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='red'><Icon name='building' />{this.state.deleteCandidate && this.state.deleteCandidate.unit}</Label>
                <Label color='red'>ELIMINACIÓN</Label> Eliminar multa</Modal.Title>
              </Modal.Header>
              { this.state.deleteCandidate &&
                <Modal.Body>
                <Form horizontal>
                    <FormGroup controlId="unit">
                      <Col componentClass={ControlLabel} sm={5}>
                        Unidad
                      </Col>
                      <Col sm={7}>
                        <FormControl.Static>{this.state.deleteCandidate.unit}</FormControl.Static>
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="title">
                      <Col componentClass={ControlLabel} sm={5}>
                        Motivo
                      </Col>
                      <Col sm={7}>
                        <FormControl.Static>{this.state.deleteCandidate.title}</FormControl.Static>
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="description">
                      <Col componentClass={ControlLabel} sm={5}>
                        Descripción
                      </Col>
                      <Col sm={7}>
                        <FormControl.Static>{this.state.deleteCandidate.description}</FormControl.Static>
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="skip_reason">
                      <Col componentClass={ControlLabel} sm={5}>
                        Motivo de eliminación (*)
                      </Col>
                      <Col sm={5}>
                        <Autosuggest
                          datalist={this.state.suggestions}
                          onChange={this.handleChangeSkipReason}
                        />
                      </Col>
                      <Col sm={2}>
                        <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Motivo de eliminación de multa.</Tooltip>}>
                          <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                        </OverlayTrigger>
                      </Col>
                    </FormGroup>
                </Form>

                <p></p>
                <p className="psmall-center"><b>Nota:</b> La eliminación de esta multa será visible, por lo que el motivo de la eliminación debe ser claro.</p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="danger"
                  disabled={this.state.disableDeletePenalty}
                  isLoading={this.state.isDeleting}
                  onClick={this.handleDelete}
                  text="Eliminar"
                  loadingText="Eliminando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showOK} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                <Label color='green'><Icon name='building' />{this.state.okCandidate && this.state.okCandidate.unit}</Label>
                <Label color='green'>MULTA</Label> Marcar multa como procesada</Modal.Title>
              </Modal.Header>
              { this.state.okCandidate &&
                <Modal.Body>
                ¿Marca multa de la unidad <Label color='green'>{this.state.okCandidate.unit}</Label> como procesada OK?
                <p></p>
                <p className="psmall"><b>Nota:</b> Procesar la multa, significa que fue aceptada por el/la residente y se cancelará.</p>
                </Modal.Body>
              }
              <Modal.Footer>
                <LoaderButton bsStyle="success" isLoading={this.state.isOK} onClick={this.handleOk} text="Ok" loadingText="Guardando…"/>
                <Button onClick={this.handleClose}>Cancelar</Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showDetails} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Label color='blue'>
                  <Icon name='edit' />{this.state.detailCandidate && this.state.detailCandidate.unit}</Label> Detalle de la multa
                </Modal.Title>
              </Modal.Header>
              <PenaltyDetailModal {...this.props} cell={this.state.detailCandidate}/>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
}
