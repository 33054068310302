import React, { Component } from "react";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import { getUserAttributes } from '../../libs/awsLib';
import { Link } from "react-router-dom";
import DailyBookEvents from "./DailyBookEvents";
import NewDailyBook from "./NewDailyBook";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

import ReactToPrint from 'react-to-print';

import "react-datepicker/dist/react-datepicker.css";
import "./DailyBook.css";

import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
setDefaultLocale('es', es)

export default class DailyBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: '',
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      selectedDate: new Date(),
      runJoy: false,
      steps: [
        {
          target: '.btn-primary',
          content: 'Para agregar una nuevo evento en el libro presione aquí',
          placement: 'bottom',
        },
        {
          target: '.DailyBook .Events',
          content: 'En este listado se muestran los eventos del libro de novedades existentes por día',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ profile: user.profile });
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true },()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 5000)
      });
    } else {
      this.setState(newState,()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 5000)
      });
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleChange = (date) => {
    if (date === null || date === "") {
      date = new Date();
    }
    this.setState({ selectedDate: date });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy } = this.state;
    return (
      <div className="DailyBook" ref={el => (this.componentRef = el)}>
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Libros</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      Novedades
	    </PageHeader>
        <ButtonGroup>
          <DatePicker
            className="date-picker"
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}/>
          <NewDailyBook {...this.props}
            isLoading={this.state.isLoading}
            selectedDate={this.state.selectedDate}
            onAlertSuccess={this.handleAlertSuccess}
            onAlertError={this.handleAlertError}/>
          <Button onClick={this.handleUpdate} className="actionsBtn">
            <Glyphicon glyph="refresh" />
          </Button>
        </ButtonGroup>
        { this.state.profile === 'Supervisor' &&
          <ButtonGroup className="printBtnGroup">
            <ReactToPrint
              trigger={() => <Button><Glyphicon glyph="print" /></Button>}
              content={() => this.componentRef}
            />
          </ButtonGroup>
        }
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <DailyBookEvents {...this.props}
          isLoading={this.state.isLoading}
          selectedDate={this.state.selectedDate}
          onAlertSuccess={this.handleAlertSuccess}
          onAlertError={this.handleAlertError}
          onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
