import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Visitor.css";
import { Icon, Label } from 'semantic-ui-react'
import NewVisitor from "./NewVisitor";
import NewVisitorCountry from "./NewVisitorCountry";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListVisitorSummary from "./ListVisitorSummary";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';

export default class VisitorSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      parkings_slots_in_use: [],
      runJoy: false,
      steps: [
        {
          target: '.Visitor .visitors',
          content: 'En este listado se muestran las visitas que ingresaron en vehículo',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Visitor .table a',
          content: 'Puedes ver el detalle de una visita haciendo click en el enlace',
          placement: 'bottom',
        },
        {
          target: '.Visitor .slots',
          content: (
            <div>
              <p>En esta sección se muestran los estacionamientos de visita.</p>
              <p>Las cuadros <Label color="red">rojos</Label> representan estacionamientos en uso,</p>
              <p>mientras que los cuadros <Label color="green">verdes</Label> representan estacionamientos libres.</p>
            </div>
          ),
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message, closable) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false },()=>{
      if (closable) {
        window.setTimeout(()=>{this.setState({showAlertError:false})}, 3000)
      }
    });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#', parkings_slots_in_use: [] });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleSize = (sizeValue) => {
      this.setState({size: sizeValue});
  }

  handleSlots = (slots) => {
      this.setState({parkings_slots_in_use: slots});
  }

  isInUse = (slot) => {
    return this.state.parkings_slots_in_use.indexOf(slot) !== -1 ? "red" : "green";
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {

    const { steps, runJoy, size } = this.state;

    const parkings_lots = this.props.condo && this.props.condo.parkings_lots ?
      this.props.condo.parkings_lots.map((slot, index) =>
        <Label key={index} color={this.isInUse(slot)}><Icon name='car' />{slot}</Label>
      )
      : [];

    return (
      <div className="Visitor">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item componentClass={Link} href="/summary" to="/summary">Resumen</Breadcrumb.Item>
	      <Breadcrumb.Item active>Visitas</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
    	<PageHeader>
	      <span className="pheader">({size}) Visitas en vehículo</span>
        <ButtonGroup>
          <Button onClick={this.handleUpdate} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
        </ButtonGroup>
        {parkings_lots.length > 0 && <Label className="slots" color="green">Estacionamientos:</Label> }
        {parkings_lots}
	    </PageHeader>
      <ButtonGroup>
        {this.props.condo
          && this.props.condo.visitor
          && this.props.condo.visitor.country
            ? <NewVisitorCountry {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
            : <NewVisitor {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
          }
      </ButtonGroup>
      <ButtonGroup>
        <Link to="/visitor/daily">
           <Button variant="secondary" ><Glyphicon glyph="link"/> ir a Visitas del día</Button>
       </Link>
      </ButtonGroup>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListVisitorSummary {...this.props} onHandleSlots={this.handleSlots} onHandleSize={this.handleSize} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
