import React, { Component } from 'react'
import { Grid, Icon, Button, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import {  getUserAttributes } from '../../libs/awsLib';
import './GridMenu.css';
import { checkAccess } from '../../libs/commons'

export default class GridMenu extends Component {

  constructor(props) {
      super(props);

      this.state = {
        activeItem: '',
        profile: ''
      }
  }

  componentDidMount = async () => {
    const user = await getUserAttributes();
    this.setState({ profile: user.profile });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (this.props.onGoMenu) {
      this.props.onGoMenu(name);
    }
  }

  renderOperatorButton() {
    const suspended = this.props.condo && this.props.condo.payment && this.props.condo.payment.suspended;
    if (this.state.profile !== 'Operador') {
      return (<Button name='/book/notice' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Comunicados</Button.Content>
              <Button.Content>
                <Icon name='bullhorn' size='massive'/>
              </Button.Content>
            </Button>)
    } else if (this.props.condo && this.props.condo.access_control) {
      return (<Button name='/access/control/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Accesos controlados</Button.Content>
              <Button.Content>
                <Icon name='key' size='massive'/>
              </Button.Content>
            </Button>)
    } else {
      return (<Button name='/book/diarybook' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Libro</Button.Content>
              <Button.Content>
                <Icon name='book' size='massive'/>
              </Button.Content>
            </Button>)
    }
  }

  render() {
    const suspended = this.props.condo && this.props.condo.payment && this.props.condo.payment.suspended;
    return (
      <div>
      {
        this.props && this.props.summary
        ? this.renderSummary(suspended)
        : this.renderHome(suspended)
      }
      </div>
    )
  }

  renderSummary(suspended) {
    return (
      <Grid verticalAlign='middle' columns={3} centered>
        <Grid.Row>
          <Grid.Column>
          <Button name='/delivery/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
            <Button.Content className="grid-label">Encomiendas</Button.Content>
            <Button.Content>
              <Icon name='dolly' size='massive'/>
            </Button.Content>
          </Button>
          </Grid.Column>
          <Grid.Column>
            <Button name='/visitor/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Visitas</Button.Content>
              <Button.Content>
                <Icon name='car' size='massive'/>
              </Button.Content>
            </Button>
          </Grid.Column>
          {checkAccess('ADVANCED', 'EXPERT') &&
          <Grid.Column>
            <Button name='/pet/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Mascotas</Button.Content>
              <Button.Content>
                <Icon name='paw' size='massive'/>
              </Button.Content>
            </Button>
          </Grid.Column>
          }
        </Grid.Row>
      </Grid>
    )
  }

  renderHome(suspended) {
    return (
      <div>
      {
        !this.props.condo &&
        <Segment>
          <Dimmer active inverted>
            <Loader size='massive' inverted content='Cargando contenido...' />
          </Dimmer>

          <Image src="/black-logo.png" />
        </Segment>
      }
      {
        this.props.condo &&
      <Grid verticalAlign='middle' columns={3} centered className="grid-summary-menu">
        <Grid.Row>
          <Grid.Column>
          <Button name='/delivery/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
            <Button.Content className="grid-label">Encomiendas</Button.Content>
            <Button.Content>
              <Icon name='dolly' size='massive'/>
            </Button.Content>
          </Button>
          </Grid.Column>
          <Grid.Column>
            <Button name='/visitor/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Visitas</Button.Content>
              <Button.Content>
                <Icon name='car' size='massive'/>
              </Button.Content>
            </Button>
          </Grid.Column>
          {checkAccess('ADVANCED', 'EXPERT') &&
          <Grid.Column>
            <Button name='/booking' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
              <Button.Content className="grid-label">Reservas</Button.Content>
              <Button.Content>
                <Icon name='calendar alternate' size='massive'/>
              </Button.Content>
            </Button>
          </Grid.Column>}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
              { this.renderOperatorButton() }
          </Grid.Column>
          {checkAccess('ADVANCED', 'EXPERT') &&
          <Grid.Column>
              <Button name='/visitor/provider' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
                <Button.Content className="grid-label">Proveedores</Button.Content>
                <Button.Content>
                  <Icon name='wrench' size='massive'/>
                </Button.Content>
              </Button>
            </Grid.Column>
            }
            {checkAccess('ADVANCED', 'EXPERT') &&
            <Grid.Column>
              <Button name='/pet/summary' size='massive' color='linkedin' onClick={this.handleItemClick} disabled={suspended}>
                <Button.Content className="grid-label">Mascotas</Button.Content>
                <Button.Content>
                  <Icon name='paw' size='massive'/>
                </Button.Content>
              </Button>
            </Grid.Column>
            }
        </Grid.Row>
      </Grid>
      }
      </div>
    )
  }
}
