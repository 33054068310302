import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Delivery.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListDeliverySummary from "./ListDeliverySummary";
import NewDelivery from "./NewDelivery";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import DeliveryExport from "./DeliveryExport";

export default class DeliverySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      deliveries: [],
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      runJoy: false,

      steps: [
        {
          target: '.Delivery .deliveries',
          content: 'En este listado se muestran las encomiendas pendientes del condominio',
          placement: 'bottom',
        },
        {
          target: '.Delivery .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí',
          placement: 'bottom',
        },
        {
          target: '.Delivery .table .btnDoDelivery',
          content: 'Puedes realizar la entrega de una encomienda al residente',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertError: false };
    this.setState({showAlertSuccess:true},()=>{
        window.setTimeout(()=>{this.setState({showAlertSuccess:false})
      }, 7000)
    });

    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message, closable) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false },()=>{
      if (closable) {
        window.setTimeout(()=>{this.setState({showAlertError:false})}, 3000)
      }
    });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#' });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleSize = (deliveries) => {
      this.setState({deliveries: deliveries, size: deliveries.length});
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy, size } = this.state;
    const pending = this.props.condo && this.props.condo.payment && this.props.condo.payment.pending;
    return (
      <div className="Delivery">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item componentClass={Link} href="/summary" to="/summary">Resumen</Breadcrumb.Item>
	      <Breadcrumb.Item active>Encomiendas</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
      <PageHeader>
	      <span className="pheader">({size}) Encomiendas recibidas</span>
        <ButtonGroup className="headerBtn">
          <Button onClick={this.handleUpdate} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
          { !pending &&
            <DeliveryExport data={this.state.deliveries} deliveryType={this.props.deliveryType}/>
          }
        </ButtonGroup>
	    </PageHeader>
        <ButtonGroup className="headerBtn">
          <NewDelivery {...this.props} onAlertSuccess={this.handleAlertSuccess} onAlertError={this.handleAlertError}/>
        </ButtonGroup>

        {
          this.state.showAlertSuccess &&
          <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <ListDeliverySummary {...this.props} onHandleSize={this.handleSize} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate}/>
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	      />
      </div>
    );
  }

}
