import React, { Component } from "react";
import {
    Col, Form, FormGroup, ControlLabel,
    FormControl
   } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import { formatRut } from '../../libs/validation';
import moment from 'moment';

export default class AccessControlDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.cell,
      isDeleting: false,
      isReseting: false,
      show: false,
      showReset: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  nameFormatter = (name, lastname) => {
    return name + " " + lastname;
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  residentFormatter = (resident) => {
    return resident
    && typeof(resident.name) !== 'undefined'
    && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
  }

  elapsedFormatter = (cell, color) => {
    if (cell) {
      let hours = parseInt(cell / 60, 10).toString();
      let minutes = parseInt(cell % 60, 10).toString();
      let exceeded_time = hours.padStart(2,"00") + ':' + minutes.padStart(2,"00");
      return <Label color={color}><Icon name='time' />{exceeded_time}</Label>
    }
  }

  render(){
    const { created_date,
      user_who_creates, departure_date, visitors_number,
      user_who_departures, detail,
      resident, access} = this.state ;

    const id = resident.card_id_type === 'OTHER' ? resident.card_id : formatRut(resident.card_id);

    return(
      <div className="Access">
        <Form horizontal>
          <FormGroup controlId="card_id">
            <Col componentClass={ControlLabel} sm={6}>
              Acceso{' '}
            </Col>
            <Col sm={6}>
              <FormControl.Static>
                <Label color='green'>{access.name}</Label>
              </FormControl.Static>
            </Col>
          </FormGroup>
          <FormGroup controlId="card_id">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        RUT (u otro){' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{id}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { resident &&
          <FormGroup controlId="resident">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Residente{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{this.residentFormatter(resident)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
          <FormGroup controlId="visitors_number">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Nº Personas{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{visitors_number}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          { detail &&
          <FormGroup controlId="detail">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Observación{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{detail}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
  		    <FormGroup controlId="created_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Ingreso{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date) + ' ('+ this.userFormatter(user_who_creates) + ')'}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          { departure_date &&
          <FormGroup controlId="departure_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Salida{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(departure_date) + ' ('+ this.userFormatter(user_who_departures) + ')'}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          }
  	    </Form>
      </div>
    );
  }
}
