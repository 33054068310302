import React, { Component } from "react";
import { invokeApig } from '../../libs/awsLib';
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Row, Col, Alert, ButtonGroup } from "react-bootstrap";
import { Icon, Label, Image, Radio } from 'semantic-ui-react'
import {validateEmail, validateText, validateFreeText, formatRut, validatePhone } from '../../libs/validation';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import LoaderButton from "../../components/LoaderButton";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import config from "../../config";
import ResidentDetailModal from "./ResidentDetailModal";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ListResident.css';

export default class ListResident extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPicture: false,
            disableEdit: true,
            isSendingValidationEmail: false,
            isDisablesSendingEmail: false,
            isDeleting: false,
            isEditing: false,
            isLoading: false,
            isError: false,
            showDetails: false,
            showDelete: false,
            showSendEmailValidation: false,
            showModalDisableSendEmail: false,
            showEdit: false,
            showResident: false,
            showAlertSuccess: false,
            showAlertError: false,
            alertMessage: '',
            editCandidate: null,
            deleteCandidate: null,
            residentCandidate: null,
            sendEmailCandidate: null,
            disableSendEmailCandidate: null,
            resident_picture_type: 'CURRENT_PIC',
            resident_picture: '',
            card_id:'',
            card_id_type: '',
            name:'',
            lastname:'',
            email: '',
            special_condition: '',
            phone: '',
            is_owner: false,
            isEditNewEmail: false
        };
    }

    deleteResident(residents) {
      return invokeApig({
        path: "/resident",
        method: "DELETE",
        body: {
          "unitId": this.props.selectedUnit["unit"],
          "card_id": this.state.deleteCandidate.card_id
        }
      });
    }

    updateResident(resident) {
      return invokeApig({
        path: `/resident`,
        method: "PUT",
        body: resident
      });
    }

    sendValidationEmail() {
      return invokeApig({
        path: `/resident/send`,
        method: "POST",
        body: {
          "unitId": this.props.selectedUnit["unit"],
          "card_id": this.state.sendEmailCandidate.card_id
        }
      });
    }

    disableSendmail() {
      return invokeApig({
        path: `/resident/send`,
        method: "PUT",
        body: {
          "unitId": this.props.selectedUnit["unit"],
          "card_id": this.state.disableSendEmailCandidate.card_id
        }
      });
    }

    setTableOption() {
      if (this.props.appLoading) {
        return <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>;
      } else {
        return this.state.isError ? "Error al obtener residentes. Intenta más tarde." : "No hay datos";
      }
    }

    handleClose = () => {
      this.setState({ showDelete: false, deleteCandidate: null,
        showEdit: false, editCandidate: null, showPicture: false, showDetails: false,
        showResident: false, residentCandidate: null, resident_picture: '', resident_picture_type: 'CURRENT_PIC',
        showSendEmailValidation: false, sendEmailCandidate: null,
        showModalDisableSendEmail: false, disableSendEmailCandidate: null });
    }

    handleDisablesSendEmail = async event => {
      event.preventDefault();
      this.setState({ isDisablesSendingEmail: true });
      try {
        const response = await this.disableSendmail();
        this.setState({ isDisablesSendingEmail: false, showModalDisableSendEmail: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al desactivar envío de correos a residente!" });
        } else {
          this.props.reloadResidents();
          this.handleAlertSuccess({ message: "Desactivación de envío de correos exitosa!" });
        }
      } catch (e) {
        this.setState({ isDisablesSendingEmail: false, showModalDisableSendEmail: false, showAlertError: true, alertMessage: "Error al enviar correo a residente!!" });
      }
    }

    handleEmailValidation = async event => {
      event.preventDefault();
      this.setState({ isSendingValidationEmail: true });
      try {
        const response = await this.sendValidationEmail();
        this.setState({ isSendingValidationEmail: false, showSendEmailValidation: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al enviar correo a residente!" });
        } else {
          this.props.reloadResidents();
          this.handleAlertSuccess({ message: "Correo enviado correctamente!" });

        }
      } catch (e) {
        this.setState({ isSendingValidationEmail: false, showSendEmailValidation: false, showAlertError: true, alertMessage: "Error al enviar correo a residente!!" });
      }
    }

    handleDelete = async event => {
      event.preventDefault();
      this.setState({ isDeleting: true });
      try {
        const response = await this.deleteResident();
        this.setState({ isDeleting: false, showDelete: false });
        if (response.code !== 0) {
          this.handleAlertError({ message: "Error al eliminar residente!" });
        } else {
          this.props.reloadResidents();
          this.handleAlertSuccess({ message: "Residente eliminado correctamente!" });

        }
      } catch (e) {
        this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar residente!" });
      }
    }

    handleAlertSuccess = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: false });
      this.setState({showAlertSuccess:true},()=>{
          window.setTimeout(()=>{this.setState({showAlertSuccess:false})
        }, 7000)
      });
    }

    handleAlertError = (message) => {
      this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    }

    handleDismissSuccess = () => {
      this.setState({ showAlertSuccess: false });
    }

    handleDismissError = () => {
      this.setState({ showAlertError: false });
    }

    handleChange = event => {
      this.setState({
          [event.target.id]: event.target.id === "is_owner" ? event.target.checked:event.target.value
      },() => {
        this.enableButton();
      });
    }

    handleChangeRadio = (e, { value }) => {
      this.setState({ resident_picture_type: value
      },() => {
        this.enableButton();
      });
    }

    enableButton = () => {
      if (this.state.showEdit) {
        const isEditNewEmail = this.state.email !== this.state.editCandidate.email;
        var disableEdit = this.state.card_id === this.state.editCandidate.card_id
          && this.state.name === this.state.editCandidate.name
          && this.state.lastname === this.state.editCandidate.lastname
          && this.state.email === this.state.editCandidate.email
          && this.state.phone === this.state.editCandidate.phone
          && this.state.special_condition === this.state.editCandidate.special_condition
          && this.state.is_owner === this.state.editCandidate.is_owner;

        if (this.props.condo && this.props.condo.picture.resident)
        {
          disableEdit =
            disableEdit && this.state.resident_picture_type === 'CURRENT_PIC';
        }

        this.setState({ disableEdit: disableEdit, isEditNewEmail: isEditNewEmail });
      }
    }

    resetForm = () => {
      this.setState({ card_id:'', card_id_type: '', name:'',
        lastname:'', email:'', phone:'', is_owner: false, special_condition: '',
        showError: false, errorMessage: '', resident_picture: '',
        resident_picture_type: 'CURRENT_PIC',});
    }

    handleEditSubmit = async event => {
      event.preventDefault();
      const errorMsg = this.getErrors();
      if (errorMsg) {
        this.setState({showError: true, errorMessage: errorMsg});
        return;
      }
      this.setState({ isLoading: true });
      try {

        const resident = {
          card_id: this.state.card_id,
          name: this.state.name,
          lastname: this.state.lastname,
          email: this.state.email ? this.state.email : '-',
          phone: this.state.phone ? this.state.phone : '-',
          special_condition: this.state.special_condition ? this.state.special_condition : '-',
          is_owner: this.state.is_owner,
          unitId: this.props.selectedUnit["unit"],
          is_new_email :this.state.isEditNewEmail
        };

        if (this.state.resident_picture_type === 'NEW_PIC') {
          resident.resident_picture_64 = this.state.resident_picture;
        }

        const response = await this.updateResident(resident);
        if (response.code === 0) {

          this.props.reloadResidents();

          this.resetForm();
          this.handleAlertSuccess({ message: "Residente actualizado exitosamente!" });
        } else {
          this.handleAlertError({ message: "Error al modificar residente! Por favor revise los datos e intente nuevamente." });

        }
        this.handleClose();
      } catch (e) {
        this.setState({ showAlertError: true, alertMessage: "Error al modificar residente!" });

      }
      this.setState({ isLoading: false });
    }

    getErrors() {
      let message = undefined;

      if (!validateText(this.state.name)) {
        message = 'Debe ingresar nombre';
        return message;
      }

      if (!validateText(this.state.lastname)) {
        message = 'Debe ingresar apellido';
        return message;
      }

      if (this.state.email && this.state.email !== '-' && !validateEmail(this.state.email)) {
        message = 'Debe ingresar email válido';
        return message;
      }

      if (this.state.phone && this.state.phone !== '-' && !validatePhone(this.state.phone)) {
        message = 'Debe ingresar teléfono válido (Formato: +56)';
        return message;
      }

      if (this.state.special_condition && this.state.special_condition !== '-' && !validateFreeText(this.state.special_condition)) {
        message = 'Debe ingresar teléfono válido (Formato: +56)';
        return message;
      }

      return message;
    }

    showEditResident = (cell, row, rowIdx) => {
      this.setState({ isLoading: false,
        card_id: cell.card_id,
        card_id_type: cell.card_id_type ? cell.card_id_type : 'RUT',
        name: cell.name, lastname: cell.lastname,
        is_owner: cell.is_owner,
        email: cell.email,
        phone: cell.phone,
        special_condition: cell.special_condition,
        showEdit: true, editCandidate: cell
      });
    }

    showConfirmDelete = (cell, row, rowIdx) => {
      this.setState({ showDelete: true, deleteCandidate: cell });
    }

    showSendValidation = (cell, row, rowIdx) => {
      this.setState({ showSendEmailValidation: true, sendEmailCandidate: cell });
    }

    showDisableSendEmail = (cell, row, rowIdx) => {
      this.setState({ showModalDisableSendEmail: true, disableSendEmailCandidate: cell });
    }

    showResidentPicture = (cell, row, rowIdx) => {
      this.setState({ showPicture: true, residentCandidate: cell });
    }

    showDetails = (cell, row, rowIdx) => {
      this.setState({ showDetails: true, residentCandidate: cell });
    }

    actionsFormatter = (cell, row) => {
      const id = row.card_id_type === 'OTHER' ? cell : formatRut(cell);
      return <Link to={{}} onClick={this.showDetails.bind(cell, row)}>{id}</Link>;
    }

    residentFormatter = (resident) => {
      return resident
      && typeof(resident.name) !== 'undefined'
      && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
    }

    ownerFormatter = (cell, row) => {
      return cell && cell === true?
        <Label color="green">SI</Label>
        :
        <Label color="red">NO</Label>
    }

    emailStatusFormatter= (cell, row) => {
      let color = cell === 'ACTIVE' ? 'green': 'red'
      if (typeof(row.has_email) !== 'undefined' && row.has_email) {
        let status = this.props.emailStatus[cell];
        return <Label color={color} className={color}>{status}</Label>
      }
      return '-';
    }

    capture = () => {
       const resident_picture = this.webcam.getScreenshot();
       this.setState({ resident_picture });
    }

    drawButton = (cell, row, rowIdx) => {
      return row['status'] === 'ACTIVE' &&
         <ButtonGroup className="actionsBtn">
          {  row['email_status'] !== 'ACTIVE' && row['email'] && row['email'] !== '-' &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Envía correo de verificación</Tooltip>}>
              <Button bsStyle="success" onClick={this.showSendValidation.bind(cell, row, rowIdx)} className="btnSendValidation">
                <Glyphicon glyph="envelope" />
              </Button>
            </OverlayTrigger>
          }
          {  row['email_status'] === 'ACTIVE' &&
            <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Desactiva las notificaciones de correo</Tooltip>}>
              <Button bsStyle="danger" onClick={this.showDisableSendEmail.bind(cell, row, rowIdx)} className="btnDisableSendValidation">
                <Glyphicon glyph="volume-off" />
              </Button>
            </OverlayTrigger>
          }
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Editar</Tooltip>}>
            <Button bsStyle="warning" onClick={this.showEditResident.bind(cell, row, rowIdx)} className="btnEditResident">
              <Glyphicon glyph="edit" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Eliminar</Tooltip>}>
           <Button bsStyle="danger" onClick={this.showConfirmDelete.bind(cell, row, rowIdx)} className="btnDeleteResident">
             <Glyphicon glyph="trash" />
           </Button>
          </OverlayTrigger>
          {this.props.condo && this.props.condo.picture.resident && typeof(row.resident_picture_url) !== 'undefined' &&
          <OverlayTrigger placement="top" delayShow={250} delayHide={400} overlay={<Tooltip id="modal-tooltip">Ver foto</Tooltip>}>
            <Button bsStyle="primary" onClick={this.showResidentPicture.bind(cell, row)} className="btnShowVisitor">
              <Glyphicon glyph="camera" />
            </Button>
          </OverlayTrigger>
          }
         </ButtonGroup>;
    }

    render(){

        const selectRowProp = {
          mode: 'radio',
          hideSelectColumn: true,  // enable hide selection column.
          clickToSelect: false,  // you should enable clickToSelect, otherwise, you can't select column.
          onSelect: this.onRowSelect
        };
        const tableOptions = {
          noDataText: this.setTableOption()
        };

        const data = this.props.residents ?
          this.props.residents: [];

        return(
          <div className="Resident">
          {
            this.state.showAlertSuccess &&
            <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          {
            this.state.showAlertError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.alertMessage}</strong>
            </Alert>
          }
          <Col sm={12} className="residents">
            <BootstrapTable
              pagination
              data={ data }
              selectRow={ selectRowProp }
              options={ tableOptions }
              hover>
              <TableHeaderColumn dataField='card_id' dataFormat={ this.actionsFormatter } isKey filter={ { type: 'TextFilter', placeholder: 'Ingrese RUT (u otro)', delay: 10 } } dataSort={ true } >RUT (u otro)</TableHeaderColumn>
              <TableHeaderColumn dataField='name'>Nombre</TableHeaderColumn>
              <TableHeaderColumn dataField='lastname'>Apellido</TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataField='is_owner' dataFormat={ this.ownerFormatter }>Dueño</TableHeaderColumn>
              <TableHeaderColumn dataField='email'><Icon name="mail" /> Email</TableHeaderColumn>
              <TableHeaderColumn dataField='email_status' headerAlign='center' dataAlign='center' dataFormat={ this.emailStatusFormatter }>Envío de email</TableHeaderColumn>
              <TableHeaderColumn dataField='phone' headerAlign='center' dataAlign='center'><Icon name="phone" />Teléfono</TableHeaderColumn>
              <TableHeaderColumn dataField='special_condition' headerAlign='center'><Icon name="wheelchair" />Condición especial</TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataFormat={this.drawButton}>Acciones</TableHeaderColumn>
            </BootstrapTable>
            {
            	this.state.lastId && (this.state.isMoreLoading ? <div><Glyphicon glyph="refresh" className="spinning" />Cargando más residentes...</div> : <div><Button onClick={this.handleMore} className="loadMore">Cargar más...</Button></div>)
            }
          </Col>
          <Modal show={this.state.showModalDisableSendEmail} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
              <Label color='red'>DESACTIVACIÓN</Label> Desactivación de envío de correos</Modal.Title>
            </Modal.Header>
            { this.state.disableSendEmailCandidate &&
              <Modal.Body><p>¿Está seguro que quiere desactivar el envio de correos de encomiendas a <Label color='red'>{this.residentFormatter(this.state.disableSendEmailCandidate)}</Label>?</p>
              <p className="psmall"><b>Nota:</b> Avisar a residente que puede volver a activar notificaciones de encomiendas.</p>
              </Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDisablesSendingEmail} onClick={this.handleDisablesSendEmail} text="Desactivar" loadingText="Desactivando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showSendEmailValidation} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='green'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
              <Label color='green'>VERIFICACIÓN</Label> Envío de correo de verificación</Modal.Title>
            </Modal.Header>
            { this.state.sendEmailCandidate &&
              <Modal.Body><p>¿Está seguro que quiere enviar correo de verificación a <Label color='green'>{this.residentFormatter(this.state.sendEmailCandidate)}</Label>?</p>
              <p className="psmall"><b>Nota:</b> Avisar a residente que recibirá un link de verificación en su correo (<b>{this.state.sendEmailCandidate.email}</b>), al cual debe acceder.</p></Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="success" isLoading={this.state.isSendingValidationEmail} onClick={this.handleEmailValidation} text="Enviar" loadingText="Enviando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showDelete} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
              <Label color='red'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
              <Label color='red'>ELIMINACIÓN</Label> Eliminar residente</Modal.Title>
            </Modal.Header>
            { this.state.deleteCandidate &&
              <Modal.Body>¿Está seguro que quiere eliminar residente <Label color='red'>{this.residentFormatter(this.state.deleteCandidate)}</Label>?</Modal.Body>
            }
            <Modal.Footer>
              <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
              <Button onClick={this.handleClose}>Cancelar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='orange'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label>
                <Label color='orange'>EDICIÓN</Label> Datos del residente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.editCandidate &&
                this.renderFormEdit()
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showDetails} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Label color='blue'>
                <Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Detalle de residente
              </Modal.Title>
            </Modal.Header>
            <ResidentDetailModal {...this.props} cell={this.state.residentCandidate}/>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
          <Modal className="modal-picture" show={this.state.showPicture} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                <Label color='blue'><Icon name='user' />Nombre:</Label> {this.state.residentCandidate && this.residentFormatter(this.state.residentCandidate)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.residentCandidate &&
                <Image
                  className="resident_picture_modal"
                  src={config.s3_files.BUCKET + this.state.residentCandidate.resident_picture_url} />
              }
            </Modal.Body>
          </Modal>
          {this.props.condo && this.props.condo.picture.resident &&
            this.state.resident_picture_type === 'NEW_PIC' &&
            <Modal className="resident_cam_modal" show={this.state.showEdit} onHide={this.handleClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
              <Webcam
                className="resident_cam"
                audio={false}
                height={config.picture.HEIGHT}
                width={config.picture.WIDTH}
                ref={node => this.webcam = node}
                screenshotFormat={config.picture.FORMAT}
                screenshotQuality={config.picture.QUALITY_HIGH}
                videoConstraints={config.picture.CONSTRAINS}
              />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
          }
          </div>
        );
    }

    renderFormEdit(){
      return(
  	  <Form horizontal onSubmit={this.handleEditSubmit}>
          <FormGroup controlId="card_id">
            <Col componentClass={ControlLabel} sm={3}>
            <Radio
              label='RUT '
              name='radioGroup'
              value='RUT'
              disabled
              checked={this.state.card_id_type === 'RUT'}
            />
            <Radio
              label='Otro'
              name='radioGroup'
              value='OTHER'
              disabled
              checked={this.state.card_id_type === 'OTHER'}
            />
            </Col>
            <Col sm={8}>
              <FormControl
              disabled
  	          onChange={this.handleChange}
  	          value={this.state.card_id}
  	        />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="name">
            <Col componentClass={ControlLabel} sm={3}>
              Nombre (*)
            </Col>
            <Col sm={8}>
              <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="ej: Diego"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="lastname">
            <Col componentClass={ControlLabel} sm={3}>
              Apellido (*)
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.lastname}
                placeholder="ej: Salas"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="email">
            <Col componentClass={ControlLabel} sm={3}>
              Email
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.email}
                placeholder="residente@condominio.com (opcional)"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="phone">
            <Col componentClass={ControlLabel} sm={3}>
              Teléfono
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.phone}
                placeholder="+56981000000 (opcional)"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Teléfono.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="special_condition">
            <Col componentClass={ControlLabel} sm={3}>
              Condición especial
            </Col>
            <Col sm={8}>
              <FormControl
                onChange={this.handleChange}
                value={this.state.special_condition}
                placeholder="ej: Movilidad reducida"
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Condición especial del residente.</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          <FormGroup controlId="is_owner">
            <Col componentClass={ControlLabel} sm={3}>
              Es dueño?
            </Col>
            <Col sm={1}>
              <FormControl
                onChange={this.handleChange}
                checked={this.state.is_owner}
                type='checkbox'
              />
            </Col>
            <Col sm={1}>
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Es dueño?</Tooltip>}>
                <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
              </OverlayTrigger>
            </Col>
          </FormGroup>
          {this.props.condo && this.props.condo.picture.resident &&
            <FormGroup controlId="resident_picture_id">
              <Col componentClass={ControlLabel} sm={3}>
              <Radio
                label='Foto actual'
                name='radioGroup'
                value='CURRENT_PIC'
                checked={this.state.resident_picture_type === 'CURRENT_PIC'}
                onChange={this.handleChangeRadio}
              />
              <Radio
                label='Foto nueva'
                name='radioGroup'
                value='NEW_PIC'
                checked={this.state.resident_picture_type === 'NEW_PIC'}
                onChange={this.handleChangeRadio}
              />
              </Col>
              { this.state.resident_picture_type === 'NEW_PIC' &&
                  <React.Fragment>
                  <Col sm={4}>
                    <Webcam
                      audio={false}
                      height={config.picture.HEIGHT_AVATAR}
                      width={config.picture.WIDTH_AVATAR}
                      screenshotFormat={config.picture.FORMAT}
                      videoConstraints={config.picture.CONSTRAINS_AVATAR}
                    />
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col md={12}>
                        <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
                      </Col>
                    </Row>
                    {this.state.resident_picture &&
                    <React.Fragment>
                      <Row>
                      <Col md={12}>
                        <p className="resident_picture_previous_text">Previa:</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <img className="resident_picture_previous" alt="Previa" src={this.state.resident_picture} />
                      </Col>
                    </Row>
                    </React.Fragment>
                    }
                  </Col>
                  </React.Fragment>
              }
              { this.state.resident_picture_type === 'CURRENT_PIC' &&
                <Col sm={8}>

                <Image
                  className="resident_picture_avatar"
                  src={this.state.editCandidate.resident_picture_url ? config.s3_files.BUCKET + this.state.editCandidate.resident_picture_url: "pic_not_found.png"} />
                </Col>
              }
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de residente.</Tooltip>}>
                  <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
                </OverlayTrigger>
              </Col>
            </FormGroup>
          }
          {
            this.state.showError &&
            <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          }
          <LoaderButton
                block
                disabled={this.state.disableEdit}
                bsStyle="warning"
                bsSize="large"
                type="submit"
                isLoading={this.state.isLoading}
                text={"Modificar"}
                loadingText="Guardando…"
              />
        </Form>
      );
    }
}
