import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import Select from 'react-select';
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { invokeApig } from "../../libs/awsLib";
import { validateNumber, validateFreeText } from '../../libs/validation';
import './NewAccessControl.css';

export class NewAccessControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitors_number: 1,
      detail: '',
      isLoading: false,
      show: false,
      showError: false,
      errorMessage: '',
      isSearching: false,
      selectedResident: null,
      selectedAccess: null,
      accessControls: []
    };
  }

  findAccess = async () => {
    this.setState({ accessControls: [] });
    try {
      const results = await this.accessControls();
      this.setState({ accessControls: results.accessControls });
    } catch (e) {
      this.setState({isError: true});
    }
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  createAccess(visitor) {
    visitor.unitId = this.props.selectedUnit["unit"];
    if (this.props.selectedUnit.hasOwnProperty('unit_name')) {
      visitor.unit_name = this.props.selectedUnit["unit_name"];
    }
    return invokeApig({
      path: "/access",
      method: "POST",
      body: visitor
    });
  }


  accessControls() {
    return invokeApig({
      path: "/access/find",
      method: "POST",
      body: {
          "status_summary": "RC"
      }
    });
  }

  shortResident (resident) {
    return {
      "name": resident["name"],
      "lastname": resident["lastname"],
      "card_id": resident["card_id"],
      "card_id_type": resident["card_id_type"],
      "email": resident["email"]
    }
  }

  shortAccess (access) {
    return {
      "id": access["id"],
      "name": access["name"]
    }
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '', detail: '',
      visitors_number: 1, selectedResident: null, selectedAccess: null,
      isSearching: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleResidentChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ selectedResident: option, showError: false });
  };

  handleAccessChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ selectedAccess: option, showError: false });
  };

  getErrors() {
    let message = undefined;

    if (this.props.condo
      && this.props.condo.access_control
      && this.props.condo.access_control.length > 0 && !this.state.selectedAccess) {
        message = 'Debe seleccionar lugar al que ingresa residente';
        return message;
    }

    if (this.props.residents.length > 0 && !this.state.selectedResident) {
        message = 'Debe seleccionar residente que ingresa al lugar';
        return message;
    }

    if (!validateNumber(this.state.visitors_number)) {
      message = 'Debe ingresar número de personas';
      return message;
    }

    if (this.state.detail.trim().length > 0 && !validateFreeText(this.state.detail, 1, 100)) {
      message = 'Debe ingresar detalle válido';
      return message;
    }

    this.setState({showError: false});
    return message;
  }

  resetForm = () => {
    this.setState({ visitors_number: 1, showError: false, errorMessage: '', detail: '',
      isSearching: false, selectedResident: null, selectedAccess: null
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const visitor = {
        //lastname: this.state.lastname,
        visitors_number: this.state.visitors_number
      };

      if (this.state.selectedResident) {
        visitor.resident = this.shortResident(this.state.selectedResident);
      }

      if (this.state.selectedAccess) {
        visitor.access = this.shortAccess(this.state.selectedAccess);
      }

      if (this.state.detail.trim() !== '') {
        visitor.detail = this.state.detail.trim();
      }

      const response = await this.createAccess(visitor);
      if (response.code === 0) {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Accesso ingresado exitosamente!", refresh: true }, visitor);
      } else {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear acceso! " + msgExtra });
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.props.onAlertError({ message: "Error al registrar acceso! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  render() {

    const residents = this.props.residents ?
      this.props.residents.map(v => ({
        label: v.name + " " + v.lastname + " ("+ v.card_id +")",
        value: v
      })) : [];

    const accessControls = this.props.condo.access_control ?
      this.props.condo.access_control.map(v => ({
        label: v.name,
        value: v
      })) : [];

    return (
      <div className="accessBtn">

      <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar acceso</Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos del acceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew(residents, accessControls)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  renderFormNew(residents, accessControls){
    return(
    <Form horizontal onSubmit={this.handleSubmit}>

        <FormGroup controlId="accessControls">
          <Col componentClass={ControlLabel} sm={4}>
            Acceso (*)
          </Col>
          <Col sm={7}>
              <Select
                onChange={this.handleAccessChange}
                options={accessControls}
                placeholder="Seleccione lugar al que accede" />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que accede al lugar</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="resident">
          <Col componentClass={ControlLabel} sm={4}>
            Residente (*)
          </Col>
          <Col sm={7}>
                { residents.length > 0 ?
                  <Select
                    onChange={this.handleResidentChange}
                    options={residents}
                    placeholder="Seleccione residente que accede" />
                :
                  <div>Unidad no tiene residentes.
                  <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
                  </div>
                }

          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que accede al lugar</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="visitors_number">
          <Col componentClass={ControlLabel} sm={4}>
            N° de personas (*)
          </Col>
          <Col sm={2}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.visitors_number}
              type='number'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Número de personas que acceden. Incluyendo al residente.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="detail">
          <Col componentClass={ControlLabel} sm={4}>
            Observación
          </Col>
          <Col sm={7}>
            <FormControl
              maxlength="100"
              onChange={this.handleChange}
              value={this.state.detail}
              placeholder="(Opcional)"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Observación (opcional).</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              disabled={residents.length === 0}
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewAccessControl
