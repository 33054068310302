import React, { Component } from "react";
import { invokeApig } from "../../libs/awsLib";
import { Link } from "react-router-dom";
import {
    Col, Button, Glyphicon,
    Breadcrumb, PageHeader, Form, FormGroup, ControlLabel,
    FormControl, Alert
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import moment from 'moment';
import CanvasDraw from "react-canvas-draw";

export default class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.location.state,
      isLoading: false,
      show: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      sign: '',
      signProps: {
        width: 180,
        height: 180,
        brushRadius: 1,
        lazyRadius: 12
      }
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  getDelivery() {
    return invokeApig({
      path: `/delivery/find`,
      method: "POST",
      body: {
        "unitId": this.props.selectedUnit["unit"],
        "created_date": this.state.created_date
      }
    });
  }

  handleGetSign = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const response = await this.getDelivery();
      this.setState({ isLoading: false });

      if (response.code !== 0) {
        this.handleAlertError({ message: "Error al obtener firma!" });
        return;
      }

      if (response.deliveries && response.deliveries.length) {
        this.setState({ sign: response.deliveries[0].resident_sign });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleAlertError({ message: "Error al resetear clave del usuario!" });
    }
  }

  handleAlertSuccess = (message, user) => {
    var newState = { ...user, alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    this.setState(newState);
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  dateFormatter = (dateIn) => {
    if (dateIn) {
      const date = moment(dateIn);
      return date.format("DD-MM-YYYY HH:mm");
    }
  }

  typeFormatter = (type) => {
    return this.props.deliveryType[type];
  }

  statusFormatter = (status) => {
    return this.props.deliveryStatus[status];
  }

  residentFormatter = (resident) => {
    return resident
    && typeof(resident.name) !== 'undefined'
    && typeof(resident.lastname) !== 'undefined' ? resident.name + " " + resident.lastname: "";
  }

  userFormatter = (cell, row) => {
    return cell
    && typeof(cell.name) !== 'undefined'
    && typeof(cell.familyName) !== 'undefined' ? cell.name + " " + cell.familyName: cell;
  }

  render(){
    const { barcode, description, type, created_date, user_who_receives,
      delivers_date, user_who_delivers, resident, status } = this.state ;
    return(
      <div className="Delivery">
        <Breadcrumb>
  	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item componentClass={Link} href="/delivery" to="/delivery">Encomiendas</Breadcrumb.Item>
  	      <Breadcrumb.Item active>Detalle</Breadcrumb.Item>
        </Breadcrumb>
      	<PageHeader>
          Encomienda <small>Detalle</small>
        </PageHeader>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <Form horizontal onSubmit={this.handleSubmit}>
  	        <FormGroup controlId="barcode">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Código de barra{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{barcode}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="description">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Descripción{' '}
  		      </Col>
  		      <Col sm={6}>
  		        <FormControl.Static>{description}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
  		    <FormGroup controlId="type">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Tipo{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.typeFormatter(type)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
          <FormGroup controlId="status">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Estado{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.statusFormatter(status)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="created_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Fecha de recepción{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(created_date)}</FormControl.Static>
  		  	  </Col>
  		    </FormGroup>
  		    <FormGroup controlId="user_who_receives">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Conserje recepción{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.userFormatter(user_who_receives)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="delivers_date">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Fecha entrega{' '}
  		      </Col>
  		      <Col sm={6}>
              <FormControl.Static>{this.dateFormatter(delivers_date)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="user_who_delivers">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Conserje entrega{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.userFormatter(user_who_delivers)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="resident">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Residente{' '}
  		      </Col>
  		      <Col sm={6}>
  		      	<FormControl.Static>{this.residentFormatter(resident)}</FormControl.Static>
  		      </Col>
  		    </FormGroup>
          <FormGroup controlId="resident_sign">
  		      <Col componentClass={ControlLabel} sm={6}>
  		        Firma{' '}
  		      </Col>
            {this.state.resident_has_sign?
  		      <Col sm={1}>
              <FormControl.Static>
              { this.state.sign ?
              <CanvasDraw
                disabled
                ref={canvasDraw => (this.loadableCanvas = canvasDraw)}
                canvasWidth={this.state.signProps.width}
                canvasHeight={this.state.signProps.height}
                brushRadius={this.state.signProps.brushRadius}
                lazyRadius={this.state.signProps.lazyRadius}
                saveData={this.state.sign}
                />
              :
              <LoaderButton
                block
                bsStyle="warning"
                onClick={this.handleGetSign}
                isLoading={this.state.isLoading}
                text={"Ver firma"}
                loadingText="Buscando…"
              />
              }
              </FormControl.Static>
  		      </Col>
            :
            <Col sm={6}>
              <FormControl.Static>
                  Sin firma
              </FormControl.Static>
            </Col>
            }
  		    </FormGroup>
          <FormGroup>
              <center className="detail-buttons">
                <Button bsStyle="primary" className="btnBackDeliveryLsit" onClick={this.handleBack}>
                  <Glyphicon glyph="chevron-left" /> Volver
                </Button>
              </center>
          </FormGroup>
  	    </Form>
      </div>
    );
  }
}
