import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert, ButtonGroup} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { validateFreeText } from '../../libs/validation';
import "./AssetsSumary.css"

export default class CrudModal extends Component {
  constructor(props) {
    super(props)

    this.elementsMap = {}
    if (props.elements) {
      props.elements.forEach(element => {
        this.elementsMap[element.id] = element
      })
    }
    this.state = {
      isLoading: false,
      showError: false,
      errorMsg: '',
      mode: 'LIST',
      elements: props.elements,
      selectedElement: null,
      elementName: '',
      elementStatus: '',
      showConfirm: false,
      showOk: false,
      okMsg: '',
      show: false
    }
    this.f = () => this.renderList()
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleCloseConfirm = () => {
    this.setState({
      showConfirm: false
    })
  }

  handleRadioChange = event => {
    this.setState({
      elementStatus: event.target.value
    })
  }

  invoke = async (f, onReturn, okMsg, errorMsg) => {
    this.setState({
      isLoading: true
    })
    let resp
    try {
      resp = await f(this.state)
      if (resp.code === 0) {
        this.elementsMap = onReturn(resp)
        this.setState({
          okMsg: okMsg,
          showOk: true
        })
      } else {
        throw new Error(errorMsg)
      }
    } catch (e) {
      console.error(e);
      this.setState({showError: true, errorMsg: errorMsg})
    } finally {
      this.setState({
        showConfirm: false,
        isLoading: false
      })
    }
  }

  deleteElement = () =>
    this.invoke(this.props.onDelete, this.props.onReturn, this.props.mode === 'ASSETS' ?
      `El espacio común ${this.state.selectedElement} fue eliminado exitosamente.` : `El acceso controlado ${this.state.selectedElement} fue eliminado exitosamente.`,
      this.props.mode === 'ASSETS' ? 'No se pudo eliminar el espacio común.' : 'No se pudo eliminar el acceso controlado.')

  addElement = async () => {
    this.normalize()
    let errors = this.getErrors()
    if (errors != null) {
      this.setState({
        errorMsg: errors,
        showError: true
      })
      return
    }

    this.invoke(this.props.onAdd, this.props.onReturn, this.props.mode === 'ASSETS' ? `El espacio común fue agregado exitosamente.` :
    'El acceso controlado fue agregado exitosamente.', this.props.mode === 'ASSETS' ? 'No se pudo agregar el espacio común.' : 'No se pudo agregar el acceso controlado.')
  }

  modifyElement = async () => {
    this.normalize()
    let errors = this.getErrors()
    if (errors != null) {
      this.setState({
        errorMsg: errors,
        showError: true
      })
      return
    }

    this.invoke(this.props.onModify, this.props.onReturn, this.props.mode === 'ASSETS' ? `El espacio común fue actualizado exitosamente.`: `El acceso controlado fue actualizado exitosamente.`,
    this.props.mode === 'ASSETS' ? 'No se pudo actualizar el espacio común.' : 'No se pudo actualizar el acceso controlado.')
  }

  handleClose = (event) => {
    this.setState({showError: false, showOk: false, showConfirm: false, show: false});
    this.fRenderList()
  }

  handleShow = (event) => {
    this.setState({ show: true });
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  getErrors = () => {
    let message = null;

    if (this.state.selectedElement.trim().length <= 0 || !validateFreeText(this.state.selectedElement, 1, 5)) {
      message = 'Debe ingresar un Id. válido';
      return message;
    }

    if (this.state.elementName.trim().length <= 0 || !validateFreeText(this.state.elementName, 1, 50)) {
      message = 'Debe ingresar un nombre válido';
      return message;
    }
    return message;
  }

  fRenderList = () => {
    this.f = this.renderList;
    this.setState({
      mode: 'LIST'
    })
  }

  normalize = () => {
    this.setState({
      elementName: this.state.elementName.trim(),
      selectedElement: this.state.selectedElement.trim()
    })
  }

  selectForDelete = (element) => {
    this.setState({showConfirm: true, selectedElement: element.id})
  }

  editElement = (elementId) => {
    const name = this.elementsMap[elementId].name;
    this.setState({
      selectedElement: elementId,
      elementName: name,
      mode: 'EDIT'
    })

    this.f = this.formElement
    this.setState({show: true})
  }

  showAddElement = (event) => {
    this.setState({
      mode: 'ADD',
      selectedElement: '',
      elementName: ''
    })
    this.f = this.formElement
  }

  formElement= () => {
    return <>
      {
        this.showOkErrorDialog()
      }
      <Button className="btn-warning" onClick={() => this.fRenderList()}>
        <Glyphicon glyph="chevron-left"><span style={{'fontFamily': 'Open Sans'}}>&nbsp;Volver</span></Glyphicon>
      </Button>
      <p></p>
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="selectedElement">
          <Col componentClass={ControlLabel} sm={4}>
            Id
          </Col>
          <Col sm={2}>
          <FormControl
              value={this.state.selectedElement === null ? '' : this.state.selectedElement}
              readOnly={this.state.mode === 'EDIT'}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Id</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="elementName">
          <Col componentClass={ControlLabel} sm={4}>
            Nombre del espacio (*)
          </Col>
          <Col sm={7}>
          <FormControl
              maxLength="100"
              onChange={this.handleChange}
              value={this.state.elementName}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Status</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
          <LoaderButton
              block
              onClick={() => this.state.mode === 'EDIT' ? this.modifyElement() : this.addElement()}
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={this.state.mode === 'EDIT' ? "Actualizar" : "Agregar"}
              loadingText={this.state.mode === 'EDIT' ? "Actualizando..." : "Agregando..."}
            />
      </Form>
      </>
  }

  promptDeleteElement = () => {
    return (
      <>
        <Modal
          show={this.state.showConfirm}
          onHide={this.handleCloseConfirm}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
            <Label color='red'><Icon name='building' /></Label>
            <Label color='red'>ELIMINACIÓN</Label> {this.props.mode === 'ASSETS' ? "Eliminar espacio común" : "Eliminar acceso controlado"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.props.mode === 'ASSETS' ? "¿Confirma que eliminará el espacio común " + this.state.selectedElement + "?" :
          "¿Confirma que eliminará el acceso controlado " + this.state.selectedElement + "?"}
          </Modal.Body>
          <Modal.Footer>
            <LoaderButton
              bsStyle="danger"
              isLoading={this.state.isLoading}
              onClick={this.deleteElement}
              text="Eliminar"
              loadingText="Eliminando…"/>
            <Button disabled={this.state.isLoading} onClick={this.handleCloseConfirm}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  renderList = () => {
    const l = []
    Object.entries(this.elementsMap).forEach((v) => {
      const element = this.elementsMap[v[0]]
      const c = (
        <tbody key={"tbody_elements_id_" + element.id}>
          <tr key={"tr_elements_id_" + element.id}>
            <td>{element.id}</td>
            <td>{element.name}</td>
            <td>
              <ButtonGroup>
              <Button className="btn-warning" onClick={() => this.editElement(element.id)}>
                <Glyphicon glyph="edit"></Glyphicon>
              </Button>
              <Button className="btn-danger" onClick={() => this.selectForDelete(element)}>
                <Glyphicon glyph="trash"></Glyphicon>
              </Button>
              </ButtonGroup></td>
          </tr>
        </tbody>
      )
      l.push(c)
    })
    return (
      <>
      {
        this.showOkErrorDialog()
      }
      <table>
        <thead>
          <tr>
            <th className="text-center">Id</th>
            <th className="text-center">Nombre</th>
            <th className="text-center">Acción</th>
        </tr>
        </thead>
        {l}
      </table>
      <br></br>
      <Button bsStyle="primary" onClick={this.showAddElement}>
        <Glyphicon glyph="plus-sign"/>&nbsp;Agregar</Button>
      {this.promptDeleteElement()}
    </>
    )
  }

  showOkErrorDialog = () => {
    return ((this.state.showOk || this.state.showError) &&
      <Alert bsStyle={this.state.showOk ? "info" : "danger"} onDismiss={() => this.setState({showOk: false, showError: false})}>
        {this.state.showOk ? this.state.okMsg : this.state.errorMsg}
      </Alert>)
  }

  render() {
    return (
    <div className="accessBtn">
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' /></Label>&nbsp;{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.f()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
