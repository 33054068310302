import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Pet.css";
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button } from "react-bootstrap";
import ListPetSummary from "./ListPetSummary";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import PetExport from "./PetExport";

export default class PetSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      pets: [],
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      size: '#',
      runJoy: false,
      steps: [
        {
          target: '.Pet .pets',
          content: 'En este listado se muestran las mascotas de todo el condominio',
          placement: 'bottom',
        },
        {
          target: '.Pet .table .filter',
          content: 'Puedes filtrar ingresando un texto aquí o en cualquiera de los campos adyacentes',
          placement: 'bottom',
        }
      ]
    };
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertSuccess: true, showAlertError: false };
    if (message.refresh) {
      this.setState({ ...newState, isLoading: true });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true, size: '#' });
  }

  handleSize = (pets) => {
    this.setState({pets: pets, size: pets.length});
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy, size } = this.state;
    const pending = this.props.condo && this.props.condo.payment && this.props.condo.payment.pending;
    return (
      <div className="Pet">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item componentClass={Link} href="/summary" to="/summary">Resumen</Breadcrumb.Item>
	      <Breadcrumb.Item active>Mascotas</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
      <PageHeader>
        <span className="pheader">({size}) Mascotas del condominio</span>
        <ButtonGroup className="headerBtn">
          <Button onClick={this.handleUpdate} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
          { !pending &&
            <PetExport data={this.state.pets} petSize={this.props.petSize}/>
          }
        </ButtonGroup>
      </PageHeader>
        {
        	this.state.showAlertSuccess &&
        	<Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        {
        	this.state.showAlertError &&
        	<Alert bsStyle="danger" onDismiss={this.handleDismissError}>
        	  <strong>{this.state.alertMessage}</strong>
        	</Alert>
        }
        <ListPetSummary {...this.props} onHandleSize={this.handleSize} isLoading={this.state.isLoading} onEndUpdate={this.handleEndUpdate} />
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	    />
      </div>
    );
  }

}
