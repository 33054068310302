import ReactDOM from 'react-dom';
import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Row, Col, Alert } from "react-bootstrap";
import { Radio } from 'semantic-ui-react';
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label } from 'semantic-ui-react';
import { invokeApig } from "../../libs/awsLib";
import {validateEmail, validateText, validateFreeText, formatRut, validateRut,
  validatePassport, cleanRut, cleanRutFromReader, validatePhone} from '../../libs/validation';
import Webcam from "react-webcam";
import config from "../../config";
import './NewResident.css';
import {DelayInput} from 'react-delay-input';
import { Link } from "react-router-dom";

export class NewResident extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isLoadEdit: false,
      isEdit: props.isEdit,
      show: false,
      card_id:'',
      card_id_type: 'RUT',
      name:'',
      lastname: '',
      email: '',
      phone: '',
      special_condition: '',
      is_owner: false,
      showError: false,
      errorMessage: '',
      resident_picture: '',
      isSearching: false
    };
  }

  validateForm() {
	  return this.state.email.length > 0 && this.state.name.length > 0;
  }

  isRut(){return this.state.card_id_type === 'RUT';}

  handleChangeRadio = (e, { value }) => this.setState({ card_id_type: value });

  handleChange = event => {

    if (event.target.id === "card_id") {
      var card_id_value = cleanRutFromReader(event.target.value)
      this.setState({
          [event.target.id]: this.isRut() ? formatRut(card_id_value): card_id_value,
      });
      return;
    }

    if (event.target.id === "is_owner") {
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onKeyPress = event => {
    if (event.which === 13) {
      event.preventDefault();

      this.searchPerson();

      let nameInput = ReactDOM.findDOMNode(this.refs.nameInput);
      if (nameInput && nameInput.focus instanceof Function) {
          nameInput.focus();
      }
    }
  }

  onBlur = event => {
    this.searchPerson();
  }

  searchPerson = async event => {
    const card_id = this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id;
    const previuos_card_id = this.state.previuos_card_id;

    if (card_id.length < 7) return;
    if (card_id === previuos_card_id) return;

    this.setState({ isSearching: true, person_found: 1,
      lastname_placeholder: 'Buscando...', name_placeholder: 'Buscando...',
      lastname: '', name: '' });
    const response = await this.getPerson(card_id);

    this.setState({person_found: response.code})
    if (response.code === 0) {
      this.setState({
        name: response.person.name,
        lastname: response.person.lastname
      })
    }

    this.setState({ isSearching: false, previuos_card_id: card_id, name_placeholder: '', lastname_placeholder: '' });
  }

  createResident(resident) {
    resident.unitId = this.props.selectedUnit["unit"];
    return invokeApig({
      path: "/resident",
      method: "POST",
      body: resident
    });
  }

  updateResident(resident) {
    return invokeApig({
      path: `/user`,
      method: "PUT",
      body: resident
    });
  }

  getPerson(card_id) {
      return invokeApig({
          path: "/person/" + card_id,
          method: "GET"
      });
  }

  handleClose = () => {
    this.setState({ show: false, showError: false, errorMessage: '' });
    this.setState({ card_id: '', card_id_type: 'RUT', name: '', lastname: '',
      email: '', phone: '', is_owner: false, person_found: 1, special_condition: '',
      isSearching: false, resident_picture: '', previuos_card_id: '' });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  getErrors() {
    let message = undefined;
    if (this.isRut() && !validateRut(this.state.card_id)) {
      message = 'Debe ingresar un RUT válido';
      return message;
    } else if (!this.isRut() && !validatePassport(this.state.card_id)) {
      message = 'Debe ingresar un id válido';
      return message;
    }

    if (!validateText(this.state.name)) {
      message = 'Debe ingresar nombre';
      return message;
    }

    if (!validateText(this.state.lastname)) {
      message = 'Debe ingresar apellido';
      return message;
    }

    if (this.state.email && !validateEmail(this.state.email)) {
      message = 'Debe ingresar email válido';
      return message;
    }

    if (this.state.phone && !validatePhone(this.state.phone)) {
      message = 'Debe ingresar teléfono válido (Formato: +56)';
      return message;
    }

    if (this.state.special_condition && !validateFreeText(this.state.special_condition)) {
      message = 'Debe ingresar condición especial';
      return message;
    }

    return message;
  }

  resetForm = () => {
    this.setState({ email:'', name:'', lastname: '', resident_picture: '',
      is_owner: false, card_id: '', showError: false, person_found: 1, special_condition: '',
      isSearching: false, errorMessage: '', previuos_card_id: ''
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const resident = {
        card_id: this.isRut() ? cleanRut(this.state.card_id) : this.state.card_id,
        card_id_type: this.state.card_id_type,
        name: this.state.name,
        lastname: this.state.lastname,
        email: this.state.email ? this.state.email : '-',
        phone: this.state.phone ? this.state.phone : '-',
        special_condition: this.state.special_condition ? this.state.special_condition : '-',
        is_owner: this.state.is_owner
      };

      if (this.state.resident_picture) {
        resident["resident_picture_64"] = this.state.resident_picture;
      }

      const response = await this.createResident(resident);
      if (response.code !== 0) {
        this.props.onAlertError({ message: "Error al registrar residente." });
      } else {
        this.props.reloadResidents();
        this.resetForm();
        this.props.onAlertSuccess({ message: "Residente ingresado exitosamente!" }, resident);
      }

      this.handleClose();
    } catch (e) {
      this.props.onAlertError({ message: "Error al registrar residente! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  capture = () => {
     const resident_picture = this.webcam.getScreenshot();
     this.setState({ resident_picture });
  }
  renderFormNew() {
    return(
	  <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="card_id">
          <Col componentClass={ControlLabel} sm={3}>
          <Radio
            label='RUT'
            name='radioGroup'
            value='RUT'
            checked={this.state.card_id_type === 'RUT'}
            onChange={this.handleChangeRadio}
          />
          <Radio
            label='Otro'
            name='radioGroup'
            value='OTHER'
            checked={this.state.card_id_type === 'OTHER'}
            onChange={this.handleChangeRadio}
          />
          </Col>
          <Col sm={7}>
              <DelayInput
                leadingNotify={true}
                trailingNotify={true}
                minLength={0}
                autoFocus={true}
                delayTimeout={100}
                placeholder="Rut u otro"
                onKeyPress={this.onKeyPress}
                onBlur={this.onBlur}
                id="card_id"
                className="form-control"
                value={this.state.card_id}
                onChange={this.handleChange} />
          </Col>
          <Col sm={1}>
            { this.state.isSearching &&
              <Icon loading color='blue' name='spinner' size='large'/>
            }
            { this.state.person_found === 0 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona encontrada</Tooltip>}>
                <Icon color='green' name='user' size='large' />
              </OverlayTrigger>
            }
            { this.state.person_found === 404 &&
              <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Persona no encontrada. Debe registrar datos</Tooltip>}>
                <Icon color='red' name='user times' size='large'/>
              </OverlayTrigger>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">RUT u otra identificación</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={3}>
            Nombre (*)
          </Col>
          <Col sm={8}>
            <FormControl
              ref="nameInput"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="ej: Diego"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Nombre.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="lastname">
          <Col componentClass={ControlLabel} sm={3}>
            Apellido (*)
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.lastname}
              placeholder="ej: Salas"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Apellido.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="email">
          <Col componentClass={ControlLabel} sm={3}>
            Email
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.email}
              placeholder="residente@condominio.com (opcional)"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Email.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="phone">
          <Col componentClass={ControlLabel} sm={3}>
            Teléfono
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.phone}
              placeholder="+56981000000 (opcional)"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Teléfono.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="special_condition">
          <Col componentClass={ControlLabel} sm={3}>
            Condición especial
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.special_condition}
              placeholder="ej: Movilidad reducida"
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Condición especial del residente.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="is_owner">
          <Col componentClass={ControlLabel} sm={3}>
            Es dueño?
          </Col>
          <Col sm={1}>
            <FormControl
              onChange={this.handleChange}
              type='checkbox'
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Es dueño?</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {this.props.condo && this.props.condo.picture.resident &&
        <FormGroup controlId="resident_picture">
          <Col componentClass={ControlLabel} sm={3}>
            Foto residente
          </Col>
          <Col sm={4}>
            <Webcam
              audio={false}
              height={config.picture.HEIGHT_AVATAR}
              width={config.picture.WIDTH_AVATAR}
              screenshotFormat={config.picture.FORMAT}
              videoConstraints={config.picture.CONSTRAINS_AVATAR}
            />
          </Col>
          <Col sm={4}>
            <Row>
              <Col md={12}>
                <Button onClick={this.capture}><Icon name="camera" /> Tomar foto</Button>
              </Col>
            </Row>
            {this.state.resident_picture &&
            <React.Fragment>
              <Row>
              <Col md={12}>
                <p className="resident_picture_previous_text">Previa:</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <img className="resident_picture_previous" alt="Previa" src={this.state.resident_picture} />
              </Col>
            </Row>
            </React.Fragment>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Foto de la mascota.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        }
        <FormGroup>
          <p className="psmall">
          <b>Nota:</b> Si residente se registra con correo, informarle que recibirá un link de verificación, al cual debe acceder. Una vez verificado el correo podrá recibir información de encomiendas, visitas y reservas.
          </p>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={"Crear"}
              loadingText="Guardando…"
            />
      </Form>
    );
  }

  render() {
    return (
      <div className="residentBtn">
	    <Button bsStyle="primary" onClick={this.handleShow}><Glyphicon glyph="user"/> Registrar residente</Button>
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos del residente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {this.props.condo && this.props.condo.picture.resident &&
      <Modal className="resident_cam_modal" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Webcam
          className="resident_cam"
          audio={false}
          height={config.picture.HEIGHT}
          width={config.picture.WIDTH}
          ref={node => this.webcam = node}
          screenshotFormat={config.picture.FORMAT}
          screenshotQuality={config.picture.QUALITY_HIGH}
          videoConstraints={config.picture.CONSTRAINS}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      }
      </div>
    );
  }
}

export default NewResident
