import React, { Component } from "react";
import { Link } from "react-router-dom";
import { invokeApig } from '../../libs/awsLib';
import {arrayRemove} from '../../libs/arrays';
import { Breadcrumb, Alert, PageHeader, Glyphicon, ButtonGroup, Button, Modal,
  Form, FormGroup, FormControl, ControlLabel,
  Tooltip, OverlayTrigger, Col } from "react-bootstrap";
import { Icon, Label } from 'semantic-ui-react'
import Select from 'react-select';
import LoaderButton from "../../components/LoaderButton";
import Joyride, {CallBackProps, STATUS} from 'react-joyride';
import Scheduler, {SchedulerData, ViewTypes, CellUnits, DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import "./Booking.css";
import moment from 'moment'
import 'moment/locale/es';
import withDragDropContext from './withDnDContext'

class Booking extends Component {
  constructor(props) {
    super(props);

    moment.locale('es');
    let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Custom, false, false, {

        customCellWidth: 30,
        nonAgendaDayCellHeaderFormat: 'DD/MM|HH:mm',
        schedulerWidth: '100%',
        schedulerHeight: '100%',
        localeMoment: moment,
        dayStartFrom: 0,
        dayStopTo: 23,
        resourceName: 'Activos',
        crossResourceMove: false,
        checkConflict: true,
        views: [
            {viewName: 'Día', viewType: ViewTypes.Custom},
            {viewName: 'Semana', viewType: ViewTypes.Week},
            {viewName: 'Mes', viewType: ViewTypes.Month},
        ]
    }, {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime
    });


    this.state = {
      show: false,
      showInfo: false,
      showUpdate: false,
      showDelete: false,
      isLoading: false,
      isDeleting: false,
      showAlertSuccess: false,
      showAlertError: false,
      alertMessage: '',
      runJoy: false,
      newEvent: null,
      infoEvent: null,
      updateEvent: null,
      deleteEvent: null,
      selectedResident: null,
      startUpdated: '',
      endUpdated: '',
      payment_info: '',
      bookings: [],
      assets: [],
      today: null,
      viewModel: schedulerData,
      steps: [
        {
          target: '.scheduler',
          content: 'Para crear una reserva presione sobre el rango horario',
          placement: 'bottom',
        },
        {
          target: '.scheduler',
          content: 'Puedes ver el detalle posicionando el mouse sobre la reserva',
          placement: 'bottom',
        }
      ]
    };
  }

  componentDidMount() {
    this.findBooking();
  }

  titleFormatted() {
    return this.props.selectedUnit["unit"] + " "
      + this.state.selectedResident.name + " "
      + this.state.selectedResident.lastname;
  }

  getCustomDate = (schedulerData, num, date = undefined) => {
    var selectDate = schedulerData.startDate;

    if(date !== undefined)
        selectDate = date;

    selectDate = schedulerData.localeMoment(selectDate).minute(0).second(0);

    let startDate = selectDate,
        endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
        cellUnit = CellUnits.Hour;
    if(num === 1) {
        startDate = schedulerData.startDate;
        endDate = schedulerData.localeMoment(schedulerData.endDate).add(1, 'days').format(DATE_FORMAT);
    } else if(num === -1) {
        startDate = schedulerData.localeMoment(schedulerData.startDate).add(-1, 'days').format(DATE_FORMAT);
        endDate = schedulerData.endDate;
    }

    return {
        startDate,
        endDate,
        cellUnit
    };
}

isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if(schedulerData.cellUnit === CellUnits.Hour){
        let hour = localeMoment(time).hour();
        if(hour < 1)
            return true;
    }
    else {
        let dayOfWeek = localeMoment(time).weekday();
        if (dayOfWeek === 0 || dayOfWeek === 6)
            return true;
    }

    return false;
}

  userFormatter = (resident) => {
    return resident
    && typeof(resident.name) !== 'undefined'
    && typeof(resident.familyName) !== 'undefined' ? resident.name + " " + resident.familyName: "";
  }

  findBooking = async () => {
    this.setState({ isLoading: true, assets: null, today: new moment().format(DATE_FORMAT) });
    this.state.viewModel.setResources([]);
    this.state.viewModel.setEvents([]);

    try {
        const bookingsResult = await this.bookings();
        bookingsResult.bookings.forEach(this.checkOldEnvent);

        this.state.viewModel.setResources(bookingsResult.assets)
        this.state.viewModel.setEvents(bookingsResult.bookings);

        this.setState({ assets: bookingsResult.assets });
        this.setState({ bookings: bookingsResult.bookings });
    } catch (e) {
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  checkOldEnvent = value => {
    if (value.end < this.state.today) {
        value.bgColor = '#D9D9D9';
        value.resizable = false;
        value.movable = false;
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleResidentChange = selectedOption => {
    const option = selectedOption ? selectedOption.value : null;
    this.setState({ selectedResident: option, showError: false });
  };

  handleDelete = async event => {
    event.preventDefault();
    this.setState({ isDeleting: true });
    try {
      const response = await this.deleteBooking();
      if (response.code !== 0) {
        this.handleAlertError({ message: "Error al eliminar reserva!" });
      } else {
        this.handleAlertSuccess({ message: "Reserva eliminada correctamente!" });

        const bookings = arrayRemove(this.state.bookings, this.state.deleteEvent)
        this.state.viewModel.setEvents(bookings);
      }
      this.handleClose();
    } catch (e) {
      this.setState({ isDeleting: false, showDelete: false, showAlertError: true, alertMessage: "Error al eliminar reserva!" });
    }
  }

  handleSubmitBooking = async event => {
    event.preventDefault();

    if (!this.state.selectedResident) {
      this.setState({showError: true, errorMessage: "Seleccione residente que reserva!"});
      return;
    }

    this.setState({ isLoading: true, assets: null });
    try {
      this.state.newEvent.title = this.titleFormatted();
      this.state.newEvent.resident = this.state.selectedResident;
      this.state.newEvent.payment_info = this.state.payment_info ? this.state.payment_info : '-';

      const booking = this.state.newEvent;
      const result = await this.createBooking(booking);

      if (result.code !== 0) {
        this.handleAlertError({ message: "Error al crear reserva!." });
      } else {
        this.state.viewModel.addEvent(booking);
        this.state.bookings.push(booking);
        this.handleAlertSuccess({ message: "Reserva realizada exitosamente!" });
      }
      this.handleClose();
    } catch (e) {
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  handleUpdateBooking = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, assets: null });
    try {
      const booking = this.state.updateEvent;
      booking.start = this.state.startUpdated;
      booking.end = this.state.endUpdated;
      const result = await this.updateBooking(booking);

      if (result.code !== 0) {
        this.handleAlertError({ message: "Error al modificar reserva!." });
      } else {
        // this.state.viewModel.moveEvent(event, slotId, slotName, start, end);
        this.state.viewModel.updateEventStart(booking, booking.start);
        this.state.viewModel.updateEventEnd(booking, booking.end);
        this.state.bookings = this.state.viewModel.events;

        this.handleAlertSuccess({ message: "Reserva modificada exitosamente!" });
      }
      this.handleClose();
    } catch (e) {
      this.setState({isError: true});
    }
    this.setState({ isLoading: false });
  }

  deleteBooking() {
    return invokeApig({
      path: "/booking",
      method: "DELETE",
      body: {
        "id": this.state.deleteEvent.id,
        "resourceId": this.state.deleteEvent.resourceId,
      }
    });
  }

  bookings() {
    var start = new moment();
    start.set({hour:0,minute:0,second:0,millisecond:0})
    start.subtract(5, 'day');
    return invokeApig({
      path: "/booking/find",
      method: "POST",
      body: {
        "start": start.format(DATETIME_FORMAT)
      }
    });
  }

  createBooking(booking) {
    booking.unit = this.props.selectedUnit["unit"];
    return invokeApig({
      path: "/booking",
      method: "POST",
      body: booking
    });
  }

  updateBooking(booking) {
    return invokeApig({
      path: "/booking",
      method: "PUT",
      body: booking
    });
  }

  handleAlertSuccess = (message) => {
    var newState = { alertMessage: message.message, showAlertError: false };
    this.setState({showAlertSuccess:true},()=>{
        window.setTimeout(()=>{this.setState({showAlertSuccess:false})
      }, 7000)
    });

    if (message.refresh) {
      this.setState({ ...newState, isLoading: true,  });
    } else {
      this.setState(newState);
    }
  }

  handleDismissSuccess = () => {
	  this.setState({ showAlertSuccess: false });
  }

  handleAlertError = (message, closable) => {
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false });
    this.setState({ alertMessage: message.message, showAlertError: true, showAlertSuccess: false },()=>{
      if (closable) {
        window.setTimeout(()=>{this.setState({showAlertError:false})}, 3000)
      }
    });
  }

  handleDismissError = () => {
	  this.setState({ showAlertError: false, showError: false });
  }

  handleUpdate = () => {
    this.setState({ isLoading: true });
  }

  handleEndUpdate = () => {
    this.setState({ isLoading: false });
  }

  handleHelp = () => {
    this.setState({ runJoy: true });
  }

  prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.state.bookings);
        this.setState({
            viewModel: schedulerData
        })
  }

  nextClick = (schedulerData)=> {
      schedulerData.next();
      schedulerData.setEvents(this.state.bookings);
      this.setState({
          viewModel: schedulerData
      })
  }

  onViewChange = (schedulerData, view) => {
      schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
      schedulerData.setEvents(this.state.bookings);
      this.setState({
          viewModel: schedulerData
      })
  }

  onSelectDate = (schedulerData, date) => {
      schedulerData.setDate(date);
      schedulerData.setEvents(this.state.bookings);
      this.setState({
          viewModel: schedulerData
      })
  }

  eventClicked = (schedulerData, event) => {
    this.setState({
        showInfo: true, infoEvent: event
    });
  };

  prepareDelete = (schedulerData, event) => { //prepare delete
    if (typeof(event.resizable) !== 'undefined' && !event.resizable &&
        typeof(event.movable) !== 'undefined' && !event.movable) {
      this.handleAlertError({ message: "No es posible eliminar reservas en fechas pasadas!" }, true);
      return;
    }

    this.setState({
        showDelete: true, deleteEvent: event
    });
  };

  ops2 = (schedulerData, event) => {
      alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
      if(!this.props.selectedUnit){
        this.handleAlertError({ message: "Debe seleccionar unidad" }, true);
        return;
      }

      const today = new moment().format(DATE_FORMAT)
      this.setState({ today: today });
      if(end < today){
        this.handleAlertError({ message: "No puede crear reservas en fechas pasadas!" }, true);
        return;
      }

      let newFreshId = 0; //TODO randomize
      schedulerData.events.forEach((item) => {
          if(item.id >= newFreshId)
              newFreshId = item.id + 1;
      });

      let newEvent = {
          id: newFreshId,
          start: start,
          end: end,
          resourceId: slotId,
          resourceName: slotName,
          bgColor: 'red'
      }

      this.setState({
          show: true, newEvent: newEvent
      });
  }

  updateEventStart = (schedulerData, event, newStart) => {
      if (event.start === newStart) {
        return;
      }

      this.setState({
          showUpdate: true, updateEvent: event, startUpdated: newStart, endUpdated: event.end
      });
  }

  updateEventEnd = (schedulerData, event, newEnd) => {
      if (event.end === newEnd) {
        return;
      }

      this.setState({
          showUpdate: true, updateEvent: event, startUpdated: event.start, endUpdated: newEnd
      });
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (event.start === start && event.end === end) {
      return;
    }

    this.setState({
        showUpdate: true, updateEvent: event, startUpdated: start, endUpdated: end
    });
  }

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
      if(schedulerData.viewType === ViewTypes.Custom) {
          schedulerData.next();
          schedulerData.setEvents(this.state.bookings);
          this.setState({
              viewModel: schedulerData
          });

          schedulerContent.scrollLeft = maxScrollLeft - 10;
      }
  }

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
      if(schedulerData.viewType === ViewTypes.Custom) {
          schedulerData.prev();
          schedulerData.setEvents(this.state.bookings);
          this.setState({
              viewModel: schedulerData
          });

          schedulerContent.scrollLeft = 10;
      }
  }

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
      console.log('onScrollTop');
  }

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
      console.log('onScrollBottom');
  }

  toggleExpandFunc = (schedulerData, slotId) => {
      schedulerData.toggleExpandStatus(slotId);
      this.setState({
          viewModel: schedulerData
      });
  }

  conflictOccurred = (schedulerData, action, event, type, slotId, slotName, start, end) => {
      // alert(`Conflict occurred. {action: ${action}, event: ${event}`);
      this.handleAlertError({ message: "Ya existe reserva en el tramo seleccionado" }, true);
  }

  handleClose = () => {
    this.setState({ show: false, showInfo: false, showDelete: false, showUpdate: false, showError: false, errorMessage: '',
      selectedResident: null, newEvent: null, deleteEvent: null, isDeleting: false, payment_info: '',
      updateEvent: null, startUpdated: '', endUpdated: '', infoEvent: null});
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ runJoy: false });
    }
  }

  render() {
    const { steps, runJoy, viewModel } = this.state;
    const residents = this.props.residents ?
      this.props.residents.map(v => ({
        label: v.name + " " + v.lastname + " ("+ v.card_id +")",
        value: v
      })) : [];

    return (
      <div className="Booking">
	  	<Breadcrumb>
	  	  <Breadcrumb.Item componentClass={Link} href="/" to="/">Home</Breadcrumb.Item>
	      <Breadcrumb.Item active>Reservas</Breadcrumb.Item>
        <Glyphicon className="help" glyph="info-sign" onClick={this.handleHelp} />
      </Breadcrumb>
      <PageHeader>
        <span className="pheader">Reserva de espacios comunes</span>
        <ButtonGroup>
          <Button onClick={this.findBooking} className="btnRefresh">
            <Glyphicon glyph="refresh" />
          </Button>
        </ButtonGroup>
      </PageHeader>
        {
          this.state.showAlertSuccess &&
          <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        {
          this.state.showAlertError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        }
        <Scheduler schedulerData={viewModel}
                   prevClick={this.prevClick}
                   nextClick={this.nextClick}
                   onSelectDate={this.onSelectDate}
                   onViewChange={this.onViewChange}
                   eventItemClick={this.eventClicked}
                   viewEventClick={this.prepareDelete}
                   viewEventText="Eliminar"
                   // viewEvent2Text="Ops 2"
                   // viewEvent2Click={this.ops2}
                   updateEventStart={this.updateEventStart}
                   updateEventEnd={this.updateEventEnd}
                   moveEvent={this.moveEvent}
                   newEvent={this.newEvent}
                   onScrollLeft={this.onScrollLeft}
                   onScrollRight={this.onScrollRight}
                   onScrollTop={this.onScrollTop}
                   onScrollBottom={this.onScrollBottom}
                   toggleExpandFunc={this.toggleExpandFunc}
                   conflictOccurred={this.conflictOccurred}
        />

        { this.state.isLoading &&
          <div><Glyphicon glyph="refresh" className="spinning" />Cargando datos... por favor espere</div>
        }
        <Modal show={this.state.showDelete} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
            { this.state.deleteEvent &&
            <Label color='red'><Icon name='building' />{this.state.deleteEvent.unit}</Label>
            }
            <Label color='red'>ELIMINACIÓN</Label> Eliminar reserva</Modal.Title>
          </Modal.Header>
          { this.state.deleteEvent &&
            <Modal.Body>¿Está seguro que quiere eliminar la reserva <Label color='red'><Icon name='trash' />{this.state.deleteEvent.title}</Label>?</Modal.Body>
          }
          <Modal.Footer>
            <LoaderButton bsStyle="danger" isLoading={this.state.isDeleting} onClick={this.handleDelete} text="Eliminar" loadingText="Eliminando…"/>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
              <Modal.Title><Label color='blue'><Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}</Label> Datos de la reserva</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.newEvent &&
              this.renderFormNew(residents)
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showUpdate} onHide={this.handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>
              { this.state.updateEvent &&
              <Label color='orange'><Icon name='building' />{this.state.updateEvent.unit}</Label>
              }
              <Label color='orange'>EDICIÓN</Label> Nuevas fechas de la reserva</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.updateEvent &&
              this.renderFormUpdate()
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showInfo} onHide={this.handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>
              { this.state.infoEvent &&
              <Label color='green'><Icon name='building' />{this.state.infoEvent.unit}</Label>
              }
              <Label color='green'>INFO</Label> Datos de la reserva</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.infoEvent &&
              this.renderFormInfo()
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Joyride
	        steps={steps}
	        run={runJoy}
        	continuous={true}
          callback={this.handleJoyrideCallback}
        	locale={{ back: 'Atrás', close: 'Cerrar', last: 'Finalizar', next: 'Siguiente', skip: 'Saltar' }}
	      />
      </div>
    );
  }

  renderFormUpdate(){
    return(
    <Form horizontal onSubmit={this.handleUpdateBooking}>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={5}>
            Unidad/Residente
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.updateEvent.title}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="start">
          <Col componentClass={ControlLabel} sm={5}>
            <Glyphicon glyph="time" /> Desde
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.startUpdated}</FormControl.Static>
            <del>{this.state.updateEvent.start}</del>
          </Col>
        </FormGroup>
        <FormGroup controlId="end">
          <Col componentClass={ControlLabel} sm={5}>
            <Glyphicon glyph="time" /> Hasta
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.endUpdated}</FormControl.Static>
            <del color="red">{this.state.updateEvent.end}</del>
          </Col>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="warning"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={"Modificar"}
              loadingText="Guardando…"
            />
      </Form>
    );
  }

  renderFormInfo(){
    return(
    <Form horizontal onSubmit={this.handleUpdateBooking}>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={5}>
            Unidad/Residente
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.infoEvent.title}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="start">
          <Col componentClass={ControlLabel} sm={5}>
            <Glyphicon glyph="time" /> Desde
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.infoEvent.start}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="end">
          <Col componentClass={ControlLabel} sm={5}>
            <Glyphicon glyph="time" /> Hasta
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.infoEvent.end}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={5}>
            Info. adicional
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.state.infoEvent.payment_info}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={5}>
            Conserje
          </Col>
          <Col sm={7}>
            <FormControl.Static>{this.userFormatter(this.state.infoEvent.user_who_creates)}</FormControl.Static>
          </Col>
        </FormGroup>
      </Form>
    );
  }

  renderFormNew(residents){
    return(
    <Form horizontal onSubmit={this.handleSubmitBooking}>
        <FormGroup controlId="room">
          <Col componentClass={ControlLabel} sm={3}>
            Lugar
          </Col>
          <Col sm={9}>
            <FormControl.Static>{this.state.newEvent.resourceName}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="start">
          <Col componentClass={ControlLabel} sm={3}>
            <Glyphicon glyph="time" /> Desde
          </Col>
          <Col sm={9}>
            <FormControl.Static>{this.state.newEvent.start}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="end">
          <Col componentClass={ControlLabel} sm={3}>
            <Glyphicon glyph="time" /> Hasta
          </Col>
          <Col sm={9}>
            <FormControl.Static>{this.state.newEvent.end}</FormControl.Static>
          </Col>
        </FormGroup>
        <FormGroup controlId="resident">
          <Col componentClass={ControlLabel} sm={3}>
            Residente (*)
          </Col>
          <Col sm={8}>
            { residents.length ?
              <Select
                onChange={this.handleResidentChange}
                options={residents}
                placeholder="Seleccione" />
              :
              <div>Unidad no tiene residentes.
              <Link to={{ pathname:"/resident" }}> Ingresar un residente aquí</Link>
              </div>
            }
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Corresponde al residente que recibe la encomienda.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="payment_info">
          <Col componentClass={ControlLabel} sm={3}>
            Info. adicional
          </Col>
          <Col sm={8}>
            <FormControl
            autoFocus={true}
	          value={this.state.payment_info}
	          placeholder="01234567890"
            onChange={this.handleChange}
	        />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Folio de pago si es requerido.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              disabled={!residents.length}
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={"Crear"}
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default withDragDropContext(Booking)
