import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Button, Glyphicon, Modal,
  Tooltip, OverlayTrigger, Col, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Icon, Label, Radio } from 'semantic-ui-react'
import { invokeApig } from "../../libs/awsLib";
import { validateTextAndNumber, validateFreeText2 } from '../../libs/validation';
import Autosuggest from '../../libs/autosuggest/Autosuggest';
import moment from 'moment'
import 'moment/locale/es';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import "./NewPenalty.css";

export class NewPenalty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      penalty_type: "PENALTY",
      send_email: false,
      selectedTime: new Date(),
      description: '',
      title: '',
      payment_info: '',
      isLoading: false,
      isLoadEdit: false,
      show: false,
      showError: false,
      errorMessage: '',
      value: '',
      suggestions: ['Ruidos molestos', 'Artículos sobre balcón', 'Basura', 'Mascota', 'Otro', 'Insultos', 'Fumar', 'Peleas']
    };
  }

  handleChangeTime = (time) => {
    if (time === null || time === "") {
      time = new Date();
    }
    this.setState({ selectedTime: time });
  }

  handleChange = event => {
    if (event.target.id === "send_email") {
      this.setState({
          [event.target.id]: event.target.checked,
      });
      return;
    }

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  createPenalty(penalty) {
    return invokeApig({
      path: "/penalty",
      method: "POST",
      body: penalty
    });
  }

  handleChangeRadio = (e, { value }) => this.setState({ penalty_type: value });

  onChangeTitle = (event) => {
    this.setState({
      title: event
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    // if (this.props.selectedUnit === null) {
    //   this.setState({
    //     showError: false, errorMessage: 'Selecione unidad'
    //   });
    // }

    this.resetForm();
    this.setState({ show: true });
  }

  resetForm = () => {
    this.setState({ payment_info: '', title: '', description: '', penalty_type: 'PENALTY',
      showError: false, errorMessage: '', selectedTime: new Date(), send_email: false,
    });
  }

  getErrors() {
    let message = undefined;

    if (!validateTextAndNumber(this.state.title)) {
      message = 'Debe ingresar motivo';
      return message;
    }

    if (!validateFreeText2(this.state.description)) {
      message = 'Debe ingresar descripción del evento';
      return message;
    }

    this.setState({showError: false});
    return message;
  }

  handleSubmit = async event => {
    event.preventDefault();
    const errorMsg = this.getErrors();
    if (errorMsg) {
      this.setState({showError: true, errorMessage: errorMsg});
      return;
    }
    this.setState({ isLoading: true });
    try {
      const selectedTimeMoment = moment(this.state.selectedTime);
      let time = selectedTimeMoment.local().format("HH:mm");
      let event_date = selectedTimeMoment.local().format("YYYY-MM-DD") + ' ' + time;

      const penalty = {
        unitId: this.props.selectedUnit["unit"],
        event_date: event_date,
        title: this.state.title,
        description: this.state.description,
        payment_info: this.state.payment_info,
        penalty_type: this.state.penalty_type
      };

      const response = await this.createPenalty(penalty);
      if (response.code !== 0) {
        let msgExtra = 'Por favor revise los datos e intente nuevamente.'
        this.props.onAlertError({ message: "Error al crear multa! " + msgExtra });
      } else {
        this.resetForm();
        this.props.onAlertSuccess({ message: "Multa ingresada exitosamente!", refresh: true }, penalty);
      }

      this.handleClose();
    } catch (e) {
      this.resetForm();
      this.handleClose();
      this.props.onAlertError({ message: "Error al registrar multa! Por favor revise los datos e intente nuevamente." });
    }
    this.setState({ isLoading: false });
  }

  handleDismissError = () => {
    this.setState({showError: false, errorMessage: ''});
  }

  render() {
    return (
      <div className="penaltyBtn">

      <Button bsStyle="primary" onClick={this.handleShow} disabled={this.props.selectedUnit === null}><Glyphicon glyph="user"/> Crear multa</Button>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Label color='blue'>
              <Icon name='building' />{this.props.selectedUnit && this.props.selectedUnit["unit"]}
            </Label> Datos de la multa
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFormNew()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }

  renderFormNew(){
    return(
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="card_id">
          <Col componentClass={ControlLabel} sm={3}>
            Tipo (*)
          </Col>
          <Col className="radioPenaltyType" componentClass={ControlLabel} sm={8}>
          <Radio
            label='Multa'
            name='radioGroup'
            value='PENALTY'
            checked={this.state.penalty_type === 'PENALTY'}
            onChange={this.handleChangeRadio}
          />
          <Radio
            label='Advertencia'
            name='radioGroup'
            value='WARNING'
            checked={this.state.penalty_type === 'WARNING'}
            onChange={this.handleChangeRadio}
          />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Tipo de multa.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="selectedTime">
          <Col componentClass={ControlLabel} sm={3}>
            Hora del evento (*)
          </Col>
          <Col sm={8}>
          <DatePicker
            className="datetime-picker  form-control"
            selected={this.state.selectedTime}
            onChange={this.handleChangeTime}
            maxDate={new Date()}
            showTimeSelect
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            timeFormat="HH:mm"
            timeCaption="Hora"
          />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Hora de ocurrido el evento que genera la multa.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="title">
          <Col componentClass={ControlLabel} sm={3}>
            Motivo (*)
          </Col>
          <Col sm={8}>
            <Autosuggest
              datalist={this.state.suggestions}
              placeholder="Ej: Ruidos molestos"
              onChange={this.onChangeTitle}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Motivo (Ruidos, basura, pelea, etc).</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="description">
          <Col componentClass={ControlLabel} sm={3}>
            Descripción (*)
          </Col>
          <Col sm={8}>
            <textarea
              id="description"
              className="form-control"
              rows="5"
              value={this.state.description}
              onChange={this.handleChange}
              maxLength="1000" />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">De que se trata la multa.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <FormGroup controlId="payment_info">
          <Col componentClass={ControlLabel} sm={3}>
            Folio
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={this.handleChange}
              value={this.state.payment_info}
            />
          </Col>
          <Col sm={1}>
            <OverlayTrigger overlay={<Tooltip id="modal-tooltip">Folio de cobro.</Tooltip>}>
              <Link to={{}}><Glyphicon className="info" glyph="info-sign"/></Link>
            </OverlayTrigger>
          </Col>
        </FormGroup>
        <p className="psmall-center"><b>Nota:</b> Se enviará copia por email a los residentes de la unidad.</p>
        {
          this.state.showError &&
          <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        }
        <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={this.state.isLoading}
              text={ "Crear" }
              loadingText="Guardando…"
            />
      </Form>
    );
  }
}

export default NewPenalty
